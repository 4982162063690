<template>
  <CommonCard>
    <div>
      <v-row style="padding: 30px;">
        <v-col cols="6">
          <span class="titulo-text">
            Cuotas
          </span>
        </v-col>
      </v-row>
      <v-row style="padding: 30px; padding-top: 0px;">
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <span class="text-header-table">
                      Edo de cuota
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="text-header-table">
                      Valor a pagar
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="text-header-table">
                      Fecha de pago acordado
                    </span>
                  </th>
                  <th class="text-center">
                    <span class="text-header-table">
                      Ver cuota
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in cuotas" :key="item.id">
                  <td class="text-center">
                    <span class="text-table">
                      {{ item.status }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-table">
                      {{ formatPrice(item.valorAPagar) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-table">
                      {{ getFormattDate(item.fechaPagoAcordada) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <a class="text-table" :href="'/visualizacion-comisiones/'+item.uuid"> Ver mas</a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </CommonCard>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import moment from "moment";

export default {
  components: {
    CommonCard,
  },
  props: {
    cuotas: Array,
  },
  data() {
    return {
      tab: 1,
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
        },
        {
          name: "Eclair",
          calories: 262,
        },
        {
          name: "Cupcake",
          calories: 305,
        },
        {
          name: "Gingerbread",
          calories: 356,
        },
        {
          name: "Jelly bean",
          calories: 375,
        },
        {
          name: "Lollipop",
          calories: 392,
        },
        {
          name: "Honeycomb",
          calories: 408,
        },
        {
          name: "Donut",
          calories: 452,
        },
        {
          name: "KitKat",
          calories: 518,
        },
      ],
    };
  },

  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    },
  },
};
</script>

<style>
.titulo-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  color: #4d5358;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text-header-table {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
}

.text-table {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
}
</style>
