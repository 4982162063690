<template>
  <div>
    <h2 class=" mt-5 mb-1">Datos de contacto</h2>
    <span>Ingresa tu número celular</span>
    <v-row class="mt-3">
      <v-col cols="12" md="6" class="mb-negative">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="contactInformation.tel"
              label="Celular"
              :rules="phoneRules"
              type="tel"
              maxlength="10"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="phoneRepeat">
            <v-alert type="warning" dense dismissible>
              El siguiente número de celular ya se encuentra registrado
              <ul>
                <li>{{ telRepeat }}</li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="mb-negative">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="contactInformation.phone"
              label="Teléfono"
              :rules="phoneRules"
              type="tel"
              outlined
              maxlength="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="telRepeat">
            <v-alert type="warning" dense dismissible>
              El siguiente número de teléfono ya se encuentra registrado
              <ul>
                <li>{{ phoneRepeat }}</li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <span
          >Ingresa el o los correos electrónicos y elige el predeterminado para
          contactarte</span
        >
      </v-col>
      <v-col cols="12" v-if="emailsRepeat.length > 0">
        <v-alert type="warning" dense dismissible>
          Los siguientes correos ya se encuentran registrados en la plataforma
          <ul v-for="(element, index) in emailsRepeat" :key="index">
            <li>{{ element }}</li>
          </ul>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6">
            <v-row
              v-for="(element, index) in contactInformation.emails"
              :key="index"
            >
              <v-col cols="1" class="d-flex mt-5 justify-center">
                <span class="number">{{ index + 1 }}°</span>
              </v-col>
              <v-col cols="8" sm="9">
                <CommonEmailAutocomplete
                @emailUpdate="(e) => (element.correo = e)">
                </CommonEmailAutocomplete>
              </v-col>
              <v-col cols="2" class="text-md-right">
                <v-btn
                  :color="element.favorito ? 'red' : 'gray'"
                  icon
                  @click="toggleFavorite(element)"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn
                  :disabled="contactInformation.emails.length === 1"
                  @click="isAddEmail(false, index)"
                  rounded
                  color="gray"
                  icon
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-col
              cols="12"
              class="text-center text-md-left"
              @click="isAddEmail(true, 0)"
            >
              <v-btn outlined color="#039ecc" icon rounded>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <span class="ml-3 inter-text ">Agregar Adicional </span>
            </v-col>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="d-flex justify-center justify-md-end">
        <v-btn
          color="#00A7E4"
          dark
          class="rounded-xl"
          @click="nextStep"
          :disabled="isButtonDisabled"
          width="150px"
        >
          Siguiente
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonEmailAutocomplete from '@/components/commonComponents/CommonEmailAutocomplete.vue';

export default {
  name: "DatosContacto",
  props: {
    contactInformation: Object,
    emailsRepeat: Array,
    phoneRepeat: String,
    telRepeat: String,
  },
  components:{
    CommonEmailAutocomplete
  },
  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      run: false,
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "Debe ser un correo electrónico válido",
      ],
      phoneRules: [
        (v) => !!v || "El número de teléfono es requerido",
        (v) => /^\d{10}$/.test(v) || "Debe ser un número de teléfono válido",
      ],
    };
  },
  methods: {
    isAddEmail(isAdd, index) {
      if (isAdd) {
        this.contactInformation.emails.push({ correo: "", favorito: false });
      } else {
        this.contactInformation.emails.splice(index, 1);
      }
    },
    nextStep() {
      this.$emit("isNextStep", true);
      window.dataLayer.push({
        event: "ITFY_datos_contacto",
        telefono: this.contactInformation.phone,
        correo: this.contactInformation.emails.filter((email) => email.favorito === true).map((email) => email.correo).join(''),
        boton_contacto: "Siguiente"
      })
    },
    toggleFavorite(element) {
      if (element.favorito) {
        element.favorito = false;
        this.contactInformation.emails[0].favorito = true;
        return;
      }
      this.contactInformation.emails.forEach(
        (emailObj) => (emailObj.favorito = false)
      );
      element.favorito = true;
    },
  },
  mounted() {
    window.dataLayer.push({
      event: 'ITFY_datos_contacto_v',
      no_cotizacion: undefined,
      userID: undefined,
      correo: undefined,
      telefono: undefined,
    });
  },
  computed: {
    isButtonDisabled() {
      return (
        this.contactInformation.emails.some(
          (emailObj) => !emailObj.correo || !/.+@.+\..+/.test(emailObj.correo)
        ) ||
        !this.contactInformation.phone ||
        !/^\d{10}$/.test(this.contactInformation.phone) ||
        !this.contactInformation.emails.length ||
        !this.contactInformation.tel ||
        !/^\d{10}$/.test(this.contactInformation.tel)
      );
    },
  },
  watch: {
    "contactInformation.emails": {
      handler(newValue) {
        const favoriteEmail = newValue.find((emailObj) => emailObj.favorito);
        this.contactInformation.correo = favoriteEmail
          ? favoriteEmail.correo
          : "";
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.mb-negative {
  margin-bottom: -30px;
}

.number {
  font-size: 16px;
  font-weight: bold;
}
.inter-text {
  color: #039ecc;
}
</style>
