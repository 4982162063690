<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Teléfono
        </span>
        <br />
        <span class="text-data-details">
          {{ datosSolicitud.celular ? datosSolicitud.celular : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Correo Electronico
        </span>
        <br />
        <span class="text-data-details">
          {{
            datosSolicitud.correoElectronico
              ? datosSolicitud.correoElectronico
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    datosSolicitud: Object,
  },
  methods: {},
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
