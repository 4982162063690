<template>
  <v-row no-gutters>
    <v-col cols="10">
      <v-autocomplete
        v-model="correo.correo"
        label="Correo electrónico"
        no-data-text="No se encontraron coincidencias"
        :items="correo.prediccionesCorreo"
        :filter="correo.filtroCorreo(correo.busquedaCorreo)"
        :rules="[$rules.email, correosTools.validacion]"
        :readonly="soloLectura || !esEdicionYAgente"
        :search-input.sync="correo.busquedaCorreo"
        @keyup="updatePrediccionesCorreo()"
        @change="$emit('quitarDuplicados', correo.correo, index)"
        outlined
        dense
      ></v-autocomplete>
    </v-col>
    <v-col :cols="soloLectura ? 2 : 1">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :dark="correo.favorito"
            :disabled="favoritoFijo"
            tile
            elevation="0"
            block
            style="margin-top: 3px;"
            :color="correo.favorito ? '#00a7e4': '#ABB1B3'"
            v-on="on"
            @click="!correo.favorito && $emit('cambiarFavorito', index)"
            icon
          >
            <v-icon small>
              mdi-star
            </v-icon>
          </v-btn>
        </template>
        <span>Correo favorito</span>
      </v-tooltip>
    </v-col>
    <v-col v-if="!soloLectura" cols="1">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            tile
            elevation="0"
            block
            style="margin-top: 3px;"
            color='#ABB1B3'
            v-on="on"
            :disabled="inhabilitarBorrado || (favoritoFijo && correo.favorito)"
            @click="$emit('eliminarCorreo', index)"
            icon
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
        <span>Eliminar correo</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import {
  dominiosComunes,
  validarDominioComun,
} from "../../views/pages/emision/ValidacionDominiosComunes";

import {
  dominiosSegurify,
  validarDominioSegurify,
} from "./ValidacionDominiosSegurify";

export default {
  props: {
    correo: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    soloLectura: {
      type: Boolean,
      default: false,
    },
    inhabilitarBorrado: {
      type: Boolean,
      default: false,
    },
    favoritoFijo: {
      type: Boolean,
      default: false,
    },
    soloCorreosSegurify: {
      type: Boolean,
      default: false,
    },
    esEdicionYAgente: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      validarDominioComun,
    };
  },

  computed: {
    correosTools() {
      return {
        validacion: this.soloCorreosSegurify
          ? validarDominioSegurify
          : validarDominioComun,
        dominios: this.soloCorreosSegurify ? dominiosSegurify : dominiosComunes,
      };
    },
  },

  watch: {
    "correo.correo"(v) {
      this.$emit("correoModificado", v, this.index);
    },
  },

  methods: {
    updatePrediccionesCorreo() {
      if (!this.correo.busquedaCorreo) {
        this.correo.prediccionesCorreo = [];
        return;
      }

      if (this.correo.busquedaCorreo.includes("@")) {
        this.correo.prediccionesCorreo = [this.correo.busquedaCorreo];
        return;
      }

      this.correo.prediccionesCorreo = this.correosTools.dominios.map((d) => {
        return `${this.correo.busquedaCorreo}@${d}`;
      });
      return;
    },
  },
};
</script>
