<template>
  <div>
    <common-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-end">
            <v-btn icon v-if="isDialog" @click="closeModal">
                <v-icon> mdi-close</v-icon>
            </v-btn> 
          </v-col>
        </v-row>
        <v-row class="full-center-row">
          <v-col
            class="full-center-div"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <div style="padding-bottom: 20px;">
              <v-avatar color="primary" size="80px" style="cursor: pointer;">
                <span class="white--text">
                    {{
                    (
                        name.split(" ")[0][0] +
                        (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                    ).toUpperCase()
                    }}
                </span>
                </v-avatar>
            </div>
            <div @click="toPerfil()">
              <h2 style="color: #0070C0; padding-bottom: 3px; cursor: pointer;" >
                Mi Espacio
              </h2>
            </div>
            <div style="padding-bottom: 10%;">
              <span style="color: #81CFE6;">
                {{ correo }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="esAgente" no-gutters justify="center">
          <v-col
            cols="12"
            md="12"
            lg="6"
            v-for="(actionButton, index) in actionButtons"
            :key="index"
            class="mt-2"
          >
            <v-card
              class="action-button text-center"
              flat
              @click="actionButton.action()"
              v-if="rol == 'AGENTCC' && actionButton.text == 'Solicitudes' ? false : (rol == 'AGENTCC' && actionButton.text == 'Pólizas' ? false : true)"
            >
              <v-row no-gutters justify="center">
              <v-col cols="12">
                  <v-responsive
                    aspect-ratio="16/9"
                    class="text-center d-flex justify-center"
                  >
                    <svg-icon
                      :icon="actionButton.iconName"
                      style="width: 42px; margin: 0 auto"
                    ></svg-icon>
                  </v-responsive>
                  <span>{{ actionButton.text }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </common-card>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import CommonSVGIcon from "@/components/commonComponents/icons/CommonSVGIcon.vue";

export default {
  components: {
    CommonCard,
    "svg-icon": CommonSVGIcon,
  },

  props: {
    correo: {
      type: String,
    },
    esAgente: {
      type: Boolean,
      default: false,
    },
    isDialog:{
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      name: localStorage.agenteName,

    };
  },

  computed: {
    actionButtons() {
      return [
        {
          text: "Solicitudes",
          iconName: "solicitudes",
          action: () => {
            this.$router.push("/main-hub/1");
          },
        },
        {
          text: "Pólizas",
          iconName: "polizas",
          action: () => {
            this.$router.push("/main-hub/2");
          },
        },
        {
          text: "Perfil",
          iconName: "perfil",
          action: () => {
            if (this.$route.path != "/usuario/ver-micuenta")
              this.$router.push("/usuario/ver-micuenta");
          },
        },
      ];
    },
  },

  methods: {
    closeModal(){
      this.$emit('closeMenuModal')
    },
    toPerfil(){
      if(this.rol == 'PROSPECTO'){
        this.$router.push("/perfil/prospecto/")
      }else{
        this.$router.push("/usuario/ver-micuenta")
      }


    }
  },
};
</script>

<style scoped>
.common-botton {
  background-color: #00a7e4 !important;
}

.full-center-div {
  align-self: center;
  text-align: center;
}

.full-center-row {
  align-self: center;
  text-align: center;
  min-height: 22vh;
}

.action-button {
  cursor: pointer;
}
</style>
