<template>
  <div class="pt-5">
    <v-data-table
      :items="clientes"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin clientes para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
    <template v-slot:top>
      <v-row style="padding: 15px;">
        <v-col cols="12" md="8">
          <h2>Resultados de la búsqueda</h2>
        </v-col>
        <!-- <v-col cols="12" md="4" style="text-align: end;">
          <v-btn color="transparent" elevation="0" small>
            <span style="color:#0070C0;">Descargar</span>
            <v-icon small right class="mr-2" color="#0070C0">mdi-file-pdf-box</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
    </template> 
      <template #item.cumpleSla="{ item }">
        <span>{{ item.cumpleSla == 1 ? "Sí cumple" : "No cumple" }}</span>
      </template>
      <template #item.ver="{ item }">
        <v-btn icon x-small @click="verCliente(item.uuid)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    clientes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nombre",
          value: "nombreCliente",
        },
        {
          text: "Estatus",
          value: "status",
        },
        {
          text: "Agente",
          value: "nombreAgente",
        },
        {
          text: "Teléfono",
          value: "telefonoMovil",
        },
        {
          text: "Email",
          value: "correo",
        },
        {
          text: "Regimen",
          value: "regimen",
        },
        {
          text: "RFC",
          value: "rfc",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
    };
  },

  methods: {
    verCliente(id) {
      this.$router.push(`/detalles-cliente/${id}`);
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
    background-color: #f2fafc;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 20px;
}

</style>