<template>
    <v-expansion-panel-content>
      <v-row v-if="polizaInfo.categoriaSelected != 'DAÑOS' || (polizaInfo.categoriaSelected == 'DAÑOS' && typeof ramoData.isMascota == 'undefined')">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeTab')"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
      </v-row>
      <div>
        <FormAutos v-if="polizaInfo.categoriaSelected == 'AUTOS'" :polizaInfo="polizaInfo" :ramoData="ramoData"/>
        <ViewAhorro v-if="polizaInfo.categoriaSelected == 'AHORRO'" :polizaInfo="polizaInfo" :ramoData="ramoData"/>
        <ViewFianza v-if="polizaInfo.categoriaSelected == 'FIANZA'" :polizaInfo="polizaInfo" :ramoData="ramoData"/>
        <ViewVida v-if="polizaInfo.categoriaSelected == 'VIDA'" :polizaInfo="polizaInfo" :ramoData="ramoData"/>
        <ViewSalud v-if="polizaInfo.categoriaSelected == 'SALUD'" :polizaInfo="polizaInfo" :ramoData="ramoData"/>
        <ViewDanios v-if="polizaInfo.categoriaSelected == 'DAÑOS' && typeof ramoData.isMascota == 'undefined'" :polizaInfo="polizaInfo" :ramoData="ramoData"/>
        <ViewMascota v-if="polizaInfo.categoriaSelected == 'DAÑOS' && ramoData.isMascota" :polizaInfo="polizaInfo" :ramoData="ramoData"/>
      </div>
    </v-expansion-panel-content>
</template>

<script>
// import moment from "moment";
// import { mainAxios } from "@/mainAxios.js";
import FormAutos from "../PolizaDatosGenerales/RamosForm/RamosView/ViewAutos.vue"
import ViewAhorro from "../PolizaDatosGenerales/RamosForm/RamosView/ViewAhorro.vue"
import ViewDanios from "../PolizaDatosGenerales/RamosForm/RamosView/ViewDanios.vue"
import ViewFianza from "../PolizaDatosGenerales/RamosForm/RamosView/ViewFianza.vue"
import ViewVida from "../PolizaDatosGenerales/RamosForm/RamosView/ViewVida.vue"
import ViewSalud from "../PolizaDatosGenerales/RamosForm/RamosView/ViewSalud.vue"
import ViewMascota from "../PolizaDatosGenerales/RamosForm/RamosView/ViewMascota.vue"

export default {
  components: {
    FormAutos,
    ViewAhorro,
    ViewDanios,
    ViewFianza,
    ViewVida,
    ViewSalud,
    ViewMascota
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      agentes:[],
      campanias:[],
      productos:[]
    };
  },
  methods: {

  },
  mounted(){
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
