<template>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Tipo de portafolios
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.tipoPortafolios ? ramoData.tipoPortafolios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fumador
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.fumador ? ramoData.fumador : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Plazo comprometido
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.plazoComprometido ? ramoData.plazoComprometido : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Version
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.version ? ramoData.version : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Deducibilidad
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.deducibilidad ? ramoData.deducibilidad : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Revaluable con la inflación
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.revaluableInflacion ? ramoData.revaluableInflacion : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Valor del Plan
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.valorPlan ? ramoData.valorPlan : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Uso del Auto
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.usoAuto ? ramoData.usoAuto : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Monto de la inversión/Aportación
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.montoInversion ? ramoData.montoInversion : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },

  methods: {},
};
</script>
