<template>
    <v-dialog
      v-model="openDialog"
      max-width="400"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Nuevo correo
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="newEmail"
                label="Correo Electrónico"
                :items="prediccionesCorreo"
                :filter="filtroCorreo"
                :rules="emailRules"
                :append-icon="null"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
          <v-btn
            :disabled="!canSend"
            color="primary darken-1"
            text
            @click="addEmail"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import {
  dominiosComunes,
  validarDominioComun,
  
} from "../../../helpers/validacionDominiosComunes";

export default {
  props: {
    openDialog: {
      type: Boolean,
    },
  },
  components:{
    
  },

  data(){
    return{
      validarDominioComun,
      newEmail: '',
      busquedaCorreo: '',
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe de ser valido",
        validarDominioComun,
      ],
    }

  },
  computed: {
    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [""];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map((e) => `${this.busquedaCorreo}@${e}`);
    },
    canSend(){
      const email = this.newEmail;
      const regex = /.+@.+\..+/; // Expresión regular que verifica si el símbolo @ está presente
      
      return regex.test(email);
    }
  },
  methods:{
    closeDialog() {
      this.$emit('closeDialog')
    },
    addEmail(){
      this.$emit('addEmail', this.newEmail)
      this.newEmail = ''
      this.busquedaCorreo = ''
    },
    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },
  }
}
</script>