import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../mainService";


export const createClientePost = async (request) => {
  try {
    const response = await mainAxios.post(
      `/v1/cliente/insert`,
      request,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const getSolicitudByClienteID = async (cliente_id) => {
  try {
    const response = await mainAxios.get(
      `/v1/solicitud/cliente/${cliente_id}`,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const getClienteById = async (cliente_id) => {
  try {
    const response = await mainAxios.get(
      `/v1/cliente/id/${cliente_id}`,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};


export const createUpdateTelefonosForCliente = async (form) => {
  try {
    const response = await mainAxios.post(
      `/v1/telefono/agregar`,
      form,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
