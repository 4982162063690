import CommonCard from '@/components/commonComponents/CommonCard.vue';
<template>
  <v-row>
    <v-col cols="12">
      <common-card>
        <v-container>
          <v-row>
            <v-col cols="3" md="1">
              <img
                src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Group+270988654.png"
                alt=""
                height="50"
              />
            </v-col>
            <v-col cols="9">
              <h2>Cotiza Seguro de mascota</h2>
              <span>
                Encuentra tu seguro ideal
              </span>
            </v-col>
            <v-col cols="12">
              <h4>Datos de la mascota</h4>
            </v-col>
            <commond-form
              :request="initCotizacion"
              :onlyValidations="true"
              :run="run"
              @cancel="run = false"
              @afterValidation="cotizar()"
            >
              <template v-slot:data>
                <formulario-mascota
                  :speciesParent="species"
                  :initCotizacion="initCotizacion"
                />
              </template>
            </commond-form>

            <v-col cols="12" class="d-flex justify-center justify-md-end">
              <v-col cols="6" md="2">
                <v-btn
                  @click="run = true"
                  color="#00A7E4"
                  block
                  dark
                  class="rounded-xl"
                  type="submit"
                  :disabled="canCotizacion"
                >
                  Cotizar
                </v-btn>
              </v-col>
            </v-col>
            <v-col v-if="plans" cols="12">
              <v-container>
                <v-row v-if="aseguradoras.length > 0">
                  <v-col cols="10">
                    <h2 class="mb-2" id="planesMascota">
                      Escoge la aseguradora de preferencia
                    </h2>
                  </v-col>
                  <v-col cols="2" md="2">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="cotizacionGeneral"
                          item-text="text"
                          item-value="value"
                          label="Cotización general"
                          :onlyread="true"
                          v-tooltip="'Texto del tooltip'"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <span
                        >Este código te ayudará si quieres retomar el proceso de
                        contratación.</span
                      >
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <common-lista-aseguradoras
                      :items="aseguradoras"
                      :nameFunction="'goToPlanDetail'"
                      :tipoCotizacion="'Mascota'"
                      :cotizacionGeneral="cotizacionGeneral"
                      :isValid="isValid"
                      @goToPlanDetail="goToPlanDetail"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <!-- <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center justify-md-end p-0 mt-3"
                >
                  <v-col cols="3" md="2" lg="1">
                    <v-btn
                      block
                      dark
                      class="rounded-xl"
                      color="transparent"
                      icon
                    >
                      <v-row>
                        <v-col class="mb-20" cols="12">
                          <v-icon
                            class="d-inline d-md-none"
                            color="#029ECF"
                            left
                            size="x-small"
                          >
                            mdi-content-save
                          </v-icon>
                          <v-icon
                            class="d-none d-md-inline"
                            color="#029ECF"
                            left
                          >
                            mdi-content-save
                          </v-icon>
                        </v-col>
                        <v-col class="text-center" cols="12">
                          <span
                            class="d-inline d-md-none"
                            style="color: #029ECF!important; font-size: 0.6em;"
                            >Guardar</span
                          >
                          <span
                            class="d-none d-md-inline"
                            style="color: #029ECF!important"
                            >Guardar</span
                          >
                        </v-col>
                      </v-row>
                    </v-btn>
                  </v-col>
                  <v-col cols="3" md="2" lg="1">
                    <v-btn
                      block
                      dark
                      class="rounded-xl"
                      color="transparent"
                      icon
                    >
                      <v-row>
                        <v-col class="mb-20" cols="12">
                          <v-icon
                            class="d-inline d-md-none"
                            color="#029ECF"
                            left
                            size="x-small"
                          >
                            mdi-cloud-download
                          </v-icon>
                          <v-icon
                            class="d-none d-md-inline"
                            color="#029ECF"
                            left
                          >
                            mdi-cloud-download
                          </v-icon>
                        </v-col>
                        <v-col class="text-center" cols="12">
                          <span
                            class="d-inline d-md-none"
                            style="color: #029ECF!important; font-size: 0.6em;"
                            >Descargar</span
                          >
                          <span
                            class="d-none d-md-inline"
                            style="color: #029ECF!important"
                            >Descargar</span
                          >
                        </v-col>
                      </v-row>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="3" lg="3" xl="2">
                    <v-btn
                      style="widht: 100%; padding: 6px!important"
                      color="#00A7E4"
                      block
                      dark
                      class="rounded-xl"
                      @click="goConfirmacion"
                    >
                      <span
                        class="d-inline d-md-none"
                        style="font-size: 0.5em;"
                      >
                        Envíar cotización</span
                      >
                      <span class="d-none d-md-inline"> Envíar cotización</span>
                      <v-icon
                        class="d-inline d-md-none ml-1 "
                        left
                        size="x-small"
                      >
                        mdi-send
                      </v-icon>
                      <v-icon class="d-none d-md-inline ml-2 mb-9" left>
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row> -->
            </v-col>
          </v-row>
        </v-container>
      </common-card>
    </v-col>
    <commond-loading
      :show="loading"
      loadingText="Cargando..."
      :loadingImage="dataImage.iconPet"
    />
    <CommonSolicitudCreadaModal :dialog="dialog" @userChoice="changeView" />
    <CommonActionModal
      :dialog="modalSinVigencia"
      :title="titleModal"
      :subtitle="subtitle"
      :bottonText="bottonText"
      @userChoice="choiceInvalidDate"
    />
  </v-row>
</template>
<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import { mainAxios } from "../../../../mainAxios";
import FormularioMascota from "./FormularioMascota.vue";
import CommondLoading from "../../../../components/commonComponents/CommondLoading.vue";
import dataMascota from "../../../../helpers/data/data-loading-gif.json";
import CommonListaAseguradoras from "../../../../components/commonComponents/CommonListaAseguradoras.vue";
import moment from "moment";
import CommonSolicitudCreadaModal from "@/components/commonComponents/CommonSolicitudCreadaModal.vue";
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";

export default {
  name: "CotizacionMascota",
  components: {
    CommonCard,
    FormularioMascota,
    CommondLoading,
    CommonListaAseguradoras,
    CommonSolicitudCreadaModal,
    CommondForm,
    CommonActionModal
  },
  data() {
    return {
      pet: {
        type: "",
        name: "",
        ownerData: {
          name: "",
          surname: "",
          lastname: "",
          email: "",
          phone: "",
        },
      },
      initCotizacion: {
        correo: "",
        nombreDuexo: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        telefono: "",
        tipoMascota: {
          cod_especie: "1",
          txt_desc_especie: "PERRO",
        },
        tipoMascotaId: 0,
      },
      cotizacion: {},
      plans: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      species: [],
      loading: false,
      dataImage: dataMascota,
      aseguradoras: [],
      dialog: false,
      solicitudCreada: {},
      run: false,
      isRecotizacion: false,
      requestMascotaR: {},
      modalSinVigencia: false,
      titleModal: "",
      subtitle:'',
      bottonText: "",
      isValid: true,
      productos:[],
    };
  },
  methods: {
    async cotizar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      this.isRecotizacion = false;
      const dto = {
        ...this.initCotizacion,
        ...{ cotizacion_general: this.createCotizacionGeneral() },
      };

      dto.tipoMascotaId = this.initCotizacion.tipoMascota.cod_especie;
      delete dto.tipoMascota;
      this.loading = true;
      const resp = await mainAxios.post(
        "/v1/mascota/init-cotizacion",
        dto,
        config
      );

      if (resp.data.data.cotizacion.uuid) {
        this.cotizacion = resp.data.data.cotizacion;
        this.plans = this.cotizacion.response;
        this.isValid = true
        this.aseguradoras = this.plans.map((plan) => ({
          planName:plan.PlanCodigo == 1 ? 'Plan Mascota' :
                   plan.PlanCodigo == 2 ? 'Plan Garritas' :
                   plan.PlanCodigo == 3 ? 'Plan Mejor Amigo' : '',
          planId:plan.PlanCodigo,
          price: plan.PlanPrimaTotal,
          benefits: plan.PlanCoberturas.map(
            (cobertura) => cobertura.Descripcion
          ),
          logo:
            "https://todoseguros.com.mx/wp-content/uploads/2021/06/Seguros-GMX.jpg",
        }));
      }
      this.saveinStorage();
      this.$router.push({ hash: "#planesMascota" });
      const mascotaRequestData = JSON.parse(sessionStorage.getItem("mascotaRequest"))
      window.dataLayer.push({
        event: "ITFY_mascota_cotizar",
        parentesco: this?.cotizacion?.mascota?.tipoMascotaId == 2 ? "Gato" : "Perro",
        correo: mascotaRequestData?.requestInfo?.correo,
        telefono: mascotaRequestData?.requestInfo?.telefono,
        userID: localStorage.getItem("agenteMail"),
      });
      this.loading = false;
    },
    goConfirmacion() {
      this.$router.push({
        name: "confirmarDatosMascota",
        params: { mascota: this.pet },
      });
    },
    async initLoginInter() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const response = await mainAxios.get("/v1/mascota/init", config);
      sessionStorage.interToken = response.data.data.accessToken;
      return true;
    },
    getGender() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      this.loading = true;
      mainAxios.get("/v1/mascota/gender", config).then((response) => {
        console.log(response);
        this.loading = false;
      });
    },
    async getSpecies() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      const resp = await mainAxios.get("/v1/mascota/species", config);
      this.species = resp.data.data.species;
    },
    createsolicitud(item) {
      this.loading = true;
      const request = {
        //Datos generales de la solicitud
        agente: parseInt(this.initCotizacion.agenteId),
        agenteId: parseInt(this.initCotizacion.agenteId),
        idOrigenLead: 1,
        inicio: moment().format("YYYY-MM-DD"),
        fin: moment()
          .add(1, "year")
          .format("YYYY-MM-DD"),
        anios: "1",
        fechaPrimerPago: moment().format("YYYY-MM-DD"),
        campania: "Portal Asesores Digitales Daños",
        campania_id: 61,
        categoriaSelected: "DAÑOS",
        tipoSelected: "Emisión",
        detalle:
          "Cotización realizada mediante el portal de Interify, para el ramo daños y producto Mascota",
        //Fin datos generales
        //Datos del cliente
        nombres: this.initCotizacion.nombreDuexo,
        apellidoPaterno: this.initCotizacion.apellidoPaterno,
        apellidoMaterno: this.initCotizacion.apellidoMaterno,
        fechaNacimiento: moment().format("YYYY-MM-DD"),
        edad: "0",
        genero: "NA",
        correoElectronico: this.initCotizacion.correo,
        celular: this.initCotizacion.telefono,
        estadoCivilSelected: "Soltero(a)",
        rfc: "NA",
        codigoPostal: "NA",
        estadoRepublicaSelected: "NA",
        municipio: "NA",
        calle: "NA",
        numExterior: "NA",
        numInterior: "NA",
        colonia: "NA",
        nombre_cliente: "",
        regimen_persona: "Física",
        //fin datos del cliente
        //Keys que manejan estatus o datos menores que no tenemos
        origenSolicitudSelected: "Portal",
        comercial_asignado: null,
        actividadSolicitud: null,
        archivos: null,
        razon_social: null,
        numeroPoliza: null,
        solicitudCompletaSelected: false,
        checkPendienteInfo: false,
        checkCancelacion: false,
        causaPendienteInformacion: null,
        dateFormattedInicioVigencia: null,
        menufechasParaCotizar: false,
        polizaRenovar: null,
        fechaInicioVigenciaRenovar: null,
        fechaFinVigenciaRenovar: null,
        operadorUuid: null,
        //Fin Keys que manejan estatus o datos menores que no tenemos

        //Datos por definir
        producto_id: this.obtenerProductoParaSolicitud(item.planName, false),
        aseguradora_id: this.obtenerProductoParaSolicitud(item.planName, true),
        productoId: this.obtenerProductoParaSolicitud(item.planName, false),
        //fin datos por defenir

        //inicio datos por ramo
        inicioVigencia: moment().format("YYYY-MM-DD"),
        finVigencia: moment()
          .add(1, "year")
          .format("YYYY-MM-DD"),
        periodicidadPago: 1,
        formaPagoSelected: "Tarjeta de Crédito",
        tipoInmueble: "Otro",
        otroTipoInmueble: "Mascota",
        valorInmueble: item.price,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: "0",
        valorEquipoElectronico: "0",
        sumaAseguradaRC: "0",
        sumaAseguradaCristales: "0",
        sumaAseguradaRoboViolencia: "0",
        sumaAseguradaDineroValores: "0",
        sumaAseguradaObrasDeArte: "0",
        procesoCotizacionSelected: "Cotización",
        cotizacion_general: this.cotizacionGeneral,
      };
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post("v1/solicitud/insert/", request, config)
        .then((response) => {
          if (response.status == 200) {
            this.solicitudCreada = response.data;
            this.loading = false;
            this.dialog = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    changeView(val) {
      if (val)
        this.$router.push("/detalles-solicitud/" + this.solicitudCreada.id);
      else location.reload();
    },
    createCotizacionGeneral() {
      if (!this.isRecotizacion) {
        //const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result1 = "";
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
          result1 += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        this.cotizacionGeneral = result1;
        return this.cotizacionGeneral;
      } else return this.cotizacionGeneral;
    },
    jsonComparador(obj1, obj2) {
      if (typeof obj1 !== "object" || typeof obj2 !== "object") {
        return obj1 === obj2;
      }
      var keys1 = Object.keys(obj1);
      var keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (var key of keys1) {
        if (!this.jsonComparador(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    },
    saveinStorage() {
      if (sessionStorage.getItem("mascotaRequest")) {
        sessionStorage.removeItem("mascotaRequest");
      }
      sessionStorage.setItem(
        "mascotaRequest",
        JSON.stringify({
          requestInfo: this.initCotizacion,
          cotizacionGeneral: this.cotizacionGeneral,
          isRecotizacion: true,
          aseguradoras: this.aseguradoras,
        })
      );
    },
    checkVigencia() {
      var parsedDate = moment(this.requestMascotaR.fecha_cotizacion, "DD/MM/YYYY hh:mm:ss");
      var diff = moment().diff(parsedDate, "days");
      if (diff > 7) {
        this.modalSinVigencia = true;
        (this.titleModal = "Vigencia vencida"),
          (this.subtitle =
            "¡Las cotizaciones de Interify tienen vigencia de 7 días, pero no te preocupes puedes volver a cotizar con los mismos datos!"),
          (this.bottonText = "Recotizar");
        this.isValid = false
      }
    },
    async choiceInvalidDate(val){
      if(val){
        this.cotizar(true)
      }
      this.modalSinVigencia = false
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/producto/list', config).then(response => {
        response.data.map(element => {
          if(element.ramo == 'DAÑOS' && element.canal == 'MASIVOS')
          this.productos.push({
            nombre: element.nombre,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId
          })
        })
      });
    },
    obtenerProductoParaSolicitud(planName, aseguradora){
      if(!aseguradora)
        return this.productos.find((e)=> e.nombre == planName).id 
      else
        return this.productos.find((e)=> e.nombre == planName).aseguradoraId
    },
    goToPlanDetail(item){
      console.log({item})
      localStorage.setItem('CotizacionMascotaDetail', JSON.stringify(this.cotizacion))
      this.$router.push(`/mascota/cotizacion/details/${this.cotizacionGeneral}/${item.planId}`)
    }
  },
  async created() {
    this.obtenerProductos()
    this.loading = true;
    await this.initLoginInter();
    await this.getSpecies();
    this.loading = false;
    if (sessionStorage.getItem("mascotaRequest")) {
      const data = JSON.parse(sessionStorage.getItem("mascotaRequest"));
      this.requestMascotaR = data;
      this.initCotizacion = data.requestInfo;
      this.isRecotizacion = data.isRecotizacion;
      this.cotizacionGeneral = data.cotizacionGeneral;
      this.aseguradoras = data.aseguradoras;
      this.checkVigencia()
    }
  },
  computed: {
    canCotizacion() {
      if (this.isRecotizacion) {
        var data = JSON.parse(sessionStorage.getItem("mascotaRequest"));
        if (this.jsonComparador(this.initCotizacion, data.requestInfo))
          return true;
        else return false;
      } else return false;
    },
  },
  mounted () {
    window.dataLayer.push({
      event: 'ITFY_mascota_cotizar_v',
      no_cotizacion: undefined,
      userID: localStorage.agenteMail,
      correo: undefined,
      telefono: undefined,
    });
  }
};
</script>
<style scoped>
.mb-32 {
  margin-bottom: -32px;
}
.gray {
  color: #697077;
}
.mb-20 {
  margin-bottom: -20px;
}
.mb-9 {
  margin-top: 25px;
}
</style>
