<template>
  <div class="pt-5">
    <v-data-table
      :items="solicitudes"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin solicitudes para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
    <template v-slot:top>
      <v-row style="padding: 15px;">
        <v-col cols="12" md="8">
          <h2>Resultados de la búsqueda</h2>
        </v-col>
        <v-col cols="12" md="4" style="text-align: end;">
          <v-btn color="transparent" elevation="0" small>
            <span style="color:#0070C0;">Descargar</span>
            <v-icon small right class="mr-2" color="#0070C0">mdi-file-pdf-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template> 
      <template #item.cumpleSla="{ item }">
        <span>{{ item.cumpleSla == 1 ? "Sí cumple" : "No cumple" }}</span>
      </template>
      <template #item.ver="{ item }">
        <v-btn icon x-small @click="verSolicitud(item.id)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template #item.Seleccionar="{ item }">
        <v-btn icon x-small @click="seleccionarItem(item.id)">
          <v-icon>mdi-bullseye</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
    <CommonActionModal
      :dialog="dialog"
      :title="'¿Seleccionar solicitud?'"
      :subtitle="'Se vinculará la llamada a esta solicitud. ¿Es correcto?'"
      :bottonText="'Sí, seleccionala'"
      @userChoice="choiceSolicitud"
    />
  </div>
</template>

<script>
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";

export default {
  components: {
    CommonActionModal,
  },
  props: {
    solicitudes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isModal:{
      type:Boolean,
      default:false
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Número",
          value: "id",
        },
        {
          text: "Nombre de la Solicitud",
          value: "titulo",
        },
        {
          text: "Agente",
          value: "nombreAgente",
        },
        {
          text: "Estado",
          value: "status",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Categoría",
          value: "categoria",
        },
        {
          text: "Ejecutivo",
          value: "nombreEjecutivo",
        },
        {
          text: "Fecha de Creación",
          value: "fechaCreacion",
        },
        {
          text: "SLA",
          value: "cumpleSla",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
      solicitudSeeleccionada:'',
      dialog:false
    };
  },

  methods: {
    verSolicitud(id) {  
      if(!this.isModal)
        this.$router.push(`/detalles-solicitud/${id}`);
      else
        window.open(`/detalles-solicitud/${id}`)
    },
    seleccionarItem(id){
      this.solicitudSeeleccionada = id
      this.dialog = true
    },
    choiceSolicitud(val){
      this.dialog = false
      if(val)
        this.$emit('seleccionSolicitud', this.solicitudSeeleccionada)
      else
        this.solicitudSeeleccionada = ''
    }
  },
  mounted(){
    if(this.isModal){
      this.headers.push(
        {
          text: "Seleccionar",
          value: "Seleccionar",
        },
      )
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
    background-color: #f2fafc;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 20px;
}

</style>