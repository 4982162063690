<template>
  <div>
    <div>
      <v-row class="pt-5 pb-5">
        <v-col cols="10">
          <h2>
            Detalle de cliente / {{ clienteInfo.nombreCliente }}
          </h2>
        </v-col>
      </v-row>
    </div>
    <InfoCardCliente
      :infoCards="infoCards"
      :emailRoutesConfig="emailRoutesConfig"
      :clienteID="clienteInfo.id"
      @getData="obtnerDatosClientes"
    >
    </InfoCardCliente>
    <div>
      <v-row style="padding-top: 60px;">
        <v-col
          cols="4"
          :class="
            tabReplace == 1
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(1)"
        >
        <span
            :class="
              tabReplace == 1
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          > Cliente</span>
        </v-col>
        <v-col
          cols="4"
          :class="
            tabReplace == 2
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(2)"
        >
        <span
            :class="
              tabReplace == 2
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >  Pólizas </span>
        </v-col> 
        <v-col
          cols="4"
          :class="
            tabReplace == 3
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(3)"
        >
        <span
            :class="
              tabReplace == 3
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          > Solicitudes</span>
        </v-col>
      </v-row>
    </div>
    <div v-if="tabReplace == 1">
      <div style="padding-top: 60px;">
        <ClienteDataIndex
          @getData="obtnerDatosClientes"
          :clienteInfo="clienteInfo"
        ></ClienteDataIndex>
      </div>
      <div class="pt-5">
        <ClienteContactIndex
          @getData="realoadView"
          :clienteInfo="clienteInfo"
        ></ClienteContactIndex> 
      </div>
      <div class="pt-5">
        <FiscalDatatIndex
          @getData="obtnerDatosClientes"
          :clienteInfo="clienteInfo"
        ></FiscalDatatIndex> 
      </div>
      <div class="pt-5">
        <LogClienteIndex
          :clienteInfo="clienteInfo"
        ></LogClienteIndex> 
      </div>
      <EmbeddedCliente
          v-if="rol == 'MESACONTROLINBOUND' || rol == 'OPERADORINBOUND' || rol == 'OPERACIONESINBOUND' || rol == 'ADMIN'"
          :telefonos="telefonosCliente"
          :cliente_id="clienteInfo.id"
          :is_solicitud="true"
          @success="()=>{location.reload()}"
        />
    </div>
    <div v-if="tabReplace == 2">
      <div style="padding-top: 60px;">
        <EmisionClienteIndex
          :polizasCliente="polizasCliente"
          :clienteInfo="clienteInfo"
        ></EmisionClienteIndex>
      </div>
    </div>
    <div v-if="tabReplace == 3">
      <div style="padding-top: 60px;">
        <SolicitudClienteIndex
          :solicitudesCliente="solicitudesCliente"
        ></SolicitudClienteIndex>
      </div>
    </div>
    <div>
      <CommonCreateInboundCallsModal
      v-if="dialogInboundCalls"
      :dialog="dialogInboundCalls"
      @closeModal="dialogInboundCalls = false"
      :cliente="clienteInfo"
      @success="dialogInboundCalls = false"
    />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mainAxios } from "@/mainAxios.js";
import { getLlamadasInbound } from "@/services/campañasInbound/CampañasInbound.js";
import { getSolicitudByClienteID } from "@/services/cliente/Clientes.js";

export default {
  components: {
    ClienteDataIndex: () =>import("../Details/ClienteData/ClienteDataIndex.vue"),
    InfoCardCliente: () => import("./InfoCardCliente.vue"),
    ClienteContactIndex: () => import("../Details/ClienteContactData/ClienteContactIndex.vue"),
    FiscalDatatIndex: () => import("../Details/FiscalData/FiscalDatatIndex.vue"),
    LogClienteIndex: () => import("../Details/LogCliente/LogClienteIndex.vue"),
    EmisionClienteIndex: () => import("../Details/EmisionesClienteData/EmisionClienteIndex.vue"),
    SolicitudClienteIndex: () => import("../Details/SolicitudesClienteData/SolicitudClienteIndex.vue"),
    CommonCreateInboundCallsModal:()=>import('@/components/commonComponents/Inbound/InboundCalls/CommonCreateInboundCallsModal.vue'),
    EmbeddedCliente:()=>import('@/components/commonComponents//EmbeddedCliente.vue'),
  },
  data() {
    return {
      tabReplace: 1,
      roles: JSON.parse(localStorage.getItem("roles")),
      solicitud: [],
      correos: [],
      documentos: [],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
      tipoItems: ["Nota", "Documento"],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Contrato firmado del Agente",
        "Cotización",
        "Cédula del Agente",
        "Identificación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      uuid: this.$route.params.uuid,
      clienteInfo:{},
      agentes:[],
      emailRoutesConfig: {
        sendEmailRoute: "",
        action: "",
        optionCorreosList: [],
        destinatario: "",
      },
      infoCards: {
        notas: [],
        docs: [],
        emails: [],
        llamadas: []
      },
      polizasCliente:[],
      dialogInboundCalls:false,
      solicitudesCliente:[],
      telefonosCliente:[],
      rol: JSON.parse(localStorage.getItem('roles'))[0].rol
    };
  },
  methods: {
    obtnerDatosClientes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.correos = [];
      this.clientes = []; 
      this.bodyClientes = []; 
      this.documentos = [];

      mainAxios.get(`v1/cliente/uuid/${this.uuid}`, config).then((response) => {
        this.clienteInfo = response.data
        this.formatTelefonosCliente()
        this.formatNotasDocumentos(response.data.documentosCarga);
        this.formatCorreosInfoCard(response.data.correos);
        this.getPolizas();
        this.obtenerAgentes();
        this.getCorreosContacto();
        this.getLlamadasInbound();
        this.getSolicitudByClienteID();
      });
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
            id: element.id,
            uuid: element.uuid,
          });
        });

        this.agentes.map((element) => {
          this.agenteId == element.id ? (this.agente = element.nombre) : "";
        });
      });
    },
    getCorreosContacto(){
      this.clienteInfo.correosContacto = []
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/cliente/list/correos-contacto/`+this.clienteInfo.id, config).then((response) => {
        if(!response.data[0].error)
          this.clienteInfo.correosContacto = response.data;
        this.calculateRoutesEmails();
      });
    },
    formatNotasDocumentos(docs) {
      this.infoCards.notas = [];
      this.infoCards.docs = [];
      docs.map((e) => {
        if (e.tipoRegistro == "Nota" || e.tipoRegistro == "nota")
          this.infoCards.notas.push({
            formatData:
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY") +
              " " +
              e.detalle.slice(0, 10) +
              " ... " +
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("HH:mm"),
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            ...e,
          });
        else {
          this.infoCards.docs.push({
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            onlyDate: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY"),
            url: e.url_bucket,
            ...e,
          });
        }
      })
    },
    formatCorreosInfoCard(correos) {
      this.infoCards.emails = [];
      correos.map((e) => {
        this.infoCards.emails.push({
          showInfo:
            e.subject +
            + ' ' +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY") +
            " " +
            e.subject.slice(0, 10) +
            " ... " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("HH:mm"),
          dateFormat: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm"),
          Onlyfecha: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY"),
          hora: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("HH:mm"),
          url_documento: e.documentos.length > 0 ? e.documentos[0].url_bucket : false,
          ...e,
        });
      });
    },
    calculateRoutesEmails() {
      this.emailRoutesConfig.sendEmailRoute = "/v1/portal/cliente/correo/enviar";
      this.emailRoutesConfig.action = "POST";   
      this.emailRoutesConfig.optionCorreosList = this.clienteInfo.correosContacto;
      this.emailRoutesConfig.remitente = localStorage.agenteMail;
      this.emailRoutesConfig.cliente = this.clienteInfo.id;
    },
    tabReplaceSelect(val){
      this.tabReplace = val
    },
    getPolizas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/emision/cliente/`+this.clienteInfo.id, config).then((response) => {
        this.polizasCliente = response.data;
      });
    },
    realoadView(){
      location.reload()
    },
    checkIfIsLlamadaInbound(){
      if(this.$route.query.llamadaInbound == 'true'){
        this.dialogInboundCalls  = true;
      }
    },
    closeModal(){
      this.dialogInboundCalls = false
      this.$router.push({
          name: 'detalles-cliente',
          params:{
            uuid: this.uuid 
          },
      });
    },
    async getLlamadasInbound(){
      const resp = await getLlamadasInbound('cliente', this.clienteInfo.id)
      this.infoCards.llamadas = resp.acciones
    },   
    async getSolicitudByClienteID(){
      this.solicitudesCliente = await getSolicitudByClienteID(this.clienteInfo.id)
    },
    formatTelefonosCliente(){
      this.telefonosCliente.push(this.clienteInfo.telefonoMovil);
      this.clienteInfo.phones.map((e) => {
        this.telefonosCliente.push(e.telefono)
      })
    }
  },
  computed: {

  },
  mounted() {
      this.obtnerDatosClientes()
      this.checkIfIsLlamadaInbound()
  },
};
</script>
