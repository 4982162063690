<template>
  <v-app>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-text style="padding: 20px" v-if="ayudaEnviada">
            <h3>En breve te contactaremos para brindarte ayuda</h3>
          </v-card-text>
          <v-card-text style="padding: 20px" v-if="!ayudaEnviada">
            <h3>¿Tienes algún problema al realizar tu cotización?</h3>
            <v-textarea
              style="margin-top: 20px"
              outlined
              name="input-7-1"
              v-model="detalle"
              label="¿Cómo podemos ayudarte?"
              hint="Describe tu problema brevemente"
            ></v-textarea>
            <p>
              El teléfono que tenemos registrado para contactarte es: <br /><b
                >** ** **
                {{
                  telefonoAgente ?
                  telefonoAgente.substring(
                    telefonoAgente.length - 4,
                    telefonoAgente.length
                  )
                  : ''
                }}</b
              >
            </p>
            <p>
              Si deseas ser contactado en otro teléfono actualiza tu perfil o
              ingresa el teléfono a continuación
            </p>
            <v-text-field
              name="name"
              label="Teléfono"
              v-model="telefonoContacto"
              single-line
            ></v-text-field>
            <p>
              O puedes comunicarte al teléfono <b>5588770545</b> o bien, al correo electrónico: <b>operaciones@interify.com</b>
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              v-if="!ayudaEnviada"
              :disabled="disableSendButton"
              @click="sendAyuda"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogCotiza" max-width="800px" height="800">
        <v-card height="800">
         <iframe src="/pdf/Conidciones Comerciales Aseguradoras.pdf" width="100%" height="100%"></iframe>
        </v-card>
      </v-dialog>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="2"
            fab
            dark
            color="blue"
            large
            v-bind="attrs"
            v-on="on"
            style="position: fixed; bottom: 30px; right: 10px; z-index: 5"
            @click="dialog = !dialog"
          >
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
        <span>¿Necesitas Ayuda?</span>
      </v-tooltip>

      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="2"
            fab
            dark
            color="blue"
            large
            v-bind="attrs"
            v-on="on"
            @click="dialogCotiza = !dialogCotiza"
            style="position: fixed; bottom: 120px; right: 10px; z-index: 5"
          >
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
        <span>Dudas con Cotización</span>
      </v-tooltip> -->

      <v-banner :color="'blue'" dark>
        <p>Las cotizaciones realizadas en este portal son únicamente para nuevas
        emisiones.</p>
        
        <p>Si requieres una renovación de póliza por favor, contacta a
        nuestro equipo de Operaciones a los teléfonos: <b>5588770545</b> o bien, al correo
        electrónico: <b>operaciones@interify.com</b> </p>
        
        <p>¡Con gusto te ayudaremos!</p>

        <p> <b>AVISO IMPORTANTE:</b> El pago de pólizas con las aseguradoras GNP, AIG, Ana
        Seguros y HDI, el pago debe ser realizado al momento de tu emisión. Con
        Quálitas, podrás realizar el pago posterior a la emisión de tu póliza o
        bien, descargar los recibos para pago en ventanilla bancaria.</p>

        Esta cotización estará vigente hasta <b>5 días naturales</b> a partir
        del día de hoy.

        <template v-slot:actions> </template>
      </v-banner>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-row class="mb-5" align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-stepper v-model="e6" vertical>
                  <v-stepper-step :complete="e6 > 1" step="1">
                    Por favor, selecciona el año de tu vehículo
                    <small v-if="modelo == null">Aún no seleccionado</small>
                    <small v-if="modelo != null">{{ modelo }}</small>
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-card
                      color="grey"
                      class="mb-12"
                      height="200px"
                      style="overflow: scroll"
                    >
                      <v-row class="mb-15" style="padding: 15px">
                        <v-col
                          v-for="modeloInner in modelos"
                          v-bind:key="modeloInner.id"
                        >
                          <v-btn
                            :color="
                              modelo === modeloInner.id
                                ? 'primary'
                                : 'secondary'
                            "
                            @click="seleccionarModelo(modeloInner.id)"
                            >{{ modeloInner.id }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-btn
                      color="primary"
                      :disabled="modelo == null"
                      @click="e6 = 2"
                    >
                      Continuar
                    </v-btn>
                    <v-btn text @click="e6 = 1"> Regresar </v-btn>
                  </v-stepper-content>

                  <v-stepper-step :complete="e6 > 2" step="2">
                    Selecciona la marca
                    <small v-if="marca == null">Aún no seleccionado</small>
                    <small v-if="marca != null">{{ marca.marca }}</small>
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <v-card
                      color="grey lighten-1"
                      class="mb-12"
                      height="200px"
                      style="overflow: scroll"
                    >
                      <v-row class="mb-15" style="padding: 15px">
                        <v-col
                          v-for="marcaInner in marcas"
                          v-bind:key="marcaInner.id"
                          cols="12" md="3"
                        >
                          <v-btn
                            style="width: 100%"
                            :color="
                              marca === marcaInner ? 'primary' : 'secondary'
                            "
                            @click="seleccionarMarca(marcaInner)"
                            >{{ marcaInner.marca }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-btn
                      color="primary"
                      :disabled="marca == null"
                      @click="e6 = 3"
                    >
                      Continue
                    </v-btn>
                    <v-btn text @click="e6 = e6 - 1"> Regresar </v-btn>
                  </v-stepper-content>

                  <v-stepper-step :complete="e6 > 3" step="3">
                    Selecciona la submarca
                    <small v-if="submarca == null">Aún no seleccionado</small>
                    <small v-if="submarca != null">{{ submarca.nombre }}</small>
                  </v-stepper-step>

                  <v-stepper-content step="3">
                    <v-card
                      color="grey lighten-1"
                      class="mb-12"
                      height="200px"
                      style="overflow: scroll"
                    >
                      <v-row class="mb-15" style="padding: 15px">
                        <v-col
                          v-for="submarcasInner in submarcas"
                          v-bind:key="submarcasInner.id"
                          cols="12"
                        >
                          <v-btn
                            style="width: 100%"
                            :color="
                              submarca === submarcasInner
                                ? 'primary'
                                : 'secondary'
                            "
                            @click="seleccionarSubmarca(submarcasInner)"
                            >{{ submarcasInner.nombre }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-btn
                      color="primary"
                      :disabled="submarca == null"
                      @click="e6 = 4"
                    >
                      Continue
                    </v-btn>
                    <v-btn text @click="e6 = e6 - 1"> Regresar </v-btn>
                  </v-stepper-content>
                  <v-stepper-step :complete="e6 > 4" step="4">
                    ¿Cuál es la versión del vehículo?
                    <small v-if="vehiculo == null">Aún no seleccionado</small>
                    <small v-if="vehiculo != null">{{ vehiculo.nombre }}</small>
                  </v-stepper-step>

                  <v-stepper-content step="4">
                    <v-card
                      color="grey lighten-1"
                      class="mb-12"
                      height="200px"
                      style="overflow: scroll"
                    >
                      <v-row class="mb-15" style="padding: 15px">
                        <v-col
                          v-for="vehiculoInner in vehiculos"
                          v-bind:key="vehiculoInner.id"
                          cols="12"
                        >
                          <v-btn
                            style="width: 100%"
                            :color="
                              vehiculoInner === vehiculo
                                ? 'primary'
                                : 'secondary'
                            "
                            @click="seleccionarVehiculo(vehiculoInner)"
                            >{{ vehiculoInner.nombre }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-btn
                      color="primary"
                      :disabled="vehiculo == null"
                      @click="e6 = 5"
                    >
                      Continue
                    </v-btn>
                    <v-btn text @click="e6 = e6 - 1"> Regresar </v-btn>
                  </v-stepper-content>

                  <v-stepper-step :complete="e6 > 5" step="5">
                    Por favor, ingresa los datos del conductor habitual
                    <small
                      v-if="customer.name == null && customer.lastname == null"
                      >Aún no seleccionado</small
                    >
                    <small
                      v-if="customer.name != null && customer.lastname != null"
                      >{{ customer.name }} {{ customer.lastname }}</small
                    >
                  </v-stepper-step>
                  <v-stepper-content step="5">
                    <v-card class="mb-12">
                      <v-row>
                        <v-col cols="12">
                          <v-form v-model="valid">
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    v-model="customer.name"
                                    name="name"
                                    label="Nombre"
                                    onkeypress="return (event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32)"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    v-model="customer.lastname"
                                    name="lastname"
                                    label="Primer Apellido"
                                    onkeypress="return (event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32)"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                  <v-text-field
                                    v-model="customer.lastname2"
                                    name="lastname"
                                    label="Segundo Apellido"
                                    onkeypress="return (event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode == 32)"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    v-model="customer.email"
                                    name="email"
                                    label="Correo"
                                    :rules="emailRules"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    v-model="customer.phone"
                                    name="phone"
                                    label="Teléfono"
                                    required
                                    placeholder="##########"
                                    v-mask="'##########'"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  class="col-md-4 col-12"
                                  style="padding: 12px"
                                >
                                  <v-text-field
                                    v-model="date"
                                    label="Fecha de Nacimiento dd/mm/aaaa"
                                    placeholder="dd/mm/aaaa"
                                    v-mask="'##/##/####'"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  class="col-md-4 col-12"
                                  style="padding: 12px"
                                >
                                  <v-select
                                    v-model="customer.civilStatus"
                                    :items="itemsCivilStatus"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    name="civilStatus"
                                    label="Estado Civil"
                                    required
                                  ></v-select>
                                </v-col>
                                <v-col
                                  class="col-md-4 col-12"
                                  style="padding: 12px"
                                >
                                  <v-select
                                    v-model="sexo"
                                    :items="itemsSexo"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    name="sexo"
                                    label="Sexo"
                                    required
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    v-model="customer.cp"
                                    name="codigoPostal"
                                    label="Código postal"
                                    required
                                    placeholder="#####"
                                    v-mask="'#####'"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-checkbox v-model="legales">
                                    <template v-slot:label>
                                      <div>
                                        Acepto
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <a
                                              target="_blank"
                                              href="/terminos-y-condiciones"
                                              @click.stop
                                              v-on="on"
                                            >
                                              Terminos y condiciones
                                            </a>
                                          </template>
                                          Nueva pestaña
                                        </v-tooltip>
                                        y
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <a
                                              target="_blank"
                                              href="/aviso-de-privacidad"
                                              @click.stop
                                              v-on="on"
                                            >
                                              Aviso de privacidad
                                            </a>
                                          </template>
                                          Nueva pestaña
                                        </v-tooltip>
                                      </div>
                                    </template>
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-btn text @click="e6 = e6 - 1"> Regresar </v-btn>
                  </v-stepper-content>
                </v-stepper>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card style="padding: 30px">
                  <v-card-title primary-title>
                    <div>
                      <img
                        :src="getAseguradoraImage($route.params.aseguradora)"
                        style="width: 150px"
                      />
                      <h3
                        class="headline mb-0"
                        style="margin-bottom: 30px !important"
                      >
                        Tu vehículo a cotizar
                      </h3>
                      <div>
                        <p>
                          Modelo:
                          <span style="color: gray" v-if="modelo == null"
                            >Aún no seleccionado</span
                          >
                          <span style="color: gray" v-if="modelo != null">{{
                            modelo
                          }}</span>
                        </p>
                        <p>
                          Marca:
                          <span style="color: gray" v-if="marca == null"
                            >Aún no seleccionado</span
                          >
                          <span style="color: gray" v-if="marca != null">{{
                            marca.marca
                          }}</span>
                        </p>
                        <p>
                          Submarca:
                          <span style="color: gray" v-if="submarca == null"
                            >Aún no seleccionado</span
                          >
                          <span style="color: gray" v-if="submarca != null">{{
                            submarca.nombre
                          }}</span>
                        </p>
                        <p>
                          Versión:
                          <span style="color: gray" v-if="vehiculo == null"
                            >Aún no seleccionado</span
                          >
                          <span style="color: gray" v-if="vehiculo != null">{{
                            vehiculo.nombre
                          }}</span>
                        </p>
                        <p>
                          Cliente:
                          <span
                            style="color: gray"
                            v-if="
                              customer.name == null && customer.lastname == null
                            "
                            >Aún no seleccionado</span
                          >
                          <span
                            style="color: gray"
                            v-if="
                              customer.name != null && customer.lastname != null
                            "
                            >{{ customer.name }} {{ customer.lastname }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-actions> </v-card-actions>
                </v-card>
                <div style="margin-top: 30px">
                  <v-btn
                    class="mr-2"
                    color="primary"
                    :disabled="usuarioDatos()"
                    @click="cotizar"
                    >Cotizar</v-btn
                  >
                  <v-btn
                    class="mr-2"
                    v-if="cotizaciones.length > 0"
                    color="secondary"
                    :disabled="cotizaccionGuardada"
                    @click="guardarCotizacion"
                    >Guardar cotización</v-btn
                  >
                  <v-btn
                    class="mr-2"
                    v-if="cotizaciones.length > 0"
                    text
                    color="primary"
                    @click="downloadCotizacion"
                    >Descargar PDF cotización</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row
              class="mb-5"
              v-if="
                cotizaciones.length > 0 &&
                aseguradora != 'segurify' &&
                aseguradora != 'gnp' &&
                aseguradora != 'qualitas' &&
                aseguradora != 'hdi' &&
                aseguradora != 'aig' &&
                aseguradora != 'ana'
              "
            >
              <v-col cols="12">
                <h3>Opciones disponibles</h3>
              </v-col>
            </v-row>
            <v-row
              class="mb-15"
              v-if="
                aseguradora != 'segurify' &&
                cotizaciones.length > 0 &&
                aseguradora != 'gnp' &&
                aseguradora != 'qualitas' &&
                aseguradora != 'hdi' &&
                aseguradora != 'aig' &&
                aseguradora != 'ana'
              "
            >
              <v-col cols="3">
                <v-btn block color="primary" dark>Anual</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn block color="primary" dark>Semestral</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn block color="primary" dark>Trimestral</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn block color="primary" dark>Mensual</v-btn>
              </v-col>
            </v-row>

            <v-row 
              class="mb-5"
              v-if="cotizaciones.length > 0 "
            > 
              <v-col cols="6" md="3">
                <v-btn block
                @click="formaPago = 'anual', cotizar()" 
                :color="formaPago == 'anual' ? 'primary' : ''"
                >Anual</v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn block
                @click="formaPago = 'semestral', cotizar()" 
                :color="formaPago == 'semestral' ? 'primary' : ''"
                >Semestral</v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn block
                @click="formaPago = 'trimestral', cotizar()" 
                :color="formaPago == 'trimestral' ? 'primary' : ''"
                >Trimestral</v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn block
                @click="formaPago = 'mensual', cotizar()" 
                :color="formaPago == 'mensual' ? 'primary' : ''"
                >Mensual</v-btn>
              </v-col>
            </v-row>

            <v-row 
              class="mb-5"
              v-if="cotizaciones.length > 0 && aseguradora == 'segurify'"
            >
              <v-col cols="12" md="3">
                <v-btn
                  block
                  :color="plan == 'amplia' ? 'primary' : ''"
                  dark
                  @click="plan = 'amplia'"
                  >Amplia</v-btn
                >
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  block
                  :color="plan == 'amplia-plus' ? 'primary' : ''"
                  dark
                  @click="plan = 'amplia-plus'"
                  >Amplia-Plus</v-btn
                >
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  block
                  :color="plan == 'limitada' ? 'primary' : ''"
                  dark
                  @click="plan = 'limitada'"
                  >Limitada</v-btn
                >
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  block
                  :color="plan == 'rc' ? 'primary' : ''"
                  dark
                  @click="plan = 'rc'"
                  >RC</v-btn
                >
              </v-col>
            </v-row>

            <v-row 
              class="mx-0"
              v-if="cotizaciones.length > 0 && aseguradora == 'segurify'"
            >
              <v-col cols="12">
                <h3 class="mt-5">Seleccione una opción para modificar las coberturas</h3>
              </v-col>

              <v-col cols="12" class="row mx-0">
                <v-col cols="12" md="3" 
                  v-for="(opcion, index) in opciones" 
                  v-bind:key="index"
                >
                  <v-select
                    :label="opcion.titulo"
                    :items="opcion.valores"
                    :rules="[(v) => !!v || 'Campo Requerido']"
                    item-text="valor"
                    item-value="valor"
                    v-model="opcionesCoberturaElegida[index]"
                    outlined
                  ></v-select>
                </v-col>
                
                <div class="mb-5 text-center">
                  <v-btn
                    color="primary"
                    :disabled="deshabilitarRecotizar()"
                    @click="cotizar(), cambioCoberturasEditables = true"
                  >Recotizar</v-btn>
                </div>
              </v-col>
            </v-row>

            <v-row class="" v-if="cotizaciones.length > 0">
              <v-col cols="12">
                <h3>Resultados</h3>
              </v-col>
            </v-row>

            <v-row
              v-if="aseguradora == 'segurify'"
              class="mb-5"
            >
              <v-col
                cols="12" md="3"
                v-for="(cotizacion) in cotizaciones"
                v-bind:key="cotizacion.id"
              >
                <v-card style="opacity: 1" :loading="cotizacion.loading">
                  <v-card-title primary-title v-if="cotizacion.loading">
                    <p>Cotizando...</p>
                  </v-card-title>
                  <v-card-title
                    primary-title
                    v-if="
                      !cotizacion.loading &&
                      cotizacion.cotizacion.status == 'FAILED'
                    "
                  >
                    <div style="width: 100%; text-align: center">
                      <img
                        style="height: 100px"
                        :src="getAseguradoraImage(cotizacion.aseguradora)"
                      />

                      <h3
                        class="headline mb-0"
                        style="
                          font-size: 30px !important;
                          margin-bottom: 20px !important;
                        "
                      >
                        Por el momento no contamos con las coberturas que desea cotizar, contactar al equipo de operaciones
                      </h3>
                    </div>
                  </v-card-title>
                  <v-card-title
                    primary-title
                    v-if="
                      !cotizacion.loading &&
                      cotizacion.cotizacion.status != 'FAILED'
                    "
                  >
                    <div style="width: 100%; text-align: center">
                      <!-- <div
                        class="ribbon ribbon-top-right"
                        v-if="
                          (index == 0 && aseguradora == 'segurify') ||
                          (cotizacion.cotizacion.plan == 1 &&
                            aseguradora != 'segurify')
                        "
                      >
                        <span>Recomendado</span>
                      </div> -->
                      <img
                        style="height: 100px"
                        :src="getAseguradoraImage(cotizacion.aseguradora)"
                      />
                      <v-row>
                        <v-col cols="12">
                          <coberturas-editables-comparador
                            :formaPago="formaPago"
                            :pagoTotal="cotizacion.cotizacion.total"
                            :pagoPrimero="cotizacion.cotizacion.primerPago"
                            :pagoSubsecuente="cotizacion.cotizacion.subsecuentePago"
                            :aseguradora="cotizacion.aseguradora"
                            :plan="cotizacion.cotizacion.plan"
                            :datosParaMostrar="opcionesCoberturaElegida"
                          ></coberturas-editables-comparador>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-title>
                  <v-card-actions v-if="
                      !cotizacion.loading &&
                      cotizacion.cotizacion.status != 'FAILED'
                    ">
                    <v-btn
                      color="primary"
                      style="width: 100%"
                      @click="
                        emitir(
                          getAseguradoraName(
                            cotizacion.cotizacion.aseguradoraId
                          ),
                          cotizacion.cotizacion
                        )
                      "
                      >Emitir</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-btn
                  block
                  v-if="cotizaciones.length > 0"
                  color="secondary"
                  :disabled="cotizaccionGuardada"
                  @click="guardarCotizacion"
                  >Guardar cotización</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-else class="mb-15" style="margin-top: 40px">
              <v-col
                cols="3"
                v-for="(cotizacion, index) in cotizaciones"
                v-bind:key="cotizacion.id"
              >
                <v-card style="opacity: 1" :loading="cotizacion.loading">
                  <v-card-title primary-title v-if="cotizacion.loading">
                    <p>Cotizando...</p>
                  </v-card-title>
                  <v-card-title
                    primary-title
                    v-if="
                      !cotizacion.loading &&
                      cotizacion.cotizacion.status == 'FAILED'
                    "
                  >
                    <div style="width: 100%; text-align: center">
                      <img
                        style="height: 100px"
                        :src="getAseguradoraImage(cotizacion.aseguradora)"
                      />

                      <h3
                        class="headline mb-0"
                        style="
                          font-size: 30px !important;
                          margin-bottom: 20px !important;
                        "
                      >
                        La aseguradora {{ cotizacion.aseguradora }} no cuenta con los deducibles seleccionados
                      </h3>
                    </div>
                  </v-card-title>
                  <v-card-title
                    primary-title
                    v-if="
                      !cotizacion.loading &&
                      cotizacion.cotizacion.status != 'FAILED'
                    "
                  >
                    <div style="width: 100%; text-align: center">
                      <div
                        class="ribbon ribbon-top-right"
                        v-if="
                          (index == 0 && aseguradora == 'segurify') ||
                          (cotizacion.cotizacion.plan == 1 &&
                            aseguradora != 'segurify')
                        "
                      >
                        <span>Recomendado</span>
                      </div>
                      <img
                        style="height: 100px"
                        :src="getAseguradoraImage(cotizacion.aseguradora)"
                      />
                      <h3
                        class="headline mb-0"
                        style="
                          font-size: 30px !important;
                          margin-bottom: 20px !important;
                        "
                      >
                        {{ toCurrency(cotizacion.cotizacion.total) }}
                      </h3>
                      <v-row>
                        <v-col cols="12">
                          <coberturas-editables
                            :formaPago="formaPago"
                            :pagoTotal="cotizacion.cotizacion.total"
                            :pagoPrimero="cotizacion.cotizacion.primerPago"
                            :pagoSubsecuente="cotizacion.cotizacion.subsecuentePago"
                            :aseguradora="cotizacion.aseguradora"
                            :plan="cotizacion.cotizacion.plan"
                          ></coberturas-editables>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-title>
                  <v-card-actions v-if="
                      !cotizacion.loading &&
                      cotizacion.cotizacion.status != 'FAILED'
                    ">
                    <v-btn
                      color="primary"
                      style="width: 100%"
                      @click="
                        emitir(
                          getAseguradoraName(
                            cotizacion.cotizacion.aseguradoraId
                          ),
                          cotizacion.cotizacion
                        )
                      "
                      >Emitir</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-btn
                  block
                  v-if="cotizaciones.length > 0"
                  color="secondary"
                  :disabled="cotizaccionGuardada"
                  @click="guardarCotizacion"
                  >Guardar cotización</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
  </v-app>
</template>

<script>
import { mainAxios } from '../../mainAxios';
//import CoberturasAmplia from '../../components/coberturas/coberturasAmplia.vue';
import CoberturasEditables from '../../components/coberturas/coberturasEditables.vue';
import coberturasEditablesComparador from '../../components/coberturas/coberturasEditablesComparador.vue';
import { axiosLogin } from '../../axiosLogin';
export default {
  components: {
    //CoberturasAmplia,
    CoberturasEditables,
    coberturasEditablesComparador,
  },
  data() {
    return {
      tabs: null,
      telefonoContacto: null,
      enviandoAyuda: false,
      telefonoAgente: '',
      ayudaEnviada: false,
      plan: 'amplia',
      formaPago: 'anual',
      aseguradoraRequest: this.$route.params.aseguradora,
      aseguradora: this.$route.params.aseguradora,
      dialog: false,
      dialogCotiza: false,
      cotizarClicked: false,
      cotizaciones: [],
      cotizaccionGuardada: false,
      cotizacionesNewOrder: [],
      cotizando: '',
      submarcas: [],
      vehiculos: [],
      vehiculo: null,
      marca: null,
      submarca: null,
      marcas: [],
      modelo: null,
      modelos: [],
      legales: true,
      e6: 1,
      icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      items: [
        {
          title: 'Mis Cotizaciones',
          text: 'Podrás consultar tus cotizaciones',
        },
        {
          title: 'Mis Emisiones',
          text: 'Podrás consultar tus emisiones',
        },
        {
          title: 'Cotizar',
          text: 'Podrás consultar tus emisiones',
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
      // data costumer
      customer: {
        name: null,
        lastname: null,
        lastname2: null,
        email: null,
        phone: null,
        birthDay: null,
        birthMonth: null,
        birthYear: null,
        cp: null,
        civilStatus: null,
        sexo: null,
      },
      //validate form
      valid: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'E-mail must be valid',
      ],
      date: null,
      detalle: null,
      menu: false,
      itemsCivilStatus: ['Soltero', 'Casado'],
      itemsSexo: ['Masculino', 'Femenino'],
      sexo: null,
      //Respuesta Aseguradoras
      respuestaQualitas: null,
      respuestaAig: null,
      respuestaHDI: null,
      respuestaAna: null,
      respuestaGNP: null,
      request: '',
      coberturaChangeQualitas: [],
      gastosChangeQualitas: [],
      coberturaChangeAig: [],
      gastosChangeAig: [],
      coberturaChangeGnp: [],
      gastosChangeGnp: [],
      //End Respuesta Aseguradoras
      opciones: [],
      opcionesCoberturaElegida: [],
      cambioCoberturasEditables: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    plan(val) {
      console.log(val);
      this.cotizar();
    },
  },
  computed: {
    disableSendButton(){
      if(this.detalle == null && this.telefonoContacto == null){
        return true;
      }else{
        return false;
      }
    },
    cotizacionesOrdenadas() {
      return this.cotizaciones.slice().sort(function (a, b) {
        if (a.loading && b.loading) {
          return 0;
        }
        if (a.loading && !b.loading) {
          return -1;
        }
        if (!a.loading && b.loading) {
          return 1;
        }
        if (!a.loading && !b.loading) {
          if (a.cotizacion.total > b.cotizacion.total) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    },
  },
  methods: {
    // Metodos enviados por emit
    rc(seguro, plan, cobertura) {
      if (seguro == 'qualitas') {
        this.coberturaChangeQualitas = [seguro, plan, cobertura]
      }
      if (seguro == 'aig') {
        this.coberturaChangeAig = [seguro, plan, cobertura]
      }
      if (seguro == 'gnp') {
        this.coberturaChangeGnp = [seguro, plan, cobertura]
      }
      this.cotizar();
    },
    gastosMd(seguro, plan, cobertura) {
      if (seguro == 'qualitas') {
        this.gastosChangeQualitas = [seguro, plan, cobertura]
      }
      if (seguro == 'aig') {
        this.gastosChangeAig = [seguro, plan, cobertura]
      }
      if (seguro == 'gnp') {
        this.gastosChangeGnp = [seguro, plan, cobertura]
      }
      this.cotizar();
    },
    // metodo normal
    sendAyuda() {
      this.enviandoAyuda = true;
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let request = {
        detalle: this.detalle,
        paso: this.e6,
      };
      if (this.telefonoContacto != null) {
        request.phone = this.telefonoContacto;
      }

      mainAxios
        .post(
          'v1/lead/ayuda/agente/' + localStorage.agenteUuid,
          request,
          config
        )
        .then((value) => {
          console.log(value);
          this.ayudaEnviada = true;
        });
    },
    planIdToString(id) {
      if (id == 3) {
        return 'Responsabilidad Civil';
      }
      if (id == 2) {
        return 'Limitada';
      }
      if (id == 14) {
        return 'Amplia Plus';
      }
      if (id == 1) {
        return 'Amplia';
      }
    },
    usuarioDatos() {
      let activa = 0;
      if (this.customer.name) {
        activa = this.customer.name.length > 2 ? activa + 1 : activa;
      }
      if (this.customer.lastname) {
        activa = this.customer.lastname.length > 2 ? activa + 1 : activa;
      }
      if (this.customer.lastname2) {
        activa = this.customer.lastname2.length > 2 ? activa + 1 : activa;
      }
      if (this.customer.email) {
        activa = this.customer.email.length > 4 ? activa + 1 : activa;
      }
      if (this.customer.phone) {
        activa = this.customer.phone.length > 4 ? activa + 1 : activa;
      }
      if (this.customer.cp) {
        activa = this.customer.cp.length > 4 ? activa + 1 : activa;
      }
      if (this.date) {
        activa = activa + 1;
      }
      if (this.customer.civilStatus) {
        activa = activa + 1;
      }
      if (this.sexo) {
        activa = activa + 1;
      }
      if (this.legales) {
        activa = activa + 1;
      }
      if (activa == 10) {
        return false;
      } else {
        return true;
      }
    },

    downloadCotizacion() {
      var vm = this;
      let newCotizaciones = [];
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
        responseType: 'blob',
      };
      vm.downloading = true;
      this.cotizaciones.map((element) => {
        newCotizaciones.push(element.cotizacion);
      });
      if (this.aseguradora == 'segurify') {
        mainAxios
          .post('/v1/cotizacion/pdf', newCotizaciones, config)
          .then((response) => {
            vm.downloading = false;
            require('downloadjs')(
              response.data,
              vm.customer.name +
                '_' +
                vm.customer.lastname +
                '_CotizacionSegurify.pdf',
              'application/pdf'
            );
            //download(response.data, "cotizacion.pdf", "application/pdf");
          });
      } else {
        mainAxios
          .post(
            '/v1/cotizacion/pdf/aseguradora/' + this.aseguradora,
            newCotizaciones,
            config
          )
          .then((response) => {
            vm.downloading = false;
            require('downloadjs')(
              response.data,
              vm.customer.name +
                '_' +
                vm.customer.lastname +
                '_CotizacionSegurify.pdf',
              'application/pdf'
            );
            //download(response.data, "cotizacion.pdf", "application/pdf");
          });
      }
    },
    getAseguradoraImage(aseguradora) {
      if (aseguradora == 'segurify') {
        return require('../../assets/logo_segurify.png');
      }
      if (aseguradora == 'qualitas') {
        return require('../../assets/qualitas_new.svg');
      }
      if (aseguradora == 'gnp') {
        return require('../../assets/gnp_new.svg');
      }
      if (aseguradora == 'aig') {
        return require('../../assets/aig_new_two.png');
      }
      if (aseguradora == 'ana') {
        return require('../../assets/ana.png');
      }
      if (aseguradora == 'hdi') {
        return require('../../assets/hdi_new.svg');
      }
    },
    getAseguradoraName(id) {
      if (id == 5) {
        return 'Qualitas';
      }
      if (id == 3) {
        return 'gnp';
      }
      if (id == 8) {
        return 'aig';
      }
      if (id == 2) {
        return 'ana';
      }
      if (id == 9) {
        return 'hdi';
      }
      return 'N/A';
    },
    cargarAnios() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get('/v1/vehiculo/' + this.aseguradoraRequest + '/modelos', config)
        .then((value) => {
          this.modelos = value.data;
        });
    },
    toCurrency(number) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(number); /* $2,500.00 */
    },

    guardarCotizacion() {
      var request = {
        agente: this.$route.params.uuid
          ? this.$route.params.uuid
          : localStorage.agenteUuid,
        vehiculo:
          this.modelo + ' ' + this.marca.marca + ' ' + this.vehiculo.nombre,
        cliente: this.customer.name + ' ' + this.customer.lastname,
        aseguradora: this.aseguradora,
        cotizaciones: this.cotizaciones,
      };
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post('/v1/agente/cotizacion/guardar', request, config)
        .then((value) => {
          console.log(value);
          this.$toast.open('Cotización guardada');
          this.cotizaccionGuardada = true;
        });
    },
    cargarMarcas() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(
          '/v1/vehiculo/' + this.aseguradoraRequest + '/marca/' + this.modelo,
          config
        )
        .then((value) => {
          this.marcas = value.data;
        });
    },
    cargarSubmarcas() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(
          '/v1/vehiculo/' +
            this.aseguradoraRequest +
            '/submarca/' +
            this.marca.id +
            '/' +
            this.modelo,
          config
        )
        .then((value) => {
          this.submarcas = value.data;
        });
    },
    cargarVersiones() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(
          '/v1/vehiculo/' +
            this.aseguradoraRequest +
            '/vehiculo/' +
            this.marca.id +
            '/' +
            this.submarca.id +
            '/' +
            this.modelo,
          config
        )
        .then((value) => {
          this.vehiculos = value.data;
        });
    },
    seleccionarModelo(modelo) {
      this.modelo = modelo;
      this.cargarMarcas();
    },
    seleccionarMarca(marca) {
      this.marca = marca;
      this.cargarSubmarcas();
    },
    seleccionarSubmarca(submarca) {
      this.submarca = submarca;
      this.cargarVersiones();
    },
    seleccionarVehiculo(vehiculo) {
      this.vehiculo = vehiculo;
    },
    //calculadora de edad
    getYearsOld(birthDateDay) {
      let tempDate = birthDateDay.split('/');
      let birthDay = tempDate[0];
      let birthMonth = tempDate[1];
      let birthYear = tempDate[2];
      let todayDate = new Date();
      let todayYear = todayDate.getFullYear();
      let todayMonth = todayDate.getMonth();
      let todayDay = todayDate.getDate();
      let age = todayYear - birthYear;

      if (todayMonth < birthMonth - 1) {
        age--;
      }
      if (birthMonth - 1 == todayMonth && todayDay < birthDay) {
        age--;
      }
      return age;
    },

    cotiza(aseguradora, plan, index, vehiculoId) {
      let tempDate = this.date.split('/');
      let birthDay = tempDate[0];
      let birthMonth = tempDate[1];
      let birthYear = tempDate[2];
      this.getYearsOld(this.date);

      // sexo
      let sexoCustomer = this.sexo == 'Masculino' ? 'M' : 'F';
      let forma_pago = ''

      switch (this.formaPago) {
        case 'anual':
            forma_pago = '92dabb35-e35b-4413-b29a-5d0e3fd69ba5'
          break;
        case 'semestral':
            forma_pago = '73b7851c-74b9-4c37-b65a-ae8b6ee2eabd'
          break;
        case 'trimestral':
            forma_pago = '95e07c46-7f89-4340-9459-aa146e9ae2eb'
          break;
        case 'mensual':
            forma_pago = '94af2ddc-49f7-47bf-9ff6-b42c1052e661'
          break;
      
        default:
          break;
      }

      //Si opcionesCoberturaElegida es < 1 se mandara este request
      //else agregar la data de cobertura
      this.cambioCoberturasEditables == false ? this.request = {
        vehiculo: vehiculoId,
        identificador: vehiculoId,
        'forma-pago': forma_pago,
        plan: plan,
        edad: this.getYearsOld(this.date),
        sexo: sexoCustomer,
        codigoPostal: this.customer.cp,
        modelo: this.modelo,
        cotizacion_general: this.rndStr(6),
        agenteId: this.$route.params.uuid
          ? this.$route.params.uuid
          : localStorage.agenteUuid,
        usuarioJson: {
          civilStatus: 'soltero',
          birthDay: birthDay,
          birthMonth: birthMonth,
          birthYear: birthYear,
          cp: this.customer.cp,
          name: this.customer.name,
          lastname2: this.customer.lastname2,
          lastname: this.customer.lastname,
          email: this.customer.email,
          phone: this.customer.phone,
          sexo: sexoCustomer,
        },
        vehiculoJson: {
          generoConductor: sexoCustomer,
          modelo: {
            id: this.modelo,
          },
          marca: {
            id: this.marca.id,
            marca: this.marca.marca,
          },
          submarca: {
            id: this.submarca.id,
          },
          vehiculo: {
            id: this.vehiculo.id,
            nombre: this.vehiculo.nombre,
          },
        },
        portal: 'agente',
      } : this.request = {
        vehiculo: vehiculoId,
        'forma-pago': forma_pago,
        plan: plan,
        edad: this.getYearsOld(this.date),
        sexo: sexoCustomer,
        codigoPostal: this.customer.cp,
        modelo: this.modelo,
        cotizacion_general: this.rndStr(6),
        coberturas: {
          deducibleDanios : this.opcionesCoberturaElegida[0] == '3%' ? '3' 
            : this.opcionesCoberturaElegida[0] == '5%' ? '5' : '10',
            deducibleRoboTotal : this.opcionesCoberturaElegida[1] == '5%' ? '5'
            : this.opcionesCoberturaElegida[1] == '10%' ? '10' : '20',
            sumaRC : this.opcionesCoberturaElegida[2] == '3,000,000' ? '3000000'
            : this.opcionesCoberturaElegida[2] == '4,000,000' ? '4000000' : '5000000',
            sumaGastosMedicos : this.opcionesCoberturaElegida[3] == '100,000' ? '100000'
            : this.opcionesCoberturaElegida[3] == '200,000' ? '200000' : '300000'
        },
        agenteId: this.$route.params.uuid
          ? this.$route.params.uuid
          : localStorage.agenteUuid,
        usuarioJson: {
          civilStatus: 'soltero',
          birthDay: birthDay,
          birthMonth: birthMonth,
          birthYear: birthYear,
          cp: this.customer.cp,
          name: this.customer.name,
          lastname2: this.customer.lastname2,
          lastname: this.customer.lastname,
          email: this.customer.email,
          phone: this.customer.phone,
        },
        vehiculoJson: {
          generoConductor: sexoCustomer,
          modelo: {
            id: this.modelo,
          },
          marca: {
            id: this.marca.id,
            marca: this.marca.marca,
          },
          submarca: {
            id: this.submarca.id,
          },
          vehiculo: {
            id: this.vehiculo.id,
            nombre: this.vehiculo.nombre,
          },
        },
        portal: 'agente',
      };

      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post('/v1/' + aseguradora + '/cotiza/landing', this.request, config)
        .then((value) => {
          var cotizacion = { aseguradora: aseguradora, cotizacion: value.data };
          this.$set(this.cotizaciones, index, cotizacion);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    watch: {
      cotizaciones() {
        return this.cotizaciones;
      },
    },
    // Funciones para cotizar
    cotizar() {
      this.cotizaciones = [];
      this.e6 = 6;
      //identificador()
      var cotizacion1 = {};
      var cotizacion2 = {};
      var cotizacion3 = {};
      var cotizacion4 = {};
      var res = this.vehiculo.id.split('-');
      var idVehiculo = this.vehiculo.id;
      if (res.length == 1) {
        idVehiculo = this.vehiculo.id + '-' + this.modelo;
      }
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      if (this.aseguradora == 'segurify') {
        mainAxios
          .get('/v1/vehiculo/identificador/' + idVehiculo, config)
          .then((value) => {
            var vehiculoHomologado = value.data;
            cotizacion1 = { plan: this.plan, loading: true };
            cotizacion2 = { plan: this.plan, loading: true };
            cotizacion3 = { plan: this.plan, loading: true };
            cotizacion4 = { plan: this.plan, loading: true };
            this.cotizaciones.push(cotizacion1);
            this.cotizaciones.push(cotizacion2);
            this.cotizaciones.push(cotizacion3);
            //this.cotizaciones.push(cotizacion4);
            this.cotiza('qualitas', this.plan, 1, this.vehiculo.id);
            this.cotiza(
              'aig',
              this.plan,
              0,
              vehiculoHomologado.identificadorAig
            );
            this.cotiza(
              'gnp',
              this.plan,
              2,
              vehiculoHomologado.identificadorGnp
            );
            /*this.cotiza( "ana", this.plan, 3, vehiculoHomologado.identificadorAna.split("-")[0]);*/
          });
      } else {
        cotizacion1 = { plan: 'amplia', loading: true };
        cotizacion2 = { plan: 'amplia-plus', loading: true };
        cotizacion3 = { plan: 'limitada', loading: true };
        cotizacion4 = { plan: 'rc', loading: true };

        this.cotizaciones.push(cotizacion1);
        this.cotizaciones.push(cotizacion2);
        this.cotizaciones.push(cotizacion3);
        this.cotizaciones.push(cotizacion4);

        if (this.aseguradora == 'gnp') {
          this.cotiza(
            this.aseguradora,
            'amplia',
            0,
            this.modelo +
              '-' +
              this.marca.id +
              '-' +
              this.submarca.id +
              '-' +
              this.vehiculo.id
          );
          this.cotiza(
            this.aseguradora,
            'amplia-plus',
            1,
            this.modelo +
              '-' +
              this.marca.id +
              '-' +
              this.submarca.id +
              '-' +
              this.vehiculo.id
          );
          this.cotiza(
            this.aseguradora,
            'limitada',
            2,
            this.modelo +
              '-' +
              this.marca.id +
              '-' +
              this.submarca.id +
              '-' +
              this.vehiculo.id
          );
          this.cotiza(
            this.aseguradora,
            'rc',
            3,
            this.modelo +
              '-' +
              this.marca.id +
              '-' +
              this.submarca.id +
              '-' +
              this.vehiculo.id
          );
        }
        if (this.aseguradora == 'qualitas') {
          this.cotiza(this.aseguradora, 'amplia', 0, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'amplia-plus', 1, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'limitada', 2, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'rc', 3, this.vehiculo.id);
        }
        if (this.aseguradora == 'aig') {
          this.cotiza(
            this.aseguradora,
            'amplia',
            0,
            this.vehiculo.id + '-' + this.modelo
          );
          this.cotiza(
            this.aseguradora,
            'amplia-plus',
            1,
            this.vehiculo.id + '-' + this.modelo
          );
          this.cotiza(
            this.aseguradora,
            'limitada',
            2,
            this.vehiculo.id + '-' + this.modelo
          );
          this.cotiza(
            this.aseguradora,
            'rc',
            3,
            this.vehiculo.id + '-' + this.modelo
          );
        }
        if (this.aseguradora == 'ana') {
          this.cotiza(this.aseguradora, 'amplia', 0, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'amplia-plus', 1, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'limitada', 2, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'rc', 3, this.vehiculo.id);
        }
        if (this.aseguradora == 'hdi') {
          this.cotiza(this.aseguradora, 'amplia', 1, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'amplia-plus', 0, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'limitada', 2, this.vehiculo.id);
          this.cotiza(this.aseguradora, 'rc', 3, this.vehiculo.id);
        }
      }
    },

    //data piker
    save(date) {
      this.$refs.menu.save(date);
    },
    emitir: function (aseguradora, cotizacion) {
      var deComparador = false;
      if (this.aseguradora == 'segurify') {
        deComparador = true;
      }
      switch (this.formaPago) {
        case 'anual':
            cotizacion.pagoId = 1
          break;
        case 'semestral':
            cotizacion.pagoId = 2
          break;
        case 'trimestral':
            cotizacion.pagoId = 3
          break;
        case 'mensual':
            cotizacion.pagoId = 4
          break;
      
        default:
          break;
      }
      this.$router.push({
        name: 'emite',
        params: {
          aseguradora: aseguradora,
          cotizacion: cotizacion,
          deComparador: deComparador,
          agente: this.$route.params.uuid
            ? this.$route.params.uuid
            : localStorage.agenteUuid,
          coberturas: {
            deducibleDanios : this.opcionesCoberturaElegida[0] == '3%' ? '3' 
            : this.opcionesCoberturaElegida[0] == '5%' ? '5' : '10',
            deducibleRoboTotal : this.opcionesCoberturaElegida[1] == '5%' ? '5'
            : this.opcionesCoberturaElegida[1] == '10%' ? '10' : '20',
            sumaRC : this.opcionesCoberturaElegida[2] == '3,000,000' ? '3000000'
            : this.opcionesCoberturaElegida[2] == '4,000,000' ? '4000000' : '5000000',
            sumaGastosMedicos : this.opcionesCoberturaElegida[3] == '100,000' ? '100000'
            : this.opcionesCoberturaElegida[3] == '200,000' ? '200000' : '300000'
          }, 
        },
      });
    },
    rndStr(len) {
      let text = ' ';
      let chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },
    deshabilitarRecotizar() {
      if (this.opcionesCoberturaElegida[0] == null) {
        return true;
      } else if (this.opcionesCoberturaElegida[1] == null) {
        return true;
      } else if (this.opcionesCoberturaElegida[2] == null) {
        return true;
      } else if (this.opcionesCoberturaElegida[3] == null) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    
    this.opciones.push({
        titulo: 'DAÑOS MATERIALES',
        valores: [
          {id: 1, valor: '3%'},
          {id: 2, valor: '5%'},
          {id: 3, valor: '10%'},
        ]
      },
      {
        titulo: 'ROBO TOTAL',
        valores: [
          {id: 1, valor: '5%'},
          {id: 2, valor: '10%'},
          {id: 3, valor: '20%'},
        ]
      },
      {
        titulo: 'RESPONSABILIDAD CIVIL',
        valores: [
          {id: 1, valor: '3,000,000'},
          {id: 2, valor: '4,000,000'},
          {id: 3, valor: '5,000,000'},
        ]
      },
      {
        titulo: 'GASTOS MÉDICOS OCUPANTES',
        valores: [
          {id: 1, valor: '100,000'},
          {id: 2, valor: '200,000'},
          {id: 3, valor: '300,000'},
        ]
      },
    )

    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.agenteAccessToken,
      },
    };
    axiosLogin.get('/user', config).then((value) => {
      this.telefonoAgente = value.data.principal.celular;
    });
    if (this.aseguradora == 'segurify') {
      this.aseguradoraRequest = 'qualitas';
    }
    this.cargarAnios();
  },
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
  font-size: 12px;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  font-size: 12px;
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
.v-card {
  opacity: 1 !important;
}
</style>
