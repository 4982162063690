<template>
  <v-app>
    <Navigation></Navigation>
    <v-main class="mb-15">
      <v-container fluid>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col cols="10">
                <h1>Mis Comisiones</h1>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="4" >
                <v-card outlined class="card" @click="getCuotas(1)">
                  <v-row>
                    <p>{{ comisionesPendientePagoAseguradora }}</p>
                    <h3>Pendiente de Pago Aseguradora</h3>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card outlined class="card" @click="getCuotas(2)">
                  <v-row>
                    <p>{{ comisionesCanceladas }}</p>
                    <h3>Cancelada</h3>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="4" >
                <v-card outlined class="card" @click="getCuotas(3)">
                  <v-row>
                    <p>{{ comisionesPendientePagoAgente }}</p>
                    <h3>Pendiente de Pago al Agente</h3>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card outlined class="card" @click="getCuotas(4)">
                  <v-row>
                    <p>{{ comisionesPagadaAgente }}</p>
                    <h3>Pagada al Agente</h3>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-card style="padding: 30px">
              <v-card-title primary-title>
                <v-row>
                  <v-col cols="10">
                    <span class="text-h3">Comisiones {{ titulo }}</span>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      style="height: 50px; weight: 50px"
                      class="ml-16"
                      dark
                      max-height="40px"
                      max-width="100px"
                      v-if="canCreate"
                      :disabled="descargarComisiones.length == 0"
                    >
                      <download-csv
                        :name="downloadText"
                        :data="descargarComisiones"
                      >
                        Descargar
                      </download-csv>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              
              <!-- TABLE -->
              <v-data-table
                  :headers="columnsComisiones"
                  :items="comisiones"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :loading='loading'
                  loading-text="Cargando..."
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.uuid="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="$router.push('/visualizacion-comisiones/' + item.uuid)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer fixed style="text-align:center;">
      <p style="text-align:center;width:100%; margin: 10px 0px">Segurify 2021 <a target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>  </p>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from "../../../mainAxios";
var moment = require("moment-timezone");
export default {
  components: {
    Navigation,
  },
  computed:{
    canCreate(){
      var canView = false;
      var roles=[]
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = ["ADMIN", "MESADECONTROL", "OPERACIONES","OPERADOR"];
      roles.forEach((element)=>{
        if(rolesToView.includes(element)){
          canView=true;
        }
      })
      return canView;
    }
  },
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem('agenteUuid'),
      loading: false,
      titulo: '',
      downloadText: '',
      search: '',
      comisiones: [],
      descargarComisiones: [],
      searchNuevas: "",
      searchPendientesPago: "",
      searchPagadasParcial: "",
      searchPagadasTotal: "",
      searchPorRenovar: "",
      searchRenovadas: "",
      searchFinalizadas: "",
      searchCanceladas: "",
      bloquear: true,
      productos: [],
      comisionesPendientePagoAseguradora: 0,
      comisionesCanceladas: 0,
      comisionesPendientePagoAgente: 0,
      comisionesPagadaAgente: 0,
      columnsComisiones: [
        { text: "Cuota", value: "idCuota" },
        { text: "Póliza", value: "poliza" },
        { text: "Orden", value: "orden" },
        { text: "Cliente", value: "cliente" },
        { text: "Agente", value: "agente" },
        { text: "Estatus de la Comisión", value: "statusComision" },
        { text: "% Comisión Agente", value: "porcentajeComisionAgente" },
        { text: "Importe a pagar al Agente", value: "importePagarAgente" },
        { text: "Folio de la Factura a Pagar", value: "folioFacturaPagar" },
        { text: "Ver Comisión", value: "uuid" },
      ],
    };
  },
  mounted(){
    this.obtenerProductos()
    this.getCuotas()
    this.numeroTotalCuotas()
  },
  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },
    getCuotas(status) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      }
      let reporte = ''
      status == 1 ? reporte = 'comisionesByAgenteWithStatusPendientePagoAseguradora' :
      status == 2 ? reporte = 'comisionesByAgenteWithStatusCancelada' :
      status == 3 ? reporte = 'comisionesByAgenteWithStatusPendientePagoAgente' :
                    reporte = 'comisionesByAgenteWithStatusPagadaAgente'
      
      status == 1 ? this.downloadText = 'Comisiones_Pendiente_de_pago_Aseguradora.csv' :
      status == 2 ? this.downloadText = 'Comisiones_Canceladas.csv' :
      status == 3 ? this.downloadText = 'Comisiones_Pendiente_de_pago_Agente.csv' :
                    this.downloadText = 'Comisiones_Pagadas_al_Agente.csv'
      
      status == 1 ? this.titulo = 'Pendientes de pago Aseguradora' :
      status == 2 ? this.titulo = 'Canceladas' :
      status == 3 ? this.titulo = 'Pendientes de pago Agente' :
                    this.titulo = 'Pagadas al Agente'

      this.comisiones = []
      this.descargarComisiones = []
      //Obtener Pólizas
      mainAxios.get(`/v1/cuota/${reporte}/` + `${this.agenteUuid}`, config).then(resp => {
        console.log("Comisiones: ", resp.data);
        //Cargar datos en la tabla
        resp.data.map(element => {
          this.comisiones.push({
            idCuota: element.id,
            poliza: element.polizas == "undefined" ? 'No Disponible' : element.polizas,
            orden: element.orden ? element.orden : 'No Disponible',
            cliente: element.nombreCliente ? element.nombreCliente : 'No Disponible', 
            agente: element.nombreAgente ? element.nombreAgente : 'No Disponible', 
            statusComision: element.status ? element.status : 'No Disponible',
            porcentajeComisionAgente: element.porcentajeComisionAgente ? element.porcentajeComisionAgente : 'No Disponible',
            importePagarAgente: element.importeAPagarAgente ? element.importeAPagarAgente : 'No Disponible',
            folioFacturaPagar: element.folioFacturaAPagar ? element.folioFacturaAPagar : 'No Disponible',
            uuid: element.uuid
          })
        })
        //Datos para Descargar reporte
        resp.data.map(item => {
          this.descargarComisiones.push({
            "Cuota": item.id,
            "Póliza": item.polizas == "undefined" ? 'No Disponible' : item.polizas,
            "Orden": item.orden ? item.orden : 'No Disponible',
            "Cliente": item.nombreCliente ? item.nombreCliente : 'No Disponible',
            "Agente": item.nombreAgente ? item.nombreAgente : 'No Disponible',
            "Ejecutivo": item.nombreEjecutivo ? item.nombreEjecutivo : 'No Disponible',
            "Estatus de la Comision": item.status ? item.status : 'No Disponible',
            "% Comision Agente": item.porcentajeComisionAgente ? item.porcentajeComisionAgente : 'No Disponible',
            "Importe a Pagar al Agente": item.importeAPagarAgente ? item.importeAPagarAgente : 'No Disponible',
            "Folio de la Factura a Pagar": item.folioFacturaAPagar ? item.folioFacturaAPagar : 'No Disponible',
          })
        })
      });
    },
    numeroTotalCuotas() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      }

      //Comisiones Pendiente de Pago Aeguradora
      mainAxios.get('/v1/cuota/comisionesByAgenteWithStatusPendientePagoAseguradora/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.comisionesPendientePagoAseguradora ++;
        })
      });
      //Comisiones Canceladas
      mainAxios.get('/v1/cuota/comisionesByAgenteWithStatusCancelada/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.comisionesCanceladas ++;
        })
      });
      //Comisiones Pendiente de Pago al Agente
      mainAxios.get('/v1/cuota/comisionesByAgenteWithStatusPendientePagoAgente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map( element => {
          element
          this.comisionesPendientePagoAgente ++;
        })
      });
      //Comisiones Pagada al Agente
      mainAxios.get('/v1/cuota/comisionesByAgenteWithStatusPagadaAgente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.comisionesPagadaAgente ++;
        })
      });
    },
    
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/producto/list', config).then(response => {
        // console.log('Productos: ', response.data);
        response.data.map(element => {
          this.productos.push({
            nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId
          })
        })
          //this.obtenerRamo(this.emision.productoId)
          //this.obtenerAseguradoraId(this.emision.productoId)
      });
    },
    obtenerRamo(id){
      let productoSelected = this.productos.find(element => element.id === id)
      this.ramo = productoSelected ? productoSelected.ramo : null
    },
  },
};
</script>

<style scoped>
.card {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 15px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}
.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid black;
  background-color: rgba(21, 101, 192, 1);
}
.card:hover:before {
  transform: scale(6.15);
}
</style>