<template>
  <v-row>
    <v-col cols="12">
      <common-card>
        <v-container>
          <v-row class="mb-3">
            <v-col cols="3" md="1" class="text-center">
              <img
                src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Vector.png"
                alt=""
                height="50"
              />
            </v-col>
            <v-col cols="9">
              <h2>Confirmación de datos</h2>
              <span>
                Verifica que tus datos sean correctos
              </span>
            </v-col>
          </v-row>
        </v-container>
        <v-row class="mt-5 ml-md-8">
          <v-col cols="12">
            <v-row class="d-flex align-center">
              <v-col cols="6" md="3">
                <h2 class="ml-2 ml-md-0">Títular</h2>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn style="background: #029ECF" outlined color="white" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="ml-2 ml-md-0">
                <v-row class="mb-2">
                  <v-col cols="12" md="6" lg="8" class="d-flex align-center">
                    <v-row>
                      <v-col cols="12" md="6" lg="4">
                        <h3>Nombre</h3>
                        <span>{{ mascota.ownerData.name }}</span>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <h3>Apellido P</h3>
                        <span>{{ mascota.ownerData.surname }}</span>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <h3>Apellido M</h3>
                        <span>{{ mascota.ownerData.lastname }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" class="d-sm-block d-none">
                    <img
                      src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Group+270988654.png"
                      alt=""
                      height="150"
                    />
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                  <v-col
                    cols="11"
                    class="ml-5 d-none d-md-block"
                    style="border-top: 1px solid #029ECF;"
                  >
                  </v-col>
                  <v-col cols="6" md="3">
                    <h2 class="ml-1 ml-md-0">Mascota</h2>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-btn
                      style="background: #029ECF"
                      outlined
                      color="white"
                      icon
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="ml-1 ml-md-0">
                    <v-row class="mb-2">
                      <v-col cols="12" class="d-flex align-center">
                        <v-row>
                          <v-col cols="12" md="4">
                            <h3>Nombre de la mascota</h3>
                            <span>{{ mascota.name }}</span>
                          </v-col>
                          <v-col cols="12" md="4">
                            <h3>Tipo de cuatripatitas</h3>
                            <span>{{ mascota.type }}</span>
                          </v-col>
                          <v-col cols="12" md="4">
                            <h3>Email</h3>
                            <span>{{ mascota.ownerData.email }}</span>
                          </v-col>
                        </v-row>
                      </v-col></v-row
                    ></v-col
                  >
                    <v-col cols="12" class="d-block d-sm-none d-flex justify-center mb-3">
                    <img
                      src="https://placehold.co/150x150?text=JPG+W=150px+H=150px"
                      alt=""
                      height="150"
                    />
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center justify-sm-start">
                  <v-col cols="11" sm="5">
                    <v-btn
                      style="widht: 100%; padding: 6px!important"
                      color="#00A7E4"
                      outlined
                      block
                      dark
                      class="rounded-xl"
                      @click="goConfirmacion"
                    >
                      <span> Regresar</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="11" sm="6">
                    <v-btn
                      style="widht: 100%;"
                      color="#00A7E4"
                      block
                      dark
                      class="rounded-xl"
                      @click="goConfirmacion"
                    >
                      <span> Confirmar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </common-card>
    </v-col>
  </v-row>
</template>

<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
export default {
  name: "ConfirmacionDatos",
  components: { CommonCard },
  data() {
    return {
      mascota: null,
    };
  },
  methods: {},
  created() {
    if (
      this.$route.params.mascota &&
      typeof this.$route.params.mascota === "object"
    ) {
      localStorage.setItem(
        "mascota",
        JSON.stringify(this.$route.params.mascota)
      );
      this.mascota = this.$route.params.mascota;
    } else {
      const mascotaData = localStorage.getItem("mascota");
      if (mascotaData && mascotaData !== "[object Object]") {
        this.mascota = JSON.parse(mascotaData);
      }
    }
  },
};
</script>
<style scoped></style>
