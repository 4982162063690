import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "./tokens.service";

export const validarPagoEmision = async ({ polizaId }) => {
  const requestBody = { emision_id: polizaId };
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/inter/emision/validaPago",
      requestBody,
      config
    );
    
    return serverResponse

};