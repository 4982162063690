<template>
  <v-row style="padding: 20px">
    <v-col cols="4">
      <v-select
        label="Nivel Hospitalario"
        placeholder="Requerido"
        :items="['Alto', 'Medio', 'Bajo']"
        v-model="nivelHospitalario"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Suma asegurada"
        placeholder="Requerido"
        prefix="$"
        v-model="sumaAsegurada"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Deducible"
        placeholder="Requerido"
        type="number"
        v-model="deducible"
        outlined
      ></v-text-field>
    </v-col>

    <v-col cols="4">
      <v-text-field
        label="Coaseguro"
        placeholder="Requerido"
        v-model="coaseguro"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Cero deducible por accidente"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="ceroDeduciblePorAccidente"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Medicamentos fuera de hospital"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="medicamentosFueraDeHospital"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Emergencia en el Extranjero"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="emergenciaEnExtranjero"
        outlined
      ></v-select>
    </v-col>

    <v-col cols="4">
      <v-select
        label="Enfermedades Catastróficas"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="enfermedadesCatastroficas"
        outlined
      ></v-select>
    </v-col>

    <v-col cols="4">
      <v-select
        label="Cobertura en el Extranjero"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="coberturaExtranjero"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Preexistencias"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="preexistencias"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Protección Dental"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="proteccionDental"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Maternidad Extendida"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="maternidadExtendida"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Cobertura Nacional (Multirregión)"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="coberturaNacional"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Reconocimiento de Antigüedad"
        placeholder="Requerido"
        :items="coberturasAdicionalesItems"
        v-model="reconocimientoAntiguedad"
        outlined
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Indicar las fechas para cotizar"
        placeholder="Requerido"
        v-model="fechasParaCotizar"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-textarea
        label="Dependientes: En su caso se debe indicar la edad, sexo y parentesco de cada uno"
        placeholder="Requerido"
        rows="2"
        auto-grow
        v-model="dependientes"
        outlined
      ></v-textarea>
    </v-col>

    <v-btn color="primary" @click="sendSolicitud()" :disabled="!botonGuardar">
      Guardar
    </v-btn>
    <v-btn
      color="primary"
      style="margin-left: 10px"
      v-show="rolToCompare != 'AGENT'"
      @click="$emit('nextStep')"
    >
      Siguiente
    </v-btn>

    <!-- ALERT MODAL -->
    <v-col cols="auto">
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="400"
        v-model="dialog"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
            <v-card-text>
              <div class="text-h4" style="margin-top: 10px; ">
                Envio de solicitud exitoso!
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <!-- END OF ALERT MODAL -->
  </v-row>
</template>

<script>
import moment from "moment";
import { mainAxios } from "../../../mainAxios";
export default {
  props: {
    origenSolicitud: String,
    statusSolicitud: String,
    agente: Number,
    tipo: String,
    ramo: String,
    producto: Number,
    detalleSolicitud: String,
    nombres: String,
    apellidoPaterno: String,
    apellidoMaterno: String,
    estadoCivil: String,
    estadoNacimiento: Number,
    genero: String,
    fechaNacimiento: String,
    rfc: String,
    tipoPersona: String,
    telefono: String,
    correo: String,
    codigoPostal: String,
    direccion: String,
    inicioVigencia: String,
    finVigencia: String,
    periodicidad: String,
    formaPago: String,
    operador: Number,
    solicitudCompleta: String,
    procesoCotizacion: String,
    comercialAsignado: Number,
    razonSocial: String,
    leadOrigen: Object,
    newFormProps: Object,
    esDesdeDialog: Boolean,
    numeroPoliza: String,

  },
  data() {
    return {
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      dialog: false,
      nivelHospitalario: null,
      sumaAsegurada: null,
      deducible: null,
      coaseguro: null,
      ceroDeduciblePorAccidente: null,
      medicamentosFueraDeHospital: null,
      emergenciaEnExtranjero: null,
      enfermedadesCatastroficas: null,
      coberturaExtranjero: null,
      preexistencias: null,
      proteccionDental: null,
      maternidadExtendida: null,
      coberturaNacional: null,
      reconocimientoAntiguedad: null,
      fechasParaCotizar: null,
      dependientes: null,
      coberturasAdicionalesItems: ["Si ampara", "No ampara"],
    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 2500);
    },
  },
  methods: {
    sendSolicitud() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let request = {
        /* CAMPOS DE COMPONENTE PADRE */
        origenSolicitudSelected: this.origenSolicitud,
        agenteId: this.agente,
        tipoSelected: this.tipo,
        categoriaSelected: this.ramo,
        productoId: this.producto,
        detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
        nombres: this.nombres,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        estadoCivilSelected: this.estadoCivil,
        estadoRepublicaSelected: this.estadoNacimiento,
        genero: this.genero,
        fechaNacimiento: this.fechaNacimiento
          ? moment(this.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        rfc: this.rfc,
        regimen_persona: this.tipoPersona,
        celular: this.telefono,
        correoElectronico: this.correo,
        codigoPostal: this.codigoPostal,
        direccion: this.direccion,
        inicioVigencia: moment(this.inicioVigencia, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        finVigencia: moment(this.finVigencia, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        periodicidadPago:
          this.periodicidad == "Anual"
            ? 1
            : this.periodicidad == "Semestral"
            ? 2
            : this.periodicidad == "Trimestral"
            ? 3
            : this.periodicidad == "Mensual"
            ? 4
            : "",
        formaPagoSelected: this.formaPago,
        operadorUuid: this.operador,
        solicitudCompletaSelected: this.solicitudCompleta
          ? this.solicitudCompleta
          : false,
        procesoCotizacionSelected: this.procesoCotizacion,
        checkPendienteInfo: this.checkPendienteInfo
          ? this.checkPendienteInfo
          : null,
        checkCancelacion: this.checkCancelacion ? this.checkCancelacion : null,
        causaPendienteInformacion: this.causaPendienteInformacion
          ? this.causaPendienteInformacion
          : null,
        comercial_asignado: this.comercialAsignado
          ? this.comercialAsignado
          : null,
        razon_social: this.razonSocial ? this.razonSocial : null,
        lead_id: this.leadOrigen ? this.leadOrigen.id : "",
        /* FIN DE CAMPOS DE COMPONENTE PADRE */

        /* CAMPOS DE COMPONENTE HIJO(RAMO) */
        nivelHospitalario: this.nivelHospitalario,
        sumaAsegurada: this.sumaAsegurada,
        deducible: this.deducible,
        coaseguro: this.coaseguro,
        ceroDeduciblePorAccidente: this.ceroDeduciblePorAccidente,
        medicamentosFueraDeHospital: this.medicamentosFueraDeHospital,
        emergenciaEnExtranjero: this.emergenciaEnExtranjero,
        enfermedadesCatastroficas: this.enfermedadesCatastroficas,
        coberturaExtranjero: this.coberturaExtranjero,
        preexistencias: this.preexistencias,
        proteccionDental: this.proteccionDental,
        maternidadExtendida: this.maternidadExtendida,
        coberturaNacional: this.coberturaNacional,
        reconocimientoAntiguedad: this.reconocimientoAntiguedad,
        fechasParaCotizar: this.fechasParaCotizar,
        dependientes: this.dependientes,
        ...this.newFormProps,
        emit_direct: this.emitDirect,
        operadorId: JSON.parse(localStorage.getItem("roles"))[0].agente_id
          ? JSON.parse(localStorage.getItem("roles"))[0].agente_id
          : "777",
        checkTramite: this.emitDirect,
        numeroPoliza: this.numeroPoliza ? this.numeroPoliza : "",
      };

      mainAxios
        .post("v1/solicitud/insert/", request, config)
        .then((response) => {
          if (response.status == 200) {
            const ruta = window.location.pathname;
            if (ruta.includes("/segurify/leads-segurify/lead/")) {
              this.$emit("SolicitudSuccess");
            } else {
              this.dialog = true;
              !this.esDesdeDialog &&
                setTimeout(() => this.$router.push("/solicitudes"), 2500);
              this.esDesdeDialog &&
                setTimeout(() => (this.dialog.value = false), 2500);
              this.$emit("SolicitudSuccess");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    botonGuardar() {
      if (this.tipoPersona == "Moral") {
        return (
          this.origenSolicitud != null &&
          this.statusSolicitud != null &&
          this.agente != null &&
          this.tipo != null &&
          this.ramo != null &&
          this.estadoNacimiento != null &&
          this.detalleSolicitud != null &&
          this.tipoPersona != null &&
          this.razonSocial != null &&
          this.rfc != null &&
          this.telefono != null &&
          this.correo != null &&
          this.codigoPostal != null &&
          this.inicioVigencia != null &&
          this.finVigencia != null &&
          this.periodicidad != null &&
          this.formaPago != null &&
          /* CAMPOS DEL RAMO */
          this.nivelHospitalario != null &&
          this.sumaAsegurada != null &&
          this.deducible != null &&
          this.coaseguro != null &&
          this.ceroDeduciblePorAccidente != null &&
          this.medicamentosFueraDeHospital != null &&
          this.emergenciaEnExtranjero != null &&
          this.enfermedadesCatastroficas != null &&
          this.coberturaExtranjero != null &&
          this.preexistencias != null &&
          this.proteccionDental != null &&
          this.maternidadExtendida != null &&
          this.coberturaNacional != null &&
          this.reconocimientoAntiguedad != null &&
          this.fechasParaCotizar != null
        );
      }
      return (
        this.origenSolicitud != null &&
        this.statusSolicitud != null &&
        this.agente != null &&
        this.tipo != null &&
        this.ramo != null &&
        this.detalleSolicitud != null &&
        this.nombres != null &&
        this.apellidoPaterno != null &&
        this.genero != null &&
        this.fechaNacimiento != null &&
        this.estadoNacimiento != null &&
        this.estadoCivil != null &&
        this.tipoPersona != null &&
        this.rfc != null &&
        this.telefono != null &&
        this.correo != null &&
        this.codigoPostal != null &&
        this.inicioVigencia != null &&
        this.finVigencia != null &&
        this.periodicidad != null &&
        this.formaPago != null &&
        /* CAMPOS DEL RAMO */
        this.nivelHospitalario != null &&
        this.sumaAsegurada != null &&
        this.deducible != null &&
        this.coaseguro != null &&
        this.ceroDeduciblePorAccidente != null &&
        this.medicamentosFueraDeHospital != null &&
        this.emergenciaEnExtranjero != null &&
        this.enfermedadesCatastroficas != null &&
        this.coberturaExtranjero != null &&
        this.preexistencias != null &&
        this.proteccionDental != null &&
        this.maternidadExtendida != null &&
        this.coberturaNacional != null &&
        this.reconocimientoAntiguedad != null &&
        this.fechasParaCotizar != null
      );
    },
    emitDirect() {
      if (
        this.roles == "ADMINCC" ||
        this.roles == "MANAGERCC" ||
        this.roles == "OPERADORCC" ||
        this.roles == "AGENTCC" ||
        this.roles == "AGENTCCSPONSOR"
      ) {
        return true;
      } else return false;
    },
  },
};
</script>

<style></style>
