<template>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Plan
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.plan ? ramoData.plan : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fumador
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.fumador ? ramoData.fumador : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Suma Asegurada
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sumaAsegurada ? ramoData.sumaAsegurada : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Muerte Accidental
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.muerteAccidental ? ramoData.muerteAccidental : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Perdidas Orgánicas
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.perdidasOrganicas ? ramoData.perdidasOrganicas : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Invalidez total y permanente
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.invalidezTotal ? ramoData.invalidezTotal : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Gastos funerarios
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.gastosFunerarios ? ramoData.gastosFunerarios : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },

  methods: {},
};
</script>
