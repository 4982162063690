<template>
  <div>
    <h2 class=" mt-5 mb-1">Crear contraseña</h2>
    <span
      >Para mantener la seguridad de su cuenta crear una contraseña
      segura.</span
    >
    <v-row class="mt-3">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="localPassword"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Contraseña"
          outlined
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="repeatpassword"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          label="Contraseña"
          outlined
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>

      <v-row justify="center">
        <v-col cols="12" class="text-md-center">
          <h3 class="h3-password mb-negative-30">
            La contraseña debe contener:
          </h3>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox
            :value="isLengthValid"
            :color="isLengthValid ? 'green' : 'grey'"
            readonly
            class="mb-negative-30"
          >
            <template slot="label">
              <span :class="isLengthValid ? 'valid-text' : 'invalid-text'"
                >Entre 8 y 20 caracteres</span
              >
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox
            :value="hasUppercase"
            :color="hasUppercase ? 'green' : 'grey'"
            readonly
            class="mb-negative-30"
          >
            <template slot="label">
              <span :class="hasUppercase ? 'valid-text' : 'invalid-text'"
                >1 letra mayúscula</span
              >
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox
            :value="hasNumber"
            :color="hasNumber ? 'green' : 'grey'"
            readonly
            class="mb-negative-30"
          >
            <template slot="label">
              <span :class="hasNumber ? 'valid-text' : 'invalid-text'"
                >1 o más números</span
              >
            </template>
          </v-checkbox></v-col
        >
        <v-col cols="12" md="2">
          <v-checkbox
            :value="hasSpecialChar"
            :color="hasSpecialChar ? 'green' : 'grey'"
            label="1 o más caracteres especiales"
            readonly
            class="mb-negative-30"
          >
            <template slot="label">
              <span :class="hasSpecialChar ? 'valid-text' : 'invalid-text'"
                >1 o más caracteres especiales</span
              >
            </template>
          </v-checkbox></v-col
        >
        <v-col cols="12" md="2">
          <v-checkbox
            :value="hasPasswordIsEquals"
            :color="hasPasswordIsEquals ? 'green' : 'grey'"
            label="1 o más caracteres especiales"
            readonly
          >
            <template slot="label">
              <span :class="hasPasswordIsEquals ? 'valid-text' : 'invalid-text'"
                >Las contraseñas son iguales</span
              >
            </template>
          </v-checkbox></v-col
        >
        <v-col cols="12" class="d-flex justify-center justify-md-end mb-5">
          <v-btn
            color="#00A7E4"
            dark
            class="rounded-xl"
            width="150px"
            @click="insert"
            :disabled="
              !(
                isLengthValid &&
                hasUppercase &&
                hasNumber &&
                hasSpecialChar &&
                hasPasswordIsEquals
              )
            "
          >
            Confirmar
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CrearContrasenia",
  props: { password: String },
  data() {
    return {
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Campo requerido",
        emailMatch: () => `The email and password you entered don't match`,
      },
      repeatpassword: "",
      radioGroup: null,
      localPassword: this.password,
    };
  },
  methods: {
    insert() {
      this.$emit("insert", this.repeatpassword);
      window.dataLayer.push({
        event: "ITFY_datos_contraseña",
        boton_contacto: "Confirmar"
      })
    },
  },
  computed: {
    isLengthValid() {
      return this.localPassword.length >= 8 && this.localPassword.length <= 20;
    },
    hasUppercase() {
      return /[A-Z]/.test(this.localPassword);
    },
    hasNumber() {
      return /[0-9]/.test(this.localPassword);
    },
    hasSpecialChar() {
      return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(this.localPassword);
    },
    hasPasswordIsEquals() {
      return this.localPassword === this.repeatpassword;
    },
  },
  mounted() {
    window.dataLayer.push({
      event: 'ITFY_datos_contraseña_v',
      no_cotizacion: undefined,
      userID: undefined,
      correo: undefined,
      telefono: undefined,
    });
  },
};
</script>
<style scoped>
.valid-text {
  color: green;
}

.invalid-text {
  color: #039ecc;
}
.h3-password {
  font-weight: bold;
  color: #039ecc;
}
.mb-negative-30 {
  margin-bottom: -50px;
}
</style>
