<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="phone.name"
          label="Nombre*"
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="phone.surname"
          label="Apellido paterno*"
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="phone.lastname"
          label="Apellido materno"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="phone.birthday"
          type="date"
          label="Fecha de nacimiento*"
          outlined
          :rules="required"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="phone.numberPhone"
          label="Número telefónico*"
          :rules="phoneValidation"
          outlined
          type="tel"
          maxlength="10"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="phone.email"
          label="Correo electrónico*"
          outlined
          :rules="emailValidation"
          type="email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="phone.state"
          :items="states"
          item-text="dEstado"
          item-value="cEstado"
          label="Lugar de nacimiento*"
          :rules="required"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="phone.cp"
          label="Código postal*"
          outlined
          :rules="required"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="phone.invoiceAmount"
          label="Monto de factura*"
          outlined
          :rules="required"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="phone.model"
          :items="phonesModels"
          item-text="name"
          item-value="name"
          label="Modelo*"
          :rules="required"
          outlined
          return-object
          @change="onModelChange"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <span>Opción de pago:*</span>
        <v-radio-group v-model="phone.optionPay" row :rules="required">
          <v-radio label="un solo pago" value="1"></v-radio>
          <v-radio label="12 Meses sin intereses" value="12"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" class="d-flex justify-center justify-md-end">
        <v-col cols="6" md="2">
          <v-btn
            color="#00A7E4"
            @click="createPrice"
            block
            dark
            class="rounded-xl"
            :disabled="!allFieldsValidated()"
          >
            Cotizar
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { smartPhonesModels } from "../../../../helpers/devicesModels";
import {
  requiredRule,
  emailRules,
  phoneRules,
} from "../../../../helpers/validacionesCampos";
export default {
  name: "FormularioCelular",
  props: {
    phone: Object,
    states: Array,
  },
  data() {
    return {
      required: requiredRule,
      emailValidation: emailRules,
      phoneValidation: phoneRules,
      phonesModels: smartPhonesModels,
    };
  },
  methods: {
    allFieldsValidated() {
      return [
        this.phone.name,
        this.phone.surname,
        this.phone.birthday,
        this.phone.numberPhone,
        this.phone.email,
        this.phone.state,
        this.phone.cp,
        this.phone.invoiceAmount,
        this.phone.model,
        this.phone.optionPay,
      ].every((field) => !!field);
    },
    createPrice() {
      this.$emit("createPrice");
    },
    onModelChange(onModelChange) {
      this.phone.brand = onModelChange.brand;
      this.phone.model = onModelChange.name;
    },
  },
};
</script>
<style scoped></style>
