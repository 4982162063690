<template>
  <div class="pt-5">
    <commond-form
      :request="solicitudData"
      :route="`/v1/solicitud/update/${solicitud.uuid}`"
      :run="run"
      action="PUT"
      @cancel="run = false"
      @success="solicitudUpdated"
      @afterError="run = false"
    >
      <template #data>
        <v-row>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!isAgente">
            <v-autocomplete
              label="Origen de la solicitud"
              :items="itemsOrigenSolicitud"
              v-model="solicitudData.origenSolicitudSelected"
              placeholder="Requerido"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!isAgente">
            <v-autocomplete
              label="Agente"
              :items="agentes"
              item-text="nombre"
              item-value="id"
              v-model="solicitudData.agente"
              placeholder="Requerido"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="isAgenteDirecto">
            <v-autocomplete
              label="Comercial Asignado"
              :items="comercialAsignadoList"
              item-text="nombre"
              item-value="id"
              v-model="solicitudData.comercial_asignado"
              :readonly="isAgente == true"
              placeholder="Requerido"
              :rules="requiredRule"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Tipo de solicitud"
              :items="tiposSolicitud"
              v-model="solicitudData.tipoSelected"
              placeholder="Requerido"
              :rules="requiredRule"
              outlined
              :no-data-text="'No se encontraron resultados...'"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="esInbound && solicitudData.tipoSelected != null"
          >
            <v-autocomplete
              label="Actividad de solicitud"
              :items="actidadesSolicitud"
              v-model="solicitudData.actividadSolicitud"
              placeholder="Requerido"
              :rules="requiredRule"
              :no-data-text="'No se encontraron resultados...'"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col v-if="!esInbound" m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Ramo"
              :items="currentRamos"
              v-model="solicitudData.categoriaSelected"
              placeholder="Requerido"
              item-text="ramo"
              item-value="ramo"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="!isAgente && !esInbound" m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Productos"
              v-model="solicitudData.producto_id"
              :items="currentproducto_id"
              item-text="nombre"
              item-value="id"
              placeholder="Requerido"
              :rules="requiredRule"
              outlined
              color="#00a7e4"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" xs="12" v-if="esInbound">
            <v-file-input
              v-model="solicitudData.archivos"
              color="deep-purple accent-4"
              counter
              multiple
              placeholder="Archivo INE"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ ineFile.length - 2 }} Archivos(s)
                </span>
              </template>
            </v-file-input>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Campaña"
              v-model="solicitudData.campania_id"
              :items="esInbound ? campaniasInbound : currentCampanias"
              item-text="producto"
              item-value="id"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="6" xl="3" v-if="!esInbound">
            <v-text-field
              v-model="solicitudData.idOrigenLead"
              label="ID Origen Lead"
              :rules="requiredRule"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="12">
            <v-textarea
              label="Detalle Solicitud"
              v-model="solicitudData.detalle"
              rows="2"
              auto-grow
              :rules="requiredRule"
              outlined
            ></v-textarea>
          </v-col>

        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('cancel')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";

export default {
  components: {
    CommondForm,
  },
  props: {
    solicitud: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      run: false,
      solicitudData: {
        origenSolicitudSelected: null,
        agente: null,
        categoriaSelected: null,
        comercial_asignado: null,
        tipoSelected: null,
        actividadSolicitud: null,
        producto_id: null,
        detalle: null,
        archivos: null,
        campania_id: null,
        idOrigenLead: null,
        anios: null,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
      },
      tipo_polizaPolizaList: ["Nueva", "Renovación"],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      campanias: [],
      campaniasInbound: [],
      agentes: [],
      ramos: [],
      producto_id: [],
      productoOrigal: [],
      tiposSolicitud: [],
      tiposSolicitudCompletos: [],
      pago_idList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
      forma_pagoList: [
        { id: 1, name: "Tarjeta de Crédito" },
        { id: 2, name: "Tarjeta de Débito" },
        { id: 3, name: "Depósito en banco" },
        { id: 4, name: "Transferencia" },
        { id: 5, name: "Cheque" },
        { id: 6, name: "Efectivo" },
        { id: 7, name: "Domiciliación" },
        { id: 8, name: "Cargo único" },
      ],
      currentPanel: 0,
      readonly: true,

      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
    };
  },
  watch: {
    // "solicitudData.agente"() {
    //   this.solicitudData.campania_id = null;
    //   this.solicitudData.categoriaSelected = null;
    //   this.solicitudData.producto_id = null;
    // },

    // "solicitudData.categoriaSelected"() {
    //   this.solicitudData.campania_id = null;
    //   this.solicitudData.producto_id = null;
    // },

    "solicitudData.inicio"(val) {
      var date = moment(val).format("YYYY-MM-DD");
      if (date != "Invalid date")
        this.solicitudData.fin = moment(date)
          .add(1, "year")
          .format("YYYY-MM-DD");
    },

    "solicitudData.pago_id"() {
      this.calculateCuotas();
    },

    "solicitudData.anios"(v) {
      if (this.solicitudData.inicioVigencia && !!v) {
        const inicioDate = new Date(this.solicitudData.inicioVigencia);
        inicioDate.setFullYear(inicioDate.getFullYear() + Number(v));
        this.solicitudData.finVigencia = inicioDate.toISOString().split("T")[0];
      }
    },

    "solicitudData.producto_id"(id) {
      let producto = this.producto_id.find((element) => element.id === id);
      this.solicitudData.aseguradora_id = producto
        ? producto.aseguradoraId
        : null;
    },

    "solicitudData.campania_id"(id) {
      let campania = this.campanias.find((element) => element.id === id);
      this.solicitudData.campania = campania ? campania.producto : null;
    },

    "solicitudData.campania"(val) {
      if (this.esInbound) {
        var currentCampania = this.campaniasInbound.find(
          (e) => e.campania == val
        );
        this.form.agente = currentCampania.agenteId;
        this.form.productoSelected = currentCampania.productoId;
        this.form.idOrigenLead = 1;
        this.form.categoriaSelected = currentCampania.ramo;
      }
    },

    "solicitudData.tipoSelected"(val) {
      if (this.esInbound) {
        this.actidadesSolicitud = [];
        var result = this.tiposSolicitudCompletos.filter(
          (e) => e.tipo == val && e.inbound == 1
        );
        result.map((e) => {
          this.actidadesSolicitud.push(e.actividad);
        });
        this.actidadesSolicitud.sort();
        this.solicitudData.actividadSolicitud = null;
      }
    },

    "solicitudData.inicioVigencia"(v) {
      if (this.solicitudData.anios && !!v) {
        const inicioDate = new Date(v);
        inicioDate.setFullYear(
          inicioDate.getFullYear() + Number(this.solicitudData.anios)
        );
        this.solicitudData.finVigencia = inicioDate.toISOString().split("T")[0];
      }
    },
  },
  computed: {
    currentRamos() {
      if (this.solicitudData.agente != null) {
        const agente = this.agentes.find(
          (e) => e.id == this.solicitudData.agente
        );
        return agente ? agente.ramosList : [];
      } else return [];
    },
    currentproducto_id() {
      if (this.solicitudData.categoriaSelected != null)
        return this.producto_id.filter(
          (e) => e.ramo == this.solicitudData.categoriaSelected
        );
      else return [];
    },
    currentCampanias() {
      if (this.solicitudData.categoriaSelected != null)
        return this.campanias.filter(
          (e) => e.ramo == this.solicitudData.categoriaSelected
        );
      else return [];
    },

    isAgente() {
      return this.rol == "AGENT";
    },

    isAgenteDirecto() {
      const agente = this.agentes.find(
        (a) => a.id == this.solicitudData.agente
      );
      if (!agente) return false;

      return !!agente.canalList.find((canal) => canal.id == 3);
    },

    esInbound() {
      return this.rol.includes("INBOUND");
    },

    itemsOrigenSolicitud() {
      if (this.esInbound) {
        return [
          "Correo electrónico",
          "Llamada telefónica entrada",
          "Llamada telefónica Salida",
          "Whatsapp",
          "Redes sociales",
        ];
      } else {
        return [
          "Correo electrónico",
          "Llamada telefónica",
          "Presencial",
          "Whatsapp",
          "Portal",
          "Otro",
        ];
      }
    },
  },
  methods: {
    getCampanias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.campanias = response.data;
          const currentCampania = this.campanias.find(
            (c) => c.producto == this.solicitud.campania
          );
          console.log({ currentCampania, campanias: this.campanias, query: this.solicitud.campania })
          if (currentCampania)
            this.solicitudData.campania_id = currentCampania.id;
        });
    },

    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
            ramosList: element.ramosList ? element.ramosList : [],
          });
        });
      });
    },

    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/solicitud/agente/directo/list", config)
        .then((response) => {
          this.comercialAsignadoList = response.data;
        });
    },

    getRamos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramos = response.data;
      });
    },

    getproducto_id() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.producto_id.push({
            nombre:
              `${element.ramo}` +
              " / " +
              `${element.nombre}` +
              " / " +
              `${element.nombreAseguradora}`,
            id: element.id,
            canal: element.canal ? element.canal : "Todos",
            ramo: element.ramo ? element.ramo : "No disponible",
            aseguradoraId: element.aseguradoraId
              ? element.aseguradoraId
              : "No disponible",
            tecnologia: element.tecnologia ? element.tecnologia : false,
          });
        });
        this.productoOrigal = this.producto_id;
      });
    },

    getTipoSolitud() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/tipoSolicitud/list", config).then((response) => {
        console.log({ response: response.data });
        this.tiposSolicitudCompletos = [];
        this.tiposSolicitud = [];
        if (this.rol.includes("INBOUND"))
          this.tiposSolicitudCompletos = response.data.filter(
            (e) => e.inbound == "1"
          );
        else
          this.tiposSolicitudCompletos = response.data.filter(
            (e) => e.inbound == "0"
          );

        this.tiposSolicitudCompletos.map((e) => {
          this.tiposSolicitud.push(e.tipo);
        });
        this.tiposSolicitud = this.tiposSolicitud.filter(function(
          valor,
          indice,
          arreglo
        ) {
          return arreglo.indexOf(valor) === indice;
        });
        this.tiposSolicitud.sort();
      });
    },

    getCampaniasInbound() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/campaniasInboundEci/list", config).then((response) => {
        this.campaniasInbound = response.data;
        this.campaniasInbound.sort(function(a, b) {
          var campaniaA = a.campania.toUpperCase();
          var campaniaB = b.campania.toUpperCase();
          if (campaniaA < campaniaB) {
            return -1;
          }
          if (campaniaA > campaniaB) {
            return 1;
          }
          return 0;
        });
      });
    },

    calculateDates() {
      this.solicitudData.inicio = moment().format("YYYY-MM-DD");
      this.solicitudData.fechaPrimerPago = moment().format("YYYY-MM-DD");
      this.solicitudData.fin = moment()
        .add(1, "year")
        .format("YYYY-MM-DD");
    },
    calculateCuotas() {
      switch (this.solicitudData.pago_id) {
        case 1:
          this.solicitudData.numeroCuotas = 1 * this.solicitudData.anios;
          break;
        case 2:
          this.solicitudData.numeroCuotas = 2 * this.solicitudData.anios;
          break;
        case 3:
          this.solicitudData.numeroCuotas = 4 * this.solicitudData.anios;
          break;
        case 4:
          this.solicitudData.numeroCuotas = 12 * this.solicitudData.anios;
          break;
        default:
          break;
      }
    },
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("ChangeOnlyStep", 2, this.solicitudData);
      }
    },

    setCurrentData() {
      this.solicitudData = {
        origenSolicitudSelected: this.solicitud.origen,
        agente: this.solicitud.agenteId,
        categoriaSelected: this.solicitud.categoria,
        comercial_asignado: this.solicitud.comercialAsignado,
        tipoSelected: this.solicitud.tipo,
        actividadSolicitud: this.solicitud.actividadSolicitud,
        producto_id: this.solicitud.productoId,
        detalle: this.solicitud.detalle,
        archivos: null,
        idOrigenLead: this.solicitud.idOrigenLead,
        anios: this.solicitud.anios,
        inicioVigencia: this.solicitud.inicioVigencia
          .split("/")
          .reverse()
          .join("-"),
        finVigencia: this.solicitud.finVigencia
          .split("/")
          .reverse()
          .join("-"),
        periodicidadPago: this.solicitud.periodicidadSelected
          ? this.periodicidadItems.find(
              (p) => p.text == this.solicitud.periodicidadSelected
            ).value
          : null,
        formaPagoSelected: this.solicitud.formaPagoSelected,
      };
    },

    solicitudUpdated(v) {
      this.$emit("getData")
      console.log({ solicitudUpdated: v });
    },
  },
  mounted() {
    this.getCampanias();
    this.getAgentes();
    this.getRamos();
    this.getproducto_id();
    this.calculateDates();
    this.obtenerUsersCanalDirecto();
    this.getTipoSolitud();
    this.setCurrentData();
    if (this.rol == "AGENT") this.solicitudData.agente = localStorage.id;
  },
};
</script>
