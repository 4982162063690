<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
          v-if="canEdit"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <span class="text-header-data">
          Número de póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numeroPoliza ? formData.numeroPoliza : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      formData: {
        numeroPoliza: null,
      },
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
    };
  },
  props: {
    datosSolicitud: Object,
    canEdit: Boolean
  },

  methods: {
    fillData() {
      var jsonData = JSON.parse( this.datosSolicitud.body)
      this.formData.numeroPoliza = jsonData.numeroPoliza ? jsonData.numeroPoliza : null;
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
