<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Tipo de portafolios"
              v-model="formData.tipoPortafolios"
              :items="tipoPortafoliosList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Fumador"
              v-model="formData.fumador"
              :items="fumadorList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Plazo comprometido"
              v-model="formData.plazoComprometido"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Deducibilidad"
              v-model="formData.deducibilidad"
              :items="deducibilidadList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Revaluable con la inflación"
              v-model="formData.revaluableInflacion"
              :items="revaluableInflacionList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Valor del Plan"
              v-model="formData.valorPlan"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Monto de la inversión/Aportación"
              v-model="formData.montoInversion"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="afterSend()">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  components:{
    CommondForm
  },
  data() {
    return {
      formData: {
        id: null,
        tipoPortafolios: null,
        fumador: null,
        plazoComprometido: null,
        deducibilidad: null,
        revaluableInflacion: null,
        valorPlan: null,
        montoInversion: null,
      },

      //listas
      tipoPortafoliosList: [
        "Moderado",
        "Medio",
        "Agresivo",
        "Elite Inversión",
        "Patrimonial Inversión",
        "Plus",
        "Educacional",
      ],
      fumadorList: ["Si", "No"],
      deducibilidadList: ["Si", "No"],
      revaluableInflacionList: ["Si", "No"],

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
    };
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  mounted(){
    this.fillData()
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.ramoData[e]) {
          this.formData[e] = this.ramoData[e];
        }
      });
    },

    afterSend(){
        this.formData.prima_total = this.formData.valorPlan
        this.formData.prima_neta = this.formData.montoInversion
        this.formData.primer_pago = this.formData.montoInversion
        this.formData.subsecuente_pago = this.formData.montoInversion
        this.run = true
    }
  },
};
</script>
