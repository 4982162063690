const getCorreosFijos = ({ rol, canales }) => {
  if (!canales || !rol) return [];
  const result = [];
  const buscarCanal = (canalId) => canales.find((c) => c === canalId);

  if (rol === "AGENT" && buscarCanal(1)){
    if(!result.includes('operaciones@interify.com')){
        result.push("operaciones@interify.com");
    }
  }
  if (rol === "AGENT" && buscarCanal(2)){
    if(!result.includes('atencionaclientes@interify.com')){
      result.push("atencionaclientes@interify.com");
    }
  }
  if (rol === "AGENT" && buscarCanal(3)) {
    const correosCanal = [
      'operaciones@interify.com',
      'directo@interify.com'
    ]
    correosCanal.forEach(element => {
      if(!result.includes(element)){
        result.push(element);
      }
    });
  }
  return result;
};

export default getCorreosFijos;
