<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="textValue"
          outlined
          readonly
          :rules="[validateDates]"
          v-on="on"
          v-bind="attrs"
          :background-color="bgColor || null"
        >
          <template #append>
            <CommonSVGIcon icon="calendarBlack"></CommonSVGIcon>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        v-model="dates"
        range
        locale="es"
        :selected-items-text="`${dates.length} Seleccionados`"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substring(0, 10)
        "
        min="1950-01-01"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import CommonSVGIcon from "../icons/CommonSVGIcon.vue";
import moment from "moment";

export default {
  components: {
    CommonSVGIcon,
  },

  props: {
    bgColor: {
      type: String,
    },
    inicialDates: {
      type: Boolean,
      default: true,
    },
    cleanDates: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      dates: [],
      inicioUTC: null,
      finUTC: null,
    };
  },

  watch: {
    dates() {
      if (this.dates.length > 0) {
        if (this.dates[1]) {
          this.inicioUTC = moment(this.dates[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss");
          this.finUTC = moment(this.dates[1])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.inicioUTC = moment(this.dates[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss");
          this.finUTC = moment(this.dates[0])
            .tz("America/Mexico_City", true)
            .tz("UTC")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      this.$emit("updateDate", [this.inicioUTC, this.finUTC]);
    },
    cleanDates(val) {
      if (val) this.dates = [];
    },
  },

  computed: {
    textValue() {
      return `Fechas de  ${this.formatDate(this.dates[0]) || "--/--/--"}  a  ${
        this.formatDate(this.dates[1]) || "--/--/--"
      }`;
    },
  },

  methods: {
    formatDate(enDate) {
      if (!enDate) return null;
      const dateArray = enDate.split("-");
      return dateArray.reverse().join("/");
    },
    validateDates() {
      return this.dates.length === 2 || 'Debe haber exactamente 2 fechas seleccionadas.';
    },
  },

  mounted() {
    if (this.inicialDates) {
      this.dates = [
        moment().subtract(30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
    } else {
      this.dates = [];
    }
  },
};
</script>
