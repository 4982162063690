<template>
  <v-form>
    <v-row style="padding: 20px">
      <v-col cols="4">
        <v-select
          label="Tipo de Vehículo"
          placeholder="Requerido"
          v-model="tipoVehiculo"
          :items="tipoVehiculosItems"
          :disabled="!canEdit"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="usoAutoList"
          label="Uso del Auto"
          placeholder="Requerido"
          v-model="usoAuto"
          :disabled="!canEdit"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="origenAutoItems"
          label="Origen del Auto"
          placeholder="Requerido"
          v-model="origenAuto"
          :disabled="!canEdit"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Modelo"
          placeholder="Requerido"
          v-model="modelo"
          :items="modelos"
          item-value="id"
          item-text="id"
          auto-select-first
          :disabled="!canEdit"
          @change="getMarcas()"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Marca"
          placeholder="Requerido"
          v-model="marca"
          :items="marcas"
          item-value="marca"
          item-text="marca"
          no-data-text="Primero selecciona un modelo"
          auto-select-first
          :disabled="!canEdit"
          @change="getSubmarcas()"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          label="Submarca"
          placeholder="Requerido"
          v-model="submarca"
          :items="submarcas"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una marca"
          auto-select-first
          :disabled="!canEdit"
          @change="getVersiones()"
          outlined
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          label="Version"
          placeholder="Requerido"
          v-model="version"
          :items="versiones"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una submarca"
          auto-select-first
          :disabled="!canEdit"
          outlined
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-select
          :items="coberturas"
          label="Plan"
          placeholder="Requerido"
          v-model="plan"
          :disabled="!canEdit"
          outlined
        ></v-select>
      </v-col>
      <!-- CAMPOS SOLO PARA TIPO EMISION Y RENOVACION -->
      <v-col
        cols="4"
        v-if="
          tipo == 'Emisión' ||
            tipo == 'Renovación' ||
            (statusSolicitud == 'Pendiente de Aprobacion' &&
              checkCotizacionRenovacionAprobada)
        "
      >
        <v-text-field
          label="No. De Motor"
          placeholder="Requerido"
          v-model="numMotor"
        ></v-text-field>
      </v-col>

      <v-col
        cols="4"
        v-if="
          tipo == 'Emisión' ||
            tipo == 'Renovación' ||
            (statusSolicitud == 'Pendiente de Aprobacion' &&
              checkCotizacionRenovacionAprobada)
        "
      >
        <v-text-field
          label="No. De Serie"
          placeholder="Requerido"
          v-model="numSerie"
          :maxLength="17"
          :rules="[localRules.alphanumeric, $rules.min(15), $rules.max(17)]"
        ></v-text-field>
      </v-col>

      <v-col
        cols="4"
        v-if="
          tipo == 'Emisión' ||
            tipo == 'Renovación' ||
            (statusSolicitud == 'Pendiente de Aprobacion' &&
              checkCotizacionRenovacionAprobada)
        "
      >
        <v-text-field
          label="Placas"
          placeholder="Requerido"
          v-model="placas"
          :maxLength="10"
          :rules="[localRules.alphanumeric]"
        ></v-text-field>
      </v-col>
      <!-- FIN DE CAMPOS SOLO PARA TIPO EMISION Y RENOVACION -->

      <!-- ALERT MODAL -->
      <v-col cols="auto">
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="400"
          v-model="dialog"
        >
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
              <v-card-text>
                <div class="text-h4" style="margin-top: 10px; ">
                  Actualización de solicitud exitoso!
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  text
                  @click="(dialog.value = false), $router.push('/solicitudes')"
                  >Cerrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
      <!-- END OF ALERT MODAL -->
    </v-row>
    <v-row style="padding: 20px">
      <v-col cols="6">
        <v-text-field
          v-model="creadoPor"
          label="Creado por"
          readonly
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="fechaCreacion"
          label="Fecha de Creacion"
          readonly
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6" style="margin-top: -30px">
        <v-text-field
          v-model="modificadoPor"
          label="Modificado Por"
          readonly
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6" style="margin-top: -30px">
        <v-text-field
          v-model="fechaModificacion"
          label="Fecha de Modificacion"
          readonly
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="sendSolicitud()"
          :disabled="!comportamientoBotonGuardar()"
        >
          Guardar
        </v-btn>
        <v-btn
          color="primary"
          style="margin-left: 10px"
          v-show="rolToCompare != 'AGENT'"
          @click="$emit('nextStep')"
        >
          Siguiente
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import { mainAxios } from "../../../../mainAxios";
export default {
  props: {
    origenSolicitud: String,
    statusSolicitud: String,
    agente: Number,
    tipo: String,
    ramo: String,
    producto: Number,
    detalleSolicitud: String,
    nombres: String,
    apellidoPaterno: String,
    apellidoMaterno: String,
    estadoCivil: String,
    estadoNacimiento: String,
    genero: String,
    fechaNacimiento: String,
    rfc: String,
    tipoPersona: String,
    telefono: String,
    correo: String,
    codigoPostal: String,
    direccion: String,
    inicioVigencia: String,
    finVigencia: String,
    periodicidad: String,
    formaPago: String,
    operador: Number,
    solicitudCompleta: String,
    procesoCotizacion: String,
    solicitudId: Number,
    bodyAutos: Object,
    checkTramite: Boolean,
    checkCotizacionRenovacionTerminada: Boolean,
    checkRecotizar: Boolean,
    checkCotizacionRenovacionAprobada: Boolean,
    checkCotizacionRenovacionRechazada: Boolean,
    checkPendienteInfo: Boolean,
    checkEmisionFinalizada: Boolean,
    checkFinalizada: Boolean,
    checkCancelacion: Boolean,
    motivoCancelacion: String,
    motivoRecotizacionRechazo: String,
    causaPendienteInformacion: String,
    valorOportunidad: Number,
    documentos: Array,
    comercialAsignado: Number,
    creadoPor: String,
    fechaCreacion: String,
    modificadoPor: String,
    fechaModificacion: String,
    aseguradoraId: Number,
    razonSocial: String,
    newFormProps: Object,
  },
  data() {
    return {
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      dialog: false,
      tipoVehiculo: this.bodyAutos.tipo_vehiculo
        ? this.bodyAutos.tipo_vehiculo
        : null,
      usoAuto: this.bodyAutos.uso_auto ? this.bodyAutos.uso_auto : null,
      origenAuto: this.bodyAutos.origenAuto ? this.bodyAutos.origenAuto : null,
      modelo: this.bodyAutos.modelo ? this.bodyAutos.modelo : null,
      marca: this.bodyAutos.marca ? this.bodyAutos.marca : null,
      submarca: this.bodyAutos.submarca ? this.bodyAutos.submarca : null,
      version: this.bodyAutos.version ? this.bodyAutos.version : null,
      plan: this.bodyAutos.coberturasSelected
        ? this.bodyAutos.coberturasSelected
        : null,
      numMotor: this.bodyAutos.numMotor ? this.bodyAutos.numMotor : null,
      numSerie: this.bodyAutos.numSerie ? this.bodyAutos.numSerie : null,
      placas: this.bodyAutos.placas ? this.bodyAutos.placas : null,
      roles: JSON.parse(localStorage.getItem("roles")),
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      coberturas: ["Amplia", "Amplia-Plus", "Limitada", "RC"],
      tipoVehiculosItems: [
        "Auto",
        "Motocicleta",
        "Pick-up",
        "Camión",
        "Tractocamión",
      ],
      origenAutoItems: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],

      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],
    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 2500);
    },
  },

  mounted() {
    this.getModelos();
  },

  methods: {
    getModelos() {
      mainAxios
        .get("/v1/vehiculo/qualitas/modelos", this.axiosConfig)
        .then(({ data }) => {
          this.modelos = data;
          this.getMarcas();
        });
    },

    getMarcas() {
      mainAxios
        .get(`/v1/vehiculo/qualitas/marca/${this.modelo}`, this.axiosConfig)
        .then(({ data }) => {
          this.marcas = data;
          this.getSubmarcas();
        });
    },

    getSubmarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/submarca/${this.marcaId}/${this.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.submarcas = data;
          this.getVersiones();
        });
    },

    getVersiones() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/vehiculo/${this.marcaId}/${this.submarca}/${this.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.versiones = data;
        });
    },

    cargarArchivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "multipart/form-data",
        },
      };
      for (var i = 0; i < this.documentos.length; i++) {
        const formData = new FormData();
        let file = this.documentos[i];
        let nota =
          "Solicitud #" + `${this.solicitudId}` + " Cotización Enviada";
        const tipo = "Documento";
        const tipoDocumento = "Cotización";
        formData.append("file", file);
        formData.append("nota", nota);
        formData.append("nombre", nota);
        formData.append("tipo", tipo);
        formData.append("tipo_documento", tipoDocumento);
        mainAxios
          .post(
            `/v1/solicitud/documento/upload/${this.solicitudId}`,
            formData,
            config
          )
          .then(
            function(result) {
              console.log(result);
            },
            function(error) {
              console.log(error);
            }
          );
      }
    },
    crearNotaRechazoCancelacion() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      const tipo = "Nota";
      let nota =
        "Solicitud #" + `${this.solicitudId}` + " Rechazada o cancelada";
      formData.append("nota", this.motivoRecotizacionRechazo);
      formData.append("tipo", tipo);
      formData.append("nombre", nota);
      mainAxios
        .post(
          `/v1/solicitud/documento/upload/${this.solicitudId}`,
          formData,
          config
        )
        .then(
          function(result) {
            console.log(result);
          },
          function(error) {
            console.log(error);
          }
        );
    },
    crearNotaPendienteInformacion() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      const tipo = "Nota";
      let nota =
        "Solicitud #" + `${this.solicitudId}` + " Rechazada o cancelada";
      formData.append("nota", this.causaPendienteInformacion);
      formData.append("tipo", tipo);
      formData.append("nombre", nota);
      mainAxios
        .post(
          `/v1/solicitud/documento/upload/${this.solicitudId}`,
          formData,
          config
        )
        .then(
          function(result) {
            console.log(result);
          },
          function(error) {
            console.log(error);
          }
        );
    },
    sendSolicitud() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      if (this.documentos.length > 0) {
        this.cargarArchivos();
      }

      if (this.checkCotizacionRenovacionRechazada || this.checkRecotizar) {
        this.crearNotaRechazoCancelacion();
      }

      if (this.checkPendienteInfo) {
        this.crearNotaPendienteInformacion();
      }

      let request = {
        ...this.newFormProps,
        origenSolicitudSelected: this.origenSolicitud,
        tipoSelected: this.tipo ? this.tipo : "",
        categoriaSelected: this.ramo ? this.ramo : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.producto ? this.producto : "",
        detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
        operadorUuid: this.operador ? this.operador : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento
          ? moment(this.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        genero: this.genero ? this.genero : "",
        inicioVigencia: this.inicioVigencia
          ? moment(this.inicioVigencia, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        finVigencia: this.finVigencia
          ? moment(this.finVigencia, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        celular: this.telefono ? this.telefono : "",
        correoElectronico: this.correo ? this.correo : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoNacimiento
          ? this.estadoNacimiento
          : "",
        estadoCivilSelected: this.estadoCivil ? this.estadoCivil : "",
        rfc: this.rfc ? this.rfc : "",
        periodicidadPago:
          this.periodicidad == "Anual"
            ? 1
            : this.periodicidad == "Semestral"
            ? 2
            : this.periodicidad == "Trimestral"
            ? 3
            : this.periodicidad == "Mensual"
            ? 4
            : "",
        formaPagoSelected: this.formaPago ? this.formaPago : "",
        solicitudCompletaSelected: this.solicitudCompleta
          ? this.solicitudCompleta
          : "",
        procesoCotizacionSelected: this.procesoCotizacion
          ? this.procesoCotizacion
          : "",
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this
          .checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this
          .checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this
          .checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion
          ? this.motivoCancelacion
          : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo
          ? this.motivoRecotizacionRechazo
          : null,
        causaPendienteInformacion: this.causaPendienteInformacion
          ? this.causaPendienteInformacion
          : "",
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        regimen_persona: this.tipoPersona,
        comercial_asignado: this.comercialAsignado
          ? this.comercialAsignado
          : null,
        razon_social: this.razonSocial ? this.razonSocial : null,

        //REQUEST DE AUTOS
        modelo: this.modelo ? this.modelo : "",
        marca: this.marca ? this.marca : "",
        submarca: this.submarca ? this.submarca : "",
        version: this.version ? this.version : "",
        coberturasSelected: this.plan ? this.plan : "",
        numMotor: this.numMotor ? this.numMotor : "",
        numSerie: this.numSerie ? this.numSerie : "",
        placas: this.placas ? this.placas : "",
        uso_auto: this.usoAuto ? this.usoAuto : "",
        tipo_vehiculo: this.tipoVehiculo ? this.tipoVehiculo : "",
        origenAuto: this.origenAuto ? this.origenAuto : "",
      };

      mainAxios
        .post("v1/solicitud/update/" + this.solicitudId, request, config)
        .then((response) => {
          console.log("Entro!");
          if (response.status == 200) {
            if (this.checkCotizacionRenovacionAprobada == true) {
              this.generarPrePoliza();
            }
            this.dialog = true;
          }
          setTimeout(
            () =>
              this.$router.push(`/seguimiento-solicitud/${this.solicitudId}`),
            3000
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generarPrePoliza() {
      if (this.checkCotizacionRenovacionAprobada == true) {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };

        let request = {
          apellido_materno: this.apellidoMaterno ? this.apellidoMaterno : "",
          apellido_paterno: this.apellidoPaterno ? this.apellidoPaterno : "",
          aseguradora_id: this.aseguradoraId,
          compania: "AXA",
          cliente: null,
          agente: this.agente ? this.agente : null,
          codigo_postal: this.codigoPostal ? this.codigoPostal : "",
          correo_electronico: this.correo ? this.correo : "",
          cotizacion: null,
          creado_por: this.operador,
          detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
          ejecutivo_de_operaciones: this.operador ? this.operador : "",
          estado_id: this.estadoNacimiento,
          // estado_id: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : '',
          fecha_cancelacion: null,
          fecha_emision: null,
          fecha_finalizacion: null,
          fecha_primer_pago: moment(this.inicioVigencia, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          fecha_nacimiento: this.fechaNacimiento
            ? moment(this.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "",
          fin: moment(this.finVigencia, "DD/MM/YYYY").format("YYYY-MM-DD"),
          // forma_pago: this.periodicidadSelected == 'Anual' ? 1
          //                 : this.periodicidadSelected == 'Semestral' ? 2
          //                 : this.periodicidadSelected == 'Trimestral' ? 3
          //                 : this.periodicidadSelected == 'Mensual' ? 4 : '',
          forma_pago:
            this.formaPago == "Tarjeta de Crédito"
              ? 1
              : this.formaPago == "Tarjeta de Débito"
              ? 2
              : this.formaPago == "Deposito Bancario"
              ? 3
              : this.formaPago == "Transferencia"
              ? 4
              : this.formaPago == "Cheque"
              ? 5
              : this.formaPago == "Efectivo"
              ? 6
              : this.formaPago == "Domiciliación"
              ? 7
              : "",
          genero: this.genero ? this.genero : "",
          inicio: moment(this.inicioVigencia, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          marca: this.marca ? this.marca : "",
          modelo: this.modelo ? this.modelo : "",
          modificado_por: null,
          motivo_cancelacion: null,
          motivo_finalizacion: null,
          nombre: this.nombres,
          nombre_cliente: this.nombres ? this.nombres : "",
          notas: "",
          num_motor: this.numMotor ? this.numMotor : "",
          num_serie: this.numSerie ? this.numSerie : "",
          observaciones: "",
          comercial_asignado: this.comercialAsignado
            ? this.comercialAsignado
            : null,
          operador_id: this.operador ? this.operador : "",
          origen: this.origenSolicitud ? this.origenSolicitud : "",
          origen_auto: "Nacional",
          pago_id:
            this.periodicidad == "Anual"
              ? 1
              : this.periodicidad == "Semestral"
              ? 2
              : this.periodicidad == "Trimestral"
              ? 3
              : this.periodicidad == "Mensual"
              ? 4
              : "",
          placas: this.placas ? this.placas : "",
          plan_id:
            this.plan == "Amplia"
              ? 1
              : this.plan == "Amplia-Plus"
              ? 2
              : this.plan == "Limitada"
              ? 3
              : this.plan == "RC"
              ? 4
              : null,
          /* */ poliza_anterior: null,
          /* */ poliza_cancelada: null,
          /* */ poliza_finalizada: null,
          /* */ prima_neta: 0,
          /* */ prima_total: 0,
          /* */ primer_pago: 0,
          /* */ primer_pago_poliza: 0,
          producto_id: this.producto ? this.producto : "",
          /* */ status: 0,
          status_cobro: "",
          submarca: this.submarca ? this.submarca : "",
          origenAuto: this.origenAuto ? this.origenAuto : "",
          subsecuente_pago: 0,
          telefono: this.telefono ? this.telefono : "",
          tipo_persona: this.tipoPersona ? this.tipoPersona : null,
          /* */ tipo_poliza:
            this.tipo == "Cotización" || this.tipo == "Emisión"
              ? "Nueva"
              : this.tipo == "Renovación"
              ? "Renovación"
              : "",
          ultima_modificacion: null,
          url: null,
          uso_auto: this.usoAuto ? this.usoAuto : null,
          vehiculo: `${this.marca ? this.marca : ""} ${
            this.submarca ? this.submarca : ""
          } ${this.version ? this.version : ""}`,
          version: this.version ? this.version : "",
          rfc: this.rfc ? this.rfc : "",
          solicitud_id: this.solicitudId,
          razon_social: this.razonSocial ? this.razonSocial : null,
          ...{
            edad: this.newFormProps.edad,
            codigo_postal: this.newFormProps.codigoPostal,
            calle: this.newFormProps.calle,
            num_interior: this.newFormProps.numInterior,
            num_exterior: this.newFormProps.numExterior,
            colonia: this.newFormProps.colonia,
            ciudad: this.newFormProps.municipio,
            anios: this.newFormProps.anios,
            origen: this.newFormProps.origen,
            campania: this.newFormProps.campania,
            lead_id: this.newFormProps.idOrigenLead,
            campania_id: this.newFormProps.campaniaId,
            categoriaSelected: this.newFormProps.ramo,
            estado_id: this.newFormProps.estadoId,
            estado_civil: this.estadoCivil,
          },
        };

        mainAxios
          .post("/v1/emision/manual", request, config)
          .then((response) => {
            console.log("Entro a generar pre-poliza", response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    comportamientoBotonGuardar() {
      if (
        (this.tipo == "Emisión" || this.tipo == "Renovación") &&
        this.procesoCotizacion == "Emisión"
      ) {
        return this.botonGuardar2;
      } else if (this.checkCotizacionRenovacionAprobada) {
        return this.botonGuardar2;
      }
      return this.botonGuardar;
    },
  },
  computed: {
    marcaId() {
      if (!this.marca) return null;
      return this.marcas.find((m) => m.marca == this.marca).id;
    },

    canEdit() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "AGENTCC",
        "DIRECTOR",
        "FINANZAS",
        "MANAGERCC",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    botonGuardar() {
      if (this.tipoPersona == "Moral") {
        return (
          this.origenSolicitud != null &&
          this.origenSolicitud != "" &&
          this.statusSolicitud != null &&
          this.statusSolicitud != "" &&
          this.agente != null &&
          this.agente != "" &&
          this.tipo != null &&
          this.tipo != "" &&
          this.ramo != null &&
          this.ramo != "" &&
          this.detalleSolicitud != null &&
          this.detalleSolicitud != "" &&
          this.tipoPersona != null &&
          this.tipoPersona != "" &&
          this.razonSocial != null &&
          this.razonSocial != "" &&
          this.estadoNacimiento != null &&
          this.estadoNacimiento != "" &&
          this.rfc != null &&
          this.rfc != "" &&
          this.telefono != null &&
          this.telefono != "" &&
          this.correo != null &&
          this.correo != "" &&
          this.codigoPostal != null &&
          this.codigoPostal != "" &&
          this.inicioVigencia != null &&
          this.inicioVigencia != "" &&
          this.finVigencia != null &&
          this.finVigencia != "" &&
          this.periodicidad != null &&
          this.periodicidad != "" &&
          this.formaPago != null &&
          this.formaPago != "" &&
          /* CAMPOS DEL RAMO */
          this.modelo != null &&
          this.marca != null &&
          this.submarca != null &&
          this.version != null &&
          this.plan != null
        );
      }
      return (
        this.origenSolicitud != null &&
        this.origenSolicitud != "" &&
        this.statusSolicitud != null &&
        this.statusSolicitud != "" &&
        this.agente != null &&
        this.agente != "" &&
        this.tipo != null &&
        this.tipo != "" &&
        this.ramo != null &&
        this.ramo != "" &&
        this.producto != null &&
        this.producto != "" &&
        this.detalleSolicitud != null &&
        this.detalleSolicitud != "" &&
        this.nombres != null &&
        this.nombres != "" &&
        this.apellidoPaterno != null &&
        this.apellidoPaterno != "" &&
        this.genero != null &&
        this.genero != "" &&
        this.fechaNacimiento != null &&
        this.fechaNacimiento != "" &&
        this.estadoNacimiento != null &&
        this.estadoNacimiento != "" &&
        this.estadoCivil != null &&
        this.estadoCivil != "" &&
        this.tipoPersona != null &&
        this.tipoPersona != "" &&
        this.rfc != null &&
        this.rfc != "" &&
        this.telefono != null &&
        this.telefono != "" &&
        this.correo != null &&
        this.correo != "" &&
        this.codigoPostal != null &&
        this.codigoPostal != "" &&
        this.inicioVigencia != null &&
        this.inicioVigencia != "" &&
        this.finVigencia != null &&
        this.finVigencia != "" &&
        this.periodicidad != null &&
        this.periodicidad != "" &&
        this.formaPago != null &&
        this.formaPago != "" &&
        /* CAMPOS DEL RAMO */
        this.modelo != null &&
        this.marca != null &&
        this.submarca != null &&
        this.version != null &&
        this.plan != null
      );
    },
    botonGuardar2() {
      if (this.tipoPersona == "Moral") {
        return (
          this.origenSolicitud != null &&
          this.origenSolicitud != "" &&
          this.statusSolicitud != null &&
          this.statusSolicitud != "" &&
          this.agente != null &&
          this.agente != "" &&
          this.tipo != null &&
          this.tipo != "" &&
          this.ramo != null &&
          this.ramo != "" &&
          this.detalleSolicitud != null &&
          this.detalleSolicitud != "" &&
          this.tipoPersona != null &&
          this.tipoPersona != "" &&
          this.razonSocial != null &&
          this.razonSocial != "" &&
          this.estadoNacimiento != null &&
          this.estadoNacimiento != "" &&
          this.rfc != null &&
          this.rfc != "" &&
          this.telefono != null &&
          this.telefono != "" &&
          this.correo != null &&
          this.correo != "" &&
          this.codigoPostal != null &&
          this.codigoPostal != "" &&
          this.inicioVigencia != null &&
          this.inicioVigencia != "" &&
          this.finVigencia != null &&
          this.finVigencia != "" &&
          this.periodicidad != null &&
          this.periodicidad != "" &&
          this.formaPago != null &&
          this.formaPago != "" &&
          /* CAMPOS DEL RAMO */
          this.modelo != null &&
          this.marca != null &&
          this.submarca != null &&
          this.version != null &&
          this.plan != null &&
          this.numMotor != null &&
          this.numSerie != null &&
          this.placas != null
        );
      }
      return (
        this.origenSolicitud != null &&
        this.origenSolicitud != "" &&
        this.statusSolicitud != null &&
        this.statusSolicitud != "" &&
        this.agente != null &&
        this.agente != "" &&
        this.tipo != null &&
        this.tipo != "" &&
        this.ramo != null &&
        this.ramo != "" &&
        this.producto != null &&
        this.producto != "" &&
        this.detalleSolicitud != null &&
        this.detalleSolicitud != "" &&
        this.nombres != null &&
        this.nombres != "" &&
        this.apellidoPaterno != null &&
        this.apellidoPaterno != "" &&
        this.genero != null &&
        this.genero != "" &&
        this.fechaNacimiento != null &&
        this.fechaNacimiento != "" &&
        this.estadoNacimiento != null &&
        this.estadoNacimiento != "" &&
        this.estadoCivil != null &&
        this.estadoCivil != "" &&
        this.tipoPersona != null &&
        this.tipoPersona != "" &&
        this.rfc != null &&
        this.rfc != "" &&
        this.telefono != null &&
        this.telefono != "" &&
        this.correo != null &&
        this.correo != "" &&
        this.codigoPostal != null &&
        this.codigoPostal != "" &&
        this.inicioVigencia != null &&
        this.inicioVigencia != "" &&
        this.finVigencia != null &&
        this.finVigencia != "" &&
        this.periodicidad != null &&
        this.periodicidad != "" &&
        this.formaPago != null &&
        this.formaPago != "" &&
        this.solicitudCompleta != null &&
        this.solicitudCompleta != "" &&
        /* CAMPOS DEL RAMO */
        this.modelo != null &&
        this.marca != null &&
        this.submarca != null &&
        this.version != null &&
        this.plan != null &&
        this.numMotor != null &&
        this.numSerie != null &&
        this.placas != null
      );
    },
  },
};
</script>

<style></style>
