export default {
  size: (v) => !v || v.size < 5000000 || "El tamaño debe ser menor a 5 MB!",
  required: (v) => {
    if (!!v || v === 0) {
      if (typeof v == "string" && v.trim() == "") {
        return "El campo es requerido";
      }
      return true;
    } else {
      return "El campo es requerido";
    }
  },
  email: (v) => {
    if ((v || "").length == 0) {
      return true;
    } else {
      return /.+@.+\..+/.test(v) || "Correo no válido";
    }
  },
  numeric: (v) => {
    if (v == null || v == "") {
      return true;
    }
    return /^[0-9]+$/.test(v) || "El campo solo acepta números";
  },
  decimal: (v) => {
    return (
      /^[0-9]+(.[0-9]+)?$/.test(v) ||
      "El campo solo acepta números con punto decimal"
    );
  },
  regex(expresion) {
    return (v) => {
      if ((v || "").length == 0) {
        return true;
      } else {
        return new RegExp(expresion).test(v) || "Formato inválido";
      }
    };
  },
  notCero: (v) => v == "0.00" || "Ingrese un valor mayor a 0",
  mayor(number) {
    return (v) =>
      Number.parseFloat(v) > number || "Ingrese un valor mayor a " + number;
  },
  mayorIgual(number) {
    return (v) =>
      Number.parseFloat(v) >= number ||
      "Ingrese un valor mayor o igual a " + number;
  },
  menor(number) {
    return (v) =>
      Number.parseFloat(v) < number || "Ingrese un valor menor a " + number;
  },
  menorIgual(number) {
    return (v) =>
      Number.parseFloat(v) <= number ||
      "Ingrese un valor menor o igual a " + number;
  },
  longDigit(number) {
    return (v) =>
      (v || "").length == number || "Debe proporcionar " + number + " digitos";
  },
  min(number) {
    return (v) =>
      (v || "").length >= number || "Mínimo " + number + " caracteres";
  },
  max(number) {
    return (v) =>
      (v || "").length <= number || "Máximo: " + number + " caracteres";
  },
  notCeroMoney: (v) => v != "0.00" || "Monto no válido",
  notequal(string) {
    return (v) => v != string || "Valor no válido";
  },
  match: (value) => {
    if (value[0] != value[1]) return false || "No coincide";
    else return true;
  },
  requiredif(condition) {
    return (v) => (condition ? !!v || "Requerido" : true);
  },

  curp: (v) => {
    if (v == null || v.trim() == "") {
      return true;
    }
    let value = v.toUpperCase();
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = value.match(re);
    if (!validado)
      //Coincide con el formato general?
      return "La curp no es válida";

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    }
    return validado[2] != digitoVerificador(validado[1])
      ? "La curp no es válida"
      : true;
  },

  rfcSimple: (v) =>
    !v ||
    !!v.match(
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2}|)([A\d]|)$/
    ) ||
    "RFC no válido",

  rfc: (v) => {
    if (v == null || v.trim() == "") {
      return true;
    }
    let value = v.toUpperCase();
    // hay un error en la validacion de a rfc
    const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let correcto = value.match(regex); //SE CREA UN OBJETO CON LA EXPRESIÓN REGULAR
    if (!correcto) {
      // si no es correcto, se termina el proceso
      return "La RFC no es valida";
    }

    const digitoVerificador = correcto.pop(), //se obtendra el ultimo digito del rfc
      rfcSinDigito = correcto.slice(1).join(""), //se guarda el rfc sin el digito
      len = rfcSinDigito.length, //Se obtiene la longitud del rfc sin el digito verificador, 11 o 12
      //Obtener el digito esperado
      diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
      indice = len + 1; //Se obtendra la suma de la longitud del rfc sin el digito verificador mas 1

    var suma, digitoEsperado; //Se declaran las variables
    suma = len == 12 ? 0 : 481; //Ajuste para persona moral o fisica 0 es para una persona fisica, 481 es para una persona moral

    for (var i = 0; i < len; i++) {
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i); //Se hacer una operacion, donde se obtiene la posición del caracter del rfc
    }

    digitoEsperado = 11 - (suma % 11);
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";
    //El dígito verificador coincide con el esperado
    return digitoVerificador != digitoEsperado ? "La RFC no es valida" : true;
  },
  rfcSinClave: (v) => {
    if (v == null || v.trim() == "") {
      return true;
    }
    let value = v.toUpperCase();
    // hay un error en la validacion de a rfc
    const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))/;
    let correcto = value.match(regex); //SE CREA UN OBJETO CON LA EXPRESIÓN REGULAR

    if (!correcto) {
      // si no es correcto, se termina el proceso
      return "La RFC no es valida";
    }
    return true;
  },
  nameRule: (message) => {
    return function(v) {
      if (!v || v.length < 3) return "Se necesitan al menos 3 caracteres";
      if (v.match(/(.)\1\1/)) return message;
      if (v.match(/[0-9]/)) return message;
      return true;
    };
  },
};
