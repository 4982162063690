<template>
  <v-row class="d-flex justify-center">
    <v-col class="shadow-card my-4 mr-3" cols="12">
      <common-card>
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="d-flex justify-center align-center offset-md-1"
          >
            <v-row>
              <v-col cols="12">
                <h2 class="text-center">Total:</h2>
              </v-col>
              <v-col cols="12">
                <h2 class="text-inter text-center" style="margin-top: -19px">
                  {{ `${formatPrice(item.price)} / al año` }}
                </h2>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <v-col cols="11" class="text-left mb-2">
              <ul class="offset-md-1">
                <li><b>Modelo:</b> {{ item.model }}</li>
                <li><b>Costo del celular:</b> {{ item.invoiceAmount }}</li>
                <li>
                  <b>Contacto:</b>
                  {{ `${item.name} ${item.surname} ${item.lastname}` }}
                </li>
                <li><b>Celular:</b> {{ item.phone }}</li>
                <li><b>Correo:</b> {{ item.email }}</li>
                <li><b>Código cotización:</b> {{ item.cotizacionGeneral }}</li>
              </ul>
            </v-col>
          </v-col>
          <v-col cols="12" md="2" class="d-flex justify-center align-center">
            <v-btn
              color="#00A7E4"
              block
              dark
              class="rounded-xl"
              outlined
              @click="goConfirmation"
            >
              Verificar datos para su emisión
              <v-icon
                class="ml-1 "
                left
                color="#039ECC"
                size="x-small"
              >  mdi-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </common-card>
    </v-col>
  </v-row>
</template>
<script>
import CommonCard from "./CommonCard.vue";
export default {
  name: "CommonCardCotizacion",
  components: { CommonCard },
  props: { item: Object },
  data() {
    return {};
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
    goConfirmation() {
      this.$emit("goConfirmacion");
    },
  },
};
</script>
<style scoped>
.text-inter {
  color: #00a7e4;
}
.shadow-card {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.card-style {
  margin-right: 10px;
  max-width: 350px;
}
</style>
