<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Domicilio
      </template>
      <v-expansion-panel-content>
        <ViewClienteDomicilioInfo
          v-if="switch_data == 1"
          :polizaInfo="polizaInfo"
          @changeTab="(e) => (switch_data = e)"
        />
        <EditClienteDomicilioInfo
          v-if="switch_data == 2"
          :polizaInfo="polizaInfo"
          @changeTab="(e) => (switch_data = e)"
          @getData="$emit('getData'), (tabReplace = 1)"
        />
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewClienteDomicilioInfo from "./ViewClienteDomicilioInfo.vue";
import EditClienteDomicilioInfo from "./EditClienteDomicilioInfo.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewClienteDomicilioInfo,
    EditClienteDomicilioInfo,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    polizaInfo: Object,
  },
  methods: {},
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
