<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
          v-if="canEdit"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="datosSolicitud.tipo == 'Renovación'">
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          No. de Póliza a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.polizaRenovar ? formData.polizaRenovar : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Inicio de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaInicioVigenciaRenovar
              ? getFormattDate(formData.fechaInicioVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Fin de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaFinVigenciaRenovar
              ? getFormattDate(formData.fechaFinVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
        v-if="datosSolicitud.tipo == 'Emisión' || datosSolicitud.tipo == 'Renovación'"
      >
        <span class="text-header-data">
          Número de póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numeroPoliza ? formData.numeroPoliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Años de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.anios ? formData.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Inicio de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.inicio ? getFormattDate(formData.inicio) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fin de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fin ? getFormattDate(formData.fin) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Periodicidad de Pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.periodicidadSelected
              ? getFormatPeriodicidad(formData.periodicidadSelected)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Forma de Pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.formaPagoSelected
              ? formData.formaPagoSelected
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Tipo de Inmueble
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.tipoInmueble ? formData.tipoInmueble : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Especificar Tipo de inmueble
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.otroTipoInmueble ? formData.otroTipoInmueble : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Valor del inmueble
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.valorInmueble ? formData.valorInmueble : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Nivel del departamento
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.nivelDepartamento ? formData.nivelDepartamento : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Pisos Totales
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.pisosTotales ? formData.pisosTotales : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Número de pisos altos
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numeroPisosAltos ? formData.numeroPisosAltos : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Sotanos
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sotanos ? formData.sotanos : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Valor de los contenidos
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.valorContenidos ? formData.valorContenidos : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Valor del equipo electrónico
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.valorEquipoElectronico ? formData.valorEquipoElectronico : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Suma asegurada Responsabilidad Civil
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sumaAseguradaRC ? formData.sumaAseguradaRC : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Suma asegurada Cristales
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sumaAseguradaCristales ? formData.sumaAseguradaCristales : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Suma asegurada robo con violencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sumaAseguradaRoboViolencia ? formData.sumaAseguradaRoboViolencia : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Suma asegurada dinero y valores
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sumaAseguradaDineroValores ? formData.sumaAseguradaDineroValores : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Suma asegurada obras de arte o piezas de difícil reposición
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sumaAseguradaObrasDeArte ? formData.sumaAseguradaObrasDeArte : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
      formData: {
        numeroPoliza: null,
        anios: null,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
        tipoInmueble: null,
        otroTipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },
    };
  },
  props: {
    datosSolicitud: Object,
    canEdit: Boolean
  },

  methods: {
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getFormatPeriodicidad(id) {
      var p = this.periodicidadItems.find((e) => e.value == id);
      return p ? p.text : "No disponible";
    },
    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      this.formData.tipoInmueble = jsonData.tipoInmueble ? jsonData.tipoInmueble : null;
      this.formData.otroTipoInmueble = jsonData.otroTipoInmueble ? jsonData.otroTipoInmueble : null;
      this.formData.valorInmueble = jsonData.valorInmueble ? jsonData.valorInmueble : null;
      this.formData.nivelDepartamento = jsonData.nivelDepartamento ? jsonData.nivelDepartamento : null;
      this.formData.pisosTotales = jsonData.pisosTotales ? jsonData.pisosTotales : null;
      this.formData.numeroPisosAltos = jsonData.numeroPisosAltos ? jsonData.numeroPisosAltos : null;
      this.formData.sotanos = jsonData.sotanos ? jsonData.sotanos : null;
      this.formData.valorContenidos = jsonData.valorContenidos ? jsonData.valorContenidos : null;
      this.formData.valorEquipoElectronico = jsonData.valorEquipoElectronico ? jsonData.valorEquipoElectronico : null;
      this.formData.sumaAseguradaRC = jsonData.sumaAseguradaRC ? jsonData.sumaAseguradaRC : null;
      this.formData.sumaAseguradaCristales = jsonData.sumaAseguradaCristales ? jsonData.sumaAseguradaCristales : null;
      this.formData.sumaAseguradaRoboViolencia = jsonData.sumaAseguradaRoboViolencia ? jsonData.sumaAseguradaRoboViolencia : null;
      this.formData.sumaAseguradaDineroValores = jsonData.sumaAseguradaDineroValores ? jsonData.sumaAseguradaDineroValores : null;
      this.formData.sumaAseguradaObrasDeArte = jsonData.sumaAseguradaObrasDeArte ? jsonData.sumaAseguradaObrasDeArte : null;
     //Comun para todos
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.fin = jsonData.fin ? jsonData.fin : null;
      this.formData.inicio = jsonData.inicio ? jsonData.inicio : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
      //fin de comun 
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
