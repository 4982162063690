<template>
  <CommonExpansionPanel :currentPanel="currentPanel">
    <template v-slot:title-panel>
      Datos del cliente
    </template>
    <v-expansion-panel-content>
      <v-form ref="secoundStepForm">
        <v-row>
          <v-col cols="12" m="12" md="6" lg="4" xl="4">
            <v-autocomplete
              label="Tipo de persona"
              outlined
              v-model="data.regimen"
              primary
              :items="regimentList"
              color="#00a7e4"
              type="text"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              label="Nombre(s)"
              outlined
              v-model="data.nombre"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              label="Apellidos Paterno"
              outlined
              v-model="data.apellidoPaterno"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Apellidos Materno"
              outlined
              v-model="data.apellidoMaterno"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha de Nacimiento"
              outlined
              v-model="data.fechaNacimiento"
              primary
              color="#00a7e4"
              type="date"
             ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Edad"
              outlined
              v-model="data.edad"
              primary
              color="#00a7e4"
              type="text"
              readonly
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="RFC"
              outlined
              v-model="data.rfc"
              primary
              :rules="rulesRfc"
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Género"
              outlined
              v-model="data.genero"
              :items="generoList"
              primary
              color="#00a7e4"
              type="text"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Estado Civil"
              outlined
              v-model="data.estadoCivil"
              primary
              :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
              color="#00a7e4"
              type="text"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Teléfono"
              outlined
              v-model="data.telefono"
              primary
              color="#00a7e4"
              counter="10"
              :rules="[$rules.numeric, longDigitValidation(10)]"
              :maxLength="10"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Correo Electrónico"
              outlined
              v-model="data.correo"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Codigo postal"
              outlined
              v-model="data.codigoPostal"
              primary
              color="#00a7e4"
              type="text"
              counter="5"
              :rules="[$rules.numeric, longDigitValidation(5)]"
              :maxLength="5"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Calle"
              outlined
              v-model="data.calle"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Numero exterior"
              outlined
              v-model="data.num_exterior"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Numero interior"
              outlined
              v-model="data.num_interior"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Colonia"
              outlined
              v-model="data.colonia"
              :items="colonias"
              item-text="nombreColonia"
              item-value="nombreColonia"
              primary
              color="#00a7e4"
              type="text"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Ciudad"
              outlined
              v-model="data.ciudad"
              primary
              color="#00a7e4"
              readonly
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Estado"
              placeholder="Requerido"
              :items="estados"
              item-text="name"
              item-value="id"
              readonly
              outlined
              v-model="data.estado"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
      <v-row style="text-align: end;">
        <v-col cols="12">
          <v-btn rounded dark class="common-botton" @click="$emit('goBack')" style="margin-right: 10px;">
            <span>
              Anterior
            </span>
          </v-btn>
          <v-btn rounded dark class="common-botton" @click="validateStep">
            <span>
              Guardar
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "../../../commonComponents/CommonExpansionPanel.vue";
import { getUbicacionesCP } from "@/services/codigoPostal/CodigoPostal.js";
import RfcFacil from 'rfc-facil';
import moment from "moment";

export default {
  components: {
    CommonExpansionPanel,
  },
  props: {
    currentPanel: {
      type: Number,
      default: 0,
    },
    cliente: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      data: {
        nombre: "",
        apellidoMaterno: "",
        apellidoPaterno: "",
        regimen: "",
        rfc: "",
        telefono: "",
        fechaNacimiento: "",
        genero: "",
        correo: "",
        codigoPostal: "",
        calle: "",
        num_exterior: "",
        num_interior: "",
        colonia: "",
        ciudad: "",
        estado: "",
        usuario:"",
        globalInteractionId:"",
        cliente_uuid:"",
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      campañas: [],
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      regimentList: ["Física", "Moral"],
      rulesRfc: [
        (v) => !!v || "Este campo es requerido",
        (v) => {
          if (v == null || v.trim() == "") {
            return true;
          }
          let value = v.toUpperCase();
          // hay un error en la validacion de a rfc
          const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?(([A-Z\d]{2})([A\d])|)$/;
          let correcto = value.match(regex); //SE CREA UN OBJETO CON LA EXPRESIÓN REGULAR

          return !!correcto || "La RFC no es valida";
        },
      ],
      generoList: ["Masculino","Femenino"],
      colonias: [],
      longDigitValidation(number) {
        return (v) =>
          !v ||
          v.length == number ||
          "Debe proporcionar " + number + " digitos";
      },
    };
  },
  watch: {
    "data.fechaNacimiento"(newValue) {
      if (newValue != "" && newValue != null) {
        var years = moment(newValue, "YYYY-MM-DD");
        var today = moment().format('YYYY-MM-DD');
        var diference = moment(today, 'YYYY-MM-DD').diff(
          moment(years, 'YYYY-MM-DD'),
          "years"
        );
        this.data.edad = diference;
      }
      this.actualizarRfc();
    },
    "data.nombre"() {
      this.actualizarRfc();
    },
    "data.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "data.apellidoMaterno"() {
      this.actualizarRfc();
    },
    async "data.codigoPostal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const response = await getUbicacionesCP(val);
          console.log({response})
          if (response.listaColonias.length >= 1) {
            this.cpFlag = true;
            this.colonias = response.listaColonias;
            this.data.estado = Number(response.nEstado);
            this.data.ciudad = response.nombreMunicipio;
          } else if (response.error == "") {
            alert("No existe ese codigo postal");
          }
        }
      }
    },
    "dataOrigen.uuid_data_segurify"() {
      this.getdata();
      this.getCanales();
      this.getRamos();
      this.getCampanias();
    },
    cliente(){
      this.fillData()
    }
  },

  methods: {
    validateStep() {
      if (this.$refs.secoundStepForm.validate()) {
        this.$emit('goToSave',this.data)
      }
    },
    fillData() {
      var keys = Object.keys(this.data);
      keys.map((e) => {
        if (typeof this.cliente[e] != "undefined") {
          this.data[e] = this.cliente[e];
        }
      });

      this.data.telefono = JSON.parse(localStorage.getItem('jsonLlamada')).numero  ?? '';
      this.data.usuario = localStorage.getItem('id') ?? '';
      this.data.globalInteractionId = JSON.parse(localStorage.getItem('jsonLlamada')).global_id ?? '';
      this.data.cliente_uuid = this.cliente.uuid ?? '';
      this.data.cliente_id = this.cliente.id ?? '';
    },
    actualizarRfc() {
      if (
        !this.data.nombre ||
        !this.data.apellidoPaterno ||
        !this.data.apellidoMaterno ||
        !this.data.fechaNacimiento
      )
        return;
      const arregloNacimiento = this.data.fechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.data.nombre,
        firstLastName: this.data.apellidoPaterno,
        secondLastName: this.data.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.data.rfc = rfc.substring(0, rfc.length - 3);
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
