<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeComponent')"
    >
      <template v-slot:data>
        <v-row v-if="datosSolicitud.tipo == 'Renovación'">
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="No. de Póliza a renovar"
              v-model="formData.polizaRenovar"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha Inicio de vigencia a renovar"
              v-model="formData.fechaInicioVigenciaRenovar"
              :rules="inputsRequeridos"
              type="date"
              outlined
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha Fin de vigencia a renovar"
              v-model="formData.fechaFinVigenciaRenovar"
              type="date"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="
              datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <v-text-field
              v-model="formData.numeroPoliza"
              label="Número de póliza"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.anios"
              label="Años de Vigencia"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.inicio"
              type="date"
              label="Inicio de Vigencia"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.fin"
              type="date"
              label="Fin de Vigencia"
              placeholder="Calculado automáticamente"
              :rules="inputsRequeridos"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-select
              v-model="formData.periodicidadSelected"
              label="Periodicidad de Pago"
              :items="periodicidadItems"
              item-text="text"
              item-value="value"
              :rules="inputsRequeridos"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-select
              v-model="formData.formaPagoSelected"
              label="Forma de Pago"
              :items="formaPago"
              :rules="inputsRequeridos"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Tipo de portafolios"
              v-model="formData.tipoPortafolios"
              :items="tipoPortafoliosList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Fumador"
              v-model="formData.fumador"
              :items="fumadorList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Plazo comprometido"
              v-model="formData.plazoComprometido"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Deducibilidad"
              v-model="formData.deducibilidad"
              :items="deducibilidadList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Revaluable con la inflación"
              v-model="formData.revaluableInflacion"
              :items="revaluableInflacionList"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Valor del Plan"
              v-model="formData.valorPlan"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Monto de la inversión/Aportación"
              v-model="formData.montoInversion"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="afterSend()">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  components: {
    CommondForm,
  },
  data() {
    return {
      formData: {
        anios: 1,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
        tipoPortafolios: null,
        fumador: null,
        plazoComprometido: null,
        deducibilidad: null,
        revaluableInflacion: null,
        valorPlan: null,
        montoInversion: null,
      },

      //listas
      tipoPortafoliosList: [
        "Moderado",
        "Medio",
        "Agresivo",
        "Elite Inversión",
        "Patrimonial Inversión",
        "Plus",
        "Educacional",
      ],
      fumadorList: ["Si", "No"],
      deducibilidadList: ["Si", "No"],
      revaluableInflacionList: ["Si", "No"],
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
    };
  },
  props: {
    datosSolicitud: Object,
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      console.log("aqui mero", jsonData);
      this.formData.tipoPortafolios = jsonData.tipoPortafolios
        ? jsonData.tipoPortafolios
        : null;
      this.formData.fumador = jsonData.fumador ? jsonData.fumador : null;
      this.formData.plazoComprometido = jsonData.plazoComprometido
        ? jsonData.plazoComprometido
        : null;
      this.formData.deducibilidad = jsonData.deducibilidad
        ? jsonData.deducibilidad
        : null;
      this.formData.revaluableInflacion = jsonData.revaluableInflacion
        ? jsonData.revaluableInflacion
        : null;
      this.formData.valorPlan = jsonData.valorPlan ? jsonData.valorPlan : null;
      this.formData.montoInversion = jsonData.deducibilidad
        ? jsonData.montoInversion
        : null;
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.fin = jsonData.fin ? jsonData.fin : null;
      this.formData.inicio = jsonData.inicio ? jsonData.inicio : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
    },

    afterSend() {
      this.run = true;
    },
  },
};
</script>
