<template>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Nombre
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.mascota.nombre ? ramoData.mascota.nombre : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Especie
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.mascota.especie ? ramoData.mascota.especie : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Especie
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.mascota.raza ? ramoData.mascota.raza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Genero
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.mascota.sexo ? ramoData.mascota.sexo : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Años 
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.mascota.anios ? ramoData.mascota.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Meses 
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.mascota.meses ? ramoData.mascota.meses : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <span class="text-header-data ">
          Descripcion de mascota 
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.mascota.descripcionMascota ? ramoData.mascota.descripcionMascota : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },

  methods: {},
};
</script>
