<template>
  <v-dialog v-model="dialog" persistent max-width="1500">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="10">
                <span>
                  Solicitudes
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="$emit('closeModal')"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <FiltersSolicitudIndex :isModal="true" @seleccionSolicitud="seleccionSolicitud" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FiltersSolicitudIndex from "@/views/pages/hub/solicitudes/Dasboard/FiltersSolicitudIndex.vue";
export default {
  components: {
    FiltersSolicitudIndex,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  methods: {
    userChoice(val) {
      this.$emit("userChoice", val);
    },
    closeMenuModal() {
      this.$emit("closeMenu");
    },
    seleccionSolicitud(val){
      this.$emit('seleccionSolicitud', val)
    }
  },
};
</script>

<style>
.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text {
  width: 369px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.confirmation-text {
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

  width: 369px;
  height: 30px;

  /* Subtitle/S */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;

  color: #555555;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
</style>
