<template>
  <v-bottom-sheet
    id="embedded-botton"
    v-model="sheet"
    hide-overlay
    width="350px"
    persistent
    scrollable
    round
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="embedded"
        fab
        dark
        large
        color="#00a7e4"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark>
          mdi-phone-outgoing
        </v-icon>
      </v-btn>
      <!-- <v-btn color="#00a7e4" icon size="large" dark fab v-bind="attrs" v-on="on" class="embedded">
                <v-icon color="#00a7e4" dark>                   
                     mdi-phone-outgoing 
                </v-icon>
            </v-btn> -->
    </template>

    <v-sheet
      height="420px"
      color="#00a7e4"
      class="pl-2 pr-2"
      style="border-radius: 6px;"
      flat
    >
      <v-card height="410" class="" round flat>
        <v-toolbar flat color="#00a7e4" dark>
          <v-toolbar-title class="text-center">Call it Once</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="sheet = !sheet">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row class="mt-1 mr-5" justify="space-around">
            <v-col cols="1">
              <v-btn
                id="botton-call"
                fab
                small
                color="success"
                @click="callNumber"
                :disabled="llamar || this.selectPhone == null"
              >
                <v-icon dark>mdi-phone</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="1">
              <v-btn
                fab
                small
                color="red"
                @click="stopCall"
                :disabled="!colgar"
              >
                <v-icon color="white">mdi-phone-hangup</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form ref="formDisposicion">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Telefonos clientes"
                  color="#00a7e4"
                  outlined
                  clearable
                  :items="telefonos"
                  hide-details
                  dense
                  v-model="telefonoSelected"
                  :rules="[$rules.required]"
                  :disabled="llamar"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Tipos de contacto"
                  color="#00a7e4"
                  outlined
                  clearable
                  :items="TiposContactos"
                  hide-details
                  dense
                  v-model="tipo_contacto"
                  :rules="[$rules.required]"
                  :disabled="!llamar"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Disposición"
                  clearable
                  color="#00a7e4"
                  outlined
                  :items="disposicionesAux"
                  hide-details
                  dense
                  v-model="disposicion"
                  :disabled="tipo_contacto == null"
                  item-text="disposicion"
                  item-value="id"
                  :rules="[$rules.required]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- <template v-if="isSolicitud">
          <v-card-actions class="ml-2 dd-flex justify-center text-center">
            <v-btn
              dark
              color="primary"
              @click="openModal"
              small
              :disabled="validarLead"
            >
              Crear solicitud
            </v-btn>
          </v-card-actions>
        </template> -->
        <template v-if="isVenta">
          <v-card-actions class="ml-2 dd-flex justify-center text-center">
            <v-btn
              v-if="
                roles == 'AGENTCC' ||
                  roles == 'AGENTCCSPONSORS' ||
                  roles == 'OPERADOR' ||
                  roles == 'OPERADORCC' ||
                  roles == 'ADMIN' ||
                  roles == 'RENOVACIONESCC'
              "
              dark
              color="primary"
              @click="openPolizaModal"
              small
              :disabled="validarLead"
            >
              Crear Póliza
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import { getlDispocionesClienteList } from "@/services/disposiciones/disposiciones.service.js";
import { getCampañasClientes } from "@/services/cliente/CampañasClientes.js";
import { getClienteById } from "@/services/cliente/Clientes.js";
import { createLlamadaInbound } from "@/services/campañasInbound/CampañasInbound.js";
import moment from "moment";

export default {
  name: "Embedded",
  components: {},
  props: {
    cliente_id: {
      type: Number,
      default: 0,
    },
    telefonos: {
      type: Array,
      default: () => [],
    },
    is_cliente: {
      type: Boolean,
      default: false,
    },
    is_solicitud: {
      type: Boolean,
      default: false,
    },
    is_poliza: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      isSolicitud: false, // verifico si la disposicion es solicitud
      isVenta: false, // verifico si la disposicion es Venta
      registrarAgenda: false, // registra la llamada para una agenda
      isConference: false,
      loading: false,
      loadingBotton: false,
      sendMessage: false, // active if send global_id from poliza
      // icon
      // icon: Incoming,
      // listas variable
      TiposContactos: ["Efectivo", "No Efectivo"],
      disposiciones: [],
      disposicionesAux: [],
      polizas: [], // lista de las polizas
      // config dialog embedded // pasar en otro archivo secundario
      sheet: false,
      llamar: false,
      colgar: false,
      mute: false,
      callInfo: null,

      // var user
      calendar: false,
      dateFormatted: null,

      // var to agend call
      dateAgenda: null,
      ListNumber: [],
      selectPhone: null,

      // disposicion
      tipo_contacto: null,
      disposicion: null,
      poliza: null,
      global_id: null,
      is_emitido: false,
      servicio_embebido: "",
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      fechaPrimerLlamada: "",
      campaniasCliente: [],
      clienteDetalles: {},
      telefonoSelected: "",
      inicio:"",
    };
  },
  async mounted() {
    this.getCampañasClientes();
    this.getDisposicionesCliente();
    this.getClienteById();
    loadScript(
      "https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js"
    )
      .then(() => {
        window.bpspat.api.init("https://aligev55.zw-callitonce.alestra.net.mx");
      })
      .catch(() => {
        console.error(
          "Fallo al carga el sdk, Refresque la pagina para realizar la llamada"
        );
      });
  },
  watch: {
    cliente_id(val) {
      if (val) this.getClienteById();
    },
    tipo_contacto(val) {
      if (val == null) {
        this.colgar = false;
        this.disposicion = null;
      }
      this.filterDisposicion();
    },
    telefonoSelected() {
      this.setCallData();
    },
    disposicion(val) {
      if (val) this.colgar = true;
      else this.colgar = false;
    },
  },

  methods: {
    setCallData() {
      const campaniacliente = this.campaniasCliente.find(
        (e) => e.medio == this.clienteDetalles.origen
      );
      this.selectPhone = this.telefonoSelected;
      this.servicio_embebido = campaniacliente
        ? campaniacliente.servicioEmbebido
          ? campaniacliente.servicioEmbebido
          : ""
        : "";
    },

    async getDisposicionesCliente() {
      this.disposiciones = await getlDispocionesClienteList();
    },

    async getCampañasClientes() {
      this.campaniasCliente = await getCampañasClientes();
    },

    async getClienteById() {
      this.clienteDetalles = await getClienteById(this.cliente_id);
    },

    async saveDisposition() {
      const disposicionName = this.disposicionesAux.find(
        (item) => item.id == this.disposicion
      );
      const request = {
        cliente_uuid: this.clienteDetalles.uuid ?? "",
        solicitud_id: this.is_solicitud ? parseInt(this.$route.params.id) : null,
        emision_id: this.is_poliza ? parseInt(this.$route.params.id) : null,
        tipo_llamada: "Outbound",
        usuario: parseInt(localStorage.id) ?? "",
        globalInteractionId: this.globalId ?? "",
        code_disposicion: this.disposicion ?? "",
        cliente_id: this.cliente_id ?? "",
        disposicion: disposicionName ? disposicionName.disposicion : null,
        inicio:this.inicio ?? "",
        fin:moment().format('yyyy-MM-DD hh:mm:ss')
      };
      try {
        await createLlamadaInbound(request);
        this.$toast.success('Llamada guardada con exito ! ')
        this.$emit('success')
      } catch {
        this.$toast.error('Algo fallo con el guardado de la llamada')
      }
    },

    filterDisposicion() {
      this.disposicionesAux = this.disposiciones.filter(
        (item) => item.tipo_contacto == this.tipo_contacto
      );
    },

    callNumber() {
      this.llamar = true;
      this.inicio = moment().format('yyyy-MM-DD hh:mm:ss')
      window.bpspat.api.selectService(this.servicio_embebido);
      ``;
      //this.selectPhone = '4492344656';
      window.bpspat.api.dialNumber(this.selectPhone);
      window.bpspat.api.addInteractionRenderedHandler(this.logs);
      window.bpspat.api.addInteractionCompletedHandler(this.logs);
    },

    stopCall() {
      window.bpspat.api.getState(({ data }) => {
        let ivr = data.interactions.find((item) => item.phone_number == 4545);
        if (ivr) {
          window.bpspat.api.completeInteractionWithDisp(
            ivr.item_id,
            String("99999999")
          );
        }
        let interaccion = data.interactions.find(
          (item) => item.phone_number == this.selectPhone
        );
        console.log({ interaccion });
        window.bpspat.api.completeInteractionWithDisp(
          ivr.item_id,
          String("99999999")
        ); // se termina la llamada con el envio de la disposicion
      });
      //Aqui guardamos todo para cuando se cuelga
      this.colgar = false;
      this.llamar = false;
      this.saveDisposition();
      this.sheet = !this.sheet;
    },

    logs(state) {
      this.globalId = state.data.global_id;
      let nuevaLlamada = {
          numero: state.data.interactions[0].phone_number,
          global_id: this.globalId,
          service: state.data.interactions[0].service,
          seColgo: false
      };
      localStorage.removeItem('jsonLlamada')
      localStorage.setItem("jsonLlamada", JSON.stringify(nuevaLlamada));
    },
  },
};
</script>

<style scoped>
.embedded {
  position: fixed;
  bottom: 160px;
  right: 0;
}
.v-dialog__content {
  justify-content: end !important;
}
</style>
