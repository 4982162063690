export const requiredRule = [(v) => !!v || "El campo es requerido"];

export const cpRules = [
  (v) => !!v || "Código postal es requerido",
  (v) =>
    (v && v.length === 5 && /^[0-9]+$/.test(v)) ||
    "Código postal debe tener 5 dígitos numéricos",
];

export const emailRules = [
  (v) => !!v || "El correo electrónico es requerido",
  (v) => /.+@.+\..+/.test(v) || "Debe ser un correo electrónico válido",
];

export const phoneRules = [
  (v) => !!v || "El número de teléfono es requerido",
  (v) => /^\d{10}$/.test(v) || "Debe ser un número de teléfono válido",
];
