<template>
  <v-row>
    <v-col cols="12">
      <common-card>
        <v-container>
          <v-row class="mb-3">
            <v-col cols="3" md="1" class="text-center">
              <img :src="dashboardJson.iconCar" alt="" height="50" />
            </v-col>
            <v-col cols="9">
              <h2>Confirmación de datos</h2>
              <span>
                Verifica que tus datos sean correctos
              </span>
            </v-col>
          </v-row>
        </v-container>
        <v-row class="pa-5 pa-md-8">
          <v-col cols="12">
            <v-row>
              <v-col sm="8" md="3">
                <h2 class="pl-2 pl-md-0">Conductor</h2>
              </v-col>
              <v-col cols="4" v-show="!showEditConductor">
                <v-btn
                  style="background: #029ECF"
                  outlined
                  color="white"
                  icon
                  small
                  @click="editConductor()"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" v-show="showEditConductor">
                <v-btn
                  v-show="showEditConductor"
                  color="#00A7E4"
                  dark
                  class="rounded-xl"
                  small
                  @click="showConfirmConductor()"
                >
                  Guardar
                </v-btn></v-col
              >
            </v-row>
            <v-row v-show="showEditConductor" class="mx-4">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="nombreU"
                  label="Nombre"
                  hide-details="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="primerApellidoU"
                  label="Apellido P"
                  hide-details="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="segundoApellidoU"
                  label="Apellido M"
                  hide-details="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="codigoPostalU"
                  label="Código Postal"
                  hide-details="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-label>Género*</v-label>
                <v-radio-group v-model="generoU" row hide-details="true">
                  <v-radio label="M" value="M" color="#00A7E4"></v-radio>
                  <v-radio label="F" value="F" color="#00A7E4"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="date"
                  v-model="fechanNacimientoU"
                  label="Edad"
                  hide-details="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="!showEditConductor">
              <v-col sm="12" md="9">
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <h3>Nombre</h3>
                    <span>{{ carPrice.personalData.nombre }}</span>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <h3>Apellido Paterno</h3>
                    <span>{{ carPrice.personalData.apellidoPaterno }}</span>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <h3>Apellido Materno</h3>
                    <span>{{ carPrice.personalData.apellidoMaterno }}</span>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <h3>Código postal</h3>
                    <span>{{ carPrice.personalData.cp }}</span>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <h3>Género</h3>
                    <span>{{
                      `${
                        carPrice.personalData.genero === "M"
                          ? "Masculino"
                          : "Femenino"
                      }`
                    }}</span>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <h3>Edad</h3>
                    <span>{{
                      calculateAge(carPrice.personalData.fechaDeNacimiento)
                    }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="12" md="3" class="d-sm-block d-none">
                <img :src="dashboardJson.iconCar" alt="" height="150" />
              </v-col>
            </v-row>
            <v-row class="d-flex align-center">
              <v-col
                cols="11"
                class="ml-5 d-none d-md-block"
                style="border-top: 1px solid #029ECF;"
              >
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="8" md="3">
                <h2 class="pl-2 pl-md-0">Vehículo</h2>
              </v-col>
              <v-col sm="4" v-show="!showEditAuto">
                <v-btn
                  style="background: #029ECF"
                  outlined
                  color="white"
                  icon
                  small
                  @click="editAuto()"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="4" v-show="showEditAuto">
                <v-btn
                  color="#00A7E4"
                  dark
                  class="rounded-xl"
                  small
                  @click="showConfirmAuto()"
                >
                  Guardar
                </v-btn></v-col
              >
            </v-row>

            <div v-show="!showEditAuto">
              <v-row>
                <v-col cols="12" md="4">
                  <h3>Marca</h3>
                  <span>{{ carPrice.carBrand.brand }}</span>
                </v-col>
                <v-col cols="12" md="4">
                  <h3>Año</h3>
                  <span>{{ carPrice.carBrand.year }}</span>
                </v-col>
                <v-col cols="12" md="4">
                  <h3>Modelo</h3>
                  <span>{{ carPrice.carBrand.model.description }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center justify-sm-center">
                <v-col cols="11" sm="5">
                  <v-btn
                    style="widht: 100%; padding: 6px!important"
                    color="#00A7E4"
                    outlined
                    block
                    dark
                    class="rounded-xl"
                    @click="goBack"
                  >
                    <span> Regresar</span>
                  </v-btn>
                </v-col>
                <v-col cols="11" sm="6">
                  <v-btn
                    style="widht: 100%;"
                    color="#00A7E4"
                    block
                    dark
                    class="rounded-xl"
                    @click="confirmForEmission"
                  >
                    <span> Confirmar</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-row v-show="showEditAuto" class="ml-4 mr-4 pb-0">
              <v-col md="6" cols="12" class="pb-0">
                <v-autocomplete
                  :items="brand"
                  :item-text="(item) => item"
                  :item-value="(item) => item"
                  label="Marca*"
                  outlined
                  v-model="marca"
                  class="v-autocomplete--small"
                  hide-details="true"
                  @change="updateVersions()"
                ></v-autocomplete>
              </v-col>
              <v-col md="6" cols="12" class="pb-0">
                <v-autocomplete
                  v-model="axo"
                  :items="years"
                  :item-text="(item) => item"
                  :item-value="(item) => item"
                  label="Año*"
                  outlined
                  class="v-autocomplete--small"
                  hide-details="true"
                  @change="getVersions(marca, axo)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-autocomplete
                  v-model="modelo"
                  :items="versions"
                  item-text="description"
                  :item-value="(item) => item"
                  label="Modelo*"
                  outlined
                  class="v-autocomplete--small"
                  hide-details="true"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </common-card>
      <commond-loading
        :show="loading"
        :loadingImage="dataImage.iconCar"
        loadingText="Cargando..."
      />
      <v-dialog persistent v-model="modalConfirmAuto" max-width="400">
        <v-card class="pa-2">
          <v-row class="text-h5 text-center">
            <v-col>
              Si cambias la info de tu vehículo, se actualizarán los resultados
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  style="padding: 6px!important"
                  color="#00A7E4"
                  block
                  dark
                  class="rounded-xl"
                  @click="cancelEditAuto()"
                >
                  <span> Cancelar</span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="#00A7E4"
                  outlined
                  block
                  dark
                  class="rounded-xl"
                  @click="saveAuto()"
                >
                  No importa, ¡quiero cambiarlo!
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="modalConfirmConductor" max-width="400">
        <v-card class="pa-2">
          <v-row class="text-h5 text-center">
            <v-col>
              Si cambias tus datos, se borrarán estos resultados
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  style="padding: 6px!important"
                  color="#00A7E4"
                  block
                  dark
                  class="rounded-xl"
                  @click="cancelEditConductor()"
                >
                  <span> Cancelar</span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="#00A7E4"
                  outlined
                  block
                  dark
                  class="rounded-xl"
                  @click="saveConductor()"
                >
                  No importa, ¡quiero cambiarlo!
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import CommondLoading from "../../../../components/commonComponents/CommondLoading.vue";
import dataVehiculo from "../../../../helpers/data/data-loading-gif.json";
import dashboardJson from "../../../../helpers/data/data-dashboard-template.json";
import { mainAxios } from "@/mainAxios.js";

//import { mapActions } from "vuex";

export default {
  name: "VehiculosConfirmacion",
  components: { CommonCard, CommondLoading },
  data() {
    return {
      dataImage: dataVehiculo,
      loading: true,
      carPrice: {},
      dashboardJson: dashboardJson,
      showEditConductor: false,
      showEditAuto: false,
      nombreU: "",
      primerApellidoU: "",
      segundoApellidoU: "",
      codigoPostalU: "",
      generoU: "",
      fechanNacimientoU: "",
      token: "",
      axo: 0,
      modelo: "",
      marca: "",
      brand: [],
      versions: [],
      years: this.generateLast60Years(),
      modalConfirmAuto: false,
      modalConfirmConductor: false,
    };
  },
  methods: {
    cancelEditConductor() {
      this.modalConfirmConductor = false;
      this.showEditConductor = false;
    },
    showConfirmConductor() {
      this.modalConfirmConductor = true;
    },
    showConfirmAuto() {
      this.modalConfirmAuto = true;
    },
    cancelEditAuto() {
      this.modalConfirmAuto = false;
      this.showEditAuto = false;
    },
    async saveAuto() {
      this.modalConfirmAuto = false;
      this.showEditAuto = false;
      this.carPrice.carBrand.year = this.axo;
      this.carPrice.carBrand.brand = this.marca;
      this.carPrice.carBrand.model = this.modelo;
      sessionStorage.setItem("carPrice", JSON.stringify(this.carPrice));
      this.$router.push({
        name: "emitir-vehiculo",
        params: { carPrice: this.carPrice },
      });
      //await this.createPrice();
    },
    editAuto() {
      this.showEditAuto = true;
    },
    async saveConductor() {
      this.showEditConductor = false;
      this.carPrice.personalData.nombre = this.nombreU;
      this.carPrice.personalData.apellidoPaterno = this.primerApellidoU;
      this.carPrice.personalData.apellidoMaterno = this.segundoApellidoU;
      this.carPrice.personalData.cp = this.codigoPostalU;
      this.carPrice.personalData.genero = this.generoU;
      this.carPrice.personalData.fechaDeNacimiento = this.fechanNacimientoU;
      sessionStorage.setItem("carPrice", JSON.stringify(this.carPrice));
      this.$router.push({
        name: "emitir-vehiculo",
        params: { carPrice: this.carPrice },
      });
      // await this.createPrice();
    },
    editConductor() {
      this.showEditConductor = true;
    },
    calculateAge(birthDate) {
      if (!birthDate) return null;
      const today = new Date();
      const birth = new Date(birthDate);
      let age = today.getFullYear() - birth.getFullYear();
      const monthDifference = today.getMonth() - birth.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birth.getDate())
      ) {
        age--;
      }
      return age;
    },
    goBack() {
      this.$router.go(-1);
    },
    async getBrands() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": this.token,
        },
      };
      mainAxios
        .get("/v1/cotizacion-ws/auto/catalogo/marcas", config)
        .then(({ data: { data } }) => {
          this.brand = data.marcas;
        });
    },
    async getVersions(brand, year) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": this.token,
        },
      };
      mainAxios
        .get(
          `/v1/cotizacion-ws/auto/catalogo/versiones/${brand}/${year}`,
          config
        )

        .then(({ data: { data } }) => {
          this.versions = data.versiones;
        });
    },
    async updateVersions() {
      console.log("updateVersion", this.marca);
      if (this.axo > 0) {
        this.loading = true;
        await this.getVersions(this.marca, this.axo);
        this.modelo = {};
        this.loading = false;
      }
    },
    generateLast60Years() {
      const currentYear = new Date().getFullYear();
      let years = [];
      for (let i = 0; i <= 60; i++) {
        years.push(currentYear - i);
      }
      return years;
    },

    confirmForEmission() {
      this.$store.dispatch("clearState");
      this.$store.dispatch("updateEmissionFromConfirmation");
      this.$router.push("/cotizacion/vehiculos/detalle");
      const dataCotizacion = JSON.parse(sessionStorage.getItem('cotizacionSelected'));
      window.dataLayer.push({
        event: "ITFY_auto_confirmacion_datos",
        userID: localStorage.getItem('agenteMail'),
        cp: this._data.codigoPostalU,
        sexo: this._data.generoU == "M" ? "Masculino" : "Femenino",
        marca: this.modelo.assembler,
        anio: this.modelo.model,
        modelo: this.modelo.description,
        boton_contacto: "Confirmar",
        tipo_vehiculo: this.modelo.type,
        no_cotizacion: dataCotizacion.cotizacion_general, 
      })
    },
  },
  async created() {
    this.loading = true;
    if (
      this.$route.params.carPrice &&
      typeof this.$route.params.carPrice === "object"
    ) {
      sessionStorage.setItem(
        "carPrice",
        JSON.stringify(this.$route.params.carPrice)
      );
      this.carPrice = this.$route.params.carPrice;
    } else {
      const carData = sessionStorage.getItem("carPrice");
      if (carData && carData !== "[object Object]") {
        this.carPrice = JSON.parse(carData);
      }
    }
    this.token = this.carPrice.token;
    this.axo = this.carPrice.carBrand.year;
    this.modelo = this.carPrice.carBrand.model;
    this.marca = this.carPrice.carBrand.brand;
    this.nombreU = this.carPrice.personalData.nombre;
    this.primerApellidoU = this.carPrice.personalData.apellidoPaterno;
    this.segundoApellidoU = this.carPrice.personalData.apellidoMaterno;
    this.codigoPostalU = this.carPrice.personalData.cp;
    this.generoU = this.carPrice.personalData.genero;
    this.fechanNacimientoU = this.carPrice.personalData.fechaDeNacimiento;
    await this.getBrands();
    await this.getVersions(
      this.carPrice.carBrand.brand,
      this.carPrice.carBrand.year
    );
    this.loading = false;
  },
  mounted () {
    const dataCotizacion = JSON.parse(sessionStorage.getItem('cotizacionSelected'));
    const emissionData = JSON.parse(sessionStorage.getItem('contractorData'))
    window.dataLayer.push({
      event: 'ITFY_auto_confirmacion_datos_v',
      no_cotizacion: dataCotizacion.cotizacion_general,
      userID: localStorage.agenteMail,
      correo: emissionData?.emission?.contractorData?.email,
      telefono: emissionData?.emission?.contractorData?.phone,
    });
  }
};
</script>
<style scoped></style>
