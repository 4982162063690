<template>
  <v-dialog v-model="OpenDialogCreateDocument" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="2">
                <v-img :src="notesImg" max-width="29" he alt=""> </v-img>
              </v-col>
              <v-col cols="10">
                <span>
                  Notas
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="closeModal"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <commond-form
            :request="formData"
            :route="routesConfigNoteCard.route"
            :run="run"
            :action="routesConfigNoteCard.action"
            @cancel="run = false"
            @success="closeComponent"
            @afterError="closeComponent"
          >
            <template v-slot:data>
              <v-row class="pt-5">
                <v-col cols="12">
                  <v-text-field
                    label="Titulo"
                    outlined
                    v-model="data.nombre"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Nota"
                    outlined
                    v-model="data.nota"
                    primary
                    color="#00a7e4"
                    :rules="requiredRule"
                    rows="5"
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- <div
                style="display: flex; justify-content: center; align-items: center;"
              >
                <v-checkbox
                  v-model="data.enviarPorCorreo"
                  label="Enviar por correo electrónico"
                  color="#00a7e4"
                ></v-checkbox>
              </div> -->
            </template>
          </commond-form>
        </v-container>
      </v-card-text>
      <v-card-actions style="justify-content: center;">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <v-btn rounded dark class="common-botton" @click="afterSend">
            <span style="padding-right: 11%;">
              Guardar
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import notes from "@/assets/notes.svg";

export default {
  components: {
    CommondForm,
  },
  props: {
    OpenDialogCreateDocument: {
      type: Boolean,
      default: false,
    },
    routesConfigNoteCard: Object,
  },

  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      data: {
        nombre: null,
        enviarPorCorreo: false,
        nota: null,
        tipo: "Nota",
        tipo_documento: null,
      },
      run: false,
      formData: null,
      notesImg: notes,
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    afterSend() {
      this.formData = new FormData();
      this.formData.append("nota", this.data.nota ? this.data.nota : null);
      this.formData.append("tipo", this.data.tipo ? this.data.tipo : null);
      this.formData.append("tipo_documento", null);
      this.formData.append(
        "nombre",
        this.data.nombre ? this.data.nombre : null
      );
      this.run = true;
    },
    closeComponent() {
      this.data = Object.assign({
        nombre: null,
        enviarPorCorreo: false,
        nota: null,
        tipo: "Nota",
        tipo_documento: null,
      });
      this.$emit("success");
    },
  },
};
</script>
