<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Modelo"
          placeholder="Requerido"
          v-model="polizaData.modelo"
          :items="modelos"
          item-value="id"
          item-text="id"
          auto-select-first
          @change="getMarcas()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Marca"
          placeholder="Requerido"
          v-model="polizaData.marca"
          :items="marcas"
          item-value="marca"
          item-text="marca"
          no-data-text="Primero selecciona un modelo"
          auto-select-first
          @change="getSubmarcas()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Submarca"
          placeholder="Requerido"
          v-model="polizaData.submarca"
          :items="submarcas"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una marca"
          auto-select-first
          @change="getVersiones()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Version"
          placeholder="Requerido"
          v-model="polizaData.version"
          :items="versiones"
          item-value="nombre"
          item-text="nombre"
          no-data-text="Primero selecciona una submarca"
          auto-select-first
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Plan"
          v-model="polizaData.plan_id"
          :items="planList"
          item-text="name"
          item-value="id"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Origen del Auto"
          v-model="polizaData.origen_auto"
          :items="origenAutoList"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Uso del Auto"
          v-model="polizaData.uso_auto"
          :items="usoAutoList"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="No de Motor"
          v-model="polizaData.num_motor"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="No. De Serie"
          v-model="polizaData.num_serie"
          :maxLength="17"
          :rules="[
            ...inputsRequeridos,
            localRules.alphanumeric,
            $rules.min(15),
            $rules.max(17),
          ]"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Placas"
          v-model="polizaData.placas"
          :maxLength="10"
          :rules="[...inputsRequeridos, localRules.alphanumeric]"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" lg="12" xl="12">
        <v-textarea
          label="Detalle de la Póliza"
          v-model="polizaData.detalle_auto"
          outlined
          color="#00a7e4"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn rounded dark class="common-botton" @click="$emit('goToSave',polizaData)">
          <span>
            Guardar
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";

export default {
  data() {
    return {
      polizaData: {
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalle_auto: null,
        plan_id: null,
        origen_auto: null,
        uso_auto: null,
        num_motor: null,
        num_serie: null,
        placas: null,
      },

      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      //listas
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],

      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
    };
  },

  computed: {
    marcaId() {
      if (!this.polizaData.marca) return null;
      return this.marcas.find((m) => m.marca == this.polizaData.marca).id;
    },
  },

  props: ["avisar"],

  mounted() {
    this.getModelos();
  },

  methods: {
    getModelos() {
      mainAxios
        .get("/v1/vehiculo/qualitas/modelos", this.axiosConfig)
        .then(({ data }) => {
          this.modelos = data;
        });
    },

    getMarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/marca/${this.polizaData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.marcas = data;
        });
    },

    getSubmarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/submarca/${this.marcaId}/${this.polizaData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.submarcas = data;
        });
    },

    getVersiones() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/vehiculo/${this.marcaId}/${this.polizaData.submarca}/${this.polizaData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.versiones = data;
        });
    },
  },
};
</script>
