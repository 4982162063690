<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
    <template v-slot:data>
      <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Nivel Hospitalario"
          v-model="formData.nivelHospitalario"
          :items="nivelHospitalarioList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada"
          v-model="formData.sumaAsegurada"
          :rules="inputsRequeridos"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Deducible"
          v-model="formData.deducible"
          :rules="inputsRequeridos"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Coaseguro"
          v-model="formData.coaseguro"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Cero deducible por accidente"
          v-model="formData.ceroDeducible"
          :items="ceroDeducibleList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Medicamentos fuera de hospital"
          v-model="formData.medicinaFueraHospital"
          :items="medicinaFueraHospitalList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Emergencia en el Extranjero"
          v-model="formData.emergenciaExtranjero"
          :items="emergenciaExtranjeroList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Enfermedades Catastróficas"
          v-model="formData.enfermedadesCatastroficas"
          :items="enfermedadesCatastroficasList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Cobertura en el Extranjero"
          v-model="formData.coberturaExtranjero"
          :items="coberturaExtranjeroList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Preexistencias"
          v-model="formData.preexistencias"
          :items="preexistenciasList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Protección Dental"
          v-model="formData.proteccionDental"
          :items="proteccionDentalList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Maternidad Extendida"
          v-model="formData.maternidadExtendida"
          :items="maternidadExtendidaList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Cobertura Nacional (Multirregión)"
          v-model="formData.coberturaNacional"
          :items="coberturaNacionalList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Reconocimiento de Antigüedad"
          v-model="formData.reconocimientoAntiguedad"
          :items="reconocimientoAntiguedadList"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          v-model="formData.fechasParaCotizar"
          label="Indicar las fechas para cotizar"
          v-bind="attrs"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="formData.dependientes"
          label="Dependientes: En su caso se debe indicar la edad, sexo y parentesco de cada uno."
          value=""
          rows="2"
          hint="Escribe aquí tus dependientes"
          outlined
          color="#00a7e4"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
    </template>
  </commond-form>
  </div>
</template>

<script>
var moment = require("moment-timezone");
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components:{
    CommondForm
  },
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        id:null,
        nivelHospitalario: null,
        sumaAsegurada: null,
        deducible: null,
        coaseguro: null,
        ceroDeducible: null,
        medicinaFueraHospital: null,
        emergenciaExtranjero: null,
        enfermedadesCatastroficas: null,
        coberturaExtranjero: null,
        preexistencias: null,
        proteccionDental: null,
        maternidadExtendida: null,
        coberturaNacional: null,
        reconocimientoAntiguedad: null,
        dependientes: null,
        fechasParaCotizar: null,
        dateFormattedInicioVigencia: null,
        menufechasParaCotizar: false,
      },

      //listas
      nivelHospitalarioList: ["Alto", "Medio", "Bajo"],
      ceroDeducibleList: ["Si ampara", "No ampara"],
      medicinaFueraHospitalList: ["Si ampara", "No ampara"],
      emergenciaExtranjeroList: ["Si ampara", "No ampara"],
      enfermedadesCatastroficasList: ["Si ampara", "No ampara"],
      coberturaExtranjeroList: ["Si ampara", "No ampara"],
      preexistenciasList: ["Si ampara", "No ampara"],
      proteccionDentalList: ["Si ampara", "No ampara"],
      maternidadExtendidaList: ["Si ampara", "No ampara"],
      coberturaNacionalList: ["Si ampara", "No ampara"],
      reconocimientoAntiguedadList: ["Si ampara", "No ampara"],
      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
    };
  },
  mounted(){
    this.fillData()
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.ramoData[e]) {
          this.formData[e] = this.ramoData[e];
        }
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    comboFechasParaCotizar() {
      this.dateFormattedInicioVigencia = this.formatDate(
        moment(this.comboFechasParaCotizar).format("YYYY-MM-DD")
      );
    },
  },
};
</script>
