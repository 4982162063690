<template>
  <v-card class="rounded-card" elevation="0">
    <slot name="image"></slot>
    <slot></slot>
  </v-card>
</template>
<script>
export default {
    name: 'CommonCardAseguradora'
};
</script>
<style scoped></style>
