<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
          v-if="canEdit"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth> 
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="datosSolicitud.tipo == 'Renovación'">
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          No. de Póliza a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.polizaRenovar ? formData.polizaRenovar : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Inicio de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaInicioVigenciaRenovar
              ? getFormattDate(formData.fechaInicioVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Fin de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaFinVigenciaRenovar
              ? getFormattDate(formData.fechaFinVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
        v-if="datosSolicitud.tipo == 'Emisión' || datosSolicitud.tipo == 'Renovación'"
      >
        <span class="text-header-data">
          Número de póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numeroPoliza ? formData.numeroPoliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Años de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.anios ? formData.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Inicio de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.inicio ? getFormattDate(formData.inicio) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fin de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fin ? getFormattDate(formData.fin) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Nivel Hospitalario
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.nivelHospitalario ? formData.nivelHospitalario : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Suma asegurada
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sumaAsegurada ? formData.sumaAsegurada : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Deducible
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.deducible ? formData.deducible : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Coaseguro
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.coaseguro ? formData.coaseguro : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Cero deducible por accidente
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.ceroDeducible ? formData.ceroDeducible : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Medicamentos fuera de hospital
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.medicinaFueraHospital ? formData.medicinaFueraHospital : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Emergencia en el Extranjero
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.emergenciaExtranjero ? formData.emergenciaExtranjero : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Enfermedades Catastróficas
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.enfermedadesCatastroficas ? formData.enfermedadesCatastroficas : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Cobertura en el Extranjero
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.coberturaExtranjero ? formData.coberturaExtranjero : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Preexistencias
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.preexistencias ? formData.preexistencias : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Protección Dental
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.proteccionDental ? formData.proteccionDental : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Maternidad Extendida
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.maternidadExtendida ? formData.maternidadExtendida : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Cobertura Nacional (Multirregión)
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.coberturaNacional ? formData.coberturaNacional : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Reconocimiento de Antigüedad
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.reconocimientoAntiguedad ? formData.reconocimientoAntiguedad : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Indicar las fechas para cotizar
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fechasParaCotizar ? formData.fechasParaCotizar : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <span class="text-header-data ">
          Dependientes: En su caso se debe indicar la edad, sexo y parentesco de cada uno.
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.dependientes ? formData.dependientes : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>

</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      formData:{
        numeroPoliza: null,
        anios: null,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
        nivelHospitalario: null,
        sumaAsegurada: null,
        deducible: null,
        coaseguro: null,
        ceroDeducible: null,
        medicinaFueraHospital: null,
        emergenciaExtranjero: null,
        enfermedadesCatastroficas: null,
        coberturaExtranjero: null,
        preexistencias: null,
        proteccionDental: null,
        maternidadExtendida: null,
        coberturaNacional: null,
        reconocimientoAntiguedad: null,
        dependientes: null,
        fechasParaCotizar: null,
        dateFormattedInicioVigencia: null,
        menufechasParaCotizar: false,
      }
    };
  },
  props: {
    datosSolicitud:  Object,
    canEdit: Boolean
  },
  methods: {
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getFormatPeriodicidad(id) {
      var p = this.periodicidadItems.find((e) => e.value == id);
      return p ? p.text : "No disponible";
    },
    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      this.formData.nivelHospitalario = jsonData.nivelHospitalario ? jsonData.nivelHospitalario : null;
      this.formData.sumaAsegurada = jsonData.sumaAsegurada ? jsonData.sumaAsegurada : null;
      this.formData.deducible = jsonData.deducible ? jsonData.deducible : null;
      this.formData.coaseguro = jsonData.coaseguro ? jsonData.coaseguro : null;
      this.formData.ceroDeducible = jsonData.ceroDeducible ? jsonData.ceroDeducible : null;
      this.formData.medicinaFueraHospital = jsonData.medicinaFueraHospital ? jsonData.medicinaFueraHospital : null;
      this.formData.emergenciaExtranjero = jsonData.emergenciaExtranjero ? jsonData.emergenciaExtranjero : null;
      this.formData.enfermedadesCatastroficas = jsonData.enfermedadesCatastroficas ? jsonData.enfermedadesCatastroficas : null;
      this.formData.coberturaExtranjero = jsonData.coberturaExtranjero ? jsonData.coberturaExtranjero : null;
      this.formData.preexistencias = jsonData.preexistencias ? jsonData.preexistencias : null;
      this.formData.proteccionDental = jsonData.proteccionDental ? jsonData.proteccionDental : null;
      this.formData.maternidadExtendida = jsonData.maternidadExtendida ? jsonData.maternidadExtendida : null;
      this.formData.coberturaNacional = jsonData.coberturaNacional ? jsonData.coberturaNacional : null;
      this.formData.reconocimientoAntiguedad = jsonData.reconocimientoAntiguedad ? jsonData.reconocimientoAntiguedad : null;
      this.formData.fechasParaCotizar = jsonData.fechasParaCotizar ? jsonData.fechasParaCotizar : null;
      this.formData.dateFormattedInicioVigencia = jsonData.dateFormattedInicioVigencia ? jsonData.dateFormattedInicioVigencia.preexistencias : null;
      this.formData.menufechasParaCotizar = jsonData.menufechasParaCotizar ? jsonData.menufechasParaCotizar : null;
     //Comun para todos
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.fin = jsonData.fin ? jsonData.fin : null;
      this.formData.inicio = jsonData.inicio ? jsonData.inicio : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
      //fin de comun 
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
