<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeComponent')"
    >
      <template v-slot:data>
        <v-row v-if="datosSolicitud.tipo == 'Renovación'">
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="No. de Póliza a renovar"
              v-model="formData.polizaRenovar"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha Inicio de vigencia a renovar"
              v-model="formData.fechaInicioVigenciaRenovar"
              :rules="inputsRequeridos"
              type="date"
              outlined
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha Fin de vigencia a renovar"
              v-model="formData.fechaFinVigenciaRenovar"
              type="date"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="
              datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <v-text-field
              v-model="formData.numeroPoliza"
              label="Número de póliza"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.anios"
              label="Años de Vigencia"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.inicio"
              type="date"
              label="Inicio de Vigencia"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.fin"
              type="date"
              label="Fin de Vigencia"
              placeholder="Calculado automáticamente"
              :rules="inputsRequeridos"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-select
              v-model="formData.periodicidadSelected"
              label="Periodicidad de Pago"
              :items="periodicidadItems"
              item-text="text"
              item-value="value"
              :rules="inputsRequeridos"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-select
              v-model="formData.formaPagoSelected"
              label="Forma de Pago"
              :items="formaPago"
              :rules="inputsRequeridos"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Tipo de Inmueble"
              v-model="formData.tipoInmueble"
              :items="tipoInmuebleList"
              item-text="name"
              item-value="id"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="formData.tipoInmueble == 'Otro'"
          >
            <v-text-field
              label="Especificar Tipo de inmueble"
              v-model="formData.otroTipoInmueble"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Valor del inmueble"
              v-model="formData.valorInmueble"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <!-- Solo si es departamente -->
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="formData.tipoInmueble == 'Departamento'"
          >
            <v-text-field
              label="Nivel del departamento"
              v-model="formData.nivelDepartamento"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="formData.tipoInmueble == 'Departamento'"
          >
            <v-text-field
              label="Pisos Totales"
              v-model="formData.pisosTotales"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            >
            </v-text-field>
          </v-col>
          <!-- Solo si es Casa -->
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="formData.tipoInmueble == 'Casa'"
          >
            <v-text-field
              label="Número de pisos altos"
              v-model="formData.numeroPisosAltos"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="formData.tipoInmueble == 'Casa'"
          >
            <v-text-field
              label="Sotanos"
              v-model="formData.sotanos"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Valor de los contenidos"
              v-model="formData.valorContenidos"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Valor del equipo electrónico"
              v-model="formData.valorEquipoElectronico"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Suma asegurada Responsabilidad Civil"
              v-model="formData.sumaAseguradaRC"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Suma asegurada Cristales"
              v-model="formData.sumaAseguradaCristales"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Suma asegurada robo con violencia "
              v-model="formData.sumaAseguradaRoboViolencia"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Suma asegurada dinero y valores"
              v-model="formData.sumaAseguradaDineroValores"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Suma asegurada obras de arte o piezas de difícil reposición"
              v-model="formData.sumaAseguradaObrasDeArte"
              prefix="$"
              v-number-only
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    datosSolicitud: Object,
  },
  data() {
    return {
      formData: {
        id: null,
        tipoInmueble: null,
        otroTipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },
      //listas
      tipoInmuebleList: ["Casa", "Departamento", "Bodega", "Edificio", "Otro"],
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      this.formData.tipoInmueble = jsonData.tipoInmueble ? jsonData.tipoInmueble : null;
      this.formData.otroTipoInmueble = jsonData.otroTipoInmueble ? jsonData.otroTipoInmueble : null;
      this.formData.valorInmueble = jsonData.valorInmueble ? jsonData.valorInmueble : null;
      this.formData.nivelDepartamento = jsonData.nivelDepartamento ? jsonData.nivelDepartamento : null;
      this.formData.pisosTotales = jsonData.pisosTotales ? jsonData.pisosTotales : null;
      this.formData.numeroPisosAltos = jsonData.numeroPisosAltos ? jsonData.numeroPisosAltos : null;
      this.formData.sotanos = jsonData.sotanos ? jsonData.sotanos : null;
      this.formData.valorContenidos = jsonData.valorContenidos ? jsonData.valorContenidos : null;
      this.formData.valorEquipoElectronico = jsonData.valorEquipoElectronico ? jsonData.valorEquipoElectronico : null;
      this.formData.sumaAseguradaRC = jsonData.sumaAseguradaRC ? jsonData.sumaAseguradaRC : null;
      this.formData.sumaAseguradaCristales = jsonData.sumaAseguradaCristales ? jsonData.sumaAseguradaCristales : null;
      this.formData.sumaAseguradaRoboViolencia = jsonData.sumaAseguradaRoboViolencia ? jsonData.sumaAseguradaRoboViolencia : null;
      this.formData.sumaAseguradaDineroValores = jsonData.sumaAseguradaDineroValores ? jsonData.sumaAseguradaDineroValores : null;
      this.formData.sumaAseguradaObrasDeArte = jsonData.sumaAseguradaObrasDeArte ? jsonData.sumaAseguradaObrasDeArte : null;
     //Comun para todos
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.fin = jsonData.fin ? jsonData.fin : null;
      this.formData.inicio = jsonData.inicio ? jsonData.inicio : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
      //fin de comun 
    },
  },
};
</script>
