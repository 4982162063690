<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        SLA de la solicitud
      </template>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="4" sm="4" md="4" lg="4" xl="4">
            <span class="text-header-data ">
              SLA
            </span>
            <br />
            <span class="text-data-details">
              {{ datosSolicitud.cumpleSla || "No cumple" }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <span class="text-header-data ">
              Fecha de información completa
            </span>
            <br />
            <span class="text-data-details">
              {{ infoSLA.fechaInfoCompleta || "No disponible" }}
            </span>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <span class="text-header-data ">
              Hora
            </span>
            <br />
            <span class="text-data-details">
              {{ infoSLA.horaInfoCompleta || "No disponible" }}
            </span>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <span class="text-header-data ">
              Fecha en trámite
            </span>
            <br />
            <span class="text-data-details">
              {{ infoSLA.fechaEnTramite || "No disponible" }}
            </span>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <span class="text-header-data ">
              Hora
            </span>
            <br />
            <span class="text-data-details">
              {{ infoSLA.horaEnTramite || "No disponible" }}
            </span>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <span class="text-header-data ">
              Fecha de finalización
            </span>
            <br />
            <span class="text-data-details">
              {{ infoSLA.fechaFinalizacion || "No disponible" }}
            </span>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <span class="text-header-data ">
              Hora
            </span>
            <br />
            <span class="text-data-details">
              {{ infoSLA.horaFinalizacion || "No disponible" }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";

export default {
  components: {
    CommonExpansionPanel,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },

  props: {
    datosSolicitud: Object,
    infoSLA: Object,
  },

  methods: {},
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
