<template>
  <div>
    <v-dialog v-model="newCorreoDialog" persistent width="500" transition="dialog-bottom-transition">
          <v-card>
            <v-card-title style="background-color: #133b7d; color: white;">
              <span class="text-h5">Crear Correo</span>
            </v-card-title>
            <v-card-text style="margin-top: 25px;">
              <div>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        :items="correosFijos"
                        label="Remitente"
                        v-model="from"
                        item-text="correo" 
                        item-value="correo" 
                        :disabled="disableTo"
                        ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="10">
                    <v-select
                        :items="correosContacto"
                        label="Destinatario"
                        v-model="to"
                        hide-details="auto"
                        item-text="correo" 
                        item-value="correo" 
                        multiple
                        chips
                        deletable-chips
                        select-all
                    >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="seleccionarCorreos"
                      >
                        <v-list-item-action>
                          <v-icon :color="to.length > 0 ? 'indigo darken-2' : ''">
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Seleccionar todo
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    
                  </v-select>
                  </v-col>
                  <v-col cols="2" class="text-right mt-auto" >
                    <div>
                      <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="openDialogMethod"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-combobox
                        v-model="cc"
                        chips
                        clearable
                        label="CC"
                        multiple
                        variant="solo"
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip
                            v-bind="attrs"
                            :model-value="selected"
                            closable
                            @click="select"
                            @click:close="remove(item)"
                          >
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-combobox
                        v-model="bcc"
                        chips
                        clearable
                        label="BCC"
                        multiple
                        variant="solo"
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip
                            v-bind="attrs"
                            :model-value="selected"
                            closable
                            @click="select"
                            @click:close="remove(item)"
                          >
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                    label="Asunto"
                    v-model="subject"
                    hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                    v-model="content"
                    label="Mensaje"
                    value=""
                    hint="Escribe aquí tu mensaje"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                    counter
                    multiple
                    small-chips
                    v-model="files"
                    truncate-length="15"
                    ></v-file-input> 
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="closeFirstDialog"
                >
                  Cerrar
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="enviarCorreo"
                >
                  Enviar
                </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <new-email :openDialog="openDialog" 
                  @closeDialog="closeDialogMethod"
                  @addEmail="addEmail">
      </new-email>
  </div>
</template>
<script>
import NewEmail from "./NewEmail.vue";
import { mainAxios } from "../../mainAxios";
import getCorreosFijos from "../../components/listadoCorreos/correosFijos";

export default {
  props: {
    solicitud: Number,
    poliza: Number,
    cuota: Number,
    cliente: Number,
    usuario: Number,
    correoAgente: String,
    idCliente: {
      type: [String, Number],
    },
    idAgente: {
      type: [String, Number],
    },
    correoPrincipal: {
      type: String,
    },
    canalesAgente: {
      type: Array,
    },
    rolAgente: {
      type: String,
    },
    newCorreoDialog:{
      type: Boolean,
    },
    correoContacto: String,
  },
  components: {
    NewEmail
  },
  data(){
    return{
      from: [],
      bcc:[],
      subject:"",
      to: "",
      content:"",
      cc:[],
      files:[],
      dialog: false,
      axiosConfig: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("agenteAccessToken")}`,
        },
      },
      axiosConfigEmail: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      },
      correosContacto:[],
      openDialog: false,
      correosFijos:[],
      disableTo:false,
      emailType: 0
    }
  },
  watch: {
    newCorreoDialog(val){
      if(val){
        if ((this.idAgente || this.idCliente) 
              && typeof this.solicitud == 'undefined'
              && typeof this.poliza == 'undefined'
              && typeof this.cliente == 'undefined'){
            this.getCorreos();  
            this.correosFijos =  getCorreosFijos({ rol: this.rolAgente, canales: this.canalesAgente});
          if(this.correosFijos.length > 0){
            this.from = this.correosFijos[0]
            this.disableTo = this.correosFijos.length == 1 ? true : false
          }
        }
        else if(typeof this.solicitud != 'undefined' 
             || typeof this.poliza    != 'undefined'
             || typeof this.cliente   != 'undefined' ){
          this.getCorreosNoUsuarios()
        } 
      }
    },
  },
  computed:{
    endpoints() {
      if (!this.idAgente && !this.idCliente) return null;
      if (this.idAgente)
        return {
          insert: "v1/correo-contacto/insert",
          update: `v1/correo-contacto/update/`,
          delete: "v1/correo-contacto/delete",
          get: `v1/agente/list/correos-contacto/${this.idAgente}`,
        };
      return {
        insert: "v1/correo-contacto/insert",
        update: `v1/correo-contacto/update/`,
        delete: "v1/correo-contacto/delete",
        get: `v1/cliente/list/correos-contacto/${this.idCliente}`,
      };
    },
    icon () {
      if (this.to.length != this.correosContacto.length) 
      return 'mdi-checkbox-blank-outline'
      else
      return 'mdi-close-box'
    },
    allEmails() {
      return this.to.length === this.correosContacto.length
    },
    endpointEnviarEmail(){
      if(this.solicitud)
        return '/v1/portal/correo/enviar'
      else if(this.poliza)
        return '/v1/portal/emision/correo/enviar'
      else if(this.cuota)
        return '/v1/portal/cuota/correo/enviar'
      else if(this.cliente)
        return '/v1/portal/cliente/correo/enviar'
      else if(this.usuario)
        return '/v1/portal/agente/correo/enviar'
      else  
        return 'not implemented'
    },
  },
  methods:{
    enviarCorreo(){
        const formData = new FormData();

        for( var m = 0; m < this.files.length; m++ ){
          let file = this.files[m];
          formData.append('files', file);
        }

        formData.append("remitente", this.from);
        formData.append("to", this.to);
        formData.append("content",this.content);
        formData.append("subject",this.subject);
        formData.append("bcc",this.bcc);
        formData.append("cc",this.cc);

        if(this.solicitud)
          formData.append("solicitud", `${this.solicitud}`);
        else if(this.poliza)
          formData.append("emision", `${this.poliza}`);
        else if(this.cuota)
          formData.append("cuota", `${this.cuota}`);
        else if(this.cliente)
          formData.append("cliente", `${this.cliente}`);
        else if(this.usuario)
          formData.append("agente_id", `${this.usuario}`);

        mainAxios.post(this.endpointEnviarEmail, formData, this.axiosConfigEmail)
        .then((result) => {
          console.log(result);
          if (result.status == 200) {
            this.$toast.success("Correo enviado con exito ! ", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
           });
           this.closeFirstDialog()
          }
        }, function (error) {
          console.log(error);
        });
      
    },
    seleccionarCorreos () {
      this.$nextTick(() => {
        if (this.allEmails) {
          this.to = []
        } else {
          this.to = this.correosContacto.slice()
        }
      })
    },
    openDialogMethod(){
      this.openDialog = true;
    },
    closeDialogMethod(){
      this.openDialog = false;
    },
    addEmail(email){
       this.openDialog = false;
       this.correosContacto.push({
        correo: email
       })
       this.to.push(email)
      this.$toast.success("Correo agregado con exito ! ", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
        });
    },
    closeFirstDialog(){
      this.from = []
      this.bcc = [],
      this.subject = "",
      this.to =  "",
      this.content = "",
      this.cc = [],
      this.files = [],
      this.dialog = false,
      this.correosContacto = [],
      this.openDialog =  false,
      this.correosFijos = [], 
      this.disableTo = false,
      this.$emit('closeSendEmail')
    },
    getCorreos() {
      this.correosContacto = []; 
      mainAxios.get(this.endpoints.get,  this.axiosConfig).then((response) => {
        if (response.data.error) return;
        if (response.data && response.data.length && !response.data[0].error)
          response.data.reverse().map((c) => (
            this.correosContacto.push(c.correo)
          ));
      })
    },
    getCorreosNoUsuarios() {
      this.correosFijos = []; 
      mainAxios.get(this.endpoints.get,  this.axiosConfig).then((response) => {
        if (response.data.error) return;
        if (response.data && response.data.length && !response.data[0].error){
          response.data.reverse().map((c) => (
            this.correosFijos.push(c.correo)
          ));
          this.from = this.correosFijos[0]
        }
      })
      if(typeof this.correoContacto != "undefined"){
        this.correosContacto.push(this.correoContacto)
      }
    }
  },

}
</script>