import intermx from '@/assets/img/intermx-1.svg';
import interwhite from '@/assets/img/interwhite.svg';

export const tenentConfig = (especificTenet = window.location.hostname) => {
    switch (especificTenet) {
        case 'asesoresdigitales.interify.mx':
        case 'asesores-digitales-develop.netlify.app':
        case 'asesoresdigitales-pruebas.interify.mx':
            localStorage.setItem('globalConfig', JSON.stringify({
                logoUrl: "https://asesoresdigitales.interify.mx/img/interify.7d9eb17f.svg",
                title: "Bienvenido a tu portal",
                subTitle: "Ingresa tu correo electrónico y contraseña",
                createAccount: "https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Inter-loader-02-08-23-3.gif",
                showCreateAccount: true,
                showQuotesDashboard: true,
                showFooter:true,
                showClienteGraph:true,
                showRemeberAccount:true,
                colorFooter:'footer footer-portalECI',
                logoFooter:'https://asesoresdigitales.interify.mx/img/interify_black.53ba1057.svg'
            }))
            return { applied: true }
        case 'porta-eci.intex.mx':
        case 'porta-eci-qa.inter.mx':
        case 'portal-eci.inter.mx':
        case 'portal-eci-qa.inter.mx':
            localStorage.setItem('globalConfig', JSON.stringify({
                logoUrl: intermx,
                title: "Bienvenido a tu portal ECI",
                subTitle: "Ingresa tu correo electrónico y contraseña",
                showCreateAccount: false,
                showQuotesDashboard: false,
                showFooter:false,
                showRemeberAccount:false,
                showMainHubGraphs:false,
                colorFooter:'footer footer-portalInterify',
                logoFooter: interwhite

            }))
            return { applied: true }
        case 'localhost':
            return { applied: false, isDevelop: true }
    }
}


export const tenantsList = [
    {
        tenantName: 'Interify',
        tenantUrl: 'asesoresdigitales.interify.mx',
    },
    {
        tenantName: 'Portal ECI',
        tenantUrl: 'porta-eci.intex.mx',
    },
    {
        tenantName: 'Interify Alianzas',
        tenantUrl: 'alianza',
    },
]

export const alianzaList = [
    {
        tenantName: 'grupoKC',
        tenantUrl: 'alianza/grupoKC',
    },
    {
        tenantName: 'betterware',
        tenantUrl: 'alianza/betterware',
    },
    {
        tenantName: 'concredito',
        tenantUrl: 'alianza/concredito',
    },
    {
        tenantName: 'finvivir',
        tenantUrl: 'alianza/finvivir',
    },
]