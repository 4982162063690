<template>
  <common-card class="my-4">
    <v-card-title>
      <h3>Datos personales</h3>
    </v-card-title>
    <v-card-text>
      <v-col cols="12">
        <span class="mt-negative mb-5"
          >Introduce los datos del titular del vehículo</span
        >
        <v-row class="mt-3">
          <v-col cols="12" md="6">
            <common-email-autocomplete
              :emailEdit="personalData.correo"
              @emailUpdate="(e) => (personalData.correo = e)"
            ></common-email-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="personalData.telefono"
              label="Teléfono*"
              :rules="phoneValidation"
              type="tel"
              outlined
              maxlength="10"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row> </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              label="Nombre*"
              v-model="personalData.nombre"
              :rules="[
                ...required,
                $rules.nameRule('Ingresa un nombre válido'),
              ]"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              label="Apellido Paterno*"
              v-model="personalData.apellidoPaterno"
              :rules="[
                ...required,
                $rules.nameRule('Ingresa un apellido válido'),
              ]"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              label="Apellido Materno"
              v-model="personalData.apellidoMaterno"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              label="Fecha de nacimiento*"
              v-model="personalData.fechaDeNacimiento"
              :rules="required"
              required
              type="date"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              label="Código postal*"
              v-model="personalData.cp"
              required
              :rules="cpValidation"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <p>Genero*</p>
            <v-radio-group v-model="personalData.genero" mandatory>
              <v-row>
                <v-col cols="12" md="6">
                  <v-radio label="Femenino" value="F" color="primary"></v-radio>
                </v-col>
                <v-col cols="12" md="6">
                  <v-radio
                    label="Masculino"
                    value="M"
                    color="primary"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </common-card>
</template>

<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";
import {
  requiredRule,
  cpRules,
  emailRules,
  phoneRules,
} from "../../../../helpers/validacionesCampos";
export default {
  name: "FormularioDatosPersonales",
  props: { personalData: Object },
  components: { CommonCard, CommonEmailAutocomplete },
  data() {
    return {
      required: requiredRule,
      cpValidation: cpRules,
      emailValidation: emailRules,
      phoneValidation: phoneRules,
    };
  },
};
</script>
<style scoped>
.mt-negative {
  margin-top: -10px;
}
</style>
