<template>
    <v-app-bar
        color="#F6F5F9"
        style="padding-left: 20px; padding-top: 10px;"
    >
    <v-row style="padding-top: 2%;">
        <v-col cols="5" sm="8" md="8" lg="8" xl="10" class="share-bar-clase" >
          <!-- <v-text-field
              class="share-bar-clase"
              label="Buscar"
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              :light="true"
              background-color="white"
          ></v-text-field> -->
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="2" style="text-align: end;">
          <v-btn class="mi-clase" icon color="#00A7E4" @click="emitOpenMenu">
            <v-icon>mdi-menu</v-icon>
        </v-btn>
        <!-- <v-btn icon>
            <v-icon>mdi-bell-outline</v-icon>
        </v-btn> -->
        <v-avatar color="primary" @click="showMenu" style="cursor: pointer;">
            <span class="white--text">
                {{
                (
                    name.split(" ")[0][0] +
                    (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                ).toUpperCase()
                }}
            </span>
            </v-avatar>
        </v-col>
    </v-row>
    </v-app-bar>
</template>

<script>
export default {
  components: {

  },
  props:{
    openProp: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    openProp(val){
      this.isOpen = val
    }
  },
  data(){
    return{
      name: localStorage.agenteName,
      isOpen: false
    }
  },
  methods:{
    emitOpenMenu(){
      this.$emit('openMenu')
    },
    showMenu(){
      this.isOpen = !this.isOpen
      this.$emit('showMenu',this.isOpen )
    }
  }

};
</script>
<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
  background-color:#F6F5F9;
}

.main-styles{
  height: 100%;
  padding: 23px;
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: none;
}

/* Media query para pantallas de 1263px o menos */
@media screen and (min-width: 1263px) {
  .mi-clase {
    display: none; /* o display: block; para mostrar el contenido */
  }
}

@media screen and (max-width: 425px) {
  .share-bar-clase {
    display: none; /* o display: block; para mostrar el contenido */
  }
}
</style>
