var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonExpansionPanel',{attrs:{"currentPanel":_vm.currentPanel},scopedSlots:_vm._u([{key:"title-panel",fn:function(){return [_vm._v(" Datos generales ")]},proxy:true}])},[_c('v-expansion-panel-content',[_c('v-form',{ref:"firstStepForm",attrs:{"lazy-validation":true}},[_c('v-row',{staticClass:"justify-end"},[_c('v-col',{attrs:{"cols":"10"}}),(_vm.data.producto != 'Llamada colgada/incompleta')?_c('v-col',{staticClass:"p-6",attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":"Mostrar todas tipificaciones","hide-details":"","inset":""},model:{value:(_vm.llamadaSeguimiento),callback:function ($$v) {_vm.llamadaSeguimiento=$$v},expression:"llamadaSeguimiento"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","m":"12","md":"6","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Medio de contacto","outlined":"","primary":"","disabled":"","color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.tipo_llamada),callback:function ($$v) {_vm.$set(_vm.data, "tipo_llamada", $$v)},expression:"data.tipo_llamada"}})],1),_c('v-col',{attrs:{"m":"12","md":"6","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Producto","outlined":"","item-value":"campania","item-text":"campania","items":_vm.currentProducts,"primary":"","color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.producto),callback:function ($$v) {_vm.$set(_vm.data, "producto", $$v)},expression:"data.producto"}})],1),(_vm.data.producto == 'Seguimiento')?_c('v-col',{attrs:{"m":"12","md":"6","xl":"3"}},[(_vm.currentSolicitudes > 0)?_c('v-autocomplete',{attrs:{"label":"Solicitud","outlined":"","item-value":"id","item-text":"id","items":_vm.solicitudes,"primary":"","color":"#00a7e4","type":"text","rules":_vm.requiredRule},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Solicitud #"+_vm._s(item.id)+" ")]),_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',[_vm._v("Estatus: "+_vm._s(item.status))])])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openSolicitudView(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)],1)],1)]}},{key:"append",fn:function(){return [(_vm.data.solicitud_id != '')?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openSolicitudView(_vm.data.solicitud_id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()]},proxy:true}],null,false,3897829883),model:{value:(_vm.data.solicitud_id),callback:function ($$v) {_vm.$set(_vm.data, "solicitud_id", $$v)},expression:"data.solicitud_id"}}):_c('v-text-field',{attrs:{"label":"Solicitud","outlined":"","readonly":"","rules":_vm.requiredRule},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-filter")])],1)]},proxy:true}],null,false,3554298101),model:{value:(_vm.data.solicitud_id),callback:function ($$v) {_vm.$set(_vm.data, "solicitud_id", $$v)},expression:"data.solicitud_id"}})],1):_vm._e(),(_vm.data.producto == 'Llamada colgada/incompleta')?_c('v-col',{attrs:{"m":"12","md":"6","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Tipificación","outlined":"","primary":"","items":[
              'Se corta llamada',
              'No se escucha',
              'Llamada equivocada',
              'Cliente Cuelga',
              'Otro' ],"color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.tipificacion),callback:function ($$v) {_vm.$set(_vm.data, "tipificacion", $$v)},expression:"data.tipificacion"}})],1):_vm._e(),(
            _vm.data.producto &&
              _vm.data.producto != 'Seguimiento' &&
              _vm.data.producto != 'Llamada colgada/incompleta'
          )?_c('v-col',{attrs:{"m":"12","md":"6","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Empresa","outlined":"","primary":"","item-value":"empresa","item-text":"empresa","items":_vm.currentEmpresas,"color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.empresa),callback:function ($$v) {_vm.$set(_vm.data, "empresa", $$v)},expression:"data.empresa"}})],1):_vm._e(),(
            _vm.data.empresa &&
              _vm.data.producto &&
              _vm.data.producto != 'Seguimiento' &&
              _vm.data.producto != 'Llamada colgada/incompleta'
          )?_c('v-col',{attrs:{"m":"12","md":"6","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Aseguradora","outlined":"","primary":"","item-value":"id","item-text":"name","items":_vm.currentAseguradoras,"color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.aseguradora),callback:function ($$v) {_vm.$set(_vm.data, "aseguradora", $$v)},expression:"data.aseguradora"}})],1):_vm._e(),(
            _vm.data.empresa &&
              _vm.data.producto &&
              _vm.data.aseguradora &&
              _vm.data.producto != 'Seguimiento' &&
              _vm.data.producto != 'Llamada colgada/incompleta'
          )?_c('v-col',{attrs:{"m":"12","md":"6","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Tipificación","outlined":"","primary":"","item-value":"tipificacion","item-text":"tipificacion","items":_vm.filtertTipificaciones,"color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.tipificacion),callback:function ($$v) {_vm.$set(_vm.data, "tipificacion", $$v)},expression:"data.tipificacion"}})],1):_vm._e(),(
            _vm.data.empresa &&
              _vm.data.producto &&
              _vm.data.aseguradora &&
              _vm.data.tipificacion &&
              _vm.data.producto != 'Seguimiento' &&
              _vm.data.producto != 'Llamada colgada/incompleta'
          )?_c('v-col',{attrs:{"m":"12","md":"6","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Motivo","outlined":"","primary":"","item-value":"motivo","item-text":"motivo","items":_vm.filtertMotivos,"color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.disposicion),callback:function ($$v) {_vm.$set(_vm.data, "disposicion", $$v)},expression:"data.disposicion"}})],1):_vm._e(),(
            _vm.data.empresa &&
              _vm.data.producto &&
              _vm.data.aseguradora &&
              _vm.data.tipificacion &&
              _vm.data.disposicion &&
              _vm.data.producto != 'Seguimiento' &&
              _vm.data.producto != 'Llamada colgada/incompleta'
          )?_c('v-col',{attrs:{"m":"12","md":"6","xl":"6"}},[_c('v-text-field',{attrs:{"label":"Póliza","outlined":"","primary":"","color":"#00a7e4","type":"text","rules":_vm.requiredRule},model:{value:(_vm.data.poliza),callback:function ($$v) {_vm.$set(_vm.data, "poliza", $$v)},expression:"data.poliza"}})],1):_vm._e(),(
            _vm.data.empresa &&
              _vm.data.producto &&
              _vm.data.aseguradora &&
              _vm.data.tipificacion &&
              _vm.data.disposicion &&
              _vm.data.poliza &&
              _vm.data.producto != 'Seguimiento' &&
              _vm.data.producto != 'Llamada colgada/incompleta'
          )?_c('v-col',{staticClass:"whitout-padding-bottom",attrs:{"cols":"12","sm":"6","md":"6","xl":"3"}},[_c('span',{staticClass:"text-header-data whitout-padding-bottom"},[_vm._v(" ¿Atendio en llamada? ")]),_c('v-radio-group',{attrs:{"row":"","rules":_vm.requiredRule},model:{value:(_vm.data.atendioLlamada),callback:function ($$v) {_vm.$set(_vm.data, "atendioLlamada", $$v)},expression:"data.atendioLlamada"}},[_c('v-radio',{attrs:{"label":"Si","value":"true","color":"#00a7e4"}}),_c('v-radio',{attrs:{"label":"No","value":"false","color":"#00a7e4"}})],1)],1):_vm._e(),(
            _vm.data.empresa &&
              _vm.data.producto &&
              _vm.data.aseguradora &&
              _vm.data.tipificacion &&
              _vm.data.disposicion &&
              _vm.data.poliza &&
              _vm.data.producto != 'Seguimiento' &&
              _vm.data.producto != 'Llamada colgada/incompleta'
          )?_c('v-col',{staticClass:"whitout-padding-bottom",attrs:{"cols":"12","sm":"6","md":"6","xl":"3"}},[_c('span',{staticClass:"text-header-data whitout-padding-bottom"},[_vm._v(" ¿Es queja? ")]),_c('v-radio-group',{attrs:{"row":"","rules":_vm.requiredRule},model:{value:(_vm.data.isQueja),callback:function ($$v) {_vm.$set(_vm.data, "isQueja", $$v)},expression:"data.isQueja"}},[_c('v-radio',{attrs:{"label":"Si","value":"true","color":"#00a7e4"}}),_c('v-radio',{attrs:{"label":"No","value":"false","color":"#00a7e4"}})],1)],1):_vm._e(),(_vm.data.producto != 'Llamada colgada/incompleta')?_c('v-col',{attrs:{"m":"12","md":"6","lg":"12","xl":"12"}},[_c('v-text-field',{attrs:{"label":"Detalle","outlined":"","primary":"","color":"#00a7e4","type":"text"},model:{value:(_vm.data.detalle),callback:function ($$v) {_vm.$set(_vm.data, "detalle", $$v)},expression:"data.detalle"}})],1):_vm._e()],1)],1),_c('v-row',{staticStyle:{"text-align":"end"}},[(
          _vm.data.producto == 'Llamada colgada/incompleta' ||
            _vm.data.producto == 'Seguimiento'
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"common-botton",attrs:{"rounded":"","dark":""},on:{"click":_vm.goToSaveLlamadaCortada}},[_c('span',[_vm._v(" Guardar ")])])],1):_vm._e(),(
          _vm.data.producto != 'Llamada colgada/incompleta' &&
            _vm.data.producto != 'Seguimiento'
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"common-botton",attrs:{"rounded":"","dark":""},on:{"click":_vm.validateStep}},[_c('span',[_vm._v(" Siguiente ")])])],1):_vm._e()],1)],1),_c('SolcitudesFiltersModal',{attrs:{"dialog":_vm.dialog},on:{"closeModal":function($event){_vm.dialog = false},"seleccionSolicitud":_vm.seleccionSolicitud}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }