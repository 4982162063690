<template>
  <v-expansion-panel-content>
    <div>
      <EditAutos
        v-if="polizaInfo.categoriaSelected == 'AUTOS'"
        @changeTab="$emit('changeTab', 1)"
        @getData="$emit('getData')"
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
      />
      <EditAhorro
        v-if="polizaInfo.categoriaSelected == 'AHORRO'"
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
        @changeTab="$emit('changeTab', 1)"
        @getData="$emit('getData')"
      />
      <EditDaños
        v-if="polizaInfo.categoriaSelected == 'DAÑOS'"
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
        @changeTab="$emit('changeTab', 1)"
        @getData="$emit('getData')"
      />
      <EditFianza
        v-if="polizaInfo.categoriaSelected == 'FIANZA'"
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
        @changeTab="$emit('changeTab', 1)"
        @getData="$emit('getData')"
      />
      <EditVida
        v-if="polizaInfo.categoriaSelected == 'VIDA'"
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
        @changeTab="$emit('changeTab', 1)"
        @getData="$emit('getData')"
      />
      <EditSalud
        v-if="polizaInfo.categoriaSelected == 'SALUD'"
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
        @changeTab="$emit('changeTab', 1)"
        @getData="$emit('getData')"
      />
      <EditTecnologia
        v-if="polizaInfo.categoriaSelected == 'FINANZAS'"
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
        @changeTab="$emit('changeTab', 1)"
        @getData="$emit('getData')"
      />
    </div>
  </v-expansion-panel-content>
</template>

<script>
//import moment from "moment";
// import { mainAxios } from "@/mainAxios.js";
import EditAutos from "../PolizaDatosGenerales/RamosForm/RamosEdit/EditAutos.vue";
import EditAhorro from "../PolizaDatosGenerales/RamosForm/RamosEdit/EditAhorro.vue";
import EditDaños from "../PolizaDatosGenerales/RamosForm/RamosEdit/EditDaños.vue";
import EditSalud from "../PolizaDatosGenerales/RamosForm/RamosEdit/EditSalud.vue";
import EditTecnologia from "../PolizaDatosGenerales/RamosForm/RamosEdit/EditTecnologia.vue";
import EditVida from "../PolizaDatosGenerales/RamosForm/RamosEdit/EditVida.vue";
import EditFianza from "../PolizaDatosGenerales/RamosForm/RamosEdit/EditFianza.vue";

export default {
  components: {
    EditAutos,
    EditAhorro,
    EditDaños,
    EditSalud,
    EditTecnologia,
    EditVida,
    EditFianza,
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
    };
  },
  methods: {
    changeComponent() {
      this.$emit("changeTab", 1);
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
