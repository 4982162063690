<template>
  <v-app>
    <v-content>
      <v-container fluid style="background-color: ghostwhite;">
        <v-row>
          <v-col>
            <transition name="fade">
              <v-row class="mb-15" align="center" justify="center">
                <v-carousel
                  cycle
                  height="505"
                  hide-delimiter-background
                  show-arrows="hover"
                >
                  <v-carousel-item v-for="(banner, i) in banners" :key="i">
                    <v-sheet height="100%">
                      <div
                        class="d-flex fill-height justify-center align-center"
                      >
                        <img
                          :src="'img/bannersHome/' + banner.url"
                          class="bannerHome"
                          alt=""
                        />
                      </div>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-row>
            </transition>
          </v-col>
        </v-row>
        <div style="text-align: center;" class="mb-15">
          <h1>
            "Encuentra tu seguro ideal"
          </h1>
          <h3 style="border-bottom:2px solid #172184;padding-bottom:5px;">
            No pierdas más tiempo buscando en diferentes lugares, encuentra todo
            lo que necesitas en nuestro hub de cotizadores de autos. ¡Comienza a
            explorar y descubre la mejor opción para ti!
          </h3>
        </div>
        <div>
          <v-row>
            <template v-for="(item, i) in items">
              <v-col
                :key="i"
                cols="6"
                md="12"
                sm="12"
                xs="12"
                lg="6"
                xl="6"
                v-on:click="$router.push({ path: item.route })"
              >
                <div class="projcard-container">
                  <div
                    :class="
                      item.title == 'Segurify Comparador'
                        ? 'projcard projcard-blue'
                        : item.title == 'Qualitas'
                        ? 'projcard projcard-qualitas'
                        : item.title == 'GNP'
                        ? 'projcard projcard-gnp'
                        : item.title == 'AIG'
                        ? 'projcard projcard-blue'
                        : item.title == 'Ana'
                        ? 'projcard projcard-ana'
                        : item.title == 'HDI'
                        ? 'projcard projcard-hdi'
                        : 'projcard projcard-blue'
                    "
                  >
                    <div class="projcard-innerbox">
                      <img class="projcard-img" :src="item.background" />
                      <div class="projcard-textbox">
                        <div class="projcard-title">
                          <img
                            :src="item.img"
                            style="max-width: 100%; max-height: 100px"
                          />
                        </div>
                        <div class="projcard-bar"></div>
                        <div class="projcard-description">{{ item.text }}</div>
                        <div class="projcard-tagbox">
                          <span
                            class="projcard-tag"
                            style="font-size: 16px !important;"
                            >Cotiza Ahora !</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import "../../../src/assets/cardsHub.css";
require("../../../src/assets/cardsHub.css");
export default {
  data() {
    return {
      banner: 0,
      banners: [
        { id: 1, url: "SEGURIFY_PortalAgentes_005_Qualitas.jpg" },
        { id: 2, url: "SEGURIFY_PortalAgentes_006_AIG.jpg" },
        { id: 3, url: "SEGURIFY_PortalAgentes_007_GNP.jpg" },
        { id: 4, url: "SEGURIFY_PortalAgentes_008_HDI.jpg" },
        { id: 5, url: "SEGURIFY_PortalAgentes_009_ANA.jpg" },
        { id: 6, url: "segurify_banners_qualitas_260922.gif" },
      ],
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      items: [
        {
          title: "Segurify Comparador",
          text:
            "Segurify: ¡Rápido y fácil! Compara costos y beneficios entre nuestras aseguradoras.",
          route: "cotiza/segurify",
          img: require("../../assets/logo_segurify.png"),
          background: require("../../assets/Seguro_de_auto_header.jpeg"),
          enabled: true,
        },
        {
          title: "Qualitas",
          text: "Más de 26 años de experiencia a tu alcance. ",
          route: "cotiza/qualitas",
          img: require("../../assets/qualitas_new.svg"),
          background: require("../../assets/happycar.jpg"),
          enabled: true,
        },
        {
          title: "GNP",
          text:
            "El respaldo de una empresa 100% mexicana, comprometida con brindar la mejor protección a sus asegurados.",
          route: "cotiza/gnp",
          img: require("../../assets/gnp_new.svg"),
          background: require("../../assets/happycarpeople.jpg"),
          enabled: true,
        },
        {
          title: "AIG",
          text:
            "La mejor experiencia de servicio al alcance de todos sus asegurados. ",
          route: "cotiza/aig",
          img: require("../../assets/aig_new_two.png"),
          background: require("../../assets/familiacarro.jpg"),

          enabled: true,
        },
        {
          title: "Ana",
          text: "Eficiencia y calidad para sus clientes. ",
          route: "cotiza/ana",
          img: require("../../assets/ana.png"),
          background: require("../../assets/accidentecoche.jpg"),
          enabled: false,
        },
        {
          title: "HDI",
          text: "Innovación para tu protección",
          route: "cotiza/hdi",
          img: require("../../assets/hdi_new.svg"),
          background: require("../../assets/seguroautos.jpg"),
          enabled: true,
        },
      ],
      transparent: "rgba(255, 255, 255, 0)",
      loading4: false,
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    loader() {
      console.log("aqui entre");
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    aquiEvento() {
      console.log("aqui");
    },
  },
};
</script>

<style>
.tittle-text {
  width: 100%;
  float: left;
  font-size: 40px;
  color: #001431;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 0;
}
</style>
