<template >
    <v-row>
        <v-col cols="12">
            <v-row>
                <v-col cols="12" md="6">
                    <common-card class="pa-4">
                        <v-card-title>
                             <v-row>
                                <h4 class="ml-2 text-h4 font-weight-bold">
                                    Conductor <v-icon class="ml-16"  >mdi-pencil</v-icon>
                                </h4>
                             </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <div class="d-flex flex-column">
                                                <h4 class="text-h6 font-weight-bold">Nombre</h4>
                                                <p class="subtitle1">Carlos</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div class="d-flex flex-column">
                                                <h4 class="text-h6 font-weight-bold">Apellido P.</h4>
                                                <p class="subtitle1">Carrera</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div class="d-flex flex-column">
                                                <h4 class="text-h6 font-weight-bold">Apellido M.</h4>
                                                <p class="subtitle1">n/a</p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <div class="d-flex flex-column">
                                                <h4 class="text-h6 font-weight-bold">C.P</h4>
                                                <p class="subtitle1">53100</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div class="d-flex flex-column">
                                                <h4 class="text-h6 font-weight-bold">Genero</h4>
                                                <p class="subtitle1">Masculino</p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div class="d-flex flex-column">
                                                <h4 class="text-h6 font-weight-bold">Edad</h4>
                                                <p class="subtitle1">38</p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </common-card>
                </v-col>
                <v-col cols="12" md="6">
                    <common-card class="pa-4">
                        <v-card-title>
                                <v-row>
                                    <h4 class="text-h4 font-weight-bold">
                                        Vehiculo <v-icon class="ml-16"  >mdi-pencil</v-icon>
                                    </h4>
                                </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="my-4">
                                        <h4 class="text-h5 font-weight-bold">Datos del vehiculo</h4>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="d-flex flex-column">
                                                         <p class="body1">NISSAN</p>
                                                         <p class="body1">MARCH SR NAVI STD 1.6L 4CIL</p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                             <v-row>
                                                <v-col cols="12">
                                                    <div class="d-flex flex-column">
                                                         <p class="body1">NAVI STD 1.6L 4CIL</p>
                                                         <p class="body1">5PTAS, 2015</p>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </common-card>
                </v-col>
            </v-row>
            <v-row class="my-4">
                <v-col cols="12">
                <common-card class="pa-4">
                <common-form
                    route="/v1/seguro/medico"
                    action="POST"
                >
                <template #data>
                    <v-container class="p-4">
                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <h4 class="text-h5 mb-2">Modalidad de pago</h4>
                                <v-select
                                    :items="items"
                                    label="Seleciona tu tipo de plan"
                                    :rules="tipoDePlanRules"
                                    outlined
                                    v-model="tipoDePlan"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <h4 class="text-h5 mb-2">Tipo de plan</h4>                                
                                <v-select
                                :items="items"
                                label="Seleciona tu tipo de plan"
                                :rules="tipoDePlanRules"
                                outlined
                                v-model="tipoDePlan"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                           <v-col cols="12">
                               <v-row>
                                   <h4 class="text-h5 ml-2 mb-2">Cobertura</h4>
                               </v-row>
                               <v-row>
                                    <v-col
                                        cols="12"
                                        md="3"
                                    >
                                        <v-select
                                        :items="items"
                                        label="Seleciona tu tipo de plan"
                                        :rules="tipoDePlanRules"
                                        outlined
                                        v-model="tipoDePlan"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="3"
                                    >
                                        <v-select
                                        :items="items"
                                        label="Seleciona tu tipo de plan"
                                        :rules="tipoDePlanRules"
                                        outlined
                                        v-model="tipoDePlan"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="3"
                                    >
                                        <v-select
                                        :items="items"
                                        label="Seleciona tu tipo de plan"
                                        :rules="tipoDePlanRules"
                                        outlined
                                        v-model="tipoDePlan"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="3"
                                    >
                                        <v-select
                                        :items="items"
                                        label="Seleciona tu tipo de plan"
                                        :rules="tipoDePlanRules"
                                        outlined
                                        v-model="tipoDePlan"
                                        ></v-select>
                                    </v-col>
                               </v-row>
                           </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                            col="12"
                            >
                                <v-row justify="end">
                                    <v-col cols="12" md="4">
                                        <v-btn depressed color="primary" block rounded>Volver a Cotizar</v-btn>
                                    </v-col>
                                </v-row>  
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </common-form>
                </common-card>
                </v-col>
            </v-row>
            <v-row class="ml-2 my-6">
                <h4 class="text-h6 text-md-h4 font-weight-bold" >
                    Escoge la aseguradora de preferencia o envia una comparativa.                 
                 </h4>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="10">
                            <common-card class="d-flex align-center pa-2">
                                <v-card-text>
                                    <div class="d-flex align-center">
                                        
                                        <div class="mx-2">
                                        <v-icon color="blue" class="mdi-36px">
                                                mdi-checkbox-marked
                                            </v-icon>
                                        </div>
                                        <div class="d-flex flex-column align-center mx-2">
                                            <v-img width="134" height="56" contain  src="../../../../assets/images/aseguradora-1.png" alt="asegoradora-1"></v-img>
                                            <span class="font-weight-bold text-h5" >
                                                Total:<br/>
                                                $5,826,32
                                            </span>
                                        </div>
                                                
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si ocasionas daños con tu vehículo, tienes $3,000,000</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si visitas al médico por un accidente</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si necesitas ayuda con tu vehículo o apoyo legal</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    class="mt-2 full-width-btn"
                                    outlined
                                    rounded
                                    block
                                    color="primary"
                                >
                                    Emitir
                                </v-btn>
                            </v-card-actions>
                        </common-card> 
                        </v-col>
                        <v-col cols="2">
                            <div  class="ml-2 d-flex flex-column box-bg align-center justify-center pa-2 rounded-xl">
                                <v-icon class="mdi-48px">mdi-bullhorn</v-icon>
                                <h2>Ver promo</h2>
                             </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10">
                            <common-card class="d-flex align-center pa-2">
                                <v-card-text>
                                    <div class="d-flex align-center">
                                        
                                        <div class="mx-2">
                                        <v-icon color="blue" class="mdi-36px">
                                                mdi-checkbox-marked
                                            </v-icon>
                                        </div>
                                        <div class="d-flex flex-column align-center mx-2">
                                            <v-img width="134" height="56" contain  src="../../../../assets/images/aseguradora-2.png" alt="asegoradora-1"></v-img>
                                            <span class="font-weight-bold text-h5" >
                                                Total:<br/>
                                                $5,826,32
                                            </span>
                                        </div>
                                                
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si ocasionas daños con tu vehículo, tienes $3,000,000</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si visitas al médico por un accidente</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si necesitas ayuda con tu vehículo o apoyo legal</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    class="mt-2 full-width-btn"
                                    outlined
                                    rounded
                                    block
                                    color="primary"
                                >
                                    Emitir
                                </v-btn>
                            </v-card-actions>
                        </common-card> 
                        </v-col>
                        <v-col cols="2">
                            <div  class="ml-2 d-flex flex-column box-bg align-center justify-center pa-2 rounded-xl">
                                <v-icon class="mdi-48px">mdi-bullhorn</v-icon>
                                <h2>Ver promo</h2>
                             </div>
                        </v-col>
                    </v-row>

                                        <v-row>
                        <v-col cols="10">
                            <common-card class="d-flex align-center pa-2">
                                <v-card-text>
                                    <div class="d-flex align-center">
                                        
                                        <div class="mx-2">
                                        <v-icon color="blue" class="mdi-36px">
                                                mdi-checkbox-marked
                                            </v-icon>
                                        </div>
                                        <div class="d-flex flex-column align-center mx-2">
                                            <v-img width="134" height="56" contain  src="../../../../assets/images/aseguradora-3.png" alt="asegoradora-1"></v-img>
                                            <span class="font-weight-bold text-h5" >
                                                Total:<br/>
                                                $5,826,32
                                            </span>
                                        </div>
                                                
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si ocasionas daños con tu vehículo, tienes $3,000,000</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si visitas al médico por un accidente</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <p class="list-item">Por si necesitas ayuda con tu vehículo o apoyo legal</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    class="mt-2 full-width-btn"
                                    outlined
                                    rounded
                                    block
                                    color="primary"
                                >
                                    Emitir
                                </v-btn>
                            </v-card-actions>
                        </common-card> 
                        </v-col>
                        <v-col cols="2">
                            <div  class="ml-2 d-flex flex-column box-bg align-center justify-center pa-2 rounded-xl">
                                <v-icon class="mdi-48px">mdi-bullhorn</v-icon>
                                <h2>Ver promo</h2>
                             </div>
                        </v-col>
                    </v-row>
                           
                </v-col>
                 
            </v-row>
            <v-row justify="end"> 
                <div class="d-flex flex-wrap align-center mt-4">
                    <div class="ml-2 d-flex flex-column justify-center align-center">
                                <v-icon color="primary">mdi-content-save</v-icon>
                                <v-btn
                                    text
                                    color="primary"
                                >
                                    Guardar
                                </v-btn>
                    </div>

                    <div class="ml-2 d-flex flex-column justify-center align-center">
                                <v-icon color="primary">mdi-cloud-download</v-icon>
                                <v-btn
                                    text
                                    color="primary"
                                >
                                    Descargar
                                </v-btn>
                    </div>

                    
                    <v-btn 
                        depressed 
                        color="primary" 
                        class="ml-2 text-center" 
                        rounded
                    >
                    Enviar comparativa
                    <v-icon class="ml-2">mdi-send</v-icon>
                    </v-btn>
                </div>
        </v-row>
        </v-col>
    </v-row>
</template>
<script>
import CommonCard from '../../../../components/commonComponents/CommonCard.vue';
import CommonForm from '../../../../components/commonComponents/CommondForm.vue';

export default {
    components: {
        CommonCard,
        CommonForm
    },
    data() {
        return  {
            items: ['item 1', 'item 2', 'item3'],     
            tipoDePlan: null,  
            tipoDePlanRules: [v => !!v || 'Tipo de plan es requerido']        
        }
    }
}
</script>
<style scoped>
    .box-bg {
        background-color: #FFD100;
        height: 100%;
    }
    .full-width-btn {
       width: 100%;
     }
</style>