<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Estadísticas
    </template>
    <v-expansion-panel-content>
      <div style="padding: 30px;">
        <v-row>
          <v-col cols="12" md="7">
            <div class="d-md-flex">
              <span
                class="text-subtitle-1 font-weight-bold mt-1 mr-2"
                style="color: #4D5358"
                >Filtrar por periodo</span
              >
              <InputPeriodo
                @updateDate="getTodasLasEmisiones"
                style="min-width: 50%; "
              ></InputPeriodo>
            </div>
          </v-col>
        </v-row>
        <div v-if="!loading">
          <v-row v-if="!noResults">
            <v-col cols="12" md="6" xs="12" style="align-self: center;">
              <cards-info-polizas :emisiones="todasLasEmisiones" />
            </v-col>
            <v-col cols="12" md="6">
              <emisiones-status-graphic
                :emisiones="todasLasEmisiones"
              ></emisiones-status-graphic>
            </v-col>
          </v-row>
          <div v-else>
            <v-col
              cols="12"
              md="12"
              style="align-items: center; text-align: center; padding: 5%;"
            >
              <span class="titulo-header">Sin resultados que mostrar</span>
            </v-col>
          </div>
        </div>
        <div v-else>
          <v-row>
            <v-col>
              <v-col cols="12" class="d-flex align-md-center justify-md-center">
                <v-img
                  class="responsive"
                  contain
                  max-height="200"
                  max-width="300"
                  :src="loader.createAccount"
                ></v-img>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import EmisionesStatusGraphic from "@/components/commonComponents/charts/EmisionesStatus.graphic.vue";
import CardsInfoPolizas from "./CardsInfoPolizas.vue";
import { mainAxios } from "@/mainAxios.js";
import InputPeriodo from "../../../../../components/commonComponents/forms/InputPeriodo.vue";
import loginData from "@/helpers/data/data-login-template.json";

export default {
  components: {
    EmisionesStatusGraphic,
    CommonExpansionPanel,
    CardsInfoPolizas,
    InputPeriodo,
  },

  data() {
    return {
      switch_data: 1,
      todasLasEmisiones: {},
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      loader: loginData,
      statusArray: [
      "Renovada",
      "Emitida Pendente de Pago",
      "Finalizada",
      "Duplicado",
      "Nueva",
      "Pagada Total",
      "Pagada Parcial",
      "Emitido Certificado",
      "Cancelada"
      ],
      noResults: false,
      loading: false,
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
  },
  mounted() {
  },
  methods: {
    changeComponent(component) {
      this.switch_data = component;
      this.$emit("getData");
    },
    getTodasLasEmisiones(dates) {
      this.loading = true;
      let body={
        fechas: dates
      }
      // if(String(window.location.pathname).includes('/alianza/perfil/')){
      //   body.alianza = parseInt(this.$route.params.alianza_id);
      // }
      mainAxios
        .post(
          `v1/emision/dashboard/data`,
          body,
          this.requestConfig
        )
        .then(({ data }) => {
          this.todasLasEmisiones = data;
          this.loading = false;
          this.checkIfHasResults();
        });
    },
    checkIfHasResults() {
      var count = 0;
      this.noResults = false;
      this.statusArray.map((e) => {
        if (this.todasLasEmisiones["Emisiones-stats"][e] == 0) count++;
      });
      if (count == Object.keys(this.todasLasEmisiones["Emisiones-stats"]).length)
        this.noResults = true;
    },
  },
};
</script>
