<template>
  <v-app>
    <v-container fluid>
      <v-row class="mb-15" v-if="emitiendo == true">
        <v-col cols="12">
          <v-card loading style="text-aling: center">
            <h2 style="text-align: center; padding: 50px">
              Estamos emitiendo tu poliza
            </h2>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="mb-15"
        v-if="emitiendo == false && emitida == true && error == true"
      >
        <v-col cols="12">
          <v-card style="text-aling: center">
            <h2 style="text-align: center; padding: 50px">
              ¡Lo siento! Ocurrió un error al emitir tu póliza. Por favor, envía
              el código de seguimiento a tu Gerente Comercial, te ayudará de
              inmediato.
            </h2>
            <div style="text-align: center">
              <v-icon
                large
                color="red darken-2"
                style="text-align: center; font-size: 100px"
              >
                mdi-alert-circle
              </v-icon>
            </div>

            <h2 style="text-align: center; padding: 50px">
              Error:{{ errorMessage }}
            </h2>
            <p style="text-align: center; padding: 50px">
              Código Seguimiento: {{ codigoConfirmacionSegurify }}
            </p>
            <div style="text-align: center; margin-bottom: 40px">
              <v-btn
                rounded
                color="primary"
                style="margin-bottom: 40px"
                @click="
                  emitiendo = false;
                  emitida = false;
                  error = false;
                "
                dark
                >Intentar de nuevo</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="mb-15"
        v-if="
          emitiendo == false &&
            emitida == true &&
            error == false &&
            aseguradora != 'gnp'
        "
      >
        <v-col cols="12">
          <v-card style="text-aling: center">
            <h2 style="text-align: center; padding: 50px">
              ¡Felicidades has emitido tu póliza con número:{{ poliza }} !
            </h2>
            <div style="text-align: center">
              <v-icon
                large
                color="green darken-2"
                style="text-align: center; font-size: 100px"
              >
                mdi-book-check
              </v-icon>
            </div>

            <h2 style="text-align: center; padding: 50px">
              Código de confirmación Segurify:
              {{ codigoConfirmacionSegurify }}
            </h2>
            <div style="text-align: center; padding: 50px">
              <v-btn
                round
                color="primary"
                :href="'https://segurify-data.s3.amazonaws.com/' + urlPoliza"
                target="_blank"
                v-if="urlPoliza != null && tipoPago == 'enlinea'"
                style="margin-right: 20px"
                dark
                >Ver Poliza</v-btn
              >
              <v-btn
                round
                color="primary"
                :href="'https://segurify-data.s3.amazonaws.com/' + urlRecibo"
                target="_blank"
                v-if="urlRecibo != null && tipoPago == 'banco'"
                dark
                >Ver Recibos</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="mb-15"
        v-if="
          emitiendo == false &&
            emitida == true &&
            error == false &&
            aseguradora == 'gnp'
        "
      >
        <v-col cols="12">
          <v-card style="text-aling: center">
            <h2 style="text-align: center; padding: 50px">
              ¡Felicidades tu poliza está lista para pago en la terminal virtual
              GNP (Aún no está emitida, deberás completar el pago para que la
              póliza se emita)!
            </h2>
            <div style="text-align: center">
              <v-icon
                large
                color="green darken-2"
                style="text-align: center; font-size: 100px"
              >
                mdi-book-check
              </v-icon>
            </div>

            <h2 style="text-align: center; padding: 50px">
              Código de confirmación Segurify:
              {{ codigoConfirmacionSegurify }}
            </h2>
            <div style="text-align: center; padding: 50px">
              <v-btn
                round
                color="primary"
                :href="
                  'https://gnpventamasiva.com.mx/Portal/pagotv.aspx?TipOP=WSP&IdT=' +
                    cotizacion.referencia +
                    '&Mac=' +
                    emision.total +
                    '&codPromo=COP0000895&idLlave=kuYiddYjtE8=&URLResp=https://portal.segurify.com/confirmaGNP/' +
                    cotizacion.referencia +
                    '/confirma'
                "
                target="_blank"
                style="margin-right: 20px"
                dark
                >Pagar poliza en terminal virtual GNP</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-15" v-if="emitiendo != true && emitida == false">
        <v-col>
          <v-row class="mb-15" align="center" justify="center">
            <v-col md="6" sm="12" xs="12">
              <v-stepper v-model="e6" vertical>
                <v-stepper-step :complete="e6 > 1" step="1">
                  Completa tus datos
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-row class="mb-15" style="padding: 15px">
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="customer.cp"
                        label="Codigo Postal"
                        required
                        placeholder="#####"
                        v-mask="'#####'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="customer.calle"
                        label="Calle"
                        onkeypress="return (event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode >= 48 && event.charCode <= 57 || event.charCode == 32)"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="customer.numeroExterior"
                        label="Numero exterior"
                        required
                        v-mask="'#####'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="customer.numeroInterior"
                        label="Numero interior"
                        v-mask="'#####'"
                      ></v-text-field>
                    </v-col>
                    <!-- Estado de la República -->
                    <v-col cols="12" md="4">
                      <v-select
                        item-text="estadoRepublica"
                        item-value="id"
                        v-model="customer.estado"
                        :items="estados"
                        placeholder="Seleccionar Estado"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="rfc"
                        label="RFC"
                        onkeypress="return (event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode >= 48 && event.charCode <= 57 || event.charCode == 32)"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-btn
                    color="primary"
                    :disabled="usuarioDatos()"
                    @click="e6 = 2"
                  >
                    Continuar
                  </v-btn>
                  <v-btn text @click="e6 = 1"> Regresar </v-btn>
                </v-stepper-content>
              </v-stepper>

              <v-stepper v-model="e6" vertical>
                <v-stepper-step :complete="e6 > 2" step="2">
                  Registra los datos del vehículo
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-row class="mb-15" style="padding: 15px">
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="motor"
                        label="Motor"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="placa"
                        label="Placa"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="name"
                        v-model="serie"
                        label="Serie"
                        required
                        v-mask="'NNNNNNNNNNNNNNNNN'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn
                    color="primary"
                    :disabled="autoDatos()"
                    @click="e6 = 3"
                  >
                    Continuar
                  </v-btn>
                  <v-btn text @click="e6 = 1"> Regresar </v-btn>
                </v-stepper-content>
              </v-stepper>

              <v-stepper v-model="e6" vertical>
                <v-stepper-step :complete="e6 > 3" step="3">
                  Registra los datos del pago
                </v-stepper-step>

                <v-stepper-content step="3">
                  <div v-if="aseguradora.toLowerCase() == 'gnp'">
                    <p>
                      Con GNP, una vez que realices tu emisión deberás pagarla
                      por medio de la terminal virtual de GNP.
                    </p>
                  </div>
                  <div v-if="aseguradora.toLowerCase() == 'qualitas'">
                    <v-radio-group v-model="tipoPago">
                      <template v-slot:label>
                        <div>
                          Selecciona el tipo de pago que quieres realizar
                        </div>
                      </template>
                      <v-radio value="enlinea">
                        <template v-slot:label>
                          <div>Pago en línea con tarjeta de crédito</div>
                        </template>
                      </v-radio>

                      <v-radio value="banco">
                        <template v-slot:label>
                          <div>
                            Descargar recibos para pago en sucursal bancaria
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                  <div v-if="tipoPago == 'enviaracliente'">
                    <p>
                      Si eliges este método de pago, al emitir tu póliza se
                      enviara una liga a tu cliente para que realice el pago por
                      su cuenta. (Disponible solamente para Quálitas)
                    </p>
                  </div>
                  <div v-if="tipoPago == 'enlinea'">
                    <v-card color="lighten-1" class="mb-12">
                      <v-row class="" style="padding: 15px">
                        <v-credit-card
                          :trans="translations"
                          @change="creditInfoChanged"
                        />
                      </v-row>
                      <v-row
                        v-if="aseguradora == 'hdi'"
                        class="mb-15"
                        style="display: flex; justify-content: center"
                      >
                        <v-col cols="12" md="7" class="m-0" style="padding: 0">
                          <v-select
                            label="Tipo de tajeta"
                            item-text="text"
                            item-value="value"
                            v-model="cardOption"
                            :items="optionsCard"
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-15"
                        style="display: flex; justify-content: center"
                      >
                        <v-col cols="12" md="7" class="m-0" style="padding: 0">
                          <v-select
                            label="Institución Bancaria"
                            item-text="nombre"
                            item-value="uuid"
                            v-model="customer.bancoUuid"
                            :items="banco"
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>

                  <v-btn text @click="e6 = 2"> Regresar </v-btn>
                </v-stepper-content>
              </v-stepper>
            </v-col>

            <v-col md="6" sm="12" xs="12">
              <v-card style="padding: 30px">
                <v-card-title primary-title>
                  <div>
                    <img
                      :src="getAseguradoraImage(aseguradora)"
                      style="width: 150px"
                    />
                    <h3
                      class="headline mb-0"
                      style="margin-bottom: 30px !important"
                    >
                      Tu vehículo a emitir
                    </h3>
                    <div>
                      <v-row>
                        <v-col cols="12" md="4">
                          <p>
                            Modelo:
                            <span style="color: gray" v-if="modelo == null"
                              >Aún no seleccionado</span
                            >
                            <span style="color: gray" v-if="modelo != null">{{
                              modelo
                            }}</span>
                          </p>
                        </v-col>

                        <v-col cols="12" md="4">
                          <p>
                            Marca:
                            <span style="color: gray" v-if="marca == null"
                              >Aún no seleccionado</span
                            >
                            <span style="color: gray" v-if="marca != null">{{
                              marca.marca
                            }}</span>
                          </p>
                        </v-col>

                        <v-col cols="12" md="12">
                          <p>
                            Versión:
                            <span style="color: gray" v-if="vehiculo == null"
                              >Aún no seleccionado</span
                            >
                            <span style="color: gray" v-if="vehiculo != null">{{
                              vehiculo.nombre
                            }}</span>
                          </p>
                        </v-col>
                      </v-row>
                      <h3
                        class="headline mb-0 mt-3"
                        style="margin-bottom: 30px !important"
                      >
                        Tus datos del cliente
                      </h3>
                      <v-row>
                        <v-col cols="12" md="6">
                          <p>
                            Cliente:
                            <span
                              style="color: gray"
                              v-if="
                                customer.name == null &&
                                  customer.lastname == null
                              "
                              >Aún no seleccionado</span
                            >
                            <span
                              style="color: gray"
                              v-if="
                                customer.name != null &&
                                  customer.lastname != null
                              "
                              >{{ customer.name }} {{ customer.lastname }}</span
                            >
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p>
                            Edad:
                            <span
                              style="color: gray"
                              v-if="customer.edad == null"
                              >Aún no seleccionado</span
                            >
                            <span
                              style="color: gray"
                              v-if="customer.edad != null"
                              >{{ customer.edad }}</span
                            >
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p>
                            Nacimiento:
                            <span
                              style="color: gray"
                              v-if="
                                customer.birthDay == null &&
                                  customer.birthMonth == null &&
                                  customer.birthYear == null
                              "
                              >Aún no seleccionado</span
                            >
                            <span
                              style="color: gray"
                              v-if="
                                customer.birthDay != null &&
                                  customer.birthMonth != null &&
                                  customer.birthYear != null
                              "
                              >{{ customer.birthDay }}/{{
                                customer.birthMonth
                              }}/{{ customer.birthYear }}</span
                            >
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p>
                            Sexo:
                            <span
                              style="color: gray"
                              v-if="customer.sexo == null"
                              >Aún no seleccionado</span
                            >
                            <span
                              style="color: gray"
                              v-if="customer.sexo != null"
                              >{{
                                customer.sexo == "M" ? "Masculino" : "Femenino"
                              }}</span
                            >
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p>
                            Correo:
                            <span
                              style="color: gray"
                              v-if="customer.email == null"
                              >Aún no seleccionado</span
                            >
                            <span
                              style="color: gray"
                              v-if="customer.email != null"
                              >{{ customer.email }}</span
                            >
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p>
                            RFC:
                            <span style="color: gray" v-if="rfc == null"
                              >Aún no seleccionado</span
                            >
                            <span style="color: gray" v-if="rfc != null">{{
                              rfc
                            }}</span>
                          </p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p>
                            Estado Civi:
                            <span
                              style="color: gray"
                              v-if="customer.civilStatus == null"
                              >Aún no seleccionado</span
                            >
                            <span
                              style="color: gray"
                              v-if="customer.civilStatus != null"
                              >{{ customer.civilStatus }}</span
                            >
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn
                    rounded
                    color="primary"
                    style="width: 100%"
                    @click="emitir"
                    :disabled="bancoDatos()"
                    >Emitir</v-btn
                  >
                </v-card-actions>
                <p v-show="bancoDatos() == true" class="mensaje">
                  Es necesario llenar todos los datos emitir.
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import "v-credit-card/dist/VCreditCard.css";
import RfcFacil from "rfc-facil";
import { mainAxios } from "../../mainAxios";

const translations = {
  name: {
    label: "Nombre como aparece en la tarjeta",
    placeholder: "Nombre completo",
  },
  card: {
    label: "Número de tarjeta",
    placeholder: "Número de tarjeta",
  },
  expiration: {
    label: "Expiration",
  },
  security: {
    label: "Código de seguridad",
    placeholder: "Código",
  },
};

export default {
  data() {
    return {
      deComparador: false,
      cardOption: "C",
      optionsCard: [
        { value: "C", text: "Crédito" },
        { value: "D", text: "Débito" },
      ],
      tipoPago: "enlinea",
      poliza: "",
      codigoConfirmacionSegurify: "",
      urlPoliza: "",
      emision: {},
      urlRecibo: "",
      request: "",
      name: "",
      cardNumber: "",
      expiration: "",
      security: "",
      serie: null,
      motor: null,
      placa: null,
      error: false,
      emitiendo: false,
      emitida: false,
      translations,
      rfc: "",
      e6: 1,
      activaEmitir: true,
      // auto
      modelo: "",
      marca: { id: "10", marca: "HONDA" },
      submarca: "",
      vehiculo: {
        id: "",
        nombre: "",
      },
      customer: {
        name: "",
        lastname: "",
        lastname2: "",
        email: "",
        phone: "",
        birthDay: null,
        birthMonth: null,
        birthYear: null,
        cp: "",
        civilStatus: "",
        sexo: "",
        edad: "",
      },
      vehiculoJson: {},
      cotizacion: {},
      banco: "",
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
        { id: 33, estadoRepublica: "Desconocio" },
      ],
    };
  },

  created() {
    this.deComparador = this.$route.params.deComparador;
    this.aseguradora = this.$route.params.aseguradora;
    this.cotizacion = this.$route.params.cotizacion;
    if (this.aseguradora == "gnp") {
      this.tipoPago = "terminalvirtual";
    }

    this.getBanco();

    this.vehiculoJson = this.convertirJson(this.cotizacion.vehiculoJson);
    this.usuarioJson = this.convertirJson(this.cotizacion.usuarioJson);
    this.modelo = this.vehiculoJson.modelo.id;
    this.marca.id = this.vehiculoJson.marca.id;
    this.marca.marca = this.vehiculoJson.marca.marca;
    this.submarca = this.vehiculoJson.submarca.id;
    this.vehiculo.id = this.vehiculoJson.vehiculo.id;
    this.vehiculo.nombre = this.vehiculoJson.vehiculo.nombre;
    this.customer.name = this.usuarioJson.name;
    this.customer.lastname = this.usuarioJson.lastname;
    this.customer.lastname2 = this.usuarioJson.lastname2;
    this.customer.email = this.usuarioJson.email;
    this.customer.phone = this.usuarioJson.phone;
    this.customer.birthDay = this.usuarioJson.birthDay;
    this.customer.birthMonth = this.usuarioJson.birthMonth;
    this.customer.birthYear = this.usuarioJson.birthYear;
    this.customer.cp = this.usuarioJson.cp;
    this.customer.civilStatus = this.usuarioJson.civilStatus;
    this.customer.sexo = this.vehiculoJson.generoConductor;
    this.customer.edad = this.getYearsOld(
      this.usuarioJson.birthDay,
      this.usuarioJson.birthMonth,
      this.usuarioJson.birthYear
    );

    this.getRfc();
  },
  methods: {
    getAseguradoraImage(aseguradora) {
      if (aseguradora == "segurify") {
        return require("../../assets/logo_segurify.png");
      }
      if (aseguradora == "qualitas") {
        return require("../../assets/qualitas_new.svg");
      }
      if (aseguradora == "gnp") {
        return require("../../assets/gnp_new.svg");
      }
      if (aseguradora == "aig") {
        return require("../../assets/aig_new.svg");
      }
      if (aseguradora == "ana") {
        return require("../../assets/ana.png");
      }
      if (aseguradora == "hdi") {
        return require("../../assets/hdi_new.svg");
      }
    },
    autoDatos() {
      let activa = 0;
      if (this.motor) {
        activa = this.motor.length > 3 ? activa + 1 : activa;
      }
      if (this.placa) {
        activa = this.placa.length > 3 ? activa + 1 : activa;
      }
      if (this.serie) {
        activa = this.serie.length > 16 ? activa + 1 : activa;
      }
      if (activa == 3) {
        return false;
      } else {
        return true;
      }
    },
    usuarioDatos() {
      let activa = 0;
      if (this.customer.cp) {
        activa = this.customer.cp.length > 3 ? activa + 1 : activa;
      }
      if (this.customer.calle) {
        activa = this.customer.calle.length > 2 ? activa + 1 : activa;
      }
      if (this.customer.numeroExterior) {
        activa = this.customer.numeroExterior.length > 0 ? activa + 1 : activa;
      }

      if (this.rfc) {
        activa = this.rfc.length > 4 ? activa + 1 : activa;
      }
      if (this.customer.estado) {
        activa = activa + 1;
      }
      if (activa == 5) {
        return false;
      } else {
        return true;
      }
    },
    bancoDatos() {
      const tarjetaTrim = this.cardNumber.replace(/ /g, "");
      let activa = 0;
      let expiracion = this.expiration.split("/");

      if (this.cotizacion.aseguradoraId == 3 || this.tipoPago == "banco") {
        if (this.motor) {
          activa = this.motor.length > 3 ? activa + 1 : activa;
        }
        if (this.placa) {
          activa = this.placa.length > 3 ? activa + 1 : activa;
        }
        if (this.serie) {
          activa = this.serie.length > 16 ? activa + 1 : activa;
        }
        if (activa == 3) {
          return false;
        } else {
          return true;
        }
      } else {
        if (tarjetaTrim) {
          activa = tarjetaTrim > 10 ? activa + 1 : activa;
        }
        if (expiracion[1]) {
          activa = activa + 1;
        }
        if (expiracion[0]) {
          activa = activa + 1;
        }
        if (this.security) {
          activa = activa + 1;
        }
        if (this.name) {
          activa = this.name.length > 3 ? activa + 1 : activa;
        }
        if (this.customer.bancoUuid) {
          activa = activa + 1;
        }
        if (activa == 6) {
          return false;
        } else {
          return true;
        }
      }
    },
    getRfc() {
      this.rfc = RfcFacil.forNaturalPerson({
        name: this.usuarioJson.name,
        firstLastName: this.usuarioJson.lastname,
        secondLastName: this.usuarioJson.lastname2,
        day: this.usuarioJson.birthDay,
        month: this.usuarioJson.birthMonth,
        year: this.usuarioJson.birthYear,
      });
    },
    convertirJson(json) {
      return JSON.parse(json);
    },
    getYearsOld(birthDay, birthMonth, birthYear) {
      let todayDate = new Date();
      let todayYear = todayDate.getFullYear();
      let todayMonth = todayDate.getMonth();
      let todayDay = todayDate.getDate();
      let age = todayYear - birthYear;

      if (todayMonth < birthMonth - 1) {
        age--;
      }
      if (birthMonth - 1 == todayMonth && todayDay < birthDay) {
        age--;
      }
      return age;
    },
    getBanco() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/bancos/qualitas`, config).then((response) => {
        this.banco = response.data;
      });
    },
    creditInfoChanged(values) {
      for (const key in values) {
        this[key] = values[key];
      }
    },

    emitir() {
      this.emitiendo = true;
      let expiracion = this.expiration.split("/");
      let plan = "amplia";
      if (this.cotizacion.plan == 2) {
        plan = "limitada";
      }
      if (this.cotizacion.plan == 3) {
        plan = "rc";
      }
      if (this.cotizacion.plan == 14) {
        plan = "amplia-plus";
      }
      const tarjetaTrim = this.cardNumber.replace(/ /g, "");
      console.log(tarjetaTrim);
      console.log(this.customer.numeroInterior);
      console.log(!this.customer.numeroInterior);
      if (!this.customer.numeroInterior) {
        this.customer.numeroInterior = " ";
      }
      let forma_pago;
      switch (this.cotizacion.pagoId) {
        case 1:
          forma_pago = "92dabb35-e35b-4413-b29a-5d0e3fd69ba5";
          break;
        case 2:
          forma_pago = "73b7851c-74b9-4c37-b65a-ae8b6ee2eabd";
          break;
        case 3:
          forma_pago = "95e07c46-7f89-4340-9459-aa146e9ae2eb";
          break;
        case 4:
          forma_pago = "94af2ddc-49f7-47bf-9ff6-b42c1052e661";
          break;

        default:
          break;
      }
      var request = {
        "forma-pago": forma_pago,
        usuario: `${this.customer.name} ${this.customer.lastname}`,
        plan: plan,
        "vehiculo-usuario": {
          serie: this.serie,
          motor: this.motor,
          placa: this.placa,
        },
        fechaNacimiento: `${this.customer.birthDay}/${this.customer.birthMonth}/${this.customer.birthYear}`,
        curp: " ",
        beneficiario: " ",
        vehiculo: this.cotizacion.identificadorVehiculo,
        modelo: this.modelo,
        telefono: `${this.customer.phone}`,
        apellidoPaterno: `${this.customer.lastname}`,
        apellidoMaterno: `${this.customer.lastname2}`,
        nombre: `${this.customer.name}`,
        cp: `${this.customer.cp}`,
        numeroInterior: `${this.customer.numeroInterior}`,
        calle: `${this.customer.calle}`,
        numeroExterior: `${this.customer.numeroExterior}`,
        estado: parseInt(this.customer.estado),

        sexo: `${this.customer.sexo}`,
        correo: `${this.customer.email}`,
        identificador: this.cotizacion.identificadorVehiculo,
        cotizacion: this.cotizacion.uuid,
        edad: `${this.customer.edad}`,
        rfc: this.rfc,
        agenteId: this.$route.params.agente
          ? this.$route.params.agente
          : localStorage.agenteUuid,
        estadoCivil: `${this.customer.civilStatus == "soltero" ? "S" : "C"}`,

        transaccion: {
          tarjeta: tarjetaTrim,
          anio: `20${expiracion[1]}`,
          mes: expiracion[0],
          cvv: this.security,
          nombre: this.name,
          banco: this.customer.bancoUuid,
        },
        coberturas: this.$route.params.coberturas,
      };
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      if (this.tipoPago != "enlinea") {
        this.$delete(request, "transaccion");
      }
      if (this.deComparador == false) {
        if (this.aseguradora == "hdi") {
          request.cardType = this.cardOption;
        }
        if (this.aseguradora == "ana") {
          request.vehiculo = request.identificador;
          request.descripcion = this.vehiculoJson.vehiculo.nombre;
        }
        if (this.aseguradora == "gnp") {
          request.identificador =
            this.modelo +
            "-" +
            this.vehiculoJson.marca.id +
            "-" +
            this.vehiculoJson.submarca.id +
            "-" +
            this.vehiculo.id;
        }
        if (this.aseguradora == "aig") {
          request.identificador =
            this.vehiculo.id +
            "-" +
            this.modelo +
            "-" +
            this.vehiculoJson.marca.id;
        }
      }

      mainAxios
        .post(
          "/v1/" + this.aseguradora.toLowerCase() + "/emite/landing",
          request,
          config
        )
        .then((value) => {
          if (value.data.status < 0) {
            this.emitiendo = false;
            this.emitida = true;
            this.error = true;
            this.codigoConfirmacionSegurify =
              value.data.codigoConfirmacionSegurify;
            this.errorMessage = value.data.errorMsg;
          } else {
            this.urlPoliza = value.data.urlPoliza;
            this.poliza = value.data.poliza;
            this.codigoConfirmacionSegurify =
              value.data.codigoConfirmacionSegurify;
            this.urlRecibo = value.data.urlRecibo;
            this.emitiendo = false;
            this.emitida = true;
            this.error = false;
            this.emision = value.data;
            if (this.aseguradora == "gnp") {
              mainAxios
                .get("/v1/cotizacion/id/" + this.emision.cotizacionId, config)
                .then((value2) => {
                  this.cotizacion = value2.data.cotizacion;
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.mensaje {
  color: red;
  font-size: 12px;
}
</style>
