var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CommonExpansionPanel',{scopedSlots:_vm._u([{key:"title-panel",fn:function(){return [_vm._v(" Datos de Generales ")]},proxy:true}])},[(typeof _vm.requestJson.esInbound == 'undefined')?_c('v-expansion-panel-content',[(_vm.datosSolicitud.categoria == 'AUTOS')?_c('div',[(
            _vm.datosSolicitud.tipo == 'Cotización' ||
              _vm.datosSolicitud.tipo == 'Emisión' ||
              _vm.datosSolicitud.tipo == 'Renovación'
          )?_c('div',[(_vm.switch_data == 1)?_c('ViewAutos',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditAutos',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1):_c('div',[(_vm.switch_data == 1)?_c('ViewNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1)]):_vm._e(),(_vm.datosSolicitud.categoria == 'AHORRO')?_c('div',[(
            _vm.datosSolicitud.tipo == 'Cotización' ||
              _vm.datosSolicitud.tipo == 'Emisión' ||
              _vm.datosSolicitud.tipo == 'Renovación'
          )?_c('div',[(_vm.switch_data == 1)?_c('ViewAhorro',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditAhorro',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1):_c('div',[(_vm.switch_data == 1)?_c('ViewNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1)]):_vm._e(),(_vm.datosSolicitud.categoria == 'DAÑOS')?_c('div',[(
            _vm.datosSolicitud.tipo == 'Cotización' ||
              _vm.datosSolicitud.tipo == 'Emisión' ||
              _vm.datosSolicitud.tipo == 'Renovación'
          )?_c('div',[(_vm.switch_data == 1)?_c('ViewDanios',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditDaños',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1):_c('div',[(_vm.switch_data == 1)?_c('ViewNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1)]):_vm._e(),(_vm.datosSolicitud.categoria == 'FIANZA')?_c('div',[(
            _vm.datosSolicitud.tipo == 'Cotización' ||
              _vm.datosSolicitud.tipo == 'Emisión' ||
              _vm.datosSolicitud.tipo == 'Renovación'
          )?_c('div',[(_vm.switch_data == 1)?_c('ViewFianza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditFianza',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1):_c('div',[(_vm.switch_data == 1)?_c('ViewNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1)]):_vm._e(),(_vm.datosSolicitud.categoria == 'SALUD')?_c('div',[(
            _vm.datosSolicitud.tipo == 'Cotización' ||
              _vm.datosSolicitud.tipo == 'Emisión' ||
              _vm.datosSolicitud.tipo == 'Renovación'
          )?_c('div',[(_vm.switch_data == 1)?_c('ViewSalud',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditSalud',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1):_c('div',[(_vm.switch_data == 1)?_c('ViewNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1)]):_vm._e(),(_vm.datosSolicitud.categoria == 'VIDA')?_c('div',[(
            _vm.datosSolicitud.tipo == 'Cotización' ||
              _vm.datosSolicitud.tipo == 'Emisión' ||
              _vm.datosSolicitud.tipo == 'Renovación'
          )?_c('div',[(_vm.switch_data == 1)?_c('ViewVida',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditVida',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1):_c('div',[(_vm.switch_data == 1)?_c('ViewNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 2}}}):_c('EditNumPoliza',{attrs:{"datosSolicitud":_vm.datosSolicitud,"canEdit":_vm.canEdit},on:{"changeComponent":function($event){_vm.switch_data = 1},"getData":function($event){return _vm.$emit('getData')}}})],1)]):_vm._e()]):_c('v-expansion-panel-content',[_c('div',[_c('ViewInbound',{attrs:{"solicitudData":_vm.datosSolicitud}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }