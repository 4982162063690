<template>
  <div class="pt-5">
    <v-data-table
      :items="solicitudes"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin solicitudes para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:top>
        <v-row style="padding: 15px;">
          <v-col cols="12" md="8">
            <h2>Resultados de la búsqueda</h2>
          </v-col>
        </v-row>
      </template>
      <template #item.ver="{ item }">
        <v-btn icon x-small @click="getToken(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template #item.primaNeta="{ item }">
        {{ item.tipo_producto != 'Mascota' ? formatPrice(item.primaNeta) : getPrimaNetaMascota(item) }}
      </template>
      <template #item.primerPago="{ item }">
        {{ formatPrice(item.primerPago) }}
      </template>
      <template #item.total="{ item }">
        {{ item.tipo_producto != 'Mascota' ? formatPrice(item.total) : getPrimaTotalMascota(item) }}
      </template>
      <template #item.fecha="{ item }">
        {{ getFormatDate(item.fecha) }}
      </template>
      <template #item.aseguradoraId="{ item }">
        {{ getAseguradoraName(item.aseguradoraId) }}
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
    <CommonRecotizar
      :dialog="dialog"
      @userChoice="recotizarProducto"
    ></CommonRecotizar>
  </div>
</template>

<script>
import moment from "moment";
import { mainAxios } from "@/mainAxios.js";
import CommonRecotizar from "@/components/commonComponents/CommonRecotizar.vue";
import imageAseguradoras from "@/helpers/data/data-image-aseguradoras.json";

export default {
  props: {
    solicitudes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommonRecotizar,
  },
  data() {
    return {
      headers: [
        {
          text: "Número",
          value: "id",
        },
        {
          text: "Nombre cliente",
          value: "nombre",
        },
        {
          text: "Correo",
          value: "correo",
        },
        {
          text: "Tipo producto",
          value: "tipo_producto",
        },
        {
          text: "Prima neta",
          value: "primaNeta",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Fecha de Creación",
          value: "fecha",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
      aseguradorasArray: [
        { id: 1, aseguradora: "Afirme" },
        { id: 2, aseguradora: "ANA" },
        { id: 3, aseguradora: "GNP" },
        { id: 4, aseguradora: "Primero" },
        { id: 5, aseguradora: "Qualitas" },
        { id: 6, aseguradora: "Multiva" },
        { id: 7, aseguradora: "Sura" },
        { id: 8, aseguradora: "AIG" },
        { id: 9, aseguradora: "HDI" },
        { id: 10, aseguradora: "Zurich" },
        { id: 11, aseguradora: "AXA" },
        { id: 12, aseguradora: "Banorte" },
      ],
      dialog: false,
      currentItem: {},
      accessToken: "",
      trackingId: "",
      images: imageAseguradoras,
      filteredAseguradoras: [],
      primas: [
        {
          nameId: "generaldeseguros",
          sumaAsegurada: "$3,000,000",
          deducible: "Sin deducible",
          coaseguro: "10%",
          topeCoaseguro: "$20,000",
          beneficios: "5 Incluidos",
          total: 0,
          show: true,
        },
        {
          nameId: "gnp",
          sumaAsegurada: "$7,365,000",
          deducible: "$19,500",
          coaseguro: "10% por padecimiento",
          topeCoaseguro: "$75,500",
          beneficios: "3 Incluidos",
          total: 0,
          show: true,
        },
        {
          nameId: "axa",
          sumaAsegurada: "$6,600,000",
          deducible: "$21,000",
          coaseguro: "10% por padecimiento",
          topeCoaseguro: "$45,000",
          beneficios: "2 Incluidos",
          total: 0,
          show: true,
        },
        {
          nameId: "mapfre",
          sumaAsegurada: "$6,000,000",
          deducible: "$19,000",
          coaseguro: "10% por padecimiento",
          topeCoaseguro: "$42,000",
          beneficios: "No Incluidos",
          total: 0,
          show: true,
        },
      ],
      aseguradoras: [],
      respCotizacionMedico: [],
    };
  },

  methods: {
    recotizarProducto(val) {
      console.log(this.currentItem);
      this.dialog = false;
      var request = {};
      if (val) {
        switch (this.currentItem.tipo_producto) {
          case "Coche":
            request = {
              personalData: JSON.parse(this.currentItem.usuarioJson),
              vehiculoData: JSON.parse(this.currentItem.vehiculoJson),
              request: JSON.parse(this.currentItem.request),
            };
            if (sessionStorage.getItem("agenteIdCotizacion")) {
              sessionStorage.removeItem("agenteIdCotizacion");
            }
            sessionStorage.setItem(
              "agenteIdCotizacion",
              this.currentItem.agenteId
            );
            var carPrice = {
              personalData: {
                nombre: request.personalData.name,
                apellidoPaterno: request.personalData.lastname,
                apellidoMaterno: request.personalData.secondLastname,
                correo: request.personalData.email,
                telefono: request.personalData.phone,
                cp: request.personalData.cp,
                fechaDeNacimiento: request.personalData.birthDate,
                genero: request.personalData.gender,
              },
              insurers: request.vehiculoData.insurers,
              insurerSelected: request.vehiculoData.insurers,
              token: this.accessToken,
              trackingId: this.trackingId,
              cotizacionGeneral: this.currentItem.cotizacionGeneral,
              recotizacion: true,
              plan: request.request.packageName,
              tipoPago: request.request.paymentOption,
              fecha_cotizacion: moment(this.currentItem.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY hh:mm:ss"),
              fecha: moment(this.currentItem.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY hh:mm:ss"),
              carBrand: {
                brand: request.vehiculoData.assembler,
                year: parseInt(request.vehiculoData.model),
                model: {
                  assembler: request.vehiculoData.assembler,
                  description: request.vehiculoData.description,
                  insurers: request.vehiculoData.insurers,
                  model: request.vehiculoData.model,
                  type: request.vehiculoData.type,
                  vehicle_id: request.vehiculoData.vehicleId,
                },
              },
            };
            this.construirCotizaciones(carPrice);
            break;
          case "Mascota":
            var jsonString = this.currentItem.usuarioJson;
            var jsonStringValido = jsonString
              .replace(/(\w+)=([^,}\]]+)/g, '"$1":"$2"')
              .replace(/,(\s*})/g, "$1");
            var objetoJSON = JSON.parse(jsonStringValido);
            var mascotaJson = this.currentItem.vehiculoJson;
            var mascotaJsonValido = mascotaJson
              .replace(/(\w+)=([^,}\]]+)/g, '"$1":"$2"')
              .replace(/,(\s*})/g, "$1");
            var objetoMascota = JSON.parse(mascotaJsonValido);
            if (sessionStorage.getItem("mascotaRequest")) {
              sessionStorage.removeItem("mascotaRequest");
            }
            sessionStorage.setItem(
              "mascotaRequest",
              JSON.stringify({
                requestInfo: {
                  nombreDuexo: objetoJSON.nombre,
                  apellidoPaterno: objetoJSON.apellidoPaterno,
                  apellidoMaterno: objetoJSON.apellidoMaterno,
                  telefono: objetoJSON.telefono,
                  cp: objetoJSON.cp,
                  nombreMascota: objetoMascota.nombre,
                  tipoMascota: {
                    cod_especie: objetoMascota.tipoMascotaId == 1 ? "1" : "2",
                    txt_desc_especie:
                      objetoMascota.tipoMascotaId == 1 ? "PERRO" : "GATO",
                  },
                  tipoMascotaId: objetoMascota.tipoMascotaId,
                  correo: objetoJSON.correo,
                  agenteId: this.currentItem.agenteId,
                },
                cotizacionGeneral: this.currentItem.cotizacionGeneral,
                isRecotizacion: true,
                fecha_cotizacion: moment(this.currentItem.fecha)
                  .subtract(6, "hours")
                  .format("DD/MM/YYYY hh:mm:ss"),
                aseguradoras: this.responseMascota(
                  JSON.parse(this.currentItem.response)
                ),
              })
            );
            this.$router.push({
              name: "cotizacionMascota",
            });
            break;
          case "Medico":
            var validJson = JSON.parse(this.currentItem.request);
            this.getNoGroupedCotizaciones(this.currentItem.cotizacionGeneral, validJson.plan);
            var contratanteJson = JSON.parse(validJson.contratate);
            if (sessionStorage.getItem("medicoRequest")) {
              sessionStorage.removeItem("medicoRequest");
            }
            var tipoContratante;
            if (contratanteJson.asegurados.length > 1)
              tipoContratante = "colectivo";
            else tipoContratante = "individual";

            request = {
              id: contratanteJson.id,
              nombre: contratanteJson.nombre,
              aPaterno: contratanteJson.aPaterno,
              aMaterno: contratanteJson.aMaterno,
              cp: contratanteJson.cp,
              correo: contratanteJson.correo,
              fechanacimiento: contratanteJson.fechanacimiento,
              genero: contratanteJson.genero,
              telefono: contratanteJson.telefono,
              ultimoPaso: 0,
              tipoContratante: tipoContratante,
              asegurados: [],
              agente: this.currentItem.agenteId,
              folio: contratanteJson.folio,
              recotizacion: true,
              cotizacionGeneral: this.currentItem.cotizacionGeneral,
              fecha_cotizacion: moment(this.currentItem.fecha).subtract('6', 'hours')
            };
            contratanteJson.asegurados.map((e) => {
              request.asegurados.push({
                id: e.id,
                nombre: e.nombre,
                fechanacimiento: e.fechanacimiento,
                parentesco: e.parentesco,
                edit: e.edit,
                genero: e.genero,
                embarazo: e.embarazo,
                cp: e.cp,
                folio: contratanteJson.folio,
              });
            });
            sessionStorage.setItem("medicoRequest", JSON.stringify(request));
            this.$router.push({
              name: "cotiza-medico",
            });

            break;
        }
      } else this.currentItem = {};
    },
    getFormatDate(date) {
      return moment(date)
        .subtract(6, "hours")
        .format("DD/MM/YYYY hh:mm:ss");
    },
    formatPrice(price) {
      var priceformat = 0;
      if (price) priceformat = price;
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(priceformat);
    },
    getAseguradoraName(id) {
      switch (id) {
        case 1:
          return "Afirme";
        case 2:
          return "ANA Seguros";
        case 3:
          return "GNP";
        case 4:
          return "Primero Seguros";
        case 5:
          return "Quálitas";
        case 6:
          return "Multiva";
        case 7:
          return "SURA RSA Seguros";
        case 8:
          return "AIG Seguros México";
        case 9:
          return "HDI Seguros";
        case 10:
          return "Zurich";
        case 11:
          return "AXA";
        case 12:
          return "BANORTE";
        case 13:
          return "BANORTE";
        case 14:
          return "CHUBB";
        case 15:
          return "GMX";
        case 16:
          return "ALLIANZ";
        case 17:
          return "ARGOS";
        case 18:
          return "ASERTA";
        case 19:
          return "ARGOS";
        case 20:
          return "BX+";
        case 21:
          return "PLAN SEGURO";
        case 22:
          return "PREVEM";
        case 23:
          return "SISNOVA";
        case 24:
          return "BUPA";
        case 25:
          return "METLIFE";
        case 26:
          return "INSIGNIA";
        case 27:
          return "MAPFRE";
        case 28:
          return "EL POTOSI";
        case 29:
          return "Quálitas";
        case 30:
          return "Gnp";
        case 31:
          return "AIG Seguros México";
        case 32:
          return "Inter.mx";
        default:
          return "Inter.mx";
      }
    },
    getAseguradoraImagen(id) {
      switch (id) {
        case 1:
          return "Afirme";
        case 2:
          return "ANA Seguros";
        case 3:
          return "gnp";
        case 4:
          return "primeroseguros";
        case 5:
          return "qualitas";
        case 6:
          return "Multiva";
        case 7:
          return "SURA RSA Seguros";
        case 8:
          return "aig";
        case 9:
          return "hdi";
        case 10:
          return "zurich";
        case 11:
          return "axa";
        case 12:
          return "BANORTE";
        case 13:
          return "BANORTE";
        case 14:
          return "aba";
        case 15:
          return "GMX";
        case 16:
          return "ALLIANZ";
        case 17:
          return "ARGOS";
        case 18:
          return "ASERTA";
        case 19:
          return "ARGOS";
        case 20:
          return "BX+";
        case 21:
          return "PLAN SEGURO";
        case 22:
          return "PREVEM";
        case 23:
          return "SISNOVA";
        case 24:
          return "BUPA";
        case 25:
          return "METLIFE";
        case 26:
          return "INSIGNIA";
        case 27:
          return "mapfre";
        case 28:
          return "EL POTOSI";
        case 29:
          return "Quálitas";
        case 30:
          return "Gnp";
        case 31:
          return "AIG Seguros México";
        case 32:
          return "Inter.mx";
        default:
          return "Inter.mx";
      }
    },
    getToken(item) {
      this.currentItem = item;
      const contractorData = {
        emission: {
          contractorData: {
            phone: item.telefono,
            email: item.correo,
          }
        }
      }
      sessionStorage.setItem("contractorData", JSON.stringify(contractorData))
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      return mainAxios.get("/v1/cotizacion-ws/auto/init", config).then(
        ({
          data: {
            data: { accessToken, trackingId },
          },
        }) => {
          this.accessToken = accessToken;
          this.trackingId = trackingId;
          this.dialog = true;
        }
      );
    },
    construirCotizaciones(carPrice) {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post(
          `/v1/cotizacion/filtros`,
          { cotizacion_general: this.currentItem.cotizacionGeneral },
          requestConfig
        )
        .then(({ data }) => {
          this.filteredAseguradoras = [];
          var prices = [];
          data.map((e) => {
            var responseJson = JSON.parse(e.response);
            var aseguradora = {
              id: e.id,
              logo: this.images[this.getAseguradoraImagen(e.aseguradoraId)],
              price: e.total,
              benefits: [
                "Por si ocasionas daños con tu vehículo, tienes $3,000,000",
                "Por si visitas al médico por un accidente",
                "Por si necesitas ayuda con tu vehículo o apoyo legal",
              ],
              coverages: responseJson.coveragesArray,
              insurer: this.getAseguradoraImagen(e.aseguradoraId),
              plan: carPrice.plan,
              tipoPago: carPrice.tipoPago,
              cotizacion_general: this.currentItem.cotizacionGeneral,
              agenteId: this.currentItem.agenteId,
              fecha: moment(this.currentItem.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY hh:mm:ss"),
            };
            this.filteredAseguradoras.push(aseguradora);
            prices.push({
              text: this.formatPrice(e.total),
              value: e.total,
            });
          });
          prices.push({ text: "Todos", value: null });
          carPrice.aseguradoras = this.filteredAseguradoras;
          carPrice.prices = prices;
          this.$router.push({
            name: "emitir-vehiculo",
            params: { carPrice: carPrice },
          });
        });
    },
    responseMascota(response) {
      this.plans = response;
      var aseguradoras = [];
      aseguradoras = this.plans.map((plan) => ({
        price: plan.PlanPrimaTotal,
        benefits: plan.PlanCoberturas.map((cobertura) => cobertura.Descripcion),
        logo:
          "https://todoseguros.com.mx/wp-content/uploads/2021/06/Seguros-GMX.jpg",
      }));
      return aseguradoras;
    },
    getNoGroupedCotizaciones(cotizacion_general, plan) {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      mainAxios
        .post(
          `/v1/cotizacion/filtros`,
          {
            cotizacion_general: cotizacion_general,
            grouped: false,
          },
          requestConfig
        )
        .then(({ data }) => {
          data.map((cotizacion) => {
            this.respCotizacionMedico.push({
              ...JSON.parse(cotizacion.response),
              aseguradora_id: JSON.parse(cotizacion.request).aseguradoraId,
            });
          });
          return this.getAseguradoras(plan);
        });
    },
    getAseguradoras(plan) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      mainAxios
        .get("/v1/seguro-medico/paquetes/" + plan, config)
        .then((resp) => {
          this.aseguradoras = resp.data.data.paquetes;
          this.respCotizacionMedico.map((e) =>{
            let aseguradora = this.aseguradoras.find((a)=>(a.id == e.aseguradora_id))
            let nombreId = aseguradora.nombreAseguradora
            .toLowerCase()
            .replace(/\s+/g, "");
            let indexAseguradora = this.primas.findIndex((e) =>(e.nameId === nombreId))
            this.primas[indexAseguradora].total = e.total 
          })
          sessionStorage.setItem("primasMedico",JSON.stringify(this.primas));
        });
    },
    async initLoginInter() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      const response = await mainAxios.get("/v1/seguro-medico/init", config);
      sessionStorage.interToken = response.data.data.accessToken;
    },
    getPrimaNetaMascota(item){
      var request = JSON.parse(item.response)
      return this.formatPrice(request[0].PlanPrima);
    },
    getPrimaTotalMascota(item){
      var request = JSON.parse(item.response)
      return this.formatPrice(request[0].PlanPrimaTotal);
    },
  },
  mounted() {
    if (!sessionStorage.interToken) {
      this.initLoginInter();
    }
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
</style>
