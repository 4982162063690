<template>
  <v-dialog
    v-model="OpenDialogShowDocs"
    persistent
    max-width="700"
    :scrollable="true"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="2">
                <v-img :src="docsImg" max-width="25" he alt=""> </v-img>
              </v-col>
              <v-col cols="10">
                <span>
                  Documentos
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="closeModal"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="height: 400px;">
        <v-container>
          <v-row>
            <v-col cols="10">
              <v-text-field
                class="share-bar-clase"
                label="Buscar"
                outlined
                dense
                prepend-inner-icon="mdi-magnify"
                :light="true"
                v-model="filtro"
              ></v-text-field>
            </v-col>
            <v-col
              cols="2"
              style="text-align: end; cursor: pointer;"
              @click="openCreateModal"
            >
              <v-icon left dense color="#0070C0">
                mdi-plus-circle-outline
              </v-icon>
            </v-col>
          </v-row>
          <div
            class="custom-div-docs"
            v-for="(docs, idx) in showNotes"
            :key="idx"
          >
            <v-row>
              <v-col cols="1">
                <v-icon left color="#0070C0">
                  mdi-file-document
                </v-icon>
              </v-col>
              <v-col cols="5">
                {{ docs.nombre }}
              </v-col>
              <v-col cols="2">
                {{ docs.onlyDate }}
              </v-col>
              <v-col cols="4" style="text-align: end;" v-if="docs.url">
                <v-btn icon :href="docs.url">
                  <v-icon color="#C1C7CD">
                    mdi-arrow-down-bold
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" style="text-align: end;" v-if="docs.url_bucket">
                <v-btn icon :href="docs.url_bucket">
                  <v-icon color="#C1C7CD">
                    mdi-arrow-down-bold
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions style="justify-content: center;">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <v-pagination
            v-model="page"
            :length="pages"
            circle
            color="#0070C0"
          ></v-pagination>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import imagenDocs from "@/assets/docs.svg";

export default {
  components: {},
  props: {
    OpenDialogShowDocs: {
      type: Boolean,
      default: false,
    },
    docs: Array,
  },

  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      run: false,
      formData: null,
      page: 1,
      limit: 5,
      filtro: null,
      docsImg: imagenDocs
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    openCreateModal() {
      this.$emit("openCreateModal");
    },
  },
  computed: {
    pages() {
      const totalPages = Math.ceil(this.docsFiltradas.length / this.limit);
      return totalPages < 1 ? 1 : totalPages;
    },
    docsFiltradas() {
      if (!this.filtro) {
        return this.docs;
      }
      const filtroMin = this.filtro.toLowerCase().trim();
      return this.docs.filter((documento) => {
        return documento.nombre.toLowerCase().includes(filtroMin);
      });
    },
    indiceInicio() {
      return (this.page - 1) * this.limit;
    },
    indiceFinal() {
      return this.page * this.limit - 1;
    },
    showNotes() {
      return this.docsFiltradas.slice(this.indiceInicio, this.indiceFinal + 1);
    },
  },
};
</script>

<style scoped>
.custom-list {
  background-color: #0058fc;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0px 5px 0px;
}

.v-expansion-panel {
  background-color: rgb(165, 55, 238);
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 5px !important;
  background-color: aliceblue;
}
.v-expansion-panel-header {
  background-color: aliceblue;
  border-radius: 5px;
}

.custom-div-docs {
  padding: 15px;
  border: 2px solid #00a7e4;
  border-radius: 23px;
  margin-block: 10px;
}
</style>
