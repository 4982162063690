<template>
  <v-row>
    <common-card class="mx-3" style="width: 100%!important">
      <v-card-title>
        <h3>Selecciona tu aseguradora</h3>
      </v-card-title>
      <v-card-text>
        <v-row
          ><v-col cols="12" class="text-start">
            <span class="mt-negative"
              >Conoce las aseguradoras con las que puedes cotizar</span
            >
          </v-col></v-row
        >
        <v-col cols="12 d-flex justify-end">
          <v-switch
            class="mx-2"
            label="Seleccionar todas"
            v-model="allSelected"
            color="#00A7E4"
            @click="toggleAll"
          ></v-switch>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-row class="d-flex justify-center my-4">
            <v-col cols="12">
              <v-row class="d-flex justify-center">
                <v-col
                  v-for="(element, index) in insurers"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="2"
                  class="shadow-card my-4 mr-3"
                  style="max-width: 17rem;"
                >
                  <common-card-aseguradora>
                    <template v-slot:image>
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                          lg="3"
                          class="text-center d-flex justify-center align-center"
                        >
                          <v-checkbox
                            class="mx-2"
                            :value="element"
                            v-model="selected"
                            color="#00A7E4"
                            @change="changeInsurer"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="10"
                          lg="9"
                          class="d-flex justify-center"
                        >
                          <v-img
                            contain
                            :src="images[element]"
                            width="100"
                            height="80"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </template>
                  </common-card-aseguradora>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
    </common-card>
  </v-row>
</template>

<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import CommonCardAseguradora from "../../../../components/commonComponents/CommonCardAseguradora.vue";
import imageAseguradoras from "../../../../helpers/data/data-image-aseguradoras.json";
export default {
  name: "ListaAseguradoras",
  components: { CommonCard, CommonCardAseguradora },
  props: { insurers: Array },
  data() {
    return {
      images: imageAseguradoras,
      localInsurers: [],
      selected: [],
      allSelected: true,
    };
  },
  methods: {
    changeInsurer() {
      this.$emit("setInsurerSelected", this.selected);
      this.allSelected = this.selected.length === this.insurers.length;
    },
    toggleAll() {
      if (this.allSelected) {
        this.selected = [...this.insurers];
      } else {
        this.selected = [];
      }
      this.changeInsurer();
    },
  },
  watch: {
    insurers: {
      immediate: true,
      handler(newVal) {
        this.localInsurers = [...newVal];
        this.toggleAll();
      },
    },
  },
};
</script>
<style scoped>
.shadow-card {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.mt-negative {
  margin-top: -10px;
}
</style>
