<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Buscar
    </template>
    <v-expansion-panel-content>
      <filters-solicitudes
        @searchUpdated="getSolicitudes"
        :currentFilters="filtros"
        :loading="loading"
      ></filters-solicitudes>
      <hr />
      <table-solicitudes
        :isModal="isModal"
        :loading="loading"
        :solicitudes="solicitudes"
        @seleccionSolicitud="seleccionSolicitud"
      ></table-solicitudes>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import FiltersSolicitudes from "./FiltersSolicitudes.vue";
import TableSolicitudes from "./TableSolicitudes.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
    FiltersSolicitudes,
    TableSolicitudes,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
    isModal:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    getSolicitudes(body) {
      // if (String(window.location.pathname).includes("/alianza/perfil/")) {
      //   body.alianza_id = (this.$route.params.alianza_id)
      // }
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      if (!body.status && body.status !== 0) body.status = 8;
      this.loading = true;
      mainAxios
        .post(
          `/v1/solicitud/list/${localStorage.agenteUuid}`,
          body,
          requestConfig
        )
        .then(({ data }) => {
          this.loading = false;
          if (data.length) this.solicitudes = data;
          else this.solicitudes = [];
          this.filtros = body;
        });
    },

    getTodasLasSolicitudes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/solicitud/info/status/1`, requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data;
        });
    },
    seleccionSolicitud(val){
      this.$emit('seleccionSolicitud', val)
    }
  },
  mounted() {
    if (String(window.location.pathname).includes("/alianza/perfil/")) {
      this.getSolicitudes({});
    }
  },
};
</script>
