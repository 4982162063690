<template>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Tipo de Inmueble
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.tipoInmueble ? ramoData.tipoInmueble : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Especificar Tipo de inmueble
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.otroTipoInmueble ? ramoData.otroTipoInmueble : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Valor del inmueble
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.valorInmueble ? ramoData.valorInmueble : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Nivel del departamento
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.nivelDepartamento ? ramoData.nivelDepartamento : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Pisos Totales
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.pisosTotales ? ramoData.pisosTotales : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Número de pisos altos
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.numeroPisosAltos ? ramoData.numeroPisosAltos : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Sotanos
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sotanos ? ramoData.sotanos : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Valor de los contenidos
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.valorContenidos ? ramoData.valorContenidos : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Valor del equipo electrónico
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.valorEquipoElectronico ? ramoData.valorEquipoElectronico : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Suma asegurada Responsabilidad Civil
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sumaAseguradaRC ? ramoData.sumaAseguradaRC : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Suma asegurada Cristales
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sumaAseguradaCristales ? ramoData.sumaAseguradaCristales : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Suma asegurada robo con violencia
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sumaAseguradaRoboViolencia ? ramoData.sumaAseguradaRoboViolencia : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Suma asegurada dinero y valores
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sumaAseguradaDineroValores ? ramoData.sumaAseguradaDineroValores : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Suma asegurada obras de arte o piezas de difícil reposición
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sumaAseguradaObrasDeArte ? ramoData.sumaAseguradaObrasDeArte : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },

  methods: {},
};
</script>
