<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn rounded dark class="common-botton" @click="changeComponent">
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Estatus
        </span>
        <br />
        <v-btn rounded dark style="background-color: #f1c21b;" :elevation="0">
          <span>
            {{ getStatus(polizaInfo.status) }}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No. Póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.poliza ? polizaInfo.poliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Poliza ID Interna
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.id ? polizaInfo.id : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No. Cotización / Lead ID
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.lead_id ? polizaInfo.lead_id : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Tipo de Póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.tipo_poliza ? polizaInfo.tipo_poliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Agente
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.agente ? getAgenteName(polizaInfo.agente) : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Ramo
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.categoriaSelected }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Producto
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.producto_id
              ? getProductoName(polizaInfo.producto_id)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Campaña
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.campania_id
              ? getCampañaName(polizaInfo.campania_id)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Inicio de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.inicio ? getFormattDate(polizaInfo.inicio) : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fin de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.fin ? getFormattDate(polizaInfo.fin) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="polizaInfo.cliente">
        <span class="text-header-data ">
          Cliente
        </span>
        <br />
        <a class="text-header-data" @click="getCliente">Ir al cliente </a>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="polizaInfo.solicitudId">
        <span class="text-header-data ">
          Solicitud
        </span>
        <br />
        <a class="text-header-data" @click="getSolicitud">Ir a la solicitud </a>
      </v-col>
      <v-col v-if="showPolizaButton" cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Poliza
        </span>
        <br />
        <v-btn
          rounded
          dark
          @click="getPolizaPDF"
          style="background-color: #f1c21b;"
          :elevation="0"
        >
          <span>
            Descargar poliza
          </span>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-expansion-panels elevation="0">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="text-header-data">
                Notas de la Póliza
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <span class="text-data-details">
                  {{ polizaInfo.notas ? polizaInfo.notas : "Sin notas" }}
                </span>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import moment from "moment";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {},
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      agentes: [],
      campanias: [],
      productos: [],
    };
  },

  computed: {
    showPolizaButton() {
      return (
        !!this.polizaInfo.cotizacionGeneral &&
        this.polizaInfo.categoriaSelected == "AUTOS" &&
        this.polizaInfo.status > 0
      );
    },
  },

  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
    getStatus(val) {
      switch (val) {
        case 0:
          return "Nueva";
        case 1:
          return "Emitida Pendente de Pago";
        case 2:
          return "Pagada Parcial";
        case 3:
          return "Pagada Total";
        case 4:
          return "Renovada";
        case 5:
          return "Finalizada";
        case 8:
          return "Duplicada";
        default:
          return "Cancelada";
      }
    },
    getProductoName(productId) {
      var producto = this.productos.find((e) => e.id == productId);
      console.log("productId", productId);
      return producto ? producto.nombre : "No disponible";
    },
    getRamoName(productId) {
      var producto = this.productos.find((e) => e.id == productId);
      return producto ? producto.ramo : "No disponible";
    },
    getPeridicidad(val) {
      switch (val) {
        case 1:
          return "Anual";
        case 2:
          return "Semestral";
        case 3:
          return "Trimestral";
        default:
          return "Mensual";
      }
    },
    getFormattDate(date) {
      var fecha = moment(date, "YYYY-MM-DD");

      if (fecha.isValid()) {
        return fecha.format("DD/MM/YYYY");
      } else {
        fecha = new Date(date);

        // Verificar si la conversión a Date es válida
        if (!isNaN(fecha.getTime())) {
          // Usar moment.js para formatear la fecha
          return moment(fecha).format("DD/MM/YYYY");
        } else {
          // Si la conversión a Date también falla, devolver un mensaje de error
          return "Fecha no válida";
        }
      }
    },
    changeComponent() {
      this.$emit("changeTab", 2);
    },
    getAgenteName(agente_id) {
      var agente = this.agentes.find((e) => e.id == agente_id);
      return agente ? agente.nombre : "No disponible";
    },
    getCampañaName(campania_id) {
      var campania = this.campanias.find((e) => e.id == campania_id);
      return campania ? campania.producto : "No disponible";
    },
    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
            ramosList: element.ramosList ? element.ramosList : [],
          });
        });
      });
    },
    getCampanias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.campanias = response.data;
        });
    },
    getproductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.productos.push({
            nombre:
              `${element.ramo}` +
              " / " +
              `${element.nombre}` +
              " / " +
              `${element.nombreAseguradora}`,
            id: element.id,
            canal: element.canal ? element.canal : "Todos",
            ramo: element.ramo ? element.ramo : "No disponible",
            aseguradoraId: element.aseguradoraId
              ? element.aseguradoraId
              : "No disponible",
            tecnologia: element.tecnologia ? element.tecnologia : false,
          });
        });
      });
    },
    getCliente() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/cliente/id/${this.polizaInfo.cliente}`, config)
        .then((response) => {
          this.$router.push("/detalles-cliente/" + response.data.uuid);
        });
    },

    getSolicitud() {
      this.$router.push("/detalles-solicitud/" + this.polizaInfo.solicitudId);
    },
    getPolizaPDF() {
      this.infoModal();
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/emision/" + this.polizaId + "/pdf/url", config)
        .then((response) => {
          if (response.data.status) {
            window.open(response.data.data.url, "_blank");
          } else this.errorModal("No pudimos encontrar el PDF de tu póliza");
        });
    },
    errorModal(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    infoModal() {
      this.$toast.info("Construyendo PDF de emisión esperé un momento...", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getAgentes(), this.getCampanias();
    this.getproductos();
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
