<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <h5 class="font-weight-light display-1 main-title">Contacto</h5>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="4">
              <v-img
                src="../assets/logo_segurify.png"
                max-height="300"
                max-width="300"
                style="padding: 0px"
              ></v-img>
            </v-col>

            <v-col cols="12" sm="4">
              <v-row class="contact-container">
                <v-icon
                  size="50px"
                  color="primary"
                >
                  mdi-office-building-marker
                </v-icon>
                <div class="info-contact">
                  <h3 class="contact-title">Ubicación:</h3>
                  <p class="font-weight-light">Av Miguel Ángel de Quevedo 722, La Concepción, Coyoacán, 04020 Ciudad de México, CDMX</p>
                </div>
              </v-row>

              <v-row class="contact-container">
                <v-icon
                  size="50px"
                  color="primary"
                >
                  mdi-phone
                </v-icon>
                <div class="info-contact">
                  <h3 class="contact-title">Teléfono:</h3>
                  <p class="font-weight-light">+52 5588770545</p>
                </div>
              </v-row>

              <v-row class="contact-container">
                <v-icon
                  size="50px"
                  color="primary"
                >
                  mdi-email
                </v-icon>
                <div class="info-contact">
                  <h3 class="contact-title">Email:</h3>
                  <p class="font-weight-light">operaciones@interify.com</p>
                </div>
              </v-row>
            </v-col>

            <v-col cols="12" sm="4" class="mt-5">
              <iframe src="https://maps.google.com/maps?q=Av%20Miguel%20%C3%81ngel%20de%20Quevedo%20722,%20La%20Concepci%C3%B3n,%20Coyoac%C3%A1n,%2004020%20Ciudad%20de%20M%C3%A9xico,%20CDMX&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg"/>
    </div>
    <v-snackbar
        v-model="snackbar.enabled"
        timeout="3000"
        right
        top
        :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar.enabled = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.main-title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

.contact-container {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.info-contact {
  display: inline;
}

.contact-title {
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
  margin-top: 10px;
}

</style>

<script>
// import {db} from '@/main'

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "O campo nome é obrigatório",
      (v) => (v && v.length >= 6) || "O nome precisa ter mais de 6 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "O campo email é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "O E-mail precisa ser válido",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "O campo de texto é obrigatório",
      (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    submit() {
      /*db.collection("contactData").add({
        name: this.name,
        email: this.email,
        message: this.textArea
      }).then(() => {
        this.snackbar.text = "Mensagem enviada com sucesso"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Erro ao enviar mensagem"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })*/
    }
  }
};
</script>
