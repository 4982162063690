<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos de Generales
      </template>
      <v-expansion-panel-content v-if="typeof requestJson.esInbound == 'undefined'">
        <div v-if="datosSolicitud.categoria == 'AUTOS'">
          <div
            v-if="
              datosSolicitud.tipo == 'Cotización' ||
                datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <ViewAutos
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditAutos
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
          <div v-else>
            <ViewNumPoliza
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditNumPoliza
              v-else
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
        </div>
        <div v-if="datosSolicitud.categoria == 'AHORRO'">
          <div
            v-if="
              datosSolicitud.tipo == 'Cotización' ||
                datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <ViewAhorro
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditAhorro
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
          <div v-else>
            <ViewNumPoliza
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditNumPoliza
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
        </div>
        <div v-if="datosSolicitud.categoria == 'DAÑOS'">
          <div
            v-if="
              datosSolicitud.tipo == 'Cotización' ||
                datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <ViewDanios
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditDaños
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
          <div v-else>
            <ViewNumPoliza
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditNumPoliza
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
        </div>
        <div v-if="datosSolicitud.categoria == 'FIANZA'">
          <div
            v-if="
              datosSolicitud.tipo == 'Cotización' ||
                datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <ViewFianza
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditFianza
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
          <div v-else>
            <ViewNumPoliza
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditNumPoliza
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
        </div>
        <div v-if="datosSolicitud.categoria == 'SALUD'">
          <div
            v-if="
              datosSolicitud.tipo == 'Cotización' ||
                datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <ViewSalud
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditSalud
              v-else
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
          <div v-else>
            <ViewNumPoliza
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditNumPoliza
              v-else
              :datosSolicitud="datosSolicitud"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
        </div>
        <div v-if="datosSolicitud.categoria == 'VIDA'">
          <div
            v-if="
              datosSolicitud.tipo == 'Cotización' ||
                datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <ViewVida
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditVida
              v-else
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
          <div v-else>
            <ViewNumPoliza
              v-if="switch_data == 1"
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 2"
            />
            <EditNumPoliza
              v-else
              :datosSolicitud="datosSolicitud"
              :canEdit="canEdit"
              @changeComponent="switch_data = 1"
              @getData="$emit('getData')"
            />
          </div>
        </div>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        <div>
          <ViewInbound :solicitudData='datosSolicitud' />
        </div>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewAutos from "./RamoForm/RamosView/ViewAutos.vue";
import ViewAhorro from "./RamoForm/RamosView/ViewAhorro.vue";
import ViewDanios from "./RamoForm/RamosView/ViewDanios.vue";
import ViewFianza from "./RamoForm/RamosView/ViewFianza.vue";
import ViewSalud from "./RamoForm/RamosView/ViewSalud.vue";
import ViewVida from "./RamoForm/RamosView/ViewVida.vue";
import ViewInbound from "./Inbound/ViewInbound.vue"

import EditAhorro from "./RamoForm/RamosEdit/EditAhorro.vue";
import EditAutos from "./RamoForm/RamosEdit/EditAutos.vue";
import EditDaños from "./RamoForm/RamosEdit/EditDaños.vue";
import EditFianza from "./RamoForm/RamosEdit/EditFianza.vue";
import EditSalud from "./RamoForm/RamosEdit/EditSalud.vue";
import EditVida from "./RamoForm/RamosEdit/EditVida.vue";

import EditNumPoliza from "./RamoForm/EditNumPoliza.vue";
import ViewNumPoliza from "./RamoForm/ViewNumPoliza.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewAutos,
    EditAutos,
    EditNumPoliza,
    ViewNumPoliza,
    ViewAhorro,
    EditAhorro,
    ViewDanios,
    EditDaños,
    ViewFianza,
    EditFianza,
    EditSalud,
    ViewSalud,
    ViewVida,
    EditVida,
    ViewInbound
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  props: {
    datosSolicitud: Object,
  },
  methods: {
    reaload() {
      this.switch_data = 1;
      location.reload();
    },
  },
  computed:{
    canEdit(){
      switch(this.rol){
        case 'AGENT':
          if(this.datosSolicitud.status == 'Pendiente de Aprobacion')
            return true
          else
            return false
        default:
          return true
      }
    },
    requestJson(){
      return JSON.parse(this.datosSolicitud.body)
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
