<template>
  <v-card style="box-shadow: none !important; position: sticky; top: 0; z-index:100">
    <v-navigation-drawer
      v-model="drawer"
      app
      :color="'#FFF'"
      :dark="false"
      :mini-variant="mini"
      :mini-variant-width="80"
    >
    <v-list-item class="mt-4">
        <v-img
          class="mx-5 responsive-img mb-2"
          contain
          :max-width="mini ? '40':'150'"
          :src="mini ? InterifyXS : globalConfig.logoUrl"
          @click="!mini ? irInicio() :  mini= !mini"
          style="cursor:pointer"
        ></v-img>

        <v-btn class="ml-auto" icon @click="mini = !mini">
          <v-icon color="#ABB1B3">{{
            !mini ? "mdi-chevron-double-left" : "mdi-chevron-double-right"
          }}</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>

      <div>
        <v-list dense v-for="(list, idx) in listaDesplegable" :key="idx">
          <v-list-group
            v-for="item in list.items"
            :key="item.title"
            v-model="item.active"
            no-action
            style="padding-top: 5%;"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <div class="d-flex align-center">
                  <v-icon size="26" color='#0070C0' @click.stop="mini = !mini">
                    {{ item.action }}
                  </v-icon>
                  <v-list-item-title
                    class="ml-3"
                    :class="{ 'text-center': mini }"
                    v-if="!mini"
                    v-text="item.title"
                  ></v-list-item-title>
                </div>
              </v-list-item-content>
            </template>

            <template v-for="child in item.items">
              <template v-if="emailCoru != 'leadscoruaig@coru.com'">
                <v-list-item
                  :key="child.title"
                  v-on:click="$router.push(child.route)"
                >
                  <template
                    v-if="child.route != '/administrador/reporte-coru-aig'"
                  >
                    <v-list-item-content
                      v-if="
                        emailCoru != 'leadscoruaig@coru.com' ||
                          child.route != '/administrador/reporte-coru-aig'
                      "
                    >
                      <v-list-item-title
                        v-text="child.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-else-if="rol == 'ADMIN' || rol == 'ADMINCC'">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="child.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
              <template
                v-else-if="child.route == '/administrador/reporte-coru-aig'"
              >
                <v-list-item
                  :key="child.title"
                  v-on:click="$router.push(child.route)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list-group>
        </v-list>
      </div>

      <v-divider></v-divider>
      <v-list dense style="cursor: pointer">
        <v-list-item>
          <v-list-item-icon>
            <v-icon >mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-on:click="saveLoginLog">Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mainAxios } from "../mainAxios";
const setListaDesplegable = require("../helpers/diccionarios/listaDesplegable.js");
import mainData from "../helpers/data/data-main-template.json";
import loginData from "../helpers/data/data-login-template.json";
import LogoInter from "@/assets/interify.svg";
import interifyXS from "@/assets/interify_xs.svg";
import { postLog, getAreaNegocio, getRoles } from "@/services/log/log.service.js";

export default {
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      emailCoru: localStorage.getItem("agenteMail"),
      name: localStorage.agenteName,
      listaDesplegable: null,
      models: {
        overlay: false,
      },
      selected: [0, 1, 2, 3, 4],
      notificaciones: [],
      dialogNotificacion: false,
      detalleNotificacion: {},
      mainTemplate: mainData,
      loginTemplate: loginData,
      logoInter: LogoInter,
      drawer:true,
      mini: false,
      InterifyXS: interifyXS,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      areaNegocio: [],
      roles: [],

    };
  },
  props:{
    mobileMenu:{
      type: Boolean,
      default: false,
    }
  },  

  watch:{
    mobileMenu(){
      this.mini = false;
      this.drawer = ! this.drawer
    }
  },

  mounted() {
    this.listaDesplegable = setListaDesplegable(this.rol);
    this.getRolesList()
    this.getAreaNegocio()
  },

  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
    async getRolesList() {
      this.roles = await getRoles();
    },
    async getAreaNegocio() {
      this.areaNegocio = await getAreaNegocio();
    },
    async saveLoginLog(){
      const body = {
        agente_id: localStorage.id,
        actividad_id: 13,
        servicio_embebido: "",
        area_negocio_id: this.getActualAreaNegocio(),
      }
      await postLog(body);
      this.logout()
    },
    getActualAreaNegocio() {
      const currentRol = this.roles.find((e) => e.rol == this.rol);
      const currentAreaNegocio = this.areaNegocio.find(
        (e) => e.id == currentRol.area_negocio
      );
      return currentAreaNegocio ? currentAreaNegocio.id : "";
    },
    obtenerNotificaciones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/agente/notificaciones/${localStorage.agenteUuid}`, config)
        .then((response) => {
          for (let index = 0; index < 5; index++) {
            this.notificaciones.push({
              titulo: response.data[index].titulo,
              creadoPor: response.data[index].creadoPor,
              subtitulo: response.data[index].subtitulo,
              fecha: response.data[index].fecha,
              id: response.data[index].id,
              contenido: response.data[index].contenido,
            });
          }
        });
    },

    verNotificacion(notificacion) {
      this.dialogNotificacion = true;
      this.detalleNotificacion = notificacion;
    },

    verNotificacionesCompletas() {
      this.$router.push(`/notificaciones/${localStorage.agenteUuid}`);
    },

    onClickOutsideStandard() {
      this.models.overlay = false;
    },

    include() {
      return [document.querySelector(".included")];
    },
    irInicio(){
      if(this.rol == 'AGENTCC')
        this.$router.push("/leads");
      else if  (this.rol != 'PROSPECTO')
        this.$router.push("/inicio");
      else
        this.$router.push("/inicio-prospectos");
    }
  },
};
</script>
<style>
.v-carousel__controls {
  bottom: 12px;
}

.rolNota {
  font-size: 12px;
  color: rgb(192, 192, 192);
}

.v-list-item__title {
  white-space: normal !important;
}
.menu-image {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.icon-background {
    background-color: #E0E0E0;
    border-radius: 50%; 
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: inline-flex; 
    justify-content: center;
    align-items: center; 
}

</style>
