export const CurrentLista = (requestJson, ramo = '') => {
    console.log({requestJson})
    console.log({ramo})
    if(requestJson.isTicketLlamada){
        return [{
            label:'Medio de contacto',
            value:'tipo_llamada'
          },
          {
            label:'Producto',
            value:'producto'
          },
          {
            label:'Tipificación',
            value:'tipificacion'
          },
          {
            label:'Empresa',
            value:'empresa'
          },
          {
            label:'Aseguradora',
            value:'aseguradora'
          },
          {
            label:'Motivo',
            value:'motivo'
          },
          {
            label:'Póliza',
            value:'poliza'
          },
          {
            label:'¿Atendio en llamada?',
            value:'atendioLlamada'
          },
          {
            label:'¿Es queja?',
            value:'isQueja'
          },
          {
            label:'Detalle',
            value:'detalle'
          },
  
        ]
    }else{
        switch(ramo){
            case 'AUTOS':
                return [
                    {
                        label: 'Origen Auto' ,
                        value: 'origenAuto'
                    },
                    {
                        label: 'Marca' ,
                        value: 'marca'
                    },
                    {
                        label: 'Submarca' ,
                        value: 'submarca'
                    },
                    {
                        label: 'Modelo' ,
                        value: 'modelo'
                    },
                    {
                        label: 'Version' ,
                        value: 'version'
                    },
                    {
                        label: 'Cobertura' ,
                        value: 'coberturasSelected'
                    },
                    {
                        label: 'Uso del auto' ,
                        value: 'uso_auto'
                    },
                    {
                        label: 'Fecha inicio' ,
                        value: 'inicio'
                    },
                    {
                        label: 'Fecha fin' ,
                        value: 'fin'
                    },
                ]

            default : 
                return []
        }
    }
} 
