<template>
  <div>
    <common-card>
      <!-- <div style="height: 100%; padding-top: 3%; padding-right: 13px; padding-left: 13px;">
              <v-row>
                <v-col cols="2" >
                </v-col>
                <v-col cols="12" md="10" class="barra-superior" style="text-align: center;">
                    <span class="white-text">¡Te ofrecemos la mejores relaciones estratégicas que impulsarán tu éxito! </span> 
                </v-col>
              </v-row>
          </div> -->
      <div style="height: 90%; padding: 4%; background-color: #093e4c;">
        <!-- <v-row>
                    <v-col cols="12" md="6">
                      <img :src="Inico_prospectos" style="height: 80%; width: 80%;">
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="header-prospecto">
                        <div>
                          En <span style="color: #0070C0;"> Interify </span> estamos encantados de presentarte la oportunidad de colaboración.
                        </div>
                      </span>
                      <div class="pt-3">
                        <span class="planes-text">
                          Conoce lo que tenemos para ti:
                        </span>                        
                      </div>
                      <div class="pt-8">
                        <span class="plan-carrera-text">
                          PLAN DE CARRERA
                        </span>                        
                      </div>
                      <div class="text-list">
                        <ul class="pt-8">
                          <li class="pt-3">
                            Objetivo del Asesor Digital 
                          </li>
                          <li class="pt-3">
                            Desarrollo de habilidades soft 
                          </li>
                          <li class="pt-3">
                            Formación continua 
                          </li>
                          <li class="pt-3">
                            Desarrollo de oportunidades 
                          </li>
                          <li class="pt-3">
                            Networking y Relaciones Profesionales
                          </li>
                          <li class="pt-3">
                            Desarrollo de Marca Personal 
                          </li>
                          <li class="pt-3">
                            Acompañamiento, mentoria y coaching 
                          </li>
                          <li class="pt-3">
                            Plataformas digitales 
                          </li>
                        </ul>
                      </div>
                      <div class="pt-8">
                        <span class="plan-carrera-text">
                          PROGRAMA DE CAPACITACIÓN
                        </span>                        
                      </div>
                      <div class="pt-2" style="cursor: pointer;">
                        <span class="planes-text">
                          Descargar plan
                        </span>                        
                      </div>
                    </v-col>
                  </v-row> -->
        <img
          :src="Inico_prospectos"
          style="height: 100%; width: 100%; cursor: pointer;"
          @click="downloadPlan"
        />
      </div>
    </common-card>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import workingOn from "@/assets/negocioAhora.svg";
import Inico_prospectos from "@/assets/inico_prospectos.png";
import programaCapacitacionImage from "@/assets/programa-capacitacion.png";

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      workingOn: workingOn,
      Inico_prospectos: Inico_prospectos,
    };
  },

  methods: {
    downloadPlan() {
      const link = document.createElement("a");
      link.href = programaCapacitacionImage;
      link.setAttribute("download", "programa.png");
      link.click();
    },
  },
  mounted() {
    window.dataLayer.push({
      event: 'ITFY_inicio_prospectos_v',
      no_cotizacion: undefined,
      userID: undefined,
      correo: undefined,
      telefono: undefined,
    });
  }
};
</script>

<style>
.header-prospecto {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planes-text {
  /* Body/L */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */
  display: flex;
  align-items: center;
  color: #4d5358;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.plan-carrera-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #81cfe6;
  order: 0;
  flex-grow: 0;
}

.text-list {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  /* or 20px */
  display: flex;
  align-items: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.barra-superior {
  height: 67px;
  background: #0070c0;
  border-radius: 10px 0px 0px 10px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  z-index: 0;
}

.white-text {
  /* Heading/5 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #f7f8fa;
  flex: none;
  order: 0;
  flex-grow: 1;
}
</style>
