<template>
  <div>
    <ClientePolizaInfo 
      @getData="$emit('getData')" 
      :polizaInfo="polizaInfo" 
    />
    <ClienteContactoInfoIndex
      @getData="$emit('getData')"
      :polizaInfo="polizaInfo"
    />
    <ClienteDomicilioInfoIndex
      @getData="$emit('getData')"
      :polizaInfo="polizaInfo"
    />
  </div>
</template>

<script>
import ClienteContactoInfoIndex from "../ClienteData/ClienteSolcitud/ClienteContactoInfo/ClienteContactoInfoIndex.vue";
import ClientePolizaInfo from "../ClienteData/ClienteSolcitud/ClientePolizaInfo/ClientePolizaInfo.vue";
import ClienteDomicilioInfoIndex from "../ClienteData/ClienteSolcitud/ClienteDomicilioInfo/ClienteDomicilioInfoIndex.vue";

export default {
  components: {
    ClienteContactoInfoIndex,
    ClientePolizaInfo,
    ClienteDomicilioInfoIndex,
  },

  data() {
    return {};
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
};
</script>
