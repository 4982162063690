<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Plan"
          v-model="polizaData.plan"
          :items="planList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Fumador"
          v-model="polizaData.fumador"
          :items="fumadorList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma Asegurada"
          v-model="polizaData.sumaAsegurada"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Muerte Accidental"
          v-model="polizaData.muerteAccidental"
          :items="muerteAccidentalList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Perdidas Orgánicas"
          v-model="polizaData.perdidasOrganicas"
          :items="perdidasOrganicasList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Invalidez total y permanente"
          v-model="polizaData.invalidezTotal"
          :items="invalidezTotalList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Gastos funerarios"
          v-model="polizaData.gastosFunerarios"
          :items="gastosFunerariosList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('goToSave', polizaData)"
        >
          <span>
            Guardar
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  data() {
    return {
      polizaData:{
        plan: null,
        fumador: null,
        sumaAsegurada: null,
        muerteAccidental: null,
        perdidasOrganicas: null,
        invalidezTotal: null,
        gastosFunerarios: null,
      },
      //listas
      planList: ["Temporal", "Ordinario", "Vida Pagos Limitados(VPL)"],
      fumadorList: ["Si", "No"],
      muerteAccidentalList: ["Si ampara", "No ampara"],
      perdidasOrganicasList: ["Si ampara", "No ampara"],
      invalidezTotalList: ["Si ampara", "No ampara"],
      gastosFunerariosList: ["Si ampara", "No ampara"],

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
    };
  },
  props: ["avisar"],
  methods: {
    avisarCambio() {
      let datos = {
        plan: this.plan,
        fumador: this.fumador,
        sumaAsegurada: this.sumaAsegurada,
        muerteAccidental: this.muerteAccidental,
        perdidasOrganicas: this.perdidasOrganicas,
        invalidezTotal: this.invalidezTotal,
        gastosFunerarios: this.gastosFunerarios,
      };
      this.avisar(datos);
    },
  },
};
</script>
