<template>
  <v-app>
    <v-container fluid>
      <v-row class="mb-15 fill-height">
        <v-col>
          <v-row>
            <v-col>
              <h1>Proximamente</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>
