<template>
  <v-expansion-panels elevation="0" class="mi-clase-con-sombra" 
    v-model="panel" 
    :readonly="readonly">
    <v-expansion-panel>
      <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            <slot name="title-panel">
            </slot>
          </h3>
        </v-expansion-panel-header>
      <slot>   
      </slot>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
props:{
  currentPanel:{
    type:Number,
    default:null
  },
  readonly:{
    type:Boolean,
    default:false
  }
},
components: {
},
data() {
  return {
    panel: this.currentPanel,
  };
},
watch: {
  currentPanel(v) {
    this.panel = v;
  }
},
methods: {
},
};
</script>

<style scoped>
.v-expansion-panel{
  border-radius: 20px;
}
.v-expansion-panels{
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active{
  border-radius: 20px !important;
}
.mi-clase-con-sombra {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;
  border-radius: 20px;
}
</style>