<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-autocomplete
              label="Tipo de Persona (Régimen)"
              outlined
              v-model="formData.tipo_persona"
              color="#00a7e4"
              type="text"
              :items="regimentList"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            lg="4"
            xl="4"
            v-if="formData.tipo_persona == 'Moral'"
          >
            <v-text-field
              label="Razón Social"
              outlined
              v-model="formData.razon_social"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.nombre"
              label="Nombre(s)"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.apellido_paterno"
              label="Apellido Paterno"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.apellido_materno"
              label="Apellido Materno"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.fecha_nacimiento"
              type="date"
              label="Fecha de Nacimiento"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.edad"
              type="text"
              label="Edad"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            m="12"
            md="6"
            lg="3"
            xl="3"
            v-if="formData.tipo_persona != null"
          >
            <v-text-field
              v-model="formData.rfc"
              label="RFC"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="rfcRules"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Género"
              outlined
              color="#00a7e4"
              v-model="formData.genero"
              :items="generoList"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Estado civil"
              outlined
              color="#00a7e4"
              v-model="formData.statusCivil"
              :items="['Soltero(a)', 'Casado(a)']"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab', 1)"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import moment from "moment";
import RfcFacil from "rfc-facil";

export default {
  components: {
    CommondForm,
  },

  data() {
    return {
      switch_data: 1,
      formData: {
        id: null,
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        fecha_nacimiento: null,
        edad: null,
        tipo_persona: null,
        genero: null,
        statusCivil: null,
        rfc: null,
      },
      run: false,
      regimentList: ["Física", "Moral"],
      generoList: ["Masculino", "Femenino"],
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
        (v) => !/(XXX)$/i.test(v) || "El RFC no debe terminar en 'XXX'",
      ],
    };
  },
  props: {
    polizaInfo: Object,
  },
  watch: {
    polizaInfo() {
      this.fillData();
    },
    "formData.fecha_nacimiento"(val) {
      var today = moment().format("YYYY-MM-DD");
      var birthday = moment(val, "YYYY-MM-DD");
      this.formData.edad = moment(today, "YYYY-MM-DD").diff(
        moment(birthday, "YYYY-MM-DD"),
        "years"
      );
      this.actualizarRfc();
    },
    "formData.nombre"() {
      this.actualizarRfc();
    },
    "formData.apellido_paterno"() {
      this.actualizarRfc();
    },
    "formData.apellido_materno"() {
      this.actualizarRfc();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.polizaInfo[e]) {
          this.formData[e] = this.polizaInfo[e];
        }
      });
      switch(this.formData.genero){
        case 'F':
          this.formData.genero = 'Femenino'
          break;
        case 'M':
          this.formData.genero = 'Masculino'
          break;
        default:
      }
    },
    actualizarRfc() {
      if (
        !this.formData.nombre ||
        !this.formData.apellido_paterno ||
        !this.formData.apellido_materno ||
        !this.formData.fecha_nacimiento
      )
        return;
      const arregloNacimiento = moment(this.formData.fecha_nacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.formData.nombre,
        firstLastName: this.formData.apellido_paterno,
        secondLastName: this.formData.apellido_materno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.formData.rfc = rfc
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
