<template>
  <div></div>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import {
  postLog,
  getActividades,
  getAreaNegocio,
  getRoles,
} from "@/services/log/log.service.js";

export default {
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      leadsLlamada: [],
      dialogInboundCalls: false,
      currentStatus: null,
      originalStatus: {
        reason: "",
        status: "",
      },
      actividades: [],
      areaNegocio: [],
      roles: [],
    };
  },
  mounted() {
    this.getActividades();
    this.getAreaNegocio();
    this.getRoles();
    setTimeout(() => {
      loadScript(
        "https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js"
      )
        .then(() => {
          if (window.bpspat && window.bpspat.api) {
            window.bpspat.api.init(
              "https://aligev55.zw-callitonce.alestra.net.mx"
            );
            window.bpspat.api.addStatusChangeHandler((newStatus) => {
              this.currentStatus = newStatus;
            });
          } else {
            console.error(
              "La API no está disponible o no se cargó correctamente."
            );
          }
        })
        .catch((error) => {
          console.error(
            "Fallo al cargar el SDK. Refresque la página para intentar nuevamente.",
            error
          );
        });
    }, 2000);
  },
  watch: {
    currentStatus(val) {
      if (val.data.reason != this.originalStatus.reason)
        this.originalStatus = Object.assign({}, val.data);

      if (val.data.reason == "")
        if (val.data.status != this.originalStatus.status)
          this.originalStatus = Object.assign({}, val.data);
    },
    originalStatus() {
      this.postLogMethod();
    },
  },
  methods: {
    async postLogMethod() {
      const body = {
        agente_id: localStorage.id,
        actividad_id: this.getActividad(),
        servicio_embebido: "",
        area_negocio_id: this.getActualAreaNegocio(),
      };
      await postLog(body)
    },
    getActividad() {
      let disp = "";
      switch (this.originalStatus.status) {
        case "ready":
          disp = this.actividades.find(
            (e) => e.nombreActividad == "Disponible"
          );
          return disp.id;
        case "not_ready":
          disp = this.actividades.find(
            (e) => e.nombreActividad == this.originalStatus.reason
          );
          return disp.id;
        default:
          disp = this.actividades.find(
            (e) => e.nombreActividad == this.originalStatus.status
          );
          return disp.id;
      }
    },
    getActualAreaNegocio() {
      const currentRol = this.roles.find((e) => e.rol == this.rol);
      const currentAreaNegocio = this.areaNegocio.find(
        (e) => e.id == currentRol.area_negocio
      );
      return currentAreaNegocio ? currentAreaNegocio.id : "";
    },
    async getActividades() {
      this.actividades = await getActividades();
    },
    async getAreaNegocio() {
      this.areaNegocio = await getAreaNegocio();
    },
    async getRoles() {
      this.roles = await getRoles();
    },
  },
};
</script>
