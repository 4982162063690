<template>
  <v-row>
    <v-col cols="12">
      <common-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h1 class="mb-3 ml-3">Emisión seguro celular</h1>
              <p>Necesitamos unos datos faltantes</p>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="phone.name"
                label="Nombre*"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="phone.surname"
                label="Apellido paterno*"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="phone.lastname"
                label="Apellido materno*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="phone.birthday"
                type="date"
                label="Fecha de nacimiento"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-row align="center">
                <v-col cols="12" md="3">
                  <span>Género</span>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                  class="d-flex justify-center justify-md-end align-end"
                >
                  <v-radio-group v-model="phone.sex" row :rules="requiredRule">
                    <v-radio label="Femenino" value="1"></v-radio>
                    <v-radio label="Masculino" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-row align="center">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="phone.generatedRfc"
                    placeholder="RFC"
                    outlined
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="phone.homoclave"
                    placeholder="Agrega homoclave"
                    outlined
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <h2 class="ml-3">Domicilio fiscal</h2>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="phone.cp"
                @change="getColonias()"
                label="Código postal"
                outlined
                :rules="requiredRule"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-model="phone.state"
                :items="states"
                @blur="getMunicipios()"
                item-text="dEstado"
                item-value="cEstado"
                label="Estado"
                :rules="requiredRule"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-model="phone.colonia"
                :items="colonias"
                item-text="nombreColonia"
                item-value="nColonia"
                label="Colonia"
                :rules="requiredRule"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="phone.street"
                label="Calle"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="phone.municipio"
                :items="municipios"
                item-text="dMnpio"
                item-value="cMnpio"
                label="Municipio"
                :rules="requiredRule"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="phone.interiorNumber"
                label="No. interior"
                outlined
                :rules="requiredRule"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="phone.exteriorNumber"
                label="No. exterior"
                outlined
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h2 class="ml-3">Datos del dispositivo</h2>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="phone.numberPhone"
                label="Número telefónico"
                :rules="phoneRules"
                outlined
                type="tel"
                maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="phone.invoiceAmount"
                label="Monto de factura"
                outlined
                :rules="requiredRule"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="phone.model"
                :items="phonesModels"
                item-text="name"
                item-value="name"
                label="Modelo"
                :rules="requiredRule"
                outlined
                return-object
                @change="onModelChange"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="phone.purchaseDate"
                type="date"
                label="Fecha compra del equipo"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h2 class="ml-3">Opción de pago</h2>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-radio-group
                v-model="phone.optionPay"
                row
                :rules="requiredRule"
              >
                <v-radio label="un solo pago" value="1"></v-radio>
                <v-radio label="12 Meses sin intereses" value="12"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-layout align-center>
                <v-checkbox
                  v-model="phone.termsAndConditions"
                  :false-value="false"
                  :true-value="true"
                ></v-checkbox>
                <v-flex class="align-center">
                  Acepto las
                  <a
                    href="https://storage.googleapis.com/inter-connect-prod.appspot.com/cocheseguro/legal/TERMINOS%20Y%20CONDICIONES%20COCHE%20SEGURO.pdf"
                    target="_blank"
                  >
                    Condiciones Generales y Términos
                  </a>
                  de uso de medios electrónicos y el tratamiento de mis datos
                  personales de acuerdo a lo establecido en el Aviso de
                  Privacidad.
                </v-flex>
              </v-layout>
              <v-checkbox
                class="mb-16-top"
                label="Acepto del cobro y/o domicialización requerida para la contratación del seguro y envío del material contractual de mi correo electrónico registrado."
                v-model="phone.aceptPayment"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                @click="goBack"
                color="#00A7E4"
                block
                dark
                class="rounded-xl"
                outlined
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                color="#00A7E4"
                block
                dark
                class="rounded-xl"
                :disabled="!allFieldsValidated()"
                @click="emit"
              >
                Emitir
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </common-card>
    </v-col>
  </v-row>
</template>
<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import { mainAxios } from "@/mainAxios.js";
import { smartPhonesModels } from "../../../../helpers/devicesModels";
import moment from "moment";
export default {
  name: "EmitirCelular",
  components: { CommonCard },
  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "Debe ser un correo electrónico válido",
      ],
      phoneRules: [
        (v) => !!v || "El número de teléfono es requerido",
        (v) => /^\d{10}$/.test(v) || "Debe ser un número de teléfono válido",
      ],
      phone: {
        name: "",
        surname: "",
        lastname: "",
        birthday: "",
        placeBirth: "",
        sex: "",
        generatedRfc: "",
        homoclave: "",
        cp: "",
        state: "",
        colonia: "",
        street: "",
        municipio: "",
        interiorNumber: 0,
        exteriorNumber: 0,
        numberPhone: "",
        invoiceAmount: 0,
        model: 0,
        brand: null,
        purchaseDate: "",
        optionPay: 0,
        termsAndConditions: false,
        aceptPayment: false,
        totalPrecioAseguradora: 0,
        uuid: "",
      },
      states: [],
      colonias: [],
      municipios: [],
      phonesModels: smartPhonesModels,
    };
  },
  methods: {
    allFieldsValidated() {
      return [
        this.phone.name,
        this.phone.surname,
        this.phone.birthday,
        this.phone.sex,
        this.phone.cp,
        this.phone.state,
        this.phone.colonia,
        this.phone.street,
        this.phone.interiorNumber,
        this.phone.numberPhone,
        this.phone.invoiceAmount,
        this.phone.model,
        this.phone.purchaseDate,
        this.phone.optionPay,
        this.phone.termsAndConditions,
        this.phone.aceptPayment,
        this.phone.homoclave,
        this.phone.municipio,
      ].every((field) => !!field);
    },
    generateRfc() {
      if (
        this.phone.name &&
        this.phone.surname &&
        this.phone.lastname &&
        this.phone.birthday
      ) {
        const year = this.phone.birthday.slice(2, 4);
        const month = this.phone.birthday.slice(5, 7);
        const day = this.phone.birthday.slice(8, 10);
        this.phone.generatedRfc = `${this.phone.surname
          .slice(0, 2)
          .toUpperCase()}${this.phone.lastname
          .slice(0, 1)
          .toUpperCase()}${this.phone.name
          .slice(0, 1)
          .toUpperCase()}${year}${month}${day}`;
      }
    },
    getStates() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/catalogo/estado", config).then((response) => {
        this.states = response.data;
      });
    },
    getColonias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/catalogo/direccion?cp=${this.phone.cp}`, config)
        .then((response) => {
          this.colonias = response.data.listaColonias;
        });
    },
    getMunicipios() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/catalogo/municipio/${this.phone.state}`, config)
        .then((response) => {
          this.municipios = response.data;
        });
    },
    emit() {
      const data = {
        codigo_postal: this.phone.cp,
        estado: this.phone.state,
        ciudad: this.phone.municipio,
        colonia: this.phone.colonia,
        calle: this.phone.street,
        numero_exterior: this.phone.exteriorNumber,
        exterior_number: this.phone.exteriorNumber,
        fecha_nacimiento: moment(this.phone.birthday, "YYYY/MM/DD").format(
          "DD/MM/YYYY"
        ),
        nombre: this.phone.name,
        apellido_paterno: this.phone.surname,
        apellido_materno: this.phone.lastname,
        telefono: this.phone.numberPhone,
        rfc: `${this.phone.generatedRfc}${this.phone.homoclave}`,
        email: localStorage.agenteMail,
        periodicity: Number(this.phone.optionPay),
        fecha_compra: moment(this.phone.purchaseDate, "YYYY/MM/DD").format(
          "DD/MM/YYYY"
        ),
        fecha_factura: moment(this.phone.purchaseDate, "YYYY/MM/DD").format(
          "DD/MM/YYYY"
        ),
        marca: this.phone.brand,
        modelo: this.phone.model,
        precio_factura: Math.trunc(Number(this.phone.totalPrecioAseguradora)),
        payment_method: this.phone.optionPay,
        cotizacion_uuid: this.phone.uuid,
      };
      console.log("datos", data);
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post(`/v1/seguro-celular/emite`, data, config)
        .then((response) => {
          if (response.status == 200) {
            this.loadingButtonEmitir = false;
            window.open(
              response.data.card_payment_URL,
              "Pago de Seguro",
              "width=750, height=750"
            );
            setTimeout(
              () =>
                this.$router.push(
                  `/inicio-celular/${response.data.policy.identifier}/emision/${response.data.id_emision[0]}`
                ),
              2000
            );
          }
        })
        .catch((exception) => {
          console.error(exception);
          this.loadingButtonEmitir = false;
        });
    },

    onModelChange(onModelChange) {
      this.phone.brand = onModelChange.brand;
      this.phone.model = onModelChange.name;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    "phone.name": "generateRfc",
    "phone.surname": "generateRfc",
    "phone.lastname": "generateRfc",
    "phone.birthday": "generateRfc",
  },
  mounted() {
    this.getStates();
    if (sessionStorage.getItem("phone")) {
      this.phone = JSON.parse(sessionStorage.getItem("phone"));
    }
    this.getMunicipios();
    this.getColonias();
  },
};
</script>
<style scoped>
.v-layout {
  align-items: center;
}

.v-flex {
  display: flex;
  align-items: center;
}
.mb-16-top {
  margin-top: -2px !important;
}
</style>
