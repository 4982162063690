<template>
  <div>
    <CommonCreateInboundCallsModal
      v-if="dialogInboundCalls"
      :dialog="dialogInboundCalls"
      @closeModal="dialogInboundCalls = false"
      @success="dialogInboundCalls = false"
      :createCliente="true"
    />
  </div>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import { mainAxios } from '@/mainAxios.js';
import CommonCreateInboundCallsModal from "../Inbound/InboundCalls/CommonCreateInboundCallsModal.vue"
import moment from "moment";

export default {
  data() {
    return {
      rol: JSON.parse(localStorage.getItem('roles'))[0].rol,
      leadsLlamada:[],
      dialogInboundCalls:false,
      intervalo:{},
       config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        }
       }
    };
  },
  components:{
    CommonCreateInboundCallsModal
  },
  mounted() {
        // Verifica si el rol del usuario es uno de los permitidos
        if (this.rol == 'MESACONTROLINBOUND' || this.rol == 'OPERADORINBOUND' || this.rol == 'OPERACIONESINBOUND') {
            // Ejecuta después de 2 segundos
            setTimeout(() => {
                loadScript("https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js")
                    .then(() => {
                        console.log('Si cargo exitosamente');
                        window.bpspat.api.init("https://aligev55.zw-callitonce.alestra.net.mx");
                        // Inicia el intervalo para verificar el estado cada 10 segundos
                        this.intervalo = window.setInterval(() => {
                            this.checkStatus();
                        }, 10000);
                    })
                    .catch(() => {
                        console.error("Fallo al cargar el sdk, Refresque la pagina para realizar la llamada");
                        location.reload();
                    });
            }, 2000);
        }
    },
    methods:{
        checkStatus(){
            window.bpspat.api.getState(this.statusChecked)
        },
        statusChecked(state){
        var jsonActividadActual = localStorage.getItem('jsonLlamada') !== null  ? JSON.parse(localStorage.getItem('jsonLlamada')) :  { numero: null, global_id: null, service: null, seColgo: false };
        switch(state.data.status){
            case 'busy':
                if(jsonActividadActual.global_id != state.data.interactions[0].global_id){
                    let nuevaLlamada = {
                        numero: state.data.interactions[0].phone_number,
                        global_id: state.data.interactions[0].global_id,
                        service: state.data.interactions[0].service,
                        inicio: moment().format('yyyy-MM-DD hh:mm:ss'),
                        seColgo: false
                    };
                    localStorage.removeItem('jsonLlamada')
                    localStorage.setItem("jsonLlamada", JSON.stringify(nuevaLlamada));
                    this.getCustomerView(nuevaLlamada.numero)
                }
            break;
            case 'after_call_work':
                if(jsonActividadActual.global_id == state.data.interactions[0].global_id && !jsonActividadActual.seColgo){
                    let nuevaLlamada = {
                            numero: state.data.interactions[0].phone_number,
                            global_id: state.data.interactions[0].global_id,
                            service: state.data.interactions[0].service,
                            seColgo: true
                        };
                    localStorage.removeItem('jsonLlamada')
                    localStorage.setItem("jsonLlamada", JSON.stringify(nuevaLlamada));
                    this.$toast.info('Se colgo la llamada')
                }
                break;
            }
        },
        getCustomerView(phone) {
            this.data = [];
            mainAxios.get("/v1/cliente/telefono/"+phone, this.config).then((response) => {
                if(response.data.uuid != null){
                    this.$router.push({
                        name: 'detalles-cliente',
                        params:{
                            uuid:response.data.uuid
                        },
                        query: {
                            llamadaInbound: 'true'
                        }
                    });
                }
                else
                this.dialogInboundCalls = true
            });
        },
    }
}
</script>
