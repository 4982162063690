var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"items":_vm.solicitudes,"headers":_vm.headers,"loading":_vm.loading,"no-data-text":"Sin solicitudes para mostrar","no-results-text":"Sin resultados para mostrar","loading-text":"Cargando...","footer-props":{
      'items-per-page-all-text': 'Todos',
      'items-per-page-text': 'Elementos por página',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticStyle:{"padding":"15px"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("Resultados de la búsqueda")])]),_c('v-col',{staticStyle:{"text-align":"end"},attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"color":"transparent","elevation":"0","small":""}},[_c('span',{staticStyle:{"color":"#0070C0"}},[_vm._v("Descargar")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","right":"","color":"#0070C0"}},[_vm._v("mdi-file-pdf-box")])],1)],1)],1)]},proxy:true},{key:"item.cumpleSla",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.cumpleSla == 1 ? "Sí cumple" : "No cumple"))])]}},{key:"item.ver",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.verSolicitud(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.Seleccionar",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.seleccionarItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-bullseye")])],1)]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [(itemsLength)?_c('div',[_vm._v(" Mostrando "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" solicitudes ")]):_vm._e()]}}])}),_c('CommonActionModal',{attrs:{"dialog":_vm.dialog,"title":'¿Seleccionar solicitud?',"subtitle":'Se vinculará la llamada a esta solicitud. ¿Es correcto?',"bottonText":'Sí, seleccionala'},on:{"userChoice":_vm.choiceSolicitud}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }