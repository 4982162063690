<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
          v-if="canEdit"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="datosSolicitud.tipo == 'Renovación'">
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          No. de Póliza a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.polizaRenovar ? formData.polizaRenovar : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Inicio de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaInicioVigenciaRenovar
              ? getFormattDate(formData.fechaInicioVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Fin de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaFinVigenciaRenovar
              ? getFormattDate(formData.fechaFinVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
        v-if="datosSolicitud.tipo == 'Emisión' || datosSolicitud.tipo == 'Renovación'"
      >
        <span class="text-header-data">
          Número de póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numeroPoliza ? formData.numeroPoliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Años de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.anios ? formData.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Inicio de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.inicio ? getFormattDate(formData.inicio) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fin de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fin ? getFormattDate(formData.fin) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Periodicidad de Pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.periodicidadSelected
              ? getFormatPeriodicidad(formData.periodicidadSelected)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Forma de Pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.formaPagoSelected
              ? formData.formaPagoSelected
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Tipo de portafolios
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.tipoPortafolios ? formData.tipoPortafolios : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fumador
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fumador ? formData.fumador : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Plazo comprometido
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.plazoComprometido
              ? formData.plazoComprometido
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Deducibilidad
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.deducibilidad ? formData.deducibilidad : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Revaluable con la inflación
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.revaluableInflacion
              ? formData.revaluableInflacion
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Valor del Plan
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.valorPlan ? formData.valorPlan : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Monto de la inversión/Aportación
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.montoInversion ? formData.montoInversion : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      formData: {
        anios: 1,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
        tipoPortafolios: null,
        fumador: null,
        plazoComprometido: null,
        deducibilidad: null,
        revaluableInflacion: null,
        valorPlan: null,
        montoInversion: null,
      },
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
    };
  },
  props: {
    datosSolicitud: Object,
    canEdit: Boolean
  },

  methods: {
    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      console.log("aqui mero", jsonData);
      this.formData.tipoPortafolios = jsonData.tipoPortafolios
        ? jsonData.tipoPortafolios
        : null;
      this.formData.fumador = jsonData.fumador ? jsonData.fumador : null;
      this.formData.plazoComprometido = jsonData.plazoComprometido
        ? jsonData.plazoComprometido
        : null;
      this.formData.deducibilidad = jsonData.deducibilidad
        ? jsonData.deducibilidad
        : null;
      this.formData.revaluableInflacion = jsonData.revaluableInflacion
        ? jsonData.revaluableInflacion
        : null;
      this.formData.valorPlan = jsonData.valorPlan ? jsonData.valorPlan : null;
      this.formData.montoInversion = jsonData.deducibilidad
        ? jsonData.montoInversion
        : null;
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.fin = jsonData.fin ? jsonData.fin : null;
      this.formData.inicio = jsonData.inicio ? jsonData.inicio : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
    },
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getFormatPeriodicidad(id) {
      var p = this.periodicidadItems.find((e) => e.value == id);
      return p ? p.text : "No disponible";
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
