<template>
  <div class="pt-5">
    <v-data-table
      :items="emisiones"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin emisiones para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:top>
        <v-row style="padding: 15px;">
          <v-col cols="12" md="8">
            <h2>Resultados de la búsqueda</h2>
          </v-col>
          <v-col cols="12" md="4" style="text-align: end;">
            <download-csv :data="columnsReport" name="polizas_tabla.csv">
              <v-btn color="transparent" elevation="0" small>
                <span style="color:#0070C0;">Descargar</span>
                <v-icon small right class="mr-2" color="#0070C0"
                  >mdi-file-pdf-box</v-icon
                >
              </v-btn>
            </download-csv>
          </v-col>
        </v-row>
      </template>
      <template #item.poliza="{ item }">
        {{ item.poliza ? item.poliza : "Sin póliza asignada" }}
      </template>
      <template #item.clienteNombre="{ item }">
        <span>{{
          `${item.nombre || ""} ${item.apellidoPaterno ||
            ""} ${item.apellidoMaterno || ""}`
        }}</span>
      </template>
      <template #item.cumpleSla="{ item }">
        <span>{{ item.cumpleSla == 1 ? "Sí cumple" : "No cumple" }}</span>
      </template>
      <template #item.ver="{ item }">
        <v-btn icon x-small @click="verEmision(item.id)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    emisiones: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Póliza",
          value: "poliza",
        },
        {
          text: "Cliente",
          value: "clienteNombre",
        },
        {
          text: "Agente",
          value: "nombreAgente",
        },
        {
          text: "Estatus Póliza",
          value: "status",
        },
        {
          text: "Prima Neta",
          value: "prima_neta",
        },
        {
          text: "Inicio de Vigencia",
          value: "inicio",
        },
        {
          text: "Fin de Vigencia",
          value: "fin",
        },
        {
          text: "Periodicidad",
          value: "periodicidad",
        },
        {
          text: "Ramo",
          value: "ramo",
        },
        {
          text: "Aseguradora",
          value: "aseguradoraNombre",
        },
        {
          text: "Ejecutivo",
          value: "ejecutivoNombre",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
      columnsReport:[]
    };
  },

  methods: {
    verEmision(id) {
      this.$router.push(`/detalles-emision/${id}`);
    },
    formartReport(){
      this.columnsReport = []
      this.emisiones.map((e)=>{
        this.columnsReport.push({
          'Póliza':e.poliza ?? 'Sin llenar',
          'Cliente':e.clienteNombre ?? 'Sin llenar',
          'Agente':e.nombreAgente ?? 'Sin llenar',
          'Estatus Póliza':e.status ?? 'Sin llenar',
          'Prima Neta':e.prima_neta ?? 'Sin llenar',
          'Inicio de Vigencia':e.inicio ?? 'Sin llenar',
          'Fin de Vigencia':e.fin ?? 'Sin llenar',
          'Periodicidad':e.periodicidad ?? 'Sin llenar',
          'Ramo':e.ramo ?? 'Sin llenar',
          'Aseguradora':e.aseguradoraNombre ?? 'Sin llenar',
          'Ejecutivo':e.aseguradoraNombre ?? 'Sin llenar',
        })
      })
      
    }
  },

  watch:{
    emisiones(){
      this.formartReport()
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
</style>
