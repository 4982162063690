<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeTab', 2)"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Codigo postal
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.codigo_postal ? polizaInfo.codigo_postal : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Calle
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.calle ? polizaInfo.calle : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No. Exterior
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.num_exterior ? polizaInfo.num_exterior : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No. Interior
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.num_interior ? polizaInfo.num_interior : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Colonia
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.colonia ? polizaInfo.colonia : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Ciudad
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.ciudad ? polizaInfo.ciudad : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Estado de la República
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.estado_id ? getEstadoName(polizaInfo.estado_id) : "Sin llenar" }}
        </span>
      </v-col>



    </v-row>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      colonias:[]
    };
  },
  props: {
    polizaInfo: Object,
  },
  watch:{
    "polizaInfo.codigo_postal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.colonias = response.data.listaColonias;
                this.polizaInfo.estado_id = Number(response.data.nEstado);
                this.polizaInfo.ciudad = response.data.nombreMunicipio;
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    },
  },
  methods: {
    getEstadoName(id){
      var estado = this.estados.find((e) => e.id == id)
      return estado ? estado.name : 'No existe'
    },
    getStates(){
      if (typeof this.polizaInfo.codigo_postal != "undefined") {
        if (this.polizaInfo.codigo_postal.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + this.polizaInfo.codigo_postal, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.colonias = response.data.listaColonias;
                this.polizaInfo.estado_id = Number(response.data.nEstado);
                this.polizaInfo.ciudad = response.data.nombreMunicipio;
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    }
  },
  mounted(){
    this.getStates()
  }
};
</script>


<style scoped>
.theme--light.v-data-table {
    background-color: #81CFE61A;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 20px;
}

.v-expansion-panel{
    border-radius: 20px;
}
.v-expansion-panels{
    z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active{
    border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
    background-color: #81CFE61A;
    color: rgba(0, 0, 0, 0.87);
}
</style>