import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const postLog = async (body) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/insertarModificarActividad",
      body,
      config
    );
    
    return serverResponse.data
};  

export const getAreaNegocio = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/catalogo/area-negocio",
      config
    );
    
    return serverResponse.data
};  

export const getActividades = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/catalogo/actividad",
      config
    );
    
    return serverResponse.data
};  

export const getRoles = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/agente/roles/list",
      config
    );
    
    return serverResponse.data
};  