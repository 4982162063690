<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Teléfono"
              outlined
              color="#00a7e4"
              v-mask="'##########'"
              v-model="formData.telefono"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <common-email-autocomplete
              :emailEdit="polizaInfo.correo_electronico"
              @emailUpdate="(e) => (formData.correo_electronico = e)"
            >
            </common-email-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab', 1)"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommonEmailAutocomplete,
    CommondForm,
  },

  data() {
    return {
      switch_data: 1,
      formData: {
        id: null,
        telefono: null,
        correo_electronico: null,
      },
      run: false,
    };
  },
  props: {
    polizaInfo: Object,
  },
  watch: {
    polizaInfo() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.polizaInfo[e]) {
          this.formData[e] = this.polizaInfo[e];
        }
      });
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
