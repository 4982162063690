<template>
  <v-dialog v-model="dialog" persistent width="500">
    <common-card>
      <v-card-text class="pa-4 black--text" style="text-align: center;">
        <div
          class="pt-5 pb-5 top-text"
          style="width: 100%; height: 100%; justify-content: center;"
        >
          Configuracion de tenent
        </div>
        <div class="pt-5 pb-5">
          <span class="confirmation-text">
            Eres dev genial escoge la versión de Interify que quieras ver
          </span>
        </div>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              label="Version"
              :items="tenantsList"
              item-text="tenantName"
              item-value="tenantUrl"
              v-model="tenant"
              outlined
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="tenant == 'alianza'">
            <v-autocomplete
              label="Alianza"
              :items="alianzaList"
              item-text="tenantName"
              item-value="tenantUrl"
              v-model="alianza"
              outlined
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <div>
          <v-row>
            <v-col cols="12">
              <v-btn class="common-botton" dark @click="applyConfig"
                >Aplicar</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </common-card>
  </v-dialog>
</template>

<script>
import CommonCard from "../CommonCard.vue";
import {
  tenantsList,
  alianzaList,
  tenentConfig,
} from "@/helpers/tenent-config";
export default {
  components: {
    CommonCard,
  },
  props: {
    dialog: Boolean,
  },

  data() {
    return {
      tenant: null,
      alianza: null,
      tenantsList: tenantsList,
      alianzaList: alianzaList,
    };
  },
  watch: {
    tenant() {
      this.alianza = null;
    },
  },
  methods: {
    applyConfig() {
      var config = tenentConfig(this.tenant);
      if (config.applied) location.reload();
    },
  },
};
</script>

<style>
.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text {
  width: 369px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.confirmation-text {
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

  width: 369px;
  height: 30px;

  /* Subtitle/S */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;

  color: #555555;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
</style>
