<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Tipo de Inmueble"
          v-model="polizaData.tipoInmueble"
          :items="tipoInmuebleList"
          item-text="name"
          item-value="id"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="polizaData.tipoInmueble == 'Otro'">
        <v-text-field
          label="Especificar Tipo de inmueble"
          v-model="polizaData.otroTipoInmueble"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Valor del inmueble"
          v-model="polizaData.valorInmueble"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <!-- Solo si es departamente -->
      <v-col cols="12" md="6" lg="3" xl="3" v-if="polizaData.tipoInmueble == 'Departamento'">
        <v-text-field
          label="Nivel del departamento"
          v-model="polizaData.nivelDepartamento"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="polizaData.tipoInmueble == 'Departamento'">
        <v-text-field
          label="Pisos Totales"
          v-model="polizaData.pisosTotales"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        >
        </v-text-field>
      </v-col>
      <!-- Solo si es Casa -->
      <v-col cols="12" md="6" lg="3" xl="3" v-if="polizaData.tipoInmueble == 'Casa'">
        <v-text-field
          label="Número de pisos altos"
          v-model="polizaData.numeroPisosAltos"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="polizaData.tipoInmueble == 'Casa'">
        <v-text-field
          label="Sotanos"
          v-model="polizaData.sotanos"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Valor de los contenidos"
          v-model="polizaData.valorContenidos"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Valor del equipo electrónico"
          v-model="polizaData.valorEquipoElectronico"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada Responsabilidad Civil"
          v-model="polizaData.sumaAseguradaRC"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada Cristales"
          v-model="polizaData.sumaAseguradaCristales"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada robo con violencia "
          v-model="polizaData.sumaAseguradaRoboViolencia"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada dinero y valores"
          v-model="polizaData.sumaAseguradaDineroValores"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada obras de arte o piezas de difícil reposición"
          v-model="polizaData.sumaAseguradaObrasDeArte"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('goToSave', polizaData)"
        >
          <span>
            Guardar
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  data() {
    return {
      polizaData: {
        tipoInmueble: null,
        otroTipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },
      //listas
      tipoInmuebleList: ["Casa", "Departamento", "Bodega", "Edificio", "Otro"],
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
    };
  },
  methods: {
  },
};
</script>
