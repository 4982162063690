<template>
  <v-row>
    <v-col cols="12">
      <common-card>
        <v-container>
          <v-row class="mb-3">
            <v-col cols="3" md="1">
              <img
                src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Vector.png"
                alt=""
                height="50"
              />
            </v-col>
            <v-col cols="9">
              <h2>Confirmación de datos</h2>
              <span>
                Verifica que tus datos sean correctos
              </span>
            </v-col>
          </v-row></v-container
        >
        <v-row class="mt-5 ml-md-8">
          <v-col cols="12">
            <v-row class="d-flex align-center">
              <v-col cols="6" md="3">
                <h2 class="ml-2 ml-md-0">Contratante</h2>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn style="background: #029ECF" outlined color="white" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="ml-2 ml-md-0">
                <v-row class="mb-2">
                  <v-col cols="12" md="6" lg="8" class="d-flex align-center">
                    <v-row>
                      <v-col cols="12" md="6" lg="4">
                        <h3>Nombre</h3>
                        <span>{{ phone.name }}</span>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <h3>Apellido P</h3>
                        <span>{{ phone.surname }}</span>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <h3>Apellido M</h3>
                        <span>{{ phone.lastname }}</span>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <h3>Teléfono</h3>
                        <span>{{ phone.numberPhone }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" class="d-sm-block d-none">
                    <img
                      src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Vector.png"
                      alt=""
                    />
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                  <v-col
                    cols="11"
                    class="ml-5 d-none d-md-block"
                    style="border-top: 1px solid #029ECF;"
                  >
                  </v-col>
                  <v-col cols="6" md="3">
                    <h2 class="ml-1 ml-md-0">Celular</h2>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-btn
                      style="background: #029ECF"
                      outlined
                      color="white"
                      icon
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="ml-1 ml-md-0">
                    <v-row class="mb-2">
                      <v-col cols="12" class="d-flex align-center">
                        <v-row>
                          <v-col cols="12" md="4">
                            <h3>Marca del dispositivo a asegurar</h3>
                            <span>{{ phone.brand }}</span>
                          </v-col>
                          <v-col cols="12" md="4">
                            <h3>Modelo del dispositivo a asegurar</h3>
                            <span>{{ phone.model }}</span>
                          </v-col>
                          <v-col cols="12" md="4">
                            <h3>Monto de factura</h3>
                            <span>{{ formatPrice(phone.invoiceAmount) }}</span>
                          </v-col>
                        </v-row>
                      </v-col></v-row
                    ></v-col
                  >
                  <v-col
                    cols="12"
                    class="d-block d-sm-none d-flex justify-center mb-3"
                  >
                    <img
                      src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Vector.png"
                      alt=""
                    />
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center justify-sm-start">
                  <v-col cols="11" sm="5">
                    <v-btn
                      style="widht: 100%; padding: 6px!important"
                      color="#00A7E4"
                      outlined
                      block
                      dark
                      class="rounded-xl"
                      @click="goBack"
                    >
                      <span> Regresar</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="11" sm="6">
                    <v-btn
                      style="widht: 100%;"
                      color="#00A7E4"
                      block
                      dark
                      class="rounded-xl"
                      @click="goEmit"
                    >
                      <span> Confirmar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </common-card>
    </v-col>
  </v-row>
</template>
<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import { mainAxios } from "@/mainAxios.js";
export default {
  name: "ConfirmacionDatosCelular",
  components: { CommonCard },
  data() {
    return {
      phone: null,
    };
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
    goBack() {
      this.$router.go(-1);
    },
    createPrice() {
      this.getToken();
      const data = {
        modelo: this.phone.model,
        precio_factura: this.phone.invoiceAmount,
        nombre: this.phone.name,
        apellido_paterno: this.phone.surname,
        apellido_materno: this.phone.lastname,
        telefono: this.phone.numberPhone,
        email: this.phone.email,
        codigo_postal: this.phone.cp,
        estado_id: Number(this.phone.state),
        portal: "Agentes",
        partner: "Segurify",
        agente_uuid: localStorage.agenteUuid,
        producto: "236",
        periodicity: 12,
        pago_id: Number(this.phone.optionPay),
      };
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post("/v1/seguro-celular/emite", data, config)
        .then(({ data }) => {
          console.log("datos", data);
        });
    },
    goEmit() {
      this.$router.push({
        name: "EmitirCelular",
      });
    },
  },
  created() {
    if (
      this.$route.params.phone &&
      typeof this.$route.params.phone === "object"
    ) {
      sessionStorage.setItem("phone", JSON.stringify(this.$route.params.phone));
      this.phone = this.$route.params.phone;
    } else {
      const phoneData = sessionStorage.getItem("phone");
      if (phoneData && phoneData !== "[object Object]") {
        this.phone = JSON.parse(phoneData);
      }
    }
  },
};
</script>
<style scoped></style>
