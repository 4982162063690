<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeComponent')"
    >
      <template v-slot:data>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
          >
            <v-text-field
              v-model="formData.numeroPoliza"
              label="Número de póliza"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
//import moment from "moment";

export default {
  data() {
    return {
      formData: {
        numeroPoliza: null,
      },

      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },
      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
    };
  },
  components: {
    CommondForm,
  },
  watch: {},
  props: {
    datosSolicitud: Object,
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      var jsonData = JSON.parse( this.datosSolicitud.body)
      this.formData.numeroPoliza = jsonData.numeroPoliza ? jsonData.numeroPoliza : null;
    },
  },
};
</script>
