export const dominiosComunes = [
    "gmail.com",
    "hotmail.com",
    "live.com.mx",
    "segurify.com",
    "inter.mx",
    "interify.mx",
    "outlook.com",
    "outlook.es",
    "yahoo.com"
  ];
  
  export const validarDominioComun = (correo) => {
    if (!correo) return true;
    const dominio = correo.split("@")[1];
  
    const similitudDominios = dominiosComunes
      .map((dominioComun, index) => {
        let letrasEnComun = 0;
        dominio.split("").forEach((letra, i) => {
          dominioComun[i] === letra && letrasEnComun++;
        });
        return { dominioComun, index, letrasEnComun };
      })
      .sort((a, b) => {
        if (a.letrasEnComun < b.letrasEnComun) return 1;
        return -1;
      });
  
    const mejorResultado = similitudDominios[0];
    if (
      mejorResultado.letrasEnComun > 6 &&
      dominiosComunes[mejorResultado.index] !== dominio
    )
      return "Dominio común con posibles errores";
  
    return true;
  };
  
  export default validarDominioComun;