<template>
  <div>
    <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
      </v-row>
      <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Tipo de Persona (Régimen)
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.regimen_persona ? formData.regimen_persona : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="formData.tipo_persona == 'Moral'"
      >
        <span class="text-header-data ">
          Razón Social
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.razon_social ? formData.razon_social : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Nombre
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.nombres ? formData.nombres : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Apellido paterno
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.apellidoPaterno
              ? formData.apellidoPaterno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Apellido materno
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.apellidoMaterno
              ? formData.apellidoMaterno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fecha nacimiento
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaNacimiento
              ? getFormatDate(formData.fechaNacimiento)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Edad
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.edad ? formData.edad : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          RFC
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.rfc ? formData.rfc : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Género
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.genero ? formData.genero : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Estado Civil
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.estadoCivilSelected ? formData.estadoCivilSelected : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      formData:{
        nombres:null,
        apellidoPaterno:null,
        apellidoMaterno:null,
        rfc:null,
        genero:null,
        fechaNacimiento:null,
        estadoCivilSelected:null,
        regimen_persona:null,
        razon_social:null,
        edad:null
      }
    };
  },
  props: {
    datosSolicitud: Object,
  },
  methods: {
    getFormatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "Sin llenar";
    },
    fillData() {
      this.formData.nombres = this.datosSolicitud.nombre ?? null
      this.formData.apellidoPaterno = this.datosSolicitud.apellidoPaterno ?? null
      this.formData.apellidoMaterno = this.datosSolicitud.apellidoMaterno ?? null
      this.formData.genero = this.datosSolicitud.sexo ?? null
      this.formData.rfc = this.datosSolicitud.rfc ?? null
      this.formData.fechaNacimiento = this.datosSolicitud.fechaNacimiento ?? null
      this.formData.estadoCivilSelected = this.datosSolicitud.estadoCivil ?? null

      var jsonData = JSON.parse(this.datosSolicitud.body)
      this.formData.regimen_persona = jsonData.regimen_persona ? jsonData.regimen_persona : null
      this.formData.razon_social = jsonData.razon_social ? jsonData.razon_social : null
      this.formData.edad = jsonData.edad ? jsonData.edad : null
    },
  },
  mounted() {
    this.fillData()
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
