<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
    <template v-slot:data>
      <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Plan"
          v-model="formData.plan"
          :items="planList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Fumador"
          v-model="formData.fumador"
          :items="fumadorList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma Asegurada"
          v-model="formData.sumaAsegurada"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Muerte Accidental"
          v-model="formData.muerteAccidental"
          :items="muerteAccidentalList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Perdidas Orgánicas"
          v-model="formData.perdidasOrganicas"
          :items="perdidasOrganicasList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Invalidez total y permanente"
          v-model="formData.invalidezTotal"
          :items="invalidezTotalList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-select
          label="Gastos funerarios"
          v-model="formData.gastosFunerarios"
          :items="gastosFunerariosList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
    </template>

  </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components:{
    CommondForm
  },
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData:{
        id:null,
        plan: null,
        fumador: null,
        sumaAsegurada: null,
        muerteAccidental: null,
        perdidasOrganicas: null,
        invalidezTotal: null,
        gastosFunerarios: null,
      },
      //listas
      planList: ["Temporal", "Ordinario", "Vida Pagos Limitados(VPL)"],
      fumadorList: ["Si", "No"],
      muerteAccidentalList: ["Si ampara", "No ampara"],
      perdidasOrganicasList: ["Si ampara", "No ampara"],
      invalidezTotalList: ["Si ampara", "No ampara"],
      gastosFunerariosList: ["Si ampara", "No ampara"],

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,

    };
  },
  mounted(){
    this.fillData()
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.ramoData[e]) {
          this.formData[e] = this.ramoData[e];
        }
      });
    },
  },
};
</script>
