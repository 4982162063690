<template>
  <v-img :src="iconLoaded" width="100%" contain></v-img>
</template>

<script>
import iconsCatalog from "./iconsCatalog";

export default {
  props: {
    icon: {
      type: String,
    },
  },

  data() {
    return {
      iconsCatalog,
      iconLoaded: null,
    };
  },

  mounted() {
    this.resolveIcon();
  },

  methods: {
    resolveIcon() {
      this.iconsCatalog[this.icon]()
        .then((source) => {
          this.iconLoaded = source.default;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
