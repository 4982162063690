<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="4"
      xl="4"
      v-for="(campo, idx) in calculateCamposRamo"
      :key="idx"
    >
      <span class="text-header-data">
        {{ campo.label }}
      </span>
      <br />
      <span>
        {{ getValue(campo) }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { CurrentLista } from "@/helpers/FormatListPolizas";
export default {
  components: {},
  props: {
    solicitudData: Array,
  },
  data() {
    return {
      switch_data: 1,
      aseguradorasList: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero" },
        { id: 5, name: "Qualitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "Sura" },
        { id: 8, name: "AIG" },
        { id: 9, name: "HDI" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "Banorte" },
      ],
    };
  },

  computed: {
    requestJson() {
      return this.solicitudData ? JSON.parse(this.solicitudData.body) : null;
    },
    calculateCamposRamo() {
      return CurrentLista(this.requestJson, this.solicitudData.categoria);
    },
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    },
    getStatus(val) {
      switch (val) {
        case 0:
          return "Nueva";
        case 1:
          return "Emitida Pendente de Pago";
        case 2:
          return "Pagada Parcial";
        case 3:
          return "Pagada Total";
        case 4:
          return "Renovada";
        case 5:
          return "Finalizada";
        case 8:
          return "Duplicada";
        default:
          return "Cancelada";
      }
    },
    getPeridicidad(val) {
      switch (val) {
        case 1:
          return "Anual";
        case 2:
          return "Semestral";
        case 3:
          return "Trimestral";
        default:
          return "Mensual";
      }
    },
    getValue(campo) {
      switch (campo.label) {
        case "Aseguradora": {
          let aseguradora = this.aseguradorasList.find(
            (e) => e.id == this.requestJson[campo.value]
          );
          return aseguradora ? aseguradora.name : "No disponible";
        }
        case "¿Atendio en llamada?":
        case "¿Es queja?" :{
          return  this.requestJson[campo.value]
            ? 'Si'
            : "No";
        }
        default: {
          return this.requestJson[campo.value]
            ? this.requestJson[campo.value]
            : "No disponible";
        }
      }
    },
    goToPolizaDetails() {
      this.$router.push(`/detalles-solicitud/${this.solicitudData.id}`);
    },
  },
};
</script>
