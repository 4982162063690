<template>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Nivel Hospitalario
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.nivelHospitalario ? ramoData.nivelHospitalario : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Suma asegurada
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.sumaAsegurada ? ramoData.sumaAsegurada : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Deducible
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.deducible ? ramoData.deducible : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Coaseguro
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.coaseguro ? ramoData.coaseguro : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Cero deducible por accidente
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.ceroDeducible ? ramoData.ceroDeducible : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Medicamentos fuera de hospital
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.medicinaFueraHospital ? ramoData.medicinaFueraHospital : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Emergencia en el Extranjero
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.emergenciaExtranjero ? ramoData.emergenciaExtranjero : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Enfermedades Catastróficas
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.enfermedadesCatastroficas ? ramoData.enfermedadesCatastroficas : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Cobertura en el Extranjero
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.coberturaExtranjero ? ramoData.coberturaExtranjero : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Preexistencias
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.preexistencias ? ramoData.preexistencias : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Protección Dental
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.proteccionDental ? ramoData.proteccionDental : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Maternidad Extendida
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.maternidadExtendida ? ramoData.maternidadExtendida : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Cobertura Nacional (Multirregión)
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.coberturaNacional ? ramoData.coberturaNacional : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Reconocimiento de Antigüedad
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.reconocimientoAntiguedad ? ramoData.reconocimientoAntiguedad : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Indicar las fechas para cotizar
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.fechasParaCotizar ? ramoData.fechasParaCotizar : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <span class="text-header-data ">
          Dependientes: En su caso se debe indicar la edad, sexo y parentesco de cada uno.
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.dependientes ? ramoData.dependientes : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },

  methods: {},
};
</script>
