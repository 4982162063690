<template>
  <v-footer style="z-index: 0!important; color: white !important;"  :class="globalConfig.colorFooter">
    <v-container>
      <v-row>
        <v-col cols="12" md="2" class="text-start offset-md-2">
          <h4>Productos</h4>
          <ul class="no-bullets">
            <li>Coche seguro</li>
            <li>Médico seguro</li>
            <li>Mascota seguro</li>
            <!-- <li>Celular seguro</li> -->
          </ul>
        </v-col>
        <v-col cols="12" md="2" class="text-start">
          <h4>Clientes</h4>
          <ul class="no-bullets">
            <li>Login</li>
            <li> <a href="https://inter.mx/aviso-de-privacidad" style="color: #ffffff;" target="_blank">Aviso de privacidad</a></li>
          </ul>
        </v-col>
        <v-col cols="12" md="2" class="text-start">
          <h4>Contáctanos</h4>
          <ul class="no-bullets">
            <li>+52 (55) 8877 0545</li>
            <li>operaciones@interify.mx</li>
          </ul>
        </v-col>
        <v-col cols="12" md="4" class="d-flex column-direction">
          <v-img
            class="mx-5 responsive-img mb-2"
            contain
            max-height="50"
            max-width="100"
            :src="globalConfig.logoFooter"
          ></v-img>
          <ul class="no-bullets row-icons">
            <li><v-icon color="white">mdi-youtube</v-icon></li>
            <!-- Asume que usas Vuetify íconos -->
            <li><v-icon color="white">mdi-facebook</v-icon></li>
            <li><v-icon color="white">mdi-twitter</v-icon></li>
            <li><v-icon color="white">mdi-instagram</v-icon></li>
            <li><v-icon color="white">mdi-linkedin</v-icon></li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import loginData from "../helpers/data/data-login-template.json";
import interifyBlack from "@/assets/interify_black.svg";

export default {
  name: "Footer",
  data() {
    return {
      loginTemplate: loginData,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      logo:interifyBlack
    };
  },
};
</script>
<style scoped>

.footer a:hover {
  color: #ddd;
}
.no-bullets {
  list-style-type: none;
  padding-left: 0;
  font-size: 12px;
}

.row-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.column-direction {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 600px) {
  .responsive-img {
    max-width: 70%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    
  }

  .row-icons {
    width: 100%;
    justify-content: flex-start;
  }
  .column-direction {
    align-items: flex-start;
  }
}
</style>
