import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import emissionStore from "./modules/emission.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    emission: emissionStore,
  },
  plugins: [createPersistedState()],
});
