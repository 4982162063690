<template>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
              <span class="text-header-data ">
                Fecha de Creación
              </span>
              <br>
              <span class="text-data-details">
                  {{ polizaInfo.fecha ? getFormattDate(polizaInfo.fecha) : 'Sin llenar' }}
              </span>
          </v-col>
          <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
              <span class="text-header-data ">
                Hora
              </span>
              <br>
              <span class="text-data-details">
                  {{ polizaInfo.fecha ? getFormattHour(polizaInfo.fecha) : 'Sin llenar' }}
              </span>
          </v-col>
          <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
              <span class="text-header-data ">
                Creado por
              </span>
              <br>
              <span class="text-data-details">
                  {{ polizaInfo.creadoPor ? polizaInfo.creadoPor : 'Sin llenar' }}
              </span>
          </v-col>
          <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
              <span class="text-header-data ">
                Fecha de Última Modificación
              </span>
              <br>
              <span class="text-data-details">
                  {{ polizaInfo.ultimaModificacion ? getFormattDate(polizaInfo.ultimaModificacion) : 'Sin llenar' }}
              </span>
          </v-col>
          <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
              <span class="text-header-data ">
                Hora
              </span>
              <br>
              <span class="text-data-details">
                  {{ polizaInfo.ultimaModificacion ? getFormattHour(polizaInfo.ultimaModificacion) : 'Sin llenar' }}
              </span>
          </v-col>
          <v-col cols="6" sm="4" md="4" lg="4" xl="4" >
              <span class="text-header-data ">
                Modificado por
              </span>
              <br>
              <span class="text-data-details">
                  {{ polizaInfo.modificadoPor ? polizaInfo.modificadoPor : 'Sin llenar' }}
              </span>
          </v-col>
      </v-row>
    </v-expansion-panel-content>
</template>

<script>
 import moment from "moment";
export default {
  components: {
  },
  props:{
    polizaInfo:{
      type: Object,
      default: () => {},
    },
    productos:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
    };
  },
  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
    getFormattDate(date){
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
    },
    getFormattHour(date){
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
    }
  },
};
</script>
