<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeTab', 2)"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Tipo de Persona (Régimen)
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.tipo_persona ? polizaInfo.tipo_persona : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        v-if="polizaInfo.tipo_persona == 'Moral'"
      >
        <span class="text-header-data ">
          Razón Social
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.razon_social ? polizaInfo.razon_social : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Nombre
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.nombre ? polizaInfo.nombre : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Apellido paterno
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.apellido_paterno
              ? polizaInfo.apellido_paterno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Apellido materno
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.apellido_materno
              ? polizaInfo.apellido_materno
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fecha nacimiento
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.fecha_nacimiento
              ? getFormatDate(polizaInfo.fecha_nacimiento)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Edad
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.fecha_nacimiento ? getEdad(polizaInfo.fecha_nacimiento) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          RFC
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.rfc ? polizaInfo.rfc : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Género
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.genero ? getGenderName(polizaInfo.genero) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Estado Civil
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.statusCivil ? polizaInfo.statusCivil : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    polizaInfo: Object,
  },
  methods: {
    getFormatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "Sin llenar";
    },
    getGenderName(val){
      switch(val){
        case 'F':
            return 'Femenino'
        case 'M':
            return 'Masculino'
        default:
            return val
      }
    },
    getEdad(date) {
      const birthdayDate = moment(date);
      const today = moment();
      console.log("aqui ", birthdayDate, today);
      const years = today.diff(birthdayDate, "years");
      return years;
    },

  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
