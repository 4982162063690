<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
          v-if="canEdit"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth> 
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="datosSolicitud.tipo == 'Renovación'">
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          No. de Póliza a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.polizaRenovar ? formData.polizaRenovar : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Inicio de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaInicioVigenciaRenovar
              ? getFormattDate(formData.fechaInicioVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Fin de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaFinVigenciaRenovar
              ? getFormattDate(formData.fechaFinVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
        v-if="datosSolicitud.tipo == 'Emisión' || datosSolicitud.tipo == 'Renovación'"
      >
        <span class="text-header-data">
          Número de póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numeroPoliza ? formData.numeroPoliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Años de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.anios ? formData.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Inicio de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.inicio ? getFormattDate(formData.inicio) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fin de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fin ? getFormattDate(formData.fin) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Plan
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.plan ? formData.plan : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fumador
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fumador ? formData.fumador : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Suma Asegurada
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.sumaAsegurada ? formData.sumaAsegurada : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Muerte Accidental
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.muerteAccidental ? formData.muerteAccidental : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Perdidas Orgánicas
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.perdidasOrganicas ? formData.perdidasOrganicas : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Invalidez total y permanente
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.invalidezTotal ? formData.invalidezTotal : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Gastos funerarios
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.gastosFunerarios ? formData.gastosFunerarios : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>

</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      formData:{
        numeroPoliza: null,
        anios: null,
        inicioVigencia: null,
        finVigencia: null,
        periodicidadPago: null,
        formaPagoSelected: null,
        plan: null,
        fumador: null,
        sumaAsegurada: null,
        muerteAccidental: null,
        perdidasOrganicas: null,
        invalidezTotal: null,
        gastosFunerarios: null,
      }
    };
  },
  props: {
    datosSolicitud:  Object,
    canEdit: Boolean
  },

  methods: {
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getFormatPeriodicidad(id) {
      var p = this.periodicidadItems.find((e) => e.value == id);
      return p ? p.text : "No disponible";
    },
    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      this.formData.plan = jsonData.plan ? jsonData.plan : null;
      this.formData.fumador = jsonData.fumador ? jsonData.fumador : null;
      this.formData.sumaAsegurada = jsonData.sumaAsegurada ? jsonData.sumaAsegurada : null;
      this.formData.muerteAccidental = jsonData.muerteAccidental ? jsonData.muerteAccidental : null;
      this.formData.perdidasOrganicas = jsonData.perdidasOrganicas ? jsonData.perdidasOrganicas : null;
      this.formData.invalidezTotal = jsonData.invalidezTotal ? jsonData.invalidezTotal : null;
      this.formData.gastosFunerarios = jsonData.gastosFunerarios ? jsonData.gastosFunerarios : null;
     //Comun para todos
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.fin = jsonData.fin ? jsonData.fin : null;
      this.formData.inicio = jsonData.inicio ? jsonData.inicio : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
      //fin de comun 
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
