import RfcFacil from "rfc-facil";
import { mainAxios } from "@/mainAxios.js";

const getDefaultState = () => ({
  insurerData: {
    logo: null,
    insurer: null,
  },
  carData: {
    brand: null,
    model: null,
    description: null,
  },
  emissionRequest: {
    cotizacion_id: null,
    contact_street: null,
    contractor_rfc: null,
    contractor_int: null,
    contractor_ext: null,
    vehicle_vin: "",
    vehicle_plate: null,
    invoice: false,
    contractor: false,
    cfdi: "612",
    use_cfdi: "G03",
  },
  contractorData: {
    firstName: null,
    lastname: null,
    secondLastname: null,
    birthDate: null,
    gender: null,
    rfc: null,
    email: null,
    phone: null,
    cp: "",
    suburbCode: null,
    suburbName: null,
    stateName: null,
    cityName: null,
  },
  driverData: {
    first_name: null,
    lastname: null,
    second_lastname: null,
    email: "",
    contactBirthDate: null,
    contactPhone: null,
    contact_rfc: null,
    contactGender: "F",
    driver_street: null,
    driver_ext: null,
    driver_int: null,
    driverZipCode: null,
    driverTownshipCode: null,
    driverStateCode: null,
    driverSuburbCode: null,
  },
  loadingPaymentData: false,
  paymentData: null,
});

const emissionStore = {
  state: getDefaultState(),

  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, payload);
      console.log("State set");
    },

    UPDATE_INSURER_DATA(state, payload) {
      state.insurerData = payload;
    },

    UPDATE_INSURER_PROPERTY(state, payload) {
      state.insurerData[payload.key] = payload.value;
    },

    UPDATE_EMISSION_REQUEST(state, payload) {
      state.emissionRequest = payload;
    },

    UPDATE_EMISSION_PROPERTY(state, payload) {
      state.emissionRequest[payload.key] = payload.value;
    },

    UPDATE_CAR_DATA(state, payload) {
      state.carData = payload;
    },

    UPDATE_CAR_DATA_PROPERTY(state, payload) {
      state.carData[payload.key] = payload.value;
    },

    UPDATE_DRIVER_DATA_PROPERTY(state, payload) {
      state.driverData[payload.key] = payload.value;
    },

    UPDATE_CONTRACTOR_DATA(state, payload) {
      state.contractorData = payload;
    },

    UPDATE_CONTRACTOR_DATA_PROPERTY(state, payload) {
      state.contractorData[payload.key] = payload.value;
    },

    UPDATE_PAYMENT_DATA(state, payload) {
      state.paymentData = payload;
    },

    UPDATE_LOADING_PAYMENT_DATA(state, payload) {
      state.loadingPaymentData = payload;
    },
  },

  actions: {
    clearState(context) {
      context.commit("SET_STATE", getDefaultState());
    },

    updateEmissionFromConfirmation(context) {
      const quoteStorage = JSON.parse(
        sessionStorage.getItem("cotizacionSelected")
      );

      context.commit("UPDATE_INSURER_DATA", {
        logo: quoteStorage.logo,
        insurer: quoteStorage.insurer,
      });

      const carStorage = JSON.parse(sessionStorage.getItem("carPrice"));
      context.commit("UPDATE_CAR_DATA", {
        brand: carStorage.carBrand.model.assembler,
        model: carStorage.carBrand.model.model,
        description: carStorage.carBrand.model.description,
      });

      const contractorStorage = carStorage.personalData;
      context.commit("UPDATE_CONTRACTOR_DATA", {
        firstName: contractorStorage.nombre,
        lastname: contractorStorage.apellidoPaterno,
        secondLastname: contractorStorage.apellidoMaterno,
        birthDate: contractorStorage.fechaDeNacimiento,
        gender: contractorStorage.genero,
        rfc: RfcFacil.forNaturalPerson({
          name: contractorStorage.nombre,
          firstLastName: contractorStorage.apellidoPaterno,
          secondLastName: contractorStorage.apellidoMaterno,
          day: contractorStorage.fechaDeNacimiento.split("-")[2],
          month: contractorStorage.fechaDeNacimiento.split("-")[1],
          year: contractorStorage.fechaDeNacimiento.split("-")[0],
        }),
        email: null,
        phone: null,
        cp: "",
        suburbCode: null,
        suburbName: null,
        stateName: null,
        cityName: null,
      });
      context.commit("UPDATE_EMISSION_PROPERTY", {
        key: "contractor_rfc",
        value: context.state.contractorData.rfc,
      });

      const cotizacionStorage = JSON.parse(
        sessionStorage.getItem("cotizacionSelected")
      );
      context.commit("UPDATE_EMISSION_PROPERTY", {
        key: "cotizacion_id",
        value: cotizacionStorage.id,
      });

      const { personalData } = carStorage;
      context.commit("UPDATE_CONTRACTOR_DATA_PROPERTY", {
        key: "phone",
        value: personalData.telefono,
      });
      context.commit("UPDATE_CONTRACTOR_DATA_PROPERTY", {
        key: "email",
        value: personalData.correo,
      });
    },

    updateInsurerLogo(context, logo) {
      context.commit("UPDATE_INSURER_PROPERTY", { key: "logo", value: logo });
    },

    updateEmissionRequest(context, payload) {
      context.commit("UPDATE_EMISSION_REQUEST", payload);
    },

    updateEmissionRequestProperty(context, payload) {
      context.commit("UPDATE_EMISSION_PROPERTY", {
        key: payload.key,
        value: payload.value,
      });
    },

    updateCarDataProperty(context, payload) {
      context.commit("UPDATE_CAR_DATA_PROPERTY", {
        key: payload.key,
        value: payload.value,
      });
    },

    updateDriverDataProperty(context, payload) {
      context.commit("UPDATE_DRIVER_DATA_PROPERTY", {
        key: payload.key,
        value: payload.value,
      });
    },

    updateContractorDataProperty(context, payload) {
      context.commit("UPDATE_CONTRACTOR_DATA_PROPERTY", {
        key: payload.key,
        value: payload.value,
      });
    },

    sendPaymentRequest(context) {
      context.commit("UPDATE_LOADING_PAYMENT_DATA", true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("agenteAccessToken")}`,
        },
      };

      const finalRequest = {
        ...context.state.emissionRequest,
        ...(!context.state.emissionRequest.contractor && {
          ...context.state.driverData,
        }),
        agente_id: sessionStorage.getItem("agenteIdCotizacion"),
      };

      mainAxios
        .post("/v1/inter/emite/api-inter", finalRequest, config)
        .then((response) => {
          if (response.data.data.payment_data)
            context.commit("UPDATE_PAYMENT_DATA", {
              ...response.data.data.payment_data,
              polizaId: response.data.poliza_id,
            });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          context.commit("UPDATE_LOADING_PAYMENT_DATA", false);
        });
    },
  },
};

export default emissionStore;
