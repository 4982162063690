<template>
  <common-card>
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px;">
          <v-col cols="12" md="6">
            <h1 v-if="esAgentes">Asesores Digitales</h1>
            <h1 v-else>
              Usuarios
            </h1>
          </v-col>
          <v-col cols="12" md="6" style="text-align: end;">
            <v-btn
              class="common-botton"
              dark
              :loading="loading"
              @click="$router.push('/usuario/crear')"
              v-if="canViewCreate"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              <span>Crear</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels
      elevation="0"
      style="padding: 30px; border-radius: 20px"
    >
      <v-expansion-panel>
        <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            Búsqueda detallada
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                label="Buscar"
                hint="Buscar un agente por nombre, correo o RFC"
                class="mx-4 my-2"
                outlined
                dense
                color="primary"
                background-color="white"
                @keyup.enter="getAgent"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" style="text-align: end;">
              <v-btn
                class="common-botton"
                dark
                :loading="loading"
                @click="getAgent"
              >
                <span>Filtrar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <v-row>
      <v-col cols="12" class="mb-10" style="padding: 40px">
        <v-data-table
          style=""
          :headers="agentesTable"
          :items="currentUsers"
          item-key="name"
          class="elevation-0"
          :loading="loading"
          loading-text="Cargando..."
          no-data-text="Para ver elementos, primero realiza una búsqueda"
        >
          <template v-slot:top>
            <v-row style="padding: 15px;">
              <v-col cols="8">
                <h2>Resultados de la búsqueda</h2>
              </v-col>
              <!-- <v-col cols="4" style="text-align: end;">
                <download-csv :data="currentUsers" name="usuarios_reportes.csv">
                <v-btn color="transparent" elevation="0" small>
                  <span style="color:#0070C0;">Descargar</span>
                  <v-icon small right class="mr-2" color="#0070C0"
                    >mdi-file-pdf-box</v-icon
                  >
                </v-btn>
              </download-csv>
              </v-col> -->
            </v-row>
          </template>
          <template v-slot:item.sexo="{ item }">
            <div v-if="item.sexo == 1">Masculino</div>
            <div v-if="item.sexo == 2">Femenino</div>
          </template>
          <template #item.fechaRegistro="{item}">
            <span>{{ moment(item.fechaRegistro).format("DD/MM/YYYY") }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <div v-if="item.status == -1">
              <v-chip color="red" dark>Baja</v-chip>
            </div>
            <div v-if="item.status == 0">
              <v-chip color="blue" dark>Nuevo</v-chip>
            </div>
            <div v-if="item.status == 1">
              <v-chip color="green" dark>Activo</v-chip>
            </div>
            <div v-if="item.status == 2">
              <v-chip color="amber darken-2" dark>Inactivo</v-chip>
            </div>
          </template>
          <template v-slot:item.uuid="{ item }">
            <v-icon small class="mr-2" @click="editItem(item.uuid)">
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </common-card>
</template>

<script>
//import RfcFacil from 'rfc-facil';
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      moment,
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      search: "",
      agentesTable: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombreCompleto",
        },
        { text: "Correo Electronico", value: "correo" },
        { text: "Fecha de Registro", value: "fechaRegistro" },
        { text: "Activo", value: "status" },
        { text: "RFC", value: "rfc" },
        { text: "Ver Agente", value: "uuid" },
      ],
      report: [],
    };
  },

  watch: {
    $route() {
      this.getAgent();
    },
  },

  props:{
    // alianza_id:{
    //   type:String,
    //   default:null  
    // }
  },

  computed: {
    canViewCreate() {
      let canView = false;
      let roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      if (this.esAgentes) {
        return false;
      }
      const rolesToView = [
        "ADMIN",
        "ADMINAGENT",
        "ADMINCC",
        "DIRECTOR",
        "OPERACIONES",
        "MANAGERCC",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },

    esAgentes() {
      return this.$route.fullPath === "/usuarios/agentes";
    },

    noFiltrar() {
      const usuariosQueNoFiltran = ["ADMINAGENT", "MANAGER"];
      return usuariosQueNoFiltran.filter((e) =>
        JSON.parse(localStorage.getItem("roles")).find((z) => z.rol == e)
      ).length;
    },

    currentUsers() {
      var usuarios = [];
      var permitedRoles = [];
      var noAgentesDigitales = [];
      var AgenteSinCanales = [];
      if (window.location.pathname == "/usuarios/agentes") {
        permitedRoles = ["AGENT"];
        permitedRoles.forEach((rol) => {
          usuarios = this.agentes.filter(
            (e) =>
              e.rol === rol &&
              e.canalesList?.some((canal) => canal.canal === "AGENTES")
          );
        });
      }
      // else if(String(window.location.pathname).includes('alianza/perfil') && this.alianza_id){
      //     usuarios = this.agentes.filter((e) => e.rol == 'AGENT' && e.alianza_id == this.alianza_id);
      // }
      else {
        switch (this.rol) {
          case "ADMIN":
            permitedRoles = ["AGENT"];
            permitedRoles.forEach((rol) => {
              usuarios = this.agentes.filter((e) => e.rol != rol);
            });
            permitedRoles.forEach((rol) => {
              noAgentesDigitales = this.agentes.filter(
                (e) =>
                  e.rol == rol &&
                  e.canalesList?.some((canal) => canal.canal != "AGENTES")
              );
            });
            permitedRoles.forEach((rol) => {
              AgenteSinCanales = this.agentes.filter(
                (e) => e.rol == rol && e.canalesList.length == 0
              );
            });
            usuarios = [
              ...usuarios,
              ...noAgentesDigitales,
              ...AgenteSinCanales,
            ];
            break;
          case "ADMINAGENT":
            permitedRoles = ["MANAGER", "RECLUTA", "ADMINAGENT"];
            permitedRoles.forEach((rol) => {
              usuarios = this.agentes.filter((e) => e.rol == rol);
            });

            var canalAgentesNoAsesorDigital = this.agentes.filter(
              (e) =>
                e.rol != "AGENTES" &&
                e.canalesList?.some((canal) => canal.canal == "AGENTES")
            );

            usuarios = [...usuarios, ...canalAgentesNoAsesorDigital];
            break;
          case "ADMINCC":
            permitedRoles = [
              "AGENTCC",
              "AGENTCCSPONSORS",
              "MANAGERCC",
              "OPERADORCC",
              "RENOVACIONESCC",
            ];
            permitedRoles.forEach((rol) => {
              usuarios = usuarios.concat(this.agentes.filter((e) => e.rol == rol));
            });
            break;
          case "MANAGERCC":
            permitedRoles = [
              "AGENTCC",
              "AGENTCCSPONSORS",
              "OPERADORCC",
              "RENOVACIONESCC",
            ];
            permitedRoles.forEach((rol) => {
              usuarios = usuarios.concat(this.agentes.filter((e) => e.rol == rol));
            });
            break;
          case "OPERACIONES":
            permitedRoles = ["OPERADOR"];
            permitedRoles.forEach((rol) => {
              usuarios = usuarios.concat(this.agentes.filter((e) => e.rol == rol));
            });
            break;
          case "MANAGER":
            permitedRoles = ["AGENT"];
            permitedRoles.forEach((rol) => {
              usuarios = this.agentes.filter(
                (e) =>
                  e.rol == rol &&
                  e.canalesList?.some((canal) => canal.canal != "AGENTES")
              );
            });
            break;
          case "DIRECTO":
              usuarios = this.agentes.filter(
                (e) =>
                  e.canalesList?.some((canal) => canal.canal == "DIRECTO")
              );
            break;
          // case "AGENTCORP":
          //     permitedRoles = ["AGENT"];
          //         permitedRoles.forEach((rol) => {
          //           usuarios = this.agentes.filter((e) => e.rol == rol && e.alianza_id == this.alianza_id);
          //         });
          //   break;
          default:
            window.alert("No tienes permiso de ver usuarios");
            usuarios = [];
            break;
        }
      }
      return usuarios;
    },
  },

  methods: {
    getAgent() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const body = {
        filtroBusqueda: this.search,
      };
      mainAxios
        .post(`/v1/agente/list-filtro`, body, config)
        .then((response) => {
          this.agentes = [];
          this.report = [];
          this.loading = false;
          response.data.map((element) => {
            this.agentes.push({
              nombreCompleto: element.nombreCompleto
                ? element.nombreCompleto
                : "No disponible",
              correo: element.correo ? element.correo : "No disponible",
              fechaRegistro: element.fechaRegistro
                ? element.fechaRegistro
                : "No disponible",
              status: element.status ? element.status : "No disponible",
              rfc: element.rfc ? element.rfc : "No disponible",
              uuid: element.uuid ? element.uuid : "No disponible",
              rol: element.rol ? element.rol : "No disponible",
              canalesList: element.canalesList ? element.canalesList : [],
             // alianza_id: element.alianzaId || null
            });
          });
        });
    },
    editItem(item) {
      console.log(item);
      this.$router.push(`/usuario/ver-micuenta/${item}`);
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
  },
  mounted() {
    this.getAgent();
  },
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
</style>
