<template>
  <v-col
    lg="6"
    cols="12"
  >
    <common-card class="mb-5" style="height: 21rem">
      <v-row style="padding: 32px">
        <v-col cols="12">
          <v-row>
            <v-col cols="2">
              <img
                src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Vector-money.png"
                alt=""
                height="50"
              />
            </v-col>
            <v-col cols="10" class="mt-2">
              <h2>Cotizaciones</h2>
              <span>
                Crea cotizaciones rápidas y con una gran variedad de opciones.
              </span>
            </v-col>
            <v-col cols="12">
              <common-carousel :items="itemsCarousel" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </common-card>
  </v-col>
</template>

<script>
const moment = require("moment");
import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-inter-horizontal.png";
import CommonCard from "../../components/commonComponents/CommonCard.vue";
import CommonCarousel from "../../components/commonComponents/CommonCarousel.vue";
import dashboardJson from "../../helpers/data/data-dashboard-template.json";
export default {
  components: { CommonCard, CommonCarousel },
  data() {
    return {
      userID: localStorage.agenteMail,
      itemsCarousel: [
        {
          icono: dashboardJson.iconPlus,
          texto: "MEDICO SEGURO",
          src: "/cotizacion/medico",
        },
        {
          icono: dashboardJson.iconCar,
          texto: "COCHE SEGURO",
          src: "/cotizacion/vehiculos",
        },
        {
          icono: dashboardJson.iconPet,
          texto: "MASCOTA SEGURA",
          src: "/cotizacion/mascota",
        },
        // {
        //   icono: dashboardJson.iconPhone,
        //   texto: "CELULAR SEGURO",
        //   src: "/cotizacion/celular",
        // },
      ],
      banner: 0,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      banners: [
        //New banners
        {
          id: 1,
          url: "segurify_banner_portalagentes_2450x400_ene2023_060123_AIG.jpg",
        },
        {
          id: 2,
          url: "segurify_banner_portalagentes_2450x400_ene2023_060123_GNP.jpg",
        },
        {
          id: 3,
          url: "segurify_banner_portalagentes_2450x400_ene2023_060123_HDI.jpg",
        },
        {
          id: 4,
          url:
            "segurify_banner_portalagentes_2450x400_ene2023_060123_qualitas.jpg",
        },

        //{ id: 1, url: 'segurify_banner_portalagentes_2450x400_190522_gnp2.jpg' },
        //{ id: 2, url: 'segurify_banner_portalagentes_2450x400_190522_aig.jpg' },
        //{ id: 3, url: 'segurify_banner_portalagentes_2450x400_190522_qualitas.jpg' },
        // { id: 1, url: 'SEGURIFY_banner_2449x500_PortalAgentes_031221.jpeg' },
        // { id: 2, url: 'SEGURIFY_banner_2449x500_BuenFin_051121_Qualitas (2).jpeg' },
        //{id: 1, url: 'SEGURIFY_banner_2449x500_BlackFriday_171121_GNP.png'},
        // {id: 1, url: 'SEGURIFY_banner_2449x500_BuenFin_091121_GNP.jpg'},
        //{id: 4, url: 'SEGURIFY_PortalAgentes_001_bienvenida.jpg'},
        //{id: 5, url: 'SEGURIFY_PortalAgentes_002_proceso.jpg'},
        //{id: 6, url: 'SEGURIFY_PortalAgentes_003_control.jpg'},
        // {id: 7, url: 'SEGURIFY_promo_AIG_05:07:21.jpg' },
      ],
      icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
      items: [
        {
          title: "Cotiza",
          text: "Cotiza con nuestras aseguradoras",
          route: "cotiza-hub",
        },
        {
          title: "Mis Emisiones",
          text:
            "Aquí guardaremos el detalle de tus cotizaciones para que lleves un mejor control de tus ventas de auto.",
          route: "mis-emisiones",
        },
        {
          title: "Mis Cotizaciones",
          text: "¿Deseas retomar una cotización? Desde aquí puedes hacerlo.",
          route: "mis-cotizaciones",
        },
        // {
        //   title: "Solicitudes",
        //   text: "¿Tienes alguna solicitud para nuestro equipo de operaciones? Desde aquí puedes hacerlo.",
        //   route:"solicitudes"
        // },
      ],
      transparent: "rgba(255, 255, 255, 0)",
      value: [423, 446, 675, 510, 590, 610, 760],
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
      year: moment().year(),
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
    };
  },
  methods: {
    verCotizaciones() {
      this.$router.push("/cotizacion-hub-seguros");
      // this.$router.push('/cotiza-hub')
    },
    verPolizas() {
      this.$router.push("/mis-emisiones");
    },
    verSolicitudes() {
      this.$router.push("/solicitudes");
    },
    verComisiones() {
      this.$router.push("/lista-comisiones");
    },
    verCuotas() {
      this.$router.push("/cuotas-hub");
    },
    verClientes() {
      this.$router.push("/lista-clientes");
    },
    verLandingCelular() {
      this.$router.push("/landing-celular");
    },
    solicitudesDataLayer(text) {
      window.dataLayer.push({
        event: "ITFY_hub_solicitudes",
        opciones: text,
        userID: localStorage.getItem('agenteMail'),
      });
    },
  },
  computed: {
    mostrarCardComision() {
      if (
        this.rol != "AGENT" ||
        this.rol != "AGENTCC" ||
        this.rol != "MANAGERCC" ||
        this.rol != "ADMINCC"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    console.log(localStorage);
    window.dataLayer.push({
      event: 'ITFY_hub_v',
      no_cotizacion: undefined,
      userID: localStorage.agenteMail,
      correo: undefined,
      telefono: undefined,
    });
  },
};
</script>

<style scoped>
.banner-home {
  width: 100%;
}
.card {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: white;
  border-radius: 15px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}
.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid black;
  background-color: rgba(21, 101, 192, 1);
}
.card:hover:before {
  transform: scale(6.15);
}
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.cardsHome:hover {
  border: 1px solid #133b7d;
}
.cardsHome {
  border: 1px solid transparent;
}
.cardsHome:hover .cardAccionHome {
  background: #133b7d;
}

.cursor {
  cursor: pointer;
}
</style>