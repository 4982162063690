<template>
  <v-row id="login-background" class="d-flex align-md-center justify-md-center">
    <v-col cols="12">
      <v-container>
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="12"
            md="3"
            xl="2"
            class="d-flex justify-center align-md-center"
          >
            <v-img
              class="responsive mx-5"
              contain
              max-height="150"
              max-width="300"
              :src="LogoInter"
            ></v-img>
          </v-col>
          <v-col cols="12" md="9" xl="10">
            <div v-if="!cargando">
              <v-card
                style="width:100%; padding: 16px;border-radius: 20px; min-height: 500px"
              >
                <v-img
                  v-show="!$vuetify.breakpoint.mdAndUp"
                  class="responsive mx-5 mt-3"
                  contain
                  max-height="150"
                  max-width="300"
                  :src="LogoInter"
                ></v-img>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-stepper v-model="step">
                        <v-stepper-header>
                          <v-stepper-step
                            class="stepper-font-size"
                            :complete="step > 1"
                            step="1"
                          >
                            <div
                              class="icon-text cursor-pointer"
                              @click="isNextStep(false, 1)"
                            >
                              <v-icon v-if="isStepCompleted(1)"
                                >mdi-check</v-icon
                              >
                              <v-icon v-else>mdi-circle-outline</v-icon>
                              <span>Datos personales</span>
                            </div>
                          </v-stepper-step>
                          <v-divider></v-divider>
                          <v-stepper-step
                            class="stepper-font-size"
                            :complete="step > 2"
                            step="2"
                          >
                            <div
                              class="icon-text cursor-pointer"
                              @click="isNextStep(false, 2)"
                            >
                              <v-icon v-if="isStepCompleted(2)"
                                >mdi-check</v-icon
                              >
                              <v-icon v-else>mdi-circle-outline</v-icon>
                              <span class="text-left">Datos de contacto</span>
                            </div>
                          </v-stepper-step>
                          <v-divider></v-divider>
                          <v-stepper-step class="stepper-font-size" step="3">
                            <div class="icon-text">
                              <v-icon v-if="isStepCompleted(3)"
                                >mdi-check</v-icon
                              >
                              <v-icon v-else>mdi-circle-outline</v-icon>
                              <span class="text-left">Crear contraseña</span>
                            </div>
                          </v-stepper-step>
                        </v-stepper-header>
                      </v-stepper>
                    </v-col>
                  </v-row>
                  <datos-personales
                    @isNextStep="isNextStep"
                    v-if="step === 1"
                    :createAccount="createAccount"
                    :rfcBad="rfcBad"
                  />
                  <datos-contacto
                    @isNextStep="isNextStep"
                    v-if="step === 2"
                    :contactInformation="contactInformation"
                    :emailsRepeat="emailsRepeat"
                    :phoneRepeat="phoneRepeat"
                    :telRepeat="telRepeat"
                  />
                  <crear-contrasenia
                    v-if="step === 3"
                    :password="password"
                    @insert="insert"
                  />
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <v-card
                style="width:100%; padding: 16px;border-radius: 20px; min-height: 500px"
                class="d-flex align-md-center justify-md-center"
              >
                <v-card-text class="text-center">
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex align-md-center justify-md-center"
                    >
                      <v-img
                        class="responsive"
                        contain
                        max-height="150"
                        max-width="300"
                        :src="loginTemplate.createAccount"
                      ></v-img>
                    </v-col>
                    <v-col cols="12">
                      <h1>Iniciando sesión...</h1>
                      <span>Espere un momento por favor</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import loginData from "../../../helpers/data/data-login-template.json";
import CrearContrasenia from "./CrearContrasenia.vue";
import DatosContacto from "./DatosContacto.vue";
import DatosPersonales from "./DatosPersonales.vue";
import { axiosLogin } from "../../../axiosLogin";
import { mainAxios } from "@/mainAxios.js";
import LogoInter from "@/assets/interify.svg";
export default {
  name: "CreacionCuenta",
  components: {
    DatosPersonales,
    DatosContacto,
    CrearContrasenia,
  },
  data() {
    return {
      cargando: false,
      step: 1,
      loginTemplate: loginData,
      prevStep: 0,
      createAccount: {
        name: "",
        surname: "",
        lastname: "",
        birthday: "",
        placeBirth: 0,
        sex: "",
        isRfc: true,
        generatedRfc: "",
        homoclave: "",
      },
      contactInformation: {
        phone: "",
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        correo: "",
        tel: "",
      },
      password: "",
      emailsRepeat: [],
      phoneRepeat: "",
      telRepeat: "",
      LogoInter: LogoInter,
      rfcBad: false,
    };
  },
  methods: {
    isNextStep(isNext, value) {
      this.prevStep = this.step;
      if (isNext) {
        this.step++;
      } else if (value <= this.step) {
        this.step = value;
      }
    },
    isStepCompleted(stepNumber) {
      return this.step > stepNumber;
    },
    insert(password) {
      const dataPetition = {
        apellidoMaterno: this.createAccount.lastname,
        apellidoPaterno: this.createAccount.surname,
        celular: this.contactInformation.tel,
        correo: this.contactInformation.correo,
        correos: this.contactInformation.emails,
        fechaNacimiento: this.createAccount.birthday,
        nacimientoEstadoId: this.createAccount.placeBirth,
        nombre: this.createAccount.name,
        password: password,
        rfc: `${this.createAccount.generatedRfc}${this.createAccount.homoclave}`,
        sexo: this.createAccount.sex,
        telefono:this.contactInformation.phone ,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + 'dd8ab065-8a04-41fe-a6f3-ce8bc193d821',
        }
      }
      this.cargando = true;
      mainAxios
        .post("/v1/prospecto/crear_cuenta", dataPetition, config)
        .then(({ data: { data, message, status } }) => {
          if (status == 200 && message === "Datos del prospecto creado") {
            this.password = password;
            this.login();
          } else {
            if (data.error === "emailsExits") {
              this.cargando = false;
              this.emailsRepeat = data.correos;
              this.phoneRepeat = data.celular;
              this.telRepeat = data.telefono;
              this.step = 2;
            } else {
              console.log("error");
              this.cargando = false;
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.cargando = false;
            if (error.response.data.message) {
                this.step = 2;
                this.$toast.warning(error.response.data.message,
                  {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  }
                );
              }
          }
        });
    },
    login() {
      var qs = require("qs");
      var vm = this;
      vm.error = false;
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: "admin",
          password: "g6PqgxyB0Z",
        },
      };
      var data = qs.stringify({
        grant_type: "password",
        username: this.contactInformation.correo,
        password: this.password,
      });
      axiosLogin
        .post("/oauth/token", data, config)
        .then((value) => {
          localStorage.agenteAccessToken = value.data.access_token;
          const configA = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          this.getUser(configA);
        })
        .catch((err) => {
          console.log(err);
          vm.error = true;
          vm.cargando = false;
          this.error = true;
        });
    },
    getUser(configA){
        axiosLogin.get("/user", configA).then((value) => {
            localStorage.id = value.data.principal.id
            localStorage.agenteName =value.data.principal.nombre + " " +
            value.data.principal.apellidoPaterno;
            localStorage.agenteUuid = value.data.principal.uuid;
            localStorage.agenteMail = this.contactInformation.correo;
            if(value.data.authorities[3].authority == 'PROSPECTO'){
              this.roles = [
              {
                  "id": null,
                  "uuid": null,
                  "rol": "PROSPECTO",
                  "nombre": 'Prospecto',
                  "canal": null,
                  "informa_a": 'ADMINCC',
                  "nivel": null,
                  "notas": null,
                  "agente_id": null
              }]
              this.changeView()
            }else{
              this.getRoles(configA)
            }
          });
    },
    getRoles(configA) {
      mainAxios.get("/v1/agente/roles", configA).then((value) => {
        this.roles = value.data;
        this.changeView();
      });
    },
    changeView() {
      var vm = this;
      localStorage.setItem("roles", JSON.stringify(this.roles));
      if (Object.keys(this.$route.query).length === 0) {
        switch (this.roles[0].rol) {
          case "ADMINCC":
          case "AGENTCCSPONSORS":
            vm.$router.push({ path: "/leads" });
            break;
          case "PROSPECTO":
            vm.$router.push({ path: "/inicio-prospectos" });
            break;
          default:
            vm.$router.push({ path: "/inicioVista" });
            break;
        }
      } else {
        vm.$router.push({ path: `${this.$route.query.from}` });
      }
    },
  },
  computed: {
    transitionName() {
      return this.stepIncreasing ? "slide-fade-right" : "slide-fade-left";
    },
    stepIncreasing() {
      return this.step > this.prevStep;
    },
  },
};
</script>

<style scoped>
#login-background {
  background-size: 32px 32px;
  background-color: #f6f5f9;
  background-repeat: repeat;
}
@media (max-width: 500px) {
  .responsive {
    max-width: 300px !important;
    max-height: 200px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
