<template>
  <v-app>
    <Navigation></Navigation>
      <v-main class="mb-15">
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <h1 
                style="padding: 20px"
              >Clientes({{ clientes.length }})</h1>
            </v-col>
            <v-col cols="6">
                <v-btn
                    @click="goBack()" 
                    elevation="2"
                    style="margin-left: 527px; margin-top: 20px"
                ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon> Regresar
                </v-btn>
            </v-col>
          </v-row>

          <v-row class="mb-15" style="padding: 30px">
              <!-- <v-col cols="3" md="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                ></v-date-picker>
              </v-col> -->
              <v-col cols="12">
                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="clientes"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                  :loading="loading"
                  loading-text="Cargando..."
                >
                  <template v-slot:top>
                    <v-row>
                      <v-col cols="11">
                        <v-text-field
                          v-model="search"
                          label="Buscar"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" class="mt-4">
                        <v-btn color="primary" small @click="crearComision()">
                          Crear
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.uuid="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="verCliente(item.uuid)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
        </v-container>
      </v-main>
  </v-app>
</template>

<script>
// import moment from 'moment';
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from '../../../mainAxios';
export default {
  components: {
      Navigation,
  },
  data() {
    return {
      agenteUuid: localStorage.getItem('agenteUuid'),
      clientes: [],
      reportDownload: [],
      search: '',
      columns: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'No. Clientes', value: 'id' },
        { text: 'Status del Cliente', value: 'status' },
        { text: 'Régimen', value: 'regimen' },
        { text: 'Agente', value: 'agente' },
        { text: 'Creado', value: 'creado' },
        { text: 'Comercial Asignado', value: 'comercial' },
        { text: 'Ver', value: 'uuid' },
      ],
    }
  },
  mounted() {
    this.getComisiones();
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    getComisiones() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      mainAxios
      .get(`/v1/cliente/listaClientes`, config)
        .then((response) => {
          console.log("Clientes: ", response.data);
          response.data.map(element => {
            this.clientes.push({
              nombre: `${element.nombre}` + ' ' + `${element.apellidoPaterno}` + ' ' + `${element.apellidoMaterno}`,
              id: element.id,
              status: element.status ? element.status : 'No Disponible',
              regimen: element.regimen ? element.regimen : 'No Disponible',
              agente: element.agenteId ? element.agenteId : 'No Disponible',
              creado: 'No Disponible',
              comercial: 'No Disponible',
              uuid: element.uuid
            })
          })
        })
    },
    verCliente(uuid) {
     this.$router.push(`/visualizacion-clientes/${uuid}`)
    },
    crearComision() {
      this.$router.push('/registro-comisiones')
    },
  },
}
</script>

<style>

</style>