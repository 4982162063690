<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="2" fab dark color="blue" large v-bind="attrs" v-on="on"
                    style="position: fixed; bottom: 30px; right: 10px; z-index: 5" @click="dialogHelp = !dialogHelp">
                    <v-icon>mdi-help</v-icon>
                </v-btn>
            </template>
            <span>¿Necesitas Ayuda?</span>
        </v-tooltip>

        <!-- Dialog Help -->
        <v-dialog v-model="dialogHelp" max-width="500px">
            <v-card dense>
                <v-card-text style="padding: 20px" v-if="ayudaEnviada">
                    <h3>En breve te contactaremos para brindarte ayuda</h3>
                </v-card-text>

                <v-card-text style="padding: 20px" v-if="!ayudaEnviada">
                    <h3>¿Tienes algún problema al realizar tu cotización?</h3>
                    <v-textarea style="margin-top: 20px" outlined name="input-7-1" v-model="detalle"
                        label="¿Cómo podemos ayudarte?" hint="Describe tu problema brevemente"></v-textarea>
                    <p>El teléfono que tenemos registrado para contactarte es: <br />
                        <b>** ** **</b>
                    </p>
                    <p>
                        Si deseas ser contactado en otro teléfono actualiza tu perfil o
                        ingresa el teléfono a continuación
                    </p>
                    <v-text-field name="name" label="Teléfono" v-model="telefonoContacto" single-line
                        v-mask="'##########'" />

                    <p>O puedes comunicarte al teléfono <b>5588770545</b> o bien, al correo electrónico:
                        <b>operaciones@interify.com</b>
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-if="!ayudaEnviada" :disabled="enviandoAyuda" @click="sendAyuda">
                        Enviar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Dialog Help -->
    </div>
</template>

<script>
import { mainAxios } from '../../../mainAxios';
import moment from 'moment';
export default {
    data: function () {
        return {
            dialogHelp: false,
            ayudaEnviada: false,
            enviandoAyuda: false,
            detalle: null,
            telefonoContacto: null,
            agente: null,
        }
    }, async mounted(){
        await this.getUser();
    }, methods: {
        async getUser() {
            try {
                let config = {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.agenteAccessToken,
                    },
                };
                let { data } = await mainAxios.get(`/v1/agente/uuid/${localStorage.agenteUuid}`, config);
                this.agente = data
            } catch (exception) {
                console.error(exception);
            }
        },
        sendAyuda() {
            this.enviandoAyuda = true;
            let config = {
                headers: {
                    Authorization: 'Bearer ' + localStorage.agenteAccessToken,
                },
            };
            if (this.telefonoContacto == null) {
                return false;
            }

            let request = {
                nombre: this.agente.nombre,
                apellidoPaterno: this.agente.apellidoPaterno,
                apellidoMaterno: this.agente.apellidoMaterno,
                celular: this.agente.celular,
                telefono: this.telefonoContacto,
                detalle: this.detalle,
                portal: 'celular',
                paso: 1,
                fecha_lead: moment().format('YYYY-MM-DD'),
                hora_lead: moment().format('hh:mm:ss')
            };

            mainAxios.post('v1/lead/ayuda/agente/' + localStorage.agenteUuid,
                request,
                config
            ).then((value) => {
                console.log(value);
                this.ayudaEnviada = true;
            }).catch((exception) => {
                console.log(exception);
                this.ayudaEnviada = false;
            });
        },
    }
}
</script>

