<template>
  <div>
    <h2 class=" mt-5 mb-1">Crear cuenta</h2>
    <span>Ingresa los datos personales del asesor digital</span>
    <commond-form
      :request="createAccount"
      :run="run"
      :onlyValidations="true"
      @cancel="run = false"
    >
      <template v-slot:data>
        <v-row class="mt-3">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="createAccount.name"
              label="Nombre"
              required
              outlined
              :rules="requiredRule"
            ></v-text-field>
            <v-text-field
              v-model="createAccount.surname"
              label="Apellido paterno"
              outlined
              :rules="requiredRule"
            ></v-text-field>
            <v-text-field
              v-model="createAccount.lastname"
              label="Apellido materno"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="createAccount.birthday"
              type="date"
              label="Fecha de nacimiento"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="createAccount.placeBirth"
              :items="states"
              item-text="dEstado"
              item-value="cEstado"
              label="Lugar de nacimiento"
              :rules="requiredRule"
              outlined
            ></v-autocomplete>
            <v-row align="center">
              <v-col cols="12" md="3">
                <span>Género</span>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="d-flex justify-center justify-md-end align-end"
              >
                <v-radio-group
                  v-model="createAccount.sex"
                  row
                  :rules="requiredRule"
                >
                  <v-radio label="Femenino" value="1"></v-radio>
                  <v-radio label="Masculino" value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="7" md="3">
                <span>¿Tu RFC es correcto?</span>
              </v-col>
              <v-col cols="5" md="9" class="d-flex justify-end">
                <v-checkbox
                  class="mr-3"
                  label="Si"
                  :input-value="createAccount.isRfc"
                  @change="createAccount.isRfc = true"
                ></v-checkbox>
                <v-checkbox
                  label="No"
                  :input-value="!createAccount.isRfc"
                  @change="createAccount.isRfc = false"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-4">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="createAccount.generatedRfc"
                  :readonly="createAccount.isRfc"
                  placeholder="RFC"
                  outlined
                  :rules="requiredRule"
                  @input="onInputToUpper"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="createAccount.homoclave"
                  placeholder="Agrega homoclave"
                  outlined
                  :rules="homoclaveRule"
                  @input="onInputToUpperHomoclave"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="rfcBad">
                <v-alert type="warning" dense dismissible>
                  RFC incorrecto
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex justify-center justify-md-end">
            <v-btn
              color="#00A7E4"
              dark
              class="rounded-xl"
              @click="nextStep"
              :disabled="!allFieldsValidated()"
              width="150px"
            >
              Siguiente
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "../../../components/commonComponents/CommondForm.vue";
import { mainAxios } from "@/mainAxios.js";
import RfcFacil from "rfc-facil";

export default {
  name: "DatosPersonales",
  components: { CommondForm },
  props: { createAccount: Object, rfcBad: Boolean },
  data() {
    return {
      cargando: false,
      requiredRule: [(v) => !!v || "El campo es requerido"],
      homoclaveRule: [
        (v) =>
          (!!v && v.length === 3 || !!v && v.length === 2) ||
          "Debe ser requerido y tener exactamente 3 o 2 caracteres",
      ],
      run: false,
      states: [],
    };
  },
  methods: {
    updateSelection(value) {
      this.createAccount.isRfc = [value];
    },
    generateRfc() {
      if (
        this.createAccount.name &&
        this.createAccount.surname &&
        this.createAccount.lastname &&
        this.createAccount.birthday
      ) {
        const arregloNacimiento = this.createAccount.birthday
        .split("-")
        .map((e) => Number(e));

        const rfc = RfcFacil.forNaturalPerson({
          name: this.createAccount.name,
          firstLastName: this.createAccount.surname,
          secondLastName: this.createAccount.lastname,
          day: arregloNacimiento[2],
          month: arregloNacimiento[1],
          year: arregloNacimiento[0],
        });
        this.createAccount.generatedRfc = rfc.substring(0, rfc.length - 3);
        this.createAccount.homoclave = rfc.substring(10, rfc.length);
      }
    },
    nextStep() {
      this.$emit("isNextStep", true);
      window.dataLayer.push({
        event: "ITFY_datos_personales",
        fecha_nacimiento: this.createAccount.birthday.split("-").reverse().join("/"),
        sexo: this.createAccount.sex == 1 ? "Femenino" : "Masculino",
        boton_contacto: "Siguiente",
      })
    },
    allFieldsValidated() {
      const fieldsToCheck = [
        this.createAccount.name,
        this.createAccount.surname,
        this.createAccount.lastname,
        this.createAccount.birthday,
        this.createAccount.placeBirth,
        this.createAccount.sex,
        this.createAccount.generatedRfc,
        this.createAccount.homoclave,
      ];

      const validations = [
        this.requiredRule[0](this.createAccount.name),
        this.requiredRule[0](this.createAccount.surname),
        this.requiredRule[0](this.createAccount.lastname),
        this.requiredRule[0](this.createAccount.birthday),
        this.requiredRule[0](this.createAccount.placeBirth),
        this.requiredRule[0](this.createAccount.sex),
        this.requiredRule[0](this.createAccount.generatedRfc),
        this.homoclaveRule[0](this.createAccount.homoclave),
      ];

      return (
        fieldsToCheck.every((field) => !!field) &&
        validations.every((validation) => validation === true)
      );
    },
    getStates() {
      const config = {
        headers: {
          Authorization: "Bearer 7af112f1-963e-4c4b-bca9-50e45fccd00a",
        },
      };
      mainAxios.get("/v1/catalogo/estado", config).then((response) => {
        this.states = response.data;
      });
    },
    onInputToUpper() {
      this.createAccount.generatedRfc = this.createAccount.generatedRfc.toUpperCase();
    },
    onInputToUpperHomoclave() {
      this.createAccount.homoclave = this.createAccount.homoclave.toUpperCase();
    },
  },
  mounted() {
    this.getStates();
    window.dataLayer.push({
      event: 'ITFY_datos_personales_v',
      no_cotizacion: undefined,
      userID: undefined,
      correo: undefined,
      telefono: undefined,
    });
  },
  watch: {
    "createAccount.name": "generateRfc",
    "createAccount.surname": "generateRfc",
    "createAccount.lastname": "generateRfc",
    "createAccount.birthday": "generateRfc",
  },
};
</script>
<style scoped></style>
