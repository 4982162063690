<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn rounded dark class="common-botton" @click="changeTab">
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Periodicidad de pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.pago_id
              ? getPeridicidad(polizaInfo.pago_id)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Número de cuotas
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.numeroCuotas ? polizaInfo.numeroCuotas : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Años
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.anios ? polizaInfo.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Forma de Pago
        </span>
        <br />
        <span class="text-data-details">
          {{ polizaInfo.anios ? polizaInfo.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Prima neta
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.prima_neta
              ? currencyFormatt(polizaInfo.prima_neta)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Prima total
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.prima_total
              ? currencyFormatt(polizaInfo.prima_total)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Monto de Primer Pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.primer_pago
              ? currencyFormatt(polizaInfo.primer_pago)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Monto de Pago Subsecuente
        </span>
        <br />
        <span class="text-data-details">
          {{
            polizaInfo.subsecuente_pago
              ? currencyFormatt(polizaInfo.subsecuente_pago)
              : "No tiene"
          }}
        </span>
      </v-col>
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
// import moment from "moment";
// import { mainAxios } from "@/mainAxios.js";

export default {
  components: {},
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      infoCards: {},
    };
  },
  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
    getFormaDePagoName(val) {
      switch (val) {
        case 1:
          return "Tarjeta de Crédito";
        case 2:
          return "Tarjeta de Débito";
        case 3:
          return "Depósito en Banco";
        case 4:
          return "Transferencia";
        case 5:
          return "Cheque";
        case 6:
          return "Efectivo";
        case 7:
          return "Domiciliación";
        case 8:
          return "Cargo Único";
        default:
          return "Sin llenar";
      }
    },
    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });
      return formatoMonedaMXN.format(val);
    },
    getPeridicidad(val) {
      switch (val) {
        case 1:
          return "Anual";
        case 2:
          return "Semestral";
        case 3:
          return "Trimestral";
        default:
          return "Mensual";
      }
    },
    changeTab() {
      this.$emit("toEdit");
    },
  },
};
</script>
