import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken, getInterToken } from "./tokens.service";

export const validateVin = async (vin, aseguradora) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  try {
    return await mainAxios.get(`/v1/coche/vin/validar/${vin}/${aseguradora}`, config);
  } catch (error) {
    throw new Error("Error: Número de serie no válido o en uso de otra póliza");
  }
};

export default validateVin;
