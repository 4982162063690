<template>
    <div>
        <v-card
            class="mx-auto"
            color="white"
            width="100%"
            style="border-radius: 20px; box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;"
            elevation="0"
            >
            <v-card-title @click="showAll" v-if="!openAll" style="justify-content: center; cursor: pointer;">
                <v-img 
                    :src="docsImg" 
                    max-width="29"
                    alt="">
                </v-img>
                <br>
            </v-card-title>
            <v-card-title  v-if="openAll" style="cursor: pointer;">
                <v-row>
                    <v-col cols="10" @click="showAll">
                        <v-row>
                            <v-col cols="2">
                                <v-img 
                                    :src="docsImg" 
                                    max-width="25"
                                    he
                                    alt="">
                                </v-img>
                            </v-col>
                            <v-col cols="10">
                                <span>
                            Docs
                        </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                    v-if="canEdit"
            cols="2"
            @click="OpenDialogCreateDocument = true"
            style="text-align: end;"
          >
            <v-img :src="AddIcon" alt="" style="width: 50px;"> </v-img>
          </v-col>
                </v-row>

            </v-card-title>

            <v-card-text style="text-align: center;" v-if="!openAll  ">
                <span style="
                    font-size: 1.25rem;
                    font-weight: 500;
                    letter-spacing: 0.0125em;
                    line-height: 2rem;
                    word-break: break-all;
                    color: rgba(0, 0, 0, 0.87);
                "> 
                Docs
                </span>
            </v-card-text>
            <v-card-text v-if="openAll">
                <div v-if="docs.length > 0" style="height: 180px !important;">
                    <!-- <span class="gray--text gray--lighten-2">
                    Lista
                    </span> -->
                    <v-row v-for="(doc, idx) in docs.slice(0, 3)" :key="idx">
                        <v-col cols="8" style="align-self: center;">
                            <h4 >
                                {{ doc.nombre }}
                            </h4>
                        </v-col>
                        <v-col cols="4" style="text-align: end;">
                            <v-icon
                                left
                                color="#00a7e4"
                                size="26"
                                style="cursor: pointer;"
                                @click="OpenDialogShowDocs = true"
                            >
                                mdi-eye-outline
                            </v-icon>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="docs.length > 0">
                    <v-row style="text-align: center;">
                        <v-col cols="12">
                        <v-btn
                            elevation="0"
                            color="white"
                            @click="OpenDialogShowDocs = true"
                        >
                        <span style="color:#0070C0">
                            ver mas
                        </span>
                        </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div v-else style="height: 216px !important; text-align: center;" >
                    <v-row style="text-align: center;">
                        <v-col cols="12">
                            <h3>    
                                No hay nada por el momento
                            </h3>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <create-document-dialog
            :OpenDialogCreateDocument="OpenDialogCreateDocument"
            :routesConfigDocsCard="routesConfigDocsCard"
            @closeModal="OpenDialogCreateDocument = false"
            @success="getData">
        </create-document-dialog>
        <show-documents-dialog
            :docs="docs"
            :OpenDialogShowDocs="OpenDialogShowDocs"
            @closeModal="OpenDialogShowDocs = false"
            @openCreateModal="OpenDialogCreateDocument = true"
        >
        </show-documents-dialog>
    </div>
</template>

<script>
import CreateDocumentDialog from '../dialogs/CreateDocumentDialog.vue';
import ShowDocumentsDialog from '../dialogs/ShowDocumentsDialog.vue';
import docs from "@/assets/docs.svg";
import addIcon from "@/assets/addIcon.svg";



export default {
  components: {
    CreateDocumentDialog,
    ShowDocumentsDialog
  },

  data() {
    return {
        showStatus: false,
        docsArray:[],
        OpenDialogCreateDocument: false,
        OpenDialogShowDocs:false,
        docsImg: docs,
        AddIcon: addIcon,
    };
  },
  props:{
    docs: Array,
    routesConfigDocsCard: Object,
    openAll: {
        type: Boolean,
        default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    }

  },
  watch:{
    openAll(val){
        this.showStatus = val
    }
  },

  methods: {
    getData(){
        this.OpenDialogCreateDocument = false;
        this.$emit("getData")
    },
    showAll(){
        this.$emit('showAll', !this.showStatus)
    }
  }
};
</script>

<style>
  .rounded-card{
    border-radius: 20px;
    max-width:"100%";
    background-color: white;
}

.mi-clase-con-sombra {
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;
    border-radius: 20px;
}
