<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
    <template v-slot:data>
      <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Tipo de Inmueble"
          v-model="formData.tipoInmueble"
          :items="tipoInmuebleList"
          item-text="name"
          item-value="id"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.tipoInmueble == 'Otro'">
        <v-text-field
          label="Especificar Tipo de inmueble"
          v-model="formData.otroTipoInmueble"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Valor del inmueble"
          v-model="formData.valorInmueble"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <!-- Solo si es departamente -->
      <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.tipoInmueble == 'Departamento'">
        <v-text-field
          label="Nivel del departamento"
          v-model="formData.nivelDepartamento"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.tipoInmueble == 'Departamento'">
        <v-text-field
          label="Pisos Totales"
          v-model="formData.pisosTotales"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        >
        </v-text-field>
      </v-col>
      <!-- Solo si es Casa -->
      <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.tipoInmueble == 'Casa'">
        <v-text-field
          label="Número de pisos altos"
          v-model="formData.numeroPisosAltos"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.tipoInmueble == 'Casa'">
        <v-text-field
          label="Sotanos"
          v-model="formData.sotanos"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Valor de los contenidos"
          v-model="formData.valorContenidos"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Valor del equipo electrónico"
          v-model="formData.valorEquipoElectronico"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada Responsabilidad Civil"
          v-model="formData.sumaAseguradaRC"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada Cristales"
          v-model="formData.sumaAseguradaCristales"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada robo con violencia "
          v-model="formData.sumaAseguradaRoboViolencia"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada dinero y valores"
          v-model="formData.sumaAseguradaDineroValores"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Suma asegurada obras de arte o piezas de difícil reposición"
          v-model="formData.sumaAseguradaObrasDeArte"
          prefix="$"
          v-number-only
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
    </template>
  </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components:{
    CommondForm
  },
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        id:null,
        tipoInmueble: null,
        otroTipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },
      //listas
      tipoInmuebleList: ["Casa", "Departamento", "Bodega", "Edificio", "Otro"],
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
    };
  },
  mounted(){
    this.fillData()
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.ramoData[e]) {
          this.formData[e] = this.ramoData[e];
        }
      });
    },
  },
};
</script>
