var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',{staticClass:"pt-5 pb-5"},[_c('v-col',{attrs:{"cols":"10"}},[_c('h2',[_vm._v(" Detalle de la solicitud ")])]),_c('v-col',{staticStyle:{"text-align":"end"},attrs:{"cols":"2"}},[_c('h2',[_vm._v("#"+_vm._s(_vm.uuid))])])],1)],1),_c('info-card-solicitudes',{attrs:{"infoCards":_vm.infoCards,"emailRoutesConfig":_vm.emailRoutesConfig},on:{"getData":_vm.updateView}}),_c('div',[_c('v-row',{staticStyle:{"padding-top":"60px"}},[_c('v-col',{class:_vm.tabReplace == 1
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"4"},on:{"click":function($event){return _vm.tabReplaceSelect(1)}}},[_c('span',{class:_vm.tabReplace == 1
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Solicitud")])]),_c('v-col',{class:_vm.tabReplace == 2
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"4"},on:{"click":function($event){return _vm.tabReplaceSelect(2)}}},[_c('span',{class:_vm.tabReplace == 2
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Cliente ")])]),_c('v-col',{class:_vm.tabReplace == 3
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"4"},on:{"click":function($event){return _vm.tabReplaceSelect(3)}}},[_c('span',{class:_vm.tabReplace == 3
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" SLA")])])],1)],1),(_vm.tabReplace == 1)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('solicitud-info-index',{attrs:{"datosSolicitud":_vm.datosSolicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('EmisionDatosGeneralesIndex',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('required-solicitudes-info',{attrs:{"datosSolicitud":_vm.datosSolicitud,"operadores":_vm.operadores},on:{"getData":_vm.updateView}})],1)]):_vm._e(),(_vm.tabReplace == 2)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('cliente-solcitud-info',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('contact-cliente-solcitud-info',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',{staticClass:"pt-5"},[_c('domicilio-solcitud-info',{attrs:{"datosSolicitud":_vm.solicitud},on:{"getData":_vm.updateView}})],1),_c('div',[(_vm.rol == 'MESACONTROLINBOUND' || _vm.rol == 'OPERADORINBOUND' || _vm.rol == 'OPERACIONESINBOUND' || _vm.rol == 'ADMIN')?_c('EmbeddedCliente',{attrs:{"telefonos":_vm.telefonosCliente,"cliente_id":_vm.datosSolicitud.solicitudBody.cliente_id,"is_solicitud":true}}):_vm._e()],1)]):_vm._e(),(_vm.tabReplace == 3)?_c('div',[_c('div',{staticStyle:{"padding-top":"60px"}},[_c('sla-solcitud',{attrs:{"datosSolicitud":_vm.datosSolicitud,"infoSLA":_vm.infoSLA}})],1)]):_vm._e(),_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }