<template>
  <div class="pt-5">
    <v-expansion-panel-content>
      <commond-form
        :request="formData"
        :route="'/v1/emision/manual/actualizar'"
        :run="run"
        :action="'POST'"
        @cancel="run = false"
        @success="$emit('getData')"
        @afterError="$emit('changeTab', 1)"
      >
        <template v-slot:data>
          <v-row>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-select
                label="Estatus de la Póliza"
                v-model="formData.status"
                :items="statusList"
                item-text="name"
                item-value="id"
                outlined
                color="#00a7e4"
                tipo_poliza="text"
                :disabled="rol != 'ADMIN'"
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                label="No. Póliza"
                outlined
                v-model="formData.poliza"
                color="#00a7e4"
                tipo_poliza="text"
                :rules="requiredRule"
                :readonly="!canEditThird"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Tipo de Póliza"
                v-model="formData.tipo_poliza"
                :items="tipo_polizaPolizaList"
                outlined
                color="#00a7e4"
                tipo_poliza="text"
                placeholder="Requerido"
                :rules="requiredRule"
                :readonly="!canEditThird"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3" v-if="!isAgente">
              <v-autocomplete
                label="Agente"
                :items="agentes"
                item-text="nombre"
                item-value="id"
                v-model="formData.agente"
                placeholder="Requerido"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Ramo"
                :items="currentRamos"
                v-model="formData.categoriaSelected"
                placeholder="Requerido"
                item-text="ramo"
                item-value="ramo"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Productos"
                v-model="formData.producto_id"
                :items="currentproducto_id"
                item-text="nombre"
                item-value="id"
                placeholder="Requerido"
                :rules="requiredRule"
                outlined
                color="#00a7e4"
              ></v-autocomplete>
            </v-col>

            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Campaña"
                v-model="formData.campania_id"
                :items="currentCampanias"
                item-text="producto"
                item-value="id"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                :readonly="true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                label="No. Cotización / Lead ID"
                v-model="formData.cotizacion"
                color="#00a7e4"
                outlined
                tipo_poliza="number"
                :readonly="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" lg="3" xl="3" v-if="formData.status == 0">
              <v-checkbox
                label="Póliza emitida"
                v-model="formData.checkPolizaEmitida"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              v-if="
                (formData.status == 0 ||
                  formData.status == 1 ||
                  formData.status == 2 ||
                  formData.status == 4) &&
                  canEdit
              "
            >
              <v-checkbox
                label="Póliza Cancelada"
                v-model="formData.checkCancelada"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              v-if="
                (formData.status == 0 ||
                  formData.status == 1 ||
                  formData.status == 2 ||
                  formData.status == 3) &&
                  canEdit
              "
            >
              <v-checkbox
                label="Póliza Duplicada"
                v-model="formData.checkPolizaDuplicada"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3" v-if="formData.status == 3">
              <v-checkbox
                label="Póliza Finalizada"
                v-model="formData.checkPolizaFinalizada"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3" v-if="formData.status == 6">
              <v-checkbox
                label="Reactivar Póliza"
                v-model="formData.checkReactivarPoliza"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              v-if="
                formData.checkCancelada ||
                  formData.finalizadaCheck ||
                  formData.checkPolizaDuplicada
              "
            >
              <v-autocomplete
                label="Motivo de Cancelación / Finalización / Duplicidad"
                v-model="formData.motivoCancelacion"
                :items="cancelacionFinalizacionList"
                item-text="name"
                item-value="name"
                outlined
                color="#00a7e4"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col m="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="formData.inicio"
                type="date"
                label="Fecha inicio de vigencia"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                :readonly="!canEditThird"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="formData.fin"
                type="date"
                label="Fecha fin de vigencia"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                :readonly="!canEditThird"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col m="12" md="12" lg="12" xl="12">
              <v-textarea
                label="Notas de la Póliza"
                outlined
                v-model="formData.notas"
                primary
                color="#00a7e4"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="text-align: end;"
            >
              <v-btn
                rounded
                dark
                color="#00a7e4"
                outlined
                @click="$emit('changeTab', 1)"
                style="margin-right: 20px;"
              >
                <span style="padding-right: 11%;">
                  Cancelar
                </span>
              </v-btn>
              <v-btn rounded dark class="common-botton" @click="run = true">
                <span style="padding-right: 11%;">
                  Guardar
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </commond-form>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        id: null,
        status: 0,
        poliza: null,
        agente: null,
        categoriaSelected: null,
        producto_id: null,
        campania_id: null,
        cotizacion: null,
        inicio: null,
        fin: null,
        notas: null,
        operador_id: null,
        tipo_poliza: null,
        aseguradora_id: null,
        rfc:null,
        anios:null
      },
      tipo_polizaPolizaList: ["Nueva", "Renovación"],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      statusList: [
        { id: 0, name: "Nueva" },
        { id: 1, name: "Emitida Pendente de Pago" },
        { id: 2, name: "Pagada Parcial" },
        { id: 3, name: "Pagada Total" },
        { id: 4, name: "Renovada" },
        { id: 5, name: "Finalizada" },
        { id: 8, name: "Cancelada" },
      ],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      campanias: [],
      agentes: [],
      ramos: [],
      producto_id: [],
      productoOrigal: [],
      pago_idList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
      forma_pagoList: [
        { id: 1, name: "Tarjeta de Crédito" },
        { id: 2, name: "Tarjeta de Débito" },
        { id: 3, name: "Depósito en banco" },
        { id: 4, name: "Transferencia" },
        { id: 5, name: "Cheque" },
        { id: 6, name: "Efectivo" },
        { id: 7, name: "Domiciliación" },
        { id: 8, name: "Cargo único" },
      ],
      currentPanel: 0,
      readonly: false,
      run: false,
      cancelacionFinalizacionList: [
        { id: 0, name: "Falta de pago" },
        { id: 1, name: "Incremento en prima / Renovación" },
        { id: 2, name: "Mal servicio agente" },
        { id: 3, name: "Mal servicion compañía" },
        { id: 4, name: "Económicas" },
        { id: 5, name: "Inconformidad con el producto" },
        { id: 6, name: "Mejora en competencia" },
        {
          id: 7,
          name: "Ya no tiene necesidad / Fallecimiento / Cambio de Agente",
        },
        { id: 8, name: "Póliza duplicada" },
      ],
    };
  },
  watch: {
    "formData.pago_id"() {
      this.calculateCuotas();
    },
    "formData.anios"(val) {
      this.calculateCuotas();
      if (val == 1) this.formData.subsecuente_pago = null;
    },
    currentStep(val) {
      if (val == 1) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
    "formData.producto_id"(id) {
      let producto = this.producto_id.find((element) => element.id === id);
      this.formData.aseguradora_id = producto
        ? producto.aseguradoraId
        : this.polizaInfo.aseguradora_id;
    },
  },
  computed: {
    currentRamos() {
      if (this.formData.agente != null)
        return this.agentes.find((e) => e.id == this.formData.agente) ? this.agentes.find((e) => e.id == this.formData.agente).ramosList : [] ;
      else return [];
    },
    currentproducto_id() {
      if (this.formData.categoriaSelected != null)
        return this.producto_id.filter(
          (e) => e.ramo == this.formData.categoriaSelected
        );
      else return [];
    },
    currentCampanias() {
      if (this.formData.categoriaSelected != null)
        return this.campanias.filter(
          (e) => e.ramo == this.formData.categoriaSelected
        );
      else return [];
    },
    isAgente() {
      return this.rol == "AGENT";
    },
    canEdit() {
      let canView = false;
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "OPERACIONES",
        "AGENTCC",
        "MANAGERCC",
        "COBRANZACC",
        "RENOVACIONESCC",
        "OPERADOR",
      ];
      rolesToView.forEach((element) => {
        if (this.rol == element) {
          canView = true;
        }
      });
      return canView;
    },
    canEditThird() {
      let canView = false;
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "OPERACIONES",
        "FINANZAS",
        "OPERADOR",
        "AGENTCC",
        "OPERADORCC",
        "AGENTCCSPONSORS",
        "MANAGERCC",
      ];
      rolesToView.forEach((element) => {
        if (this.rol == element) {
          canView = true;
        }
      });
      return canView;
    },
  },
  methods: {
    getCampanias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.campanias = response.data;
        });
    },
    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
            ramosList: element.ramosList ? element.ramosList : [],
          });
        });
      });
    },
    getRamos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramos = response.data;
      });
    },
    getproducto_id() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.producto_id.push({
            nombre:
              `${element.ramo}` +
              " / " +
              `${element.nombre}` +
              " / " +
              `${element.nombreAseguradora}`,
            id: element.id,
            canal: element.canal ? element.canal : "Todos",
            ramo: element.ramo ? element.ramo : "No disponible",
            aseguradoraId: element.aseguradoraId
              ? element.aseguradoraId
              : "No disponible",
            tecnologia: element.tecnologia ? element.tecnologia : false,
          });
        });
        this.productoOrigal = this.producto_id;
      });
    },
    calculateCuotas() {
      switch (this.formData.pago_id) {
        case 1:
          this.formData.numeroCuotas = 1 * this.formData.anios;
          break;
        case 2:
          this.formData.numeroCuotas = 2 * this.formData.anios;
          break;
        case 3:
          this.formData.numeroCuotas = 4 * this.formData.anios;
          break;
        case 4:
          this.formData.numeroCuotas = 12 * this.formData.anios;
          break;
        default:
          break;
      }
    },
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("ChangeOnlyStep", 2, this.formData);
      }
    },
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.polizaInfo[e]) {
          this.formData[e] = this.polizaInfo[e];
        }
      });
      this.formData.agente = parseInt(this.formData.agente);
      this.formData.aseguradora_id = this.polizaInfo.aseguradora_id;

      console.log({ fd: this.formData })
      this.formData.inicio = this.getFormattDate(this.formData.inicio, "YYYY-MM-DD")
      this.formData.fin = this.getFormattDate(this.formData.fin, "YYYY-MM-DD")
    },

    getFormattDate(date, format = "DD/MM/YYYY") {
      let fecha = moment(date, "YYYY-MM-DD");
      console.log({ fecha: fecha.isValid() })
      if (fecha.isValid()) {
        return fecha.format(format);
      } else {
        fecha = new Date(date);
        if (!isNaN(fecha.getTime())) {
          return moment(fecha).format("YYYY-MM-DD");
        } else {
          return "Fecha no válida";
        }
      }
    },
  },
  mounted() {
    this.getCampanias();
    this.getAgentes();
    this.getRamos();
    this.getproducto_id();
    this.fillData();
  },
};
</script>
