<template>
  <div>
    <v-card
      class="mx-auto"
      color="white"
      width="100%"
      style="border-radius: 20px; box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;"
      elevation="0"
    >
      <v-card-title
        @click="showAll"
        v-if="!showEmails"
        style="cursor: pointer;"
      >
        <v-row>
          <v-col cols="12" style="line-height: 20px;">
            <span class="text-header-data-cuotas">
              Cuotas
            </span>
            <br />
            <span class="subtitle-text">
              {{ pago_id ? getPeridicidad(pago_id) : "Sin llenar" }}
            </span>
          </v-col>
          <v-col cols="6" style="line-height: 20px;">
            <span class="num-coutas-font">
              Número de cuotas
            </span>
          </v-col>
          <v-col cols="6" style="line-height: 20px; text-align: end;">
            <span class="num-coutas-font">
              {{ getCuotaPagada() }} / {{ cuotas.length }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title v-if="showEmails" style="cursor: pointer;">
        <v-row>
          <v-col cols="10" @click="showAll">
            <v-row>
              <v-col cols="12">
                <span class="text-header-data-cuotas">
                  Cuotas
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="showEmails">
        <div v-if="cuotas.length > 0" style="height: 180px !important;">
          <v-row v-for="(e, idx) in cuotas.slice(0, 3)" :key="idx">
            <v-col cols="12" :class="e.status == 'Pendiente de pago' ? 'custom-list-pendiente' :
                                     e.status == 'Pagada' ? 'custom-list-pagado' : 'custom-list-retrasado'">
              Fecha: {{formatDate(e.fechaLimitePago)}} - {{ e.status }}: {{ formatPrice(e.valorAPagar) }}
              <span
                style="color:#0070C0; cursor: pointer; "
                @click="$router.push('/visualizacion-comisiones/'+e.uuid)"
              >
                Ver
              </span>
            </v-col>
          </v-row>
        </div>
        <div v-if="cuotas.length > 0">
          <v-row style="text-align: center;">
            <v-col cols="12">
              <v-btn
                elevation="0"
                color="white"
                @click="$emit('toCuotas')"
              >
                <span style="color:#0070C0">
                  Ver mas
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else style="height:  216px !important; text-align: center;">
          <v-row style="text-align: center;">
            <v-col cols="12">
              <h3>
                No hay nada por el momento
              </h3>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <create-email-dialog
      :OpenDialogCreateEmail="OpenDialogCreateEmail"
      :emailRoutesConfig="emailRoutesConfig"
      @closeModal="OpenDialogCreateEmail = false"
      @success="getData"
    >
    </create-email-dialog>
    <ShowEmailsDialog
      :cuotas="cuotas"
      :OpenDialogShowEmails="OpenDialogShowEmails"
      @closeModal="OpenDialogShowEmails = false"
      @openCreateModal="OpenDialogCreateDocument = true"
    >
    </ShowEmailsDialog>
  </div>
</template>

<script>
import CreateEmailDialog from "@/components/commonComponents/dialogs/CreateEmailDialog.vue";
import ShowEmailsDialog from "../dialogs/ShowEmailsDialog.vue";
import email from "@/assets/email.svg";
import addIcon from "@/assets/addIcon.svg";
import moment from "moment";

export default {
  components: {
    CreateEmailDialog,
    ShowEmailsDialog,
  },

  data() {
    return {
      showEmails: false,
      OpenDialogCreateEmail: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      OpenDialogCreateDocument: false,
      OpenDialogShowEmails: false,
      emailImg: email,
      AddIcon: addIcon,
    };
  },
  props: {
    cuotas: Array,
    emailRoutesConfig: Object,
    openAll: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    pago_id: String,
  },

  watch: {
    openAll(val) {
      this.showEmails = val;
    },
  },

  methods: {
    getData() {
      this.$emit("getData");
    },
    showAll() {
      this.$emit("showAll", !this.showEmails);
    },
    getPeridicidad(val) {
      switch (val) {
        case 1:
          return "Anual";
        case 2:
          return "Semestral";
        case 3:
          return "Trimestral";
        default:
          return "Mensual";
      }
    },
    getCuotaPagada() {
      var pagadas = 0;
      this.cuotas.map((e) => {
        if (e.status == "Pagada") {
          pagadas++;
        }
      });
      return pagadas;
    },
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style>
.rounded-card {
  border-radius: 20px;
  max-width: "100%";
}
.custom-list-pendiente {
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px 5px 0px;
  background-color: #fff7e2;
}

.custom-list-pagado {
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px 5px 0px;
  background-color: #e3f5dc;
}

.custom-list-retrasado {
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px 5px 0px;
  background-color: #fde6e9;
}

.custom-list {
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px 5px 0px;
  background-color: aliceblue;
}

.text-header-data-cuotas {
  color: #4d5358;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 15px;
}

.subtitle-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #72777b;
}

.num-coutas-font {
  margin: 0 auto;
  width: 112px;
  height: 14px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #2d2d2d;
}
</style>
