<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Información de Pago
      </template>
      <ViewPolizaPaymentData
        v-if="tabReplace == 1"
        @toEdit="tabReplace = 2"
        :polizaInfo="polizaInfo"
        
      />
       <EditPolizaPaymentData
        v-else
        :polizaInfo="polizaInfo"
        :ramoData="ramoData"
        @changeTab="tabReplace = 1"
        @getData="$emit('getData'), tabReplace = 1"
      /> 
    </CommonExpansionPanel>
  </div>
</template>

<script>
// import moment from "moment";
// import { mainAxios } from "@/mainAxios.js";
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewPolizaPaymentData from "./ViewPolizaPaymentData.vue"
import EditPolizaPaymentData from "./EditPolizaPaymentData.vue"
export default {
  components: {
    CommonExpansionPanel,
    ViewPolizaPaymentData,
    EditPolizaPaymentData
  },
  props:{
    polizaInfo:{
      type: Object,
      default: () => {},
    },
    productos:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      infoCards: {},
    };
  },
  methods: {
    fasfa(){
      console.log("si entro")
    }
  },
};
</script>
