<template>
  <div>
    <common-card class="px-4 py-4">
      <v-row class="pa-3" align="end" >
        <v-col md="3" lg="2" class="d-none d-md-block">
          <v-row >
            <v-col md="10" class="text-jutify">
              Suma asegurada 
            </v-col>
            <v-col md="2">
              <v-icon color="#00A7E4" class="mdi-20px">mdi-information</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="10" >
              Deducible
            </v-col>
            <v-col md="2" class="text-center">
              <v-icon color="#00A7E4" class="mdi-20px">mdi-information</v-icon>
            </v-col>
          </v-row>
          <v-row >
            <v-col md="10" class="text-left align-end">
              Coaseguro
            </v-col>
            <v-col md="2" class="text-right align-end">
              <v-icon color="#00A7E4" class="mdi-20px">mdi-information</v-icon>
            </v-col>
          </v-row>
          <v-row >
            <v-col md="10"  class="text-left align-end">
              Tope Coaseguro
            </v-col>
            <v-col md="2" class="text-right align-end">
              <v-icon color="#00A7E4" class="mdi-20px">mdi-information</v-icon>
            </v-col>
          </v-row>
          <v-row >
            <v-col md="10" class="text-left align-end">
              Beneficios
            </v-col>
            <v-col md="2" class="text-right align-end">
              <v-icon color="#00A7E4" class="mdi-20px">mdi-information</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9" lg="10" >
          <v-row justify="center">
            <v-col cols="12" md="3" v-for="(primaX,index) in primasShow" :key="'plan_'+index">
              <v-card  class="pa-2" >
                <v-row>
                  <v-col cols="12" class="d-flex justify-center align-center">
                    <v-img
                      width="134"
                      height="56"
                      contain
                      :src="imagenesAseguradoras[primaX.nameId]"
                      alt="asegoradora-1"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row>
                  <v-col cols="12" class="text-center pt-5">
                    <span class="font-weight-thin">Costo anual:</span>
                    <p class="font-weight-medium text-h6 mb-0 pb-2">{{formatPrice(primaX.total)}}</p>
                    <v-btn dark block auto color="#00A7E4" class="rounded-xl" 
                      :disabled="!isValid"
                      @click="$emit('createSolicitud',index)"  >
                      ¡LO QUIERO!
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row align-end class="text-center font-weight-bold">
                  <v-col cols="12" class="">
                    {{primaX.sumaAsegurada}}
                  </v-col>
                  <v-col cols="12">
                    {{primaX.deducible}}
                  </v-col>
                  <v-col cols="12">
                    {{primaX.coaseguro}}
                  </v-col>
                  <v-col cols="12">
                    {{primaX.topeCoaseguro}}
                  </v-col>
                  <v-col cols="12">
                    {{primaX.beneficios}}
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </common-card>
  </div>
</template>
<script>
import CommonCard from '../../../../components/commonComponents/CommonCard.vue';
import imageAseguradoras from '../../../../helpers/data/data-image-aseguradoras.json';
export default {
  name:'AseguradorasMedico',
  components: {
    CommonCard,
  },
  props:{
    primasParent:{
      type: Array
    },
    isValid:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      imagenesAseguradoras: imageAseguradoras,
      primas: this.primasParent
    };
  },
  methods:{
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
  },
  created(){
    this.primas = this.primasParent;
  },
  watch:{
    primasParent:function(nuevoValor){
      this.primas = nuevoValor;
    }
  },
  computed:{
    primasShow(){
      return this.primas.filter(elemento => elemento.total >0);
    }
  }
};
</script>
<style scoped>
.tarjeta {
  height: 100%; /* Asegura que todas las tarjetas tengan la misma altura */
}
.list-item {
  text-align: left;
  position: relative;
}
.list-item::before {
  content: '\2022'; /* Unicode character for bullet point */
  position: absolute;
  left: 4px;
  width: 1em;
  margin-right: 1em;
}
.full-width-btn {
  width: 100%;
}
.v-divider {
  background-color:#e5e5e554; /* Cambia el color de la línea */
  height: 1px; /* Altura de la línea */
}
</style>
