<template>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <v-container fluid>
        <!-- HEAD TITLE -->
        <v-row style="margin-top: 10px">
          <v-col cols="6">
            <h2>Crear solicitud</h2>
          </v-col>

          <v-col cols="6"  class="text-right">
            <v-btn @click="goBack()" elevation="2"
              ><v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
            </v-btn>
          </v-col>
        </v-row>

        <v-row style="margin-top: 20px">
          <v-col cols="12">
            <v-form>
              <v-row>
                <v-col>
                  <v-stepper v-model="stepper" non-linear>
                    <v-stepper-header>
                      <v-stepper-step step="1" editable class="stepper">
                        Datos de la Solicitud
                      </v-stepper-step>

                      <v-stepper-step step="2" editable v-if="!esInbound">
                        Seguimiento de la Solicitud
                      </v-stepper-step>

                      <v-stepper-step step="3" editable v-if="!esInbound">
                        SLA de la Solicitud
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- 1ST ITEM (FORM) -->
                      <v-stepper-content step="1">
                        <v-row style="padding-top: 20px">
                          <v-col cols="12">
                            <v-alert
                              dense
                              type="info"
                              text
                            >
                              Datos generales de la <strong>Solicitud</strong>.
                            </v-alert>
                          </v-col>

                          <v-col cols="3" xs="12">
                            <v-select
                              label="Estatus Solicitud"
                              :items="estado"
                              v-model="estadoSelected"
                              readonly
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="3" xs="12">
                            <v-autocomplete
                              label="Origen de la solicitud"
                              :items="origenSolicitud"
                              v-model="form.origenSolicitudSelected"
                              outlined
                              clearable
                              :no-data-text="'No se encontraron resultados...'"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="3" xs="12" v-if="!esInbound">
                            <v-autocomplete
                              label="Agente"
                              :items="agentes"
                              item-text="nombre"
                              item-value="id"
                              v-model="form.agente"
                              :readonly="isAgent == true "
                              placeholder="Requerido"
                              :rules="rules.inputsRequeridos"
                              @change="esDirecto()"
                              outlined
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6" xs="12" v-if="esAgentDirecto">
                            <v-autocomplete
                              label="Comercial Asignado"
                              :items="comercialAsignadoList"
                              item-text="nombre"
                              item-value="id"
                              v-model="comercialAsignado"
                              :readonly="isAgent == true"
                              placeholder="Requerido"
                              :rules="rules.inputsRequeridos"
                              outlined
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="3" xs="12">
                              <v-autocomplete
                                label="Tipo de solicitud"
                                :items="tiposSolicitud"
                                v-model="form.tipoSelected"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                                :no-data-text="'No se encontraron resultados...'"
                              ></v-autocomplete>
                          </v-col>
                          <v-col cols="3" xs="12" v-if="esInbound && form.tipoSelected != null">
                            <v-autocomplete
                              label="Actividad de solicitud"
                              :items="actidadesSolicitud"
                              v-model="form.actividadSolicitud"
                              placeholder="Requerido"
                              :rules="rules.inputsRequeridos"
                              :no-data-text="'No se encontraron resultados...'"
                              outlined
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                          <!-- End 2nd Col -->
                        <v-row style="padding-top: 20px">
                          <v-col cols="3" xs="12" v-if="!esInbound">
                            <v-select
                              label="Ramo"
                              :items="categoria"
                              v-model="form.categoriaSelected"
                              placeholder="Requerido"
                              :rules="rules.inputsRequeridos"
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="3" xs="12" v-if="!isAgent && !esInbound">
                            <v-autocomplete
                              label="Productos"
                              :items="productos"
                              v-model="form.productoSelected"
                              item-text="nombre"
                              item-value="id"
                              placeholder="Requerido"
                              :rules="rules.inputsRequeridos"
                              outlined
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6" xs="12">
                            <v-textarea
                              label="Detalle Solicitud"
                              v-model="form.detalle"
                              rows="2"
                              auto-grow
                              :rules="rules.inputsRequeridos"
                              outlined
                            ></v-textarea>
                          </v-col>
                          <v-col cols="3"  xs="12" v-if="esInbound">
                            <v-file-input
                              v-model="archivos"
                              color="deep-purple accent-4"
                              counter
                              multiple
                              placeholder="Archivo INE"
                              prepend-icon="mdi-paperclip"
                              outlined
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>
                          
                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ ineFile.length - 2 }} Archivos(s)
                                </span>
                              </template>
                            </v-file-input>
                          </v-col>
                          <v-col cols="3" xs="12" v-if="esInbound">
                            <v-autocomplete
                                v-model="form.campania"
                                :rules="rules.inputsRequeridos"
                                label="Campaña"
                                :items="campaniasInbound"
                                item-text="campania"
                                item-value="campania"
                                no-data-text="Primero selecciona un ramo"
                                outlined
                              ></v-autocomplete>
                          </v-col>
                          <v-col cols="3" xs="12" v-if="crearRoles == false && !esInbound">
                            <v-autocomplete
                            v-model="form.campania"
                            :rules="rules.inputsRequeridos"
                            label="Campaña"
                            :items="campaniasFiltradas"
                            item-text="producto"
                            item-value="producto"
                            no-data-text="Primero selecciona un ramo"
                            outlined
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="3" xs="12" v-if="crearRoles == false && !esInbound">
                            <v-text-field
                            v-model="form.idOrigenLead"
                            label="ID Origen Lead"
                            :rules="rules.inputsRequeridos"
                            outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-alert
                            dense
                            type="info"
                            text
                          >
                            Datos personales del <strong>cliente</strong>.
                          </v-alert>

                         <v-row style="padding-top: 20px">
                            <v-col cols="4" xs="12" >
                              <v-select
                                :items="regimenList"
                                v-model="form.regimenSelected"
                                label="Tipo de Persona"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                                clearable
                              ></v-select>
                            </v-col>
                            <v-col cols="4" xs="12"  v-if="form.regimenSelected == 'Moral'">
                              <v-text-field
                                label="Razón Social"
                                v-model="form.razonSocial"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <!-- Datos personales del clientes  -->
                          <v-row style="padding-top: 20px">
                            <v-col cols="3" xs="12" v-if="form.regimenSelected != null">
                              <v-text-field
                                label="Nombre(s)"
                                v-model="form.nombres"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" xs="12" v-if="form.regimenSelected != null">
                              <v-text-field
                                label="Apellido Paterno"
                                v-model="form.apellidoPaterno"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" xs="12" v-if="form.regimenSelected != null">
                              <v-text-field
                                label="Apellido Materno"
                                v-model="form.apellidoMaterno"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" xs="12" v-if="form.regimenSelected != null">
                              <v-text-field
                                :value="computedDateFormattedMomentjs"
                                clearable
                                v-model="form.fechaNacimiento"
                                label="Fecha de Nacimiento"
                                prepend-icon="mdi-calendar"
                                v-mask="'##/##/####'"
                                placeholder="Requerido(DD/MM/AAAA)"
                                :rules="rules.inputsRequeridos"
                                @click:clear="form.fechaNacimiento = null"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" xs="12" v-if="form.regimenSelected != null">
                              <v-text-field
                                placeholder="Requerido"
                                v-model="form.rfc"
                                label="RFC"
                                :rules="rules.rulesRfc"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" xs="12" v-if="form.regimenSelected != null">
                              <v-select
                                :items="genero"
                                v-model="form.selectedGenero"
                                label="Género"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-select>
                            </v-col>
                            <v-col cols="3" xs="12"  v-if="form.regimenSelected != null">
                              <v-text-field
                                v-model="form.edad"
                                label="Edad"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" xs="12" v-if="form.regimenSelected != null">
                              <v-select
                                :items="estadoCivil"
                                v-model="form.estadoCivilSelected"
                                label="Estado Civil"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-select>
                            </v-col>
                            <v-col cols="4" xs="12" v-if="form.regimenSelected != null">
                              <v-text-field
                                placeholder="(Requerido)##########"
                                v-mask="'##########'"
                                v-model="form.celular"
                                label="Teléfono"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="8" xs="12" v-if="form.regimenSelected != null">
                              <v-autocomplete
                              v-model="form.correoElectronico"
                              label="Correo Electrónico"
                              :items="prediccionesCorreo"
                              :filter="filtroCorreo"
                              :rules="[...rules.emailRules, validarDominioComun]"
                              :append-icon="null"
                              outlined
                              ></v-autocomplete>
                          </v-col>
                          <v-col cols="4" v-if="esInbound && form.regimenSelected != null">
                              <v-text-field
                                v-model="form.numPolizas"
                                label="Numero de poliza"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="esInbound && form.regimenSelected != null">
                              <v-text-field
                                :value="computedDateInicioVigenciaFormattedMomentjs"
                                clearable
                                v-model="form.inicioVigenciaFormatted"
                                label="Inicio de Vigencia"
                                placeholder="Requerido (dd/mm/yyyy)"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                :rules="rules.inputsRequeridos"
                                @click:clear="form.inicioVigenciaFormatted = null"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="esInbound && form.regimenSelected != null">
                              <v-text-field
                                :value="computedDateFinVigenciaFormattedMomentjs"
                                v-model="form.finVigenciaFormatted"
                                label="Fin de Vigencia"
                                placeholder="Requerido (dd/mm/yyyy)"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                :rules="rules.inputsRequeridos" 
                                hint="Calculado automáticamente"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <!-- Datos domicilio del cliente -->
                            <v-alert  v-if="form.regimenSelected != null"
                              dense
                              type="info"
                              text
                            >
                              Domicilio del <strong>cliente</strong>.
                            </v-alert>

                          <v-row style="padding-top: 20px">
                            <v-col cols="4" v-if="form.regimenSelected != null">
                              <v-text-field
                                v-mask="'#####'"
                                placeholder="(Requerido)#####"
                                label="Código Postal"
                                v-model="form.codigoPostal"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="form.regimenSelected != null">
                              <v-text-field
                                v-model="form.calle"
                                placeholder="Requerido"
                                label="Calle"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" v-if="form.regimenSelected != null">
                              <v-text-field
                              v-model="form.num_exterior"
                              placeholder="Requerido"
                              label="Número exterior"
                              outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" v-if="form.regimenSelected != null">
                              <v-text-field
                              v-model="form.num_interior"
                              placeholder="Requerido"
                              label="Número interior"
                              outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="form.regimenSelected != null">
                             <v-select
                              label="Colonia"
                              :items="colonias"
                              v-model="form.colonia"
                              :rules="rules.inputsRequeridos"
                              outlined
                              ></v-select>
                            </v-col>
                            <v-col cols="4" v-if="form.regimenSelected != null">
                              <v-text-field
                                v-model="form.municipio"
                                placeholder="Requerido"
                                label="Municipio / Ciudad"
                                readonly
                                outlined
                              ></v-text-field>
                            </v-col>

                            <v-col cols="4"  v-if="form.regimenSelected != null">
                              <v-select
                                placeholder="Requerido"
                                :items="estados"
                                item-text="estadoRepublica"
                                item-value="estadoRepublica"
                                v-model="form.estadoRepublicaSelected"
                                label="Estado de la República"
                                :readonly="['Cotización', 'Emisión', 'Renovación'].find(e => e == form.tipoSelected)"
                                :rules="rules.inputsRequeridos"
                                outlined

                              >
                            </v-select>
                            </v-col>
                          </v-row>
                          <v-alert  v-if="form.regimenSelected != null && !esInbound"
                              dense
                              type="info"
                              text
                            >
                             Campos para <strong>{{ form.tipoSelected }} </strong> del ramo  <strong> {{form.categoriaSelected}} </strong>.
                            </v-alert>

                          <v-row style="padding-top: 20px">
                            <v-col cols="4" v-if="!esInbound && form.regimenSelected != null && form.tipoSelected != 'Cotización'  && form.tipoSelected != 'Renovación' ">
                              <v-text-field
                                v-model="form.numPolizas"
                                label="Numero de poliza"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="(form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' ) && !esInbound  && form.regimenSelected != null ">
                              <v-text-field
                                v-model="form.anios"
                                label="Años de vigencia"
                                :rules="[...rules.inputsRequeridos, ...rules.numeric]"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="(form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' ) && !esInbound && form.regimenSelected != null">
                              <v-text-field
                                :value="computedDateInicioVigenciaFormattedMomentjs"
                                clearable
                                v-model="form.inicioVigenciaFormatted"
                                label="Inicio de Vigencia"
                                placeholder="Requerido (dd/mm/yyyy)"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                :rules="rules.inputsRequeridos"
                                @click:clear="form.inicioVigenciaFormatted = null"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="(form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' ) && !esInbound && form.regimenSelected != null">
                              <v-text-field
                                :value="computedDateFinVigenciaFormattedMomentjs"
                                v-model="form.finVigenciaFormatted"
                                label="Fin de Vigencia"
                                placeholder="Requerido (dd/mm/yyyy)"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                :rules="rules.inputsRequeridos" 
                                hint="Calculado automáticamente"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="(form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound && form.regimenSelected != null ">
                              <v-select
                                :items="periodicidadItems"
                                v-model="form.periodicidadSelected"
                                label="Periodicidad de pago"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-select>
                            </v-col>
                            <v-col cols="4" v-if="(form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound && form.regimenSelected != null">
                              <v-select
                                :items="formaPago"
                                v-model="form.formaPagoSelected"
                                label="Forma de Pago"
                                placeholder="Requerido"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="4" v-if="form.tipoSelected == 'Renovación'">
                              <v-text-field
                                label="No. de Póliza a renovar"
                                v-model="polizaRenovar"
                                :rules="rules.inputsRequeridos"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="form.tipoSelected == 'Renovación'">
                              <v-text-field
                                label="Fecha Inicio de vigencia a renovar"
                                placeholder="dd/mm/aaaa"
                                v-model="fechaInicioVigenciaRenovar"
                                :rules="form.finVigenciaFormatted"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="form.tipoSelected == 'Renovación'">
                              <v-text-field
                                label="Fecha Fin de vigencia a renovar"
                                placeholder="dd/mm/aaaa"
                                v-model="form.finVigenciaFormatted"
                                :rules="rules.inputsRequeridos"
                                v-mask="'##/##/####'"
                                prepend-icon="mdi-calendar"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <div v-if="(form.categoriaSelected == 'AUTOS') && (form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound  && form.regimenSelected != null ">
                              <RamoAutosComponent
                                ref="formRamo"
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="form.solicitudCompletaSelected"
                                :procesoCotizacion="form.procesoCotizacionSelected"
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :newFormProps="newFormProps"
                                :numeroPoliza="form.numPolizas"
                                @nextStep="stepper++"
                              />
                            </div>
                            <div v-else-if="(form.categoriaSelected == 'DAÑOS') && (form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound  && form.regimenSelected != null ">
                              <RamoDaniosComponent
                                ref="formRamo"
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="form.solicitudCompletaSelected"
                                :procesoCotizacion="form.procesoCotizacionSelected"
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :newFormProps="newFormProps"
                                :numeroPoliza="form.numPolizas"
                                @nextStep="stepper++"
                              />
                            </div>
                            <div v-else-if="(form.categoriaSelected == 'SALUD') && (form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound  && form.regimenSelected != null ">
                              <RamoSaludComponent
                                ref="formRamo"
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="form.solicitudCompletaSelected"
                                :procesoCotizacion="form.procesoCotizacionSelected"
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :newFormProps="newFormProps"
                                :numeroPoliza="form.numPolizas"
                                @nextStep="stepper++"
                              />
                            </div>
                            <div v-else-if="(form.categoriaSelected == 'VIDA') && (form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound  && form.regimenSelected != null ">
                              <RamoVidaComponent
                                ref="formRamo"
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="form.solicitudCompletaSelected"
                                :procesoCotizacion="form.procesoCotizacionSelected"
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :newFormProps="newFormProps"
                                :numeroPoliza="form.numPolizas"
                                @nextStep="stepper++"
                              />
                            </div>
                            <div v-else-if="(form.categoriaSelected == 'AHORRO') && (form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound  && form.regimenSelected != null ">
                              <RamoAhorroComponent
                                ref="formRamo"
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="form.solicitudCompletaSelected"
                                :procesoCotizacion="form.procesoCotizacionSelected"
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :newFormProps="newFormProps"
                                :numeroPoliza="form.numPolizas"
                                @nextStep="stepper++"
                              />
                            </div>
                            <div v-else-if="(form.categoriaSelected == 'FIANZA') && (form.tipoSelected == 'Cotización' || form.tipoSelected == 'Emisión' || form.tipoSelected == 'Renovación') && !esInbound  && form.regimenSelected != null ">
                              <RamoFianzasComponent
                                ref="formRamo"
                                :origenSolicitud="form.origenSolicitudSelected"
                                :statusSolicitud="estadoSelected"
                                :agente="form.agente"
                                :tipo="form.tipoSelected"
                                :ramo="form.categoriaSelected"
                                :producto="form.productoSelected"
                                :detalleSolicitud="form.detalle"
                                :nombres="form.nombres"
                                :apellidoPaterno="form.apellidoPaterno"
                                :apellidoMaterno="form.apellidoMaterno"
                                :estadoCivil="form.estadoCivilSelected"
                                :estadoNacimiento="form.estadoRepublicaSelected"
                                :genero="form.selectedGenero"
                                :fechaNacimiento="form.fechaNacimiento"
                                :rfc="form.rfc"
                                :tipoPersona="form.regimenSelected"
                                :telefono="form.celular"
                                :correo="form.correoElectronico"
                                :codigoPostal="form.codigoPostal"
                                :direccion="form.direccion"
                                :inicioVigencia="form.inicioVigenciaFormatted"
                                :finVigencia="form.finVigenciaFormatted"
                                :periodicidad="form.periodicidadSelected"
                                :formaPago="form.formaPagoSelected"
                                :operador="form.ejecutivo"
                                :solicitudCompleta="form.solicitudCompletaSelected"
                                :procesoCotizacion="form.procesoCotizacionSelected"
                                :comercialAsignado="comercialAsignado"
                                :razonSocial="form.razonSocial"
                                :newFormProps="newFormProps"
                                :numeroPoliza="form.numPolizas"
                                @nextStep="stepper++"
                              />
                            </div>
                        </v-row>

                        <v-row>
                           <v-col col="12" class="text-right">
                                <v-btn 
                                  color="primary"
                                  @click="stepper = 2" 
                                  v-if="form.tipoSelected != 'Cotización' && form.tipoSelected != 'Emisión' && form.tipoSelected != 'Renovación' && !esInbound "
                                  v-show="roles != 'AGENT'"
                                  >
                                  Siguiente
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  style="margin-left: 10px" 
                                  @click="sendSolicitud()"
                                  v-if="form.tipoSelected != 'Cotización' && form.tipoSelected != 'Emisión' && form.tipoSelected != 'Renovación'"
                                  :disabled="!comportamientoBotonGuardar()"
                                >
                                  Guardar
                                </v-btn>
                           </v-col>
                        </v-row>
                      </v-stepper-content>

                      <!-- 2ND ITEM (2ND FORM) -->
                      <v-stepper-content step="2">
                        <v-row style="padding: 20px; margin-top: -35px">
                          <v-col cols="6" v-if="isAgent == false">
                            <v-autocomplete
                              :items="ejecutivos"
                              label="Ejecutivo"
                              v-model="form.ejecutivo"
                              item-text="nombre"
                              item-value="uuid"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6" v-if="isAgent == false">
                            <v-select
                              label="Proceso de Cotización"
                              :items="procesoCotizacion"
                              v-model="form.procesoCotizacionSelected"
                              readonly
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6" v-if="isAgent == false">
                            <v-select
                              label="¿Solicitud Completa?"
                              :items="solicitudCompleta"
                              v-model="form.solicitudCompletaSelected"
                            ></v-select>
                          </v-col>
                          <v-col cols="4" v-if="isAgent == false">
                            <v-checkbox
                              label="Pendiente de Información"
                              v-model="checkPendienteInfo"
                            ></v-checkbox>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -70px">
                          <v-col cols="6" v-if="isAgent == false">
                            <v-checkbox
                              label="Cancelación"
                              v-model="checkCancelacion"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="6" v-if="isAgent == false && checkPendienteInfo">
                            <v-textarea
                              label="Causa de Pendiente de Información"
                              v-model="causaPendienteInformacion"
                              rows="1"
                              auto-grow
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-btn color="primary" @click="sendSolicitud()" :disabled="!comportamientoBotonGuardar()">
                          Guardar
                        </v-btn>

                        <v-btn text @click="stepper = 1"> Regresar </v-btn>
                        <v-btn text @click="stepper = 3">
                          Ver SLA
                        </v-btn>
                      </v-stepper-content>

                      <!-- 3RD ITEM (SLA) -->
                      <v-stepper-content step="3">
                        <v-row style="padding: 10px">
                          <v-col cols="6">
                            <p>SLA</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -90px">
                          <v-col cols="12">
                            <v-select
                              :items="genero"
                              label="Cumple"
                              disabled
                            ></v-select>
                          </v-col>
                          <!-- End 1st Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px">
                          <v-col cols="6">
                            <p>Fecha de Información completa</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px">
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <!-- End 2nd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px">
                          <v-col cols="6">
                            <p>Fecha de Pendiente de información</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px">
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <!-- End 3rd Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px">
                          <v-col cols="6">
                            <p>Fecha de Emisión de Datos Recibidos</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px">
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <!-- End 4th Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px">
                          <v-col cols="6">
                            <p>Fecha de Emisión en procesp</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px">
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <!-- End 5th Col -->
                        </v-row>

                        <v-row style="padding: 10px; margin-top: -50px">
                          <v-col cols="6">
                            <p>Fecha de Finalización</p>
                          </v-col>
                        </v-row>

                        <v-row style="padding: 20px; margin-top: -80px">
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="dd/mm/yyyy"
                              prepend-icon="mdi-calendar"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :items="genero"
                              placeholder="hh:mm"
                              prepend-icon="mdi-clock"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <!-- End 6th Col -->
                        </v-row>

                        <v-btn text @click="stepper = 2"> Regresar </v-btn>
                      </v-stepper-content>

                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <!-- BANNER HERE -->
        </v-row>

        <!-- ALERT MODAL -->
        <v-col cols="auto">
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="400"
            v-model="dialog"
          >
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="success" dark
                  >Confirmación de envío</v-toolbar
                >
                <v-card-text>
                  <div class="text-h4" style="margin-top: 10px; ">
                    Envio de solicitud exitoso!
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    @click="
                      (dialog.value = false), $router.push('/solicitudes')
                    "
                    >Cerrar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <!-- END OF ALERT MODAL -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from 'moment';
import Navigation from "../../../components/Nav.vue";
import { mainAxios } from "../../../mainAxios";
import RfcFacil from "rfc-facil";
import RamoAutosComponent from '../../components/ramosComponents/RamoAutosComponent.vue';
import RamoDaniosComponent from '../../components/ramosComponents/RamoDaniosComponent.vue';
import RamoSaludComponent from '../../components/ramosComponents/RamoSaludComponent.vue';
import RamoVidaComponent from '../../components/ramosComponents/RamoVidaComponent.vue';
import RamoAhorroComponent from '../../components/ramosComponents/RamoAhorroComponent.vue';
import RamoFianzasComponent from '../../components/ramosComponents/RamoFianzasComponent.vue';

import { dominiosComunes, validarDominioComun } from "@/helpers/validacionDominiosComunes.js";

export default {
  components: {
    Navigation,
    RamoAutosComponent,
    RamoDaniosComponent,
    RamoSaludComponent,
    RamoVidaComponent,
    RamoAhorroComponent,
    RamoFianzasComponent,
  },
  data() {
    const defaultForm = Object.freeze({
      origenSolicitudSelected: "Portal",
      agente: null,
      tipoSelected: null,
      categoriaSelected: null,
      modelo: null,
      marca: null,
      submarca: null,
      version: null,
      detalle: null,
      inicioVigenciaFormatted: null,
      finVigenciaFormatted: null,
      coberturasSelected: null,
      periodicidadSelected: null,
      tipoVehiculoSelected: null,
      numMotor: null,
      numSerie: null,
      placas: null,
      formaPagoSelected: null,
      nombres: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      fechaNacimiento: null,
      selectedGenero: null,
      estadoCivilSelected: null,
      rfc: null,
      celular: null,
      correoElectronico: null,
      estadoRepublicaSelected: null,
      codigoPostal: null,
      productoSelected: null,
      ejecutivo: null,
      procesoCotizacionSelected: "Cotización",
      solicitudCompletaSelected: null,
      usoAutoSelected: null,
      regimenSelected: null,
      direccion: null,
      razonSocial: null,
      actividadSolicitud: null,
    })
    return {
      busquedaCorreo: "",
      colonias: [],
      form: Object.assign({}, defaultForm),
      rules: {
        emailRules: [
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Dirección de correo incorrecta";
          },
        ],
        rulesRfc: [ 
          v => !!v || "Este campo es requerido", 
          v => ( v && v.length >= 13 ) || "Este campo debe contener 13 caracteres, sino cuenta con homoclave colocar XXX",
          v => ( v && v.length <= 13 ) || "Este campo debe contener 13 caracteres, sino cuenta con homoclave colocar XXX",
        ],
        inputsRequeridos: [(value) => !!value || "Campo Requerido"],
        numeric: [(value) => !isNaN(value) || "Número no válido"]
      },
      menuInicio: false,
      menuFin: false,
      menuFechaNacimiento: false,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      isAgent: false,
      stepper: 1,
      dialog: false,
      bandera: true,
      mostrarFormulario2: false,
      infoTarjetaComercial: null,
      agentes: [],
      ejecutivos: [],
      estado: [
        "Nueva",
        "Asignado",
        "En trámite",
        "Pendiente de aprobación",
        "Pendiente de información",
        "Recotizar",
        "Cerrado",
        "Cancelada",
      ],
      estadoCivil: ["Soltero(a)", "Casado(a)"],
      tipo: [
        "Cotización",
        "Emisión",
        "Renovación",
        "Endoso A y D",
        "Endoso B",
        "Facturacion",
        "Cancelación",
        "Otro",
      ],
      categoria: [
        "AUTOS",
        "DAÑOS",
        "SALUD",
        "VIDA",
        "AHORRO",
        "FIANZA",
      ],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
      periodicidadItems: ['Anual', 'Semestral', 'Trimestral', 'Mensual'],
      tipoVehiculosItems: ['Auto', 'Motocicleta', 'Pick-up', 'Camión', 'Tractocamión'],
      coberturas: ["Amplia", "Amplia-Plus", "Limitada", "RC"],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      regimenList: [
        "Física",
        "Moral",
      ],
      agenteUuid: localStorage.getItem("agenteUuid"),
      estadoSelected: "Nueva",
      productos: [],
      genero: ["Masculino", "Femenino"],
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: '',
      dateFechaNacimiento: '',
      menu1: false,
      menu2: false,
      edad: null,
      procesoCotizacion: ["Cotización", "Emisión", "Finalizado"],
      solicitudCompleta: ["Si", "No"],
      checkPendienteInfo: false,
      checkCancelacion: false,
      causaPendienteInformacion: null,
      canal: [],
      comercialAsignadoList: [],
      comercialAsignado: null,
      esAgentDirecto : null,
      polizaRenovar: null,
      fechaInicioVigenciaRenovar: null,
      fechaFinVigenciaRenovar: null,
      defaultForm,
      productoOrigal: [],
      campanias: [],
      campania: "",
      idOrigenLead: "",
      crearRoles: false,
      tiposSolicitud:[],
      campaniasInbound:[],
      tiposSolicitudCompletos:[],
      esInbound: false,
      actidadesSolicitud: [],
      archivos:[],
    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 4000);
    },
    date(val) {
      console.log(val);
      this.inicioVigenciaFormatted = this.formatDate(this.date);
    },
    'form.agente'(){
      if(!this.esInbound){
        this.form.categoriaSelected = ''
        this.calculateRamos()
        this.filtrarProductos()
        if(this.crearRoles && !this.esInbound)
          this.calculateProducto()
      }
    },
    'form.categoriaSelected'(){
      this.filtrarProductos()
      if(this.crearRoles && !this.esInbound)
        this.calculateProducto()
    },
    "form.nombres"() {
      this.actualizarRfc();
    },
    "form.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "form.apellidoMaterno"() {
      this.actualizarRfc();
    },
    "form.fechaNacimiento"() {
      this.calcularEdad();
      this.actualizarRfc();
    },

    "form.codigoPostal"(val) {
      if (val.toString().length < 4) return;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("v1/catalogo/direccion?cp=" + val, config)
        .then((response) => {
          this.colonias = [];
          response.data.listaColonias.forEach((item) => {
            this.colonias.push(item.nombreColonia);
          });
          this.form.municipio = response.data.nombreMunicipio;
          this.form.estadoRepublicaSelected = this.estados.find(e => e.id == Number(response.data.nEstado)).estadoRepublica;
        });
    },

    "form.inicioVigenciaFormatted"() {
      this.updateFinVigencia();
    },

    "form.anios"() {
      this.updateFinVigencia();
    },

    "form.tipoSelected"(val){
      if(this.esInbound){
        this.actidadesSolicitud = []
        var result = this.tiposSolicitudCompletos.filter(e => (e.tipo == val && e.inbound == 1))
        result.map((e => {
          this.actidadesSolicitud.push(e.actividad);
        }))
        this.actidadesSolicitud.sort();
        this.form.actividadSolicitud = null
      }
    },
    "form.campania"(val){
      if(this.esInbound){
        var currentCampania = this.campaniasInbound.find(e=> e.campania == val)
        this.form.agente = currentCampania.agenteId
        this.form.productoSelected = currentCampania.productoId
        this.form.idOrigenLead = 1
        this.form.categoriaSelected = currentCampania.ramo
      }
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    updateFinVigencia() {
      if (!this.form.anios || !this.form.inicioVigenciaFormatted) return;
      const arrayDate = this.form.inicioVigenciaFormatted.split("/");
      if (arrayDate.length < 3) return;

      this.form.finVigenciaFormatted = `${arrayDate[0]}/${arrayDate[1]}/${Number(arrayDate[2]) + Number(this.form.anios)}`;
    },
    sendSolicitud() {
      console.log("Entre aqui ")

      if (!!["AUTOS", "DAÑOS", "SALUD", "VIDA", "AHORRO", "FIANZA"].find(e => e == this.form.categoriaSelected) && (this.form.tipoSelected == 'Cotización' || this.form.tipoSelected == 'Emisión' || this.form.tipoSelected == 'Renovación') && !this.esInbound  && this.form.regimenSelected != null ) {
        // Se guarda desde el formulario del ramo
        this.$refs.formRamo.sendSolicitud();
        return;
      }

      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      var campania_id = {};
      if(!this.esInbound)
          campania_id = this.campaniasFiltradas.find(e => e.producto == this.form.campania);
      else
          campania_id = this.campaniasInbound.find(e => e.campania == this.form.campania);

      if(this.archivos.length > 0 && this.esInbound) {
          this.form.solicitudCompletaSelected = 'Si'
          this.form.checkPendienteInfo = false
        }else if(this.esInbound){
          this.form.solicitudCompletaSelected = 'No'
          this.form.checkPendienteInfo = true
          this.form.causaPendienteInformacion = 'Falta cargar INE como documento anexado'
        }

      let request = {
        origenSolicitudSelected: this.form.origenSolicitudSelected? this.form.origenSolicitudSelected : "",
        tipoSelected: this.form.tipoSelected ? this.form.tipoSelected : "",
        detalle: this.form.detalle ? this.form.detalle : "",
        campania_id: campania_id ? campania_id.id : null,       
        categoriaSelected: this.form.categoriaSelected ? this.form.categoriaSelected : "",
        productoId: this.form.productoSelected ? this.form.productoSelected : "",
        agenteId: this.form.agente ? this.form.agente : "",
        idOrigenLead: this.form.idOrigenLead ? this.form.idOrigenLead : "",
        
        regimen_persona: this.form.regimenSelected ? this.form.regimenSelected : "",
        nombres: this.form.nombres ? this.form.nombres : "",
        apellidoPaterno: this.form.apellidoPaterno ? this.form.apellidoPaterno : "",
        apellidoMaterno: this.form.apellidoMaterno ? this.form.apellidoMaterno : "",
        fechaNacimiento:  this.form.fechaNacimiento
          ? moment(this.form.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        rfc: this.form.rfc ? this.form.rfc : "",
        selectedGenero: this.form.selectedGenero ? this.form.selectedGenero : "",

        telefono: this.form.celular ? this.form.celular : "", 
        correoElectronico: this.form.correoElectronico ? this.form.correoElectronico : "",
        numeroPoliza: this.form.numPolizas ? this.form.numPolizas : "",
        inicioVigencia: this.form.inicioVigenciaFormatted ? moment(this.form.inicioVigenciaFormatted, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ) : null,
        finVigencia: this.form.finVigenciaFormatted ? moment(this.form.finVigenciaFormatted, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ) : null,

        estado_civil: this.form.estadoCivilSelected ? this.form.estadoCivilSelected : '',
        operadorUuid: this.form.ejecutivo ? this.form.ejecutivo : "",
        razon_social: this.form.razonSocial ? this.form.razonSocial : '',
        estadoRepublicaSelected: this.form.estadoRepublicaSelected ? this.form.estadoRepublicaSelected : '',

        solicitudCompletaSelected: this.form.solicitudCompletaSelected
          ? this.form.solicitudCompletaSelected
          : "",
        procesoCotizacionSelected: this.form.procesoCotizacionSelected
          ? this.form.procesoCotizacionSelected
          : "",
        checkPendienteInfo: this.checkPendienteInfo,
        checkCancelacion: this.checkCancelacion,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        ...this.newFormProps,
        operador_id: this.emitDirect ? '703' : null,
        esInbound: this.esInbound,
      };



      if(this.esInbound){
        request.actividadSolicitud = this.form.actividadSolicitud;
      }

      mainAxios
        .post("v1/solicitud/insert/", request, config)
        .then((response) => {
          // console.log("Entro!", request);
          if (response.status == 200) {
            if(this.archivos.length > 0) {
              this.sendIne(response)
            }
            this.dialog = true;
          }
          setTimeout(() => this.$router.push("/solicitudes"), 2500);
          this.$toast.info("ID relacionado a la solicitud: " + response.data.id  , {
            position: "top-right",
            timeout: 10000,
            closeOnClick: false,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        // console.log("Agente: ", response.data);
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList  ,
            ramosList:element.ramosList ? element.ramosList : []
          });
          if (this.roles == "AGENT") {
            localStorage.agenteUuid == element.uuid
              ? (this.form.agente = element.id)
              : "";
          }
        });
        // this.agentes.map(user => {
        //   console.log("Que trae USER: ", user);
        // })
      });
    },
    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/directo/list", config).then((response) => {
        console.log(response.data);
        response.data.map((element) => {
          this.comercialAsignadoList.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid
          });
        });
      });
    },
    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agentes/operador", config).then((response) => {
        // console.log(response.data);
        response.data.map((element) => {
          this.ejecutivos.push({
            nombre:
              `${element.nombre}` +
              " " +
              `${element.apellidoPaterno}` +
              " " +
              `${element.apellidoMaterno ? element.apellidoMaterno : ""}`,
            id: element.id,
            uuid: element.uuid,
          });
          if (this.roles == "OPERADOR") {
            localStorage.agenteUuid == element.uuid
              ? (this.form.ejecutivo = element.uuid)
              : "";
          }
        });
      });
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.productos.push({
            nombre:
              `${element.ramo ? element.ramo.toUpperCase() : ""}` +
              " / " +
              `${element.nombre ? element.nombre.toUpperCase() : ""}` +
              " / " +
              `${
                element.nombreAseguradora
                  ? element.nombreAseguradora.toUpperCase()
                  : ""
              }`,
            id: element.id,
            canal: element.canal ? element.canal : 'Todos',
            ramo: element.ramo ? element.ramo : 'No disponible'
          });
        this.productoOrigal = this.productos;
        });
      });
    },
    obtenerLocalStorage() {
      const id = localStorage;
      console.log("LocalStorage: ", id);
    },
    agent() {
      if (this.roles == "AGENT") {
          this.isAgent = true;
      }
    },
    agentRoles(){
      if (this.roles == "AGENT" || this.roles == "OPERADOR" || this.roles == "MESACONTROL" || this.roles == "OPERACIONES" || this.roles == "ADMINAGENT" || this.roles == "MANAGER") {
          this.crearRoles = true;
      }
    },
    comportamientoBotonGuardar() {
      if(!this.esInbound){
       
          switch (this.form.tipoSelected) {
          case 'Cotización':
            return this.formIsValid
          case 'Emisión':
          case 'Renovación':
            return this.formIsValid2
          case 'Endoso A y D':
          case 'Endoso B':
          case 'Facturacion':
          case 'Cancelación':
          case 'Otro':
            console.log("Si entra: ", 
              this.form.agente ,
              this.form.tipoSelected ,
              this.form.categoriaSelected ,
              this.form.detalle ,
              // this.form.nombres ,
              // this.form.apellidoPaterno ,
              this.form.rfc != null
            
            )
            return this.formIsValid3
          default:
            break;
        }
      }else{
        return this.formIsValidInbound;
      }
    },
    esDirecto() {
      this.agentes.map( element => {
        let elemento = JSON.parse(JSON.stringify(element))
        let canalList = elemento.canalList
        if(element.id == this.form.agente) {
          canalList.map(elm => {
            if (elm.id == 3) {
              this.esAgentDirecto = true
            } else {
              this.esAgentDirecto = false
            }
          })
        }
      })
    },
    filtrarProductos(){
      if(this.form.agente != null  && this.form.categoriaSelected != null && !this.esInbound){
        let agenteSelected = this.agentes.find(element => element.id === this.form.agente)
        let currentCanalesName = []
        let isOnlyMasivos = false

        if(agenteSelected.canalList.length == 1 && agenteSelected.canalList[0].canal == 'MASIVOS'){
          isOnlyMasivos = true
        }

        agenteSelected.canalList.map((element) => {
          currentCanalesName.push(element.canal)
        })
        
        this.productos = []
        this.form.productoSelected = null
        this.productoOrigal.map((element) => {
           if(element.canal == "Todos" && !isOnlyMasivos){
             if(element.ramo == this.form.categoriaSelected){
              this.productos.push({
                nombre: element.nombre,
                id: element.id,
                canal: element.canal ? element.canal : 'Todos',
                ramo: element.ramo ? element.ramo : 'No disponible'
              });
             }
           }else{
            let result = currentCanalesName.indexOf(element.canal)
            if(result != '-1'){
              if(element.ramo == this.form.categoriaSelected){
              this.productos.push({
                nombre: element.nombre,
                id: element.id,
                canal: element.canal ? element.canal : 'Todos',
                ramo: element.ramo ? element.ramo : 'No disponible'
              });
             }
            }
           }
         })
      }
    },
    calculateRamos(){
      if(!this.esInbound){
        let agenteSelected = this.agentes.find(element => element.id === this.form.agente)
        let currentRamosName = []
        agenteSelected.ramosList.map((element) => {
          currentRamosName.push(element.ramo)
        })
        this.categoria = currentRamosName;
      }
    },
    actualizarRfc() {
      if (
        !this.form.nombres ||
        !this.form.apellidoPaterno ||
        !this.form.apellidoMaterno ||
        !this.form.fechaNacimiento
      )
        return;
      const arregloNacimiento = this.form.fechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.form.nombres,
        firstLastName: this.form.apellidoPaterno,
        secondLastName: this.form.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.form.rfc = rfc.substring(0, rfc.length - 3);
    },
    calcularEdad() {
    const arregloNacimiento = this.form.fechaNacimiento
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
    const dateFNacimiento = new Date(`${arregloNacimiento[2]}-${arregloNacimiento[1]}-${arregloNacimiento[0]}`)
    const diferenciaMS = Date.now() - dateFNacimiento.getTime();
    const edadDiferencia = new Date(diferenciaMS); 
  
    const edad = Math.abs(edadDiferencia.getUTCFullYear() - 1970);
    if (isNaN(edad)) return;
    this.form.edad = edad;
   },
  
   validarDominioComun,
   filtroCorreo(item, queryText, itemText) {
    this.busquedaCorreo = queryText;
    return itemText.includes(queryText)
    },
    getCampanias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then(({ data }) => {
          this.campanias = data;
          this.loading = false;
        });
    },
    calculateProducto(){
      if(this.form.agente != null && this.form.categoriaSelected != null && !this.esInbound){
          const agente = this.agentes.find((e) => e.id == this.form.agente)
          const ramo = this.categoria.find((e) => e == this.form.categoriaSelected)
          var canal = agente.canalList[0].canal 
          var campaña = this.campaniasFiltradas.find((e) => e.canal == canal && e.ramo == ramo)
          this.form.campania = campaña ? campaña.producto : this.campaniasFiltradas[0].producto
          this.form.idOrigenLead = 1
      }
    },
    getTipoSolitud(){
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/tipoSolicitud/list", config).then((response) => {
        this.tiposSolicitudCompletos = []
        this.tiposSolicitud = [];
        if(this.roles.includes('INBOUND'))
          this.tiposSolicitudCompletos = response.data.filter((e => e.inbound == '1'))
        else
          this.tiposSolicitudCompletos = response.data.filter((e => e.inbound == '0'))

        this.tiposSolicitudCompletos.map((e) =>{
          this.tiposSolicitud.push(e.tipo)
        });
        this.tiposSolicitud = this.tiposSolicitud.filter(function(valor, indice, arreglo) {
          return arreglo.indexOf(valor) === indice;
        });
        this.tiposSolicitud.sort();
      });
    },
    getCampaniasInbound(){
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/campaniasInboundEci/list", config).then((response) => {
        this.campaniasInbound = response.data
          this.campaniasInbound.sort(function(a, b) {
            var campaniaA = a.campania.toUpperCase();
            var campaniaB = b.campania.toUpperCase();
            if (campaniaA < campaniaB) {
                return -1;
            }
            if (campaniaA > campaniaB) {
                return 1;
            }
            return 0;
        });
      });
    },
    sendIne(e){
      const config = {
          headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
              'Content-Type': 'multipart/form-data'
          },
        };
        const formData = new FormData();
        for( var i = 0; i < this.archivos.length; i++ ){
            let file = this.archivos[i];
            formData.append('file', file);
        }
        formData.append('nota', 'INE');
        formData.append('tipo', 'Nota');
        formData.append('tipo_documento', 'INE');
        formData.append('nombre', 'INE');
        mainAxios.post(`/v1/solicitud/documento/upload/${e.data.id}`, formData,config)
          .then(function (result) {
              console.log(result);
         
          }, function (error) {
              console.log(error);
          });
    }
  },
  computed: {
    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [''];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map(e =>
      `${this.busquedaCorreo}@${e}`
      )
    },
    emitDirect(){
      if(this.roles == 'ADMINCC'   ||
        this.roles == 'MANAGERCC'  ||
        this.roles == 'ADMIN'  ||
        this.roles == 'OPERADORCC' ||
        this.roles == 'AGENTECC'   ||
        this.roles == 'AGENTECCSPONSOR'){
        return true;
      }else
        return false;
    },
    computedDateFormattedMomentjs () {
      return this.date ? moment(this.date).format('DD/MM/YYYY') : ''
    },
    computedDateInicioVigenciaFormattedMomentjs () {
      return this.form.inicioVigenciaFormatted ? moment(this.form.inicioVigenciaFormatted).format('DD/MM/YYYY') : ''
    },
    computedDateFinVigenciaFormattedMomentjs () {
      return this.form.finVigenciaFormatted ? moment(this.form.finVigenciaFormatted).format('DD/MM/YYYY') : ''
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    //ESTE FUNCIONA PARA TIPO "COTIZACIÓN"
    formIsValid () {
      if (this.roles == 'AGENT') {
        return (
          this.form.agente != null &&
          this.form.tipoSelected != null &&
          this.form.categoriaSelected != null &&
          this.form.inicioVigenciaFormatted != null &&
          this.form.finVigenciaFormatted != null &&
          this.form.periodicidadSelected != null &&
          // this.form.nombres != null &&
          // this.form.apellidoPaterno != null &&
          // this.form.fechaNacimiento != null &&
          this.form.regimenSelected != null &&
          // this.form.selectedGenero != null &&
          // this.form.estadoCivilSelected != null &&
          this.form.rfc != null &&
          this.form.celular != null &&
          this.form.correoElectronico != null &&
          this.form.estadoRepublicaSelected != null &&
          this.form.codigoPostal != null 
          // this.form.productoSelected
        )
      }
      return (
        this.form.agente != null &&
        this.form.tipoSelected != null &&
        this.form.categoriaSelected != null &&
        this.form.inicioVigenciaFormatted != null &&
        this.form.finVigenciaFormatted != null &&
        this.form.periodicidadSelected != null &&
        // this.form.nombres != null &&
        // this.form.apellidoPaterno != null &&
        // this.form.fechaNacimiento != null &&
        this.form.regimenSelected != null &&
        // this.form.selectedGenero != null &&
        // this.form.estadoCivilSelected != null &&
        this.form.rfc != null &&
        this.form.celular != null &&
        this.form.correoElectronico != null &&
        this.form.estadoRepublicaSelected != null &&
        this.form.codigoPostal != null &&
        this.form.productoSelected
      )
    },
    //ESTE FUNCIONA PARA TIPO "EMISIÓN" Y "RENOVACIÓN"
    formIsValid2 () {
      if (this.roles == 'AGENT') {
        return (
          this.form.agente != null &&
          this.form.tipoSelected != null &&
          this.form.categoriaSelected != null &&
          this.form.inicioVigenciaFormatted != null &&
          this.form.finVigenciaFormatted != null &&
          this.form.periodicidadSelected != null &&
          this.form.formaPagoSelected != null &&
          // this.form.nombres != null &&
          // this.form.apellidoPaterno != null &&
          // this.form.fechaNacimiento != null &&
          this.form.regimenSelected != null &&
          // this.form.selectedGenero != null &&
          // this.form.estadoCivilSelected != null &&
          this.form.rfc != null &&
          this.form.celular != null &&
          this.form.correoElectronico != null &&
          this.form.estadoRepublicaSelected != null &&
          this.form.codigoPostal != null 
          // this.form.productoSelected
        )
      }
      return (
        this.form.agente != null &&
        this.form.tipoSelected != null &&
        this.form.categoriaSelected != null &&
        this.form.inicioVigenciaFormatted != null &&
        this.form.finVigenciaFormatted != null &&
        this.form.periodicidadSelected != null &&
        this.form.formaPagoSelected != null &&
        // this.form.nombres != null &&
        // this.form.apellidoPaterno != null &&
        // this.form.fechaNacimiento != null &&
        this.form.regimenSelected != null &&
        // this.form.selectedGenero != null &&
        // this.form.estadoCivilSelected != null &&
        this.form.rfc != null &&
        this.form.celular != null &&
        this.form.correoElectronico != null &&
        this.form.estadoRepublicaSelected != null &&
        this.form.codigoPostal != null &&
        this.form.productoSelected
      )
    },
    //ESTE ES PARA LOS TIPOS QUE NO SON "COTIZACIÓN", "EMISIÓN" O "RENOVACIÓN"
    formIsValid3 () {
      return (
        this.form.agente != null &&
        this.form.tipoSelected != null &&
        this.form.categoriaSelected != null &&
        this.form.detalle != null &&
        // this.form.nombres != null &&
        // this.form.apellidoPaterno != null &&
        this.form.rfc != null
      )
    },
    formIsValidInbound () {
      return (
            //Valdacion de datos de la solicitud
            this.estadoSelected != null &&
            this.form.origenSolicitudSelected != null &&
            this.form.tipoSelected != null &&
            this.form.actividadSolicitud != null &&
            this.form.detalle != null &&
            this.form.campania != null &&
            this.form.categoriaSelected != null &&
            this.form.productoSelected != null &&
            this.form.agente != null &&
            this.form.idOrigenLead != null &&
            //Datos personales del cliente
            this.form.regimenSelected != null &&
            this.form.nombres != null &&
            this.form.apellidoMaterno != null &&
            this.form.apellidoPaterno != null &&
            this.form.fechaNacimiento != null &&
            this.form.rfc != null &&
            this.form.selectedGenero != null &&
            this.form.edad != null &&
            this.form.estadoCivilSelected != null &&
            this.form.celular != null &&
            this.form.inicioVigenciaFormatted != null &&
            this.form.finVigenciaFormatted != null &&
            //Validacion domicilio del cliente
            this.form.codigoPostal != null &&
            this.form.calle != null &&
            this.form.num_exterior != null &&
            this.form.colonia != null &&
            this.form.municipio != null &&
            this.form.estadoRepublicaSelected != null
        )
    },
    campaniasFiltradas() {
      if (!this.form.categoriaSelected) return [];

      let result = [];
      result = this.campanias.filter(
        (e) => e.ramo == this.form.categoriaSelected
      );

      result = result.filter((e, index) => {
        return result.findIndex((z) => z.producto == e.producto) == index;
      });

      return result;
    },
    newFormProps() {
      return {
        edad: this.form.edad,
        codigoPostal: this.form.codigoPostal,
        calle: this.form.calle,
        numInterior: this.form.num_interior,
        numExterior: this.form.num_exterior,
        colonia: this.form.colonia,
        municipio: this.form.municipio,
        anios: this.form.anios,
        idOrigenLead: this.form.idOrigenLead,
        campania: this.form.campania
      }
    },
    origenSolicitud(){
      if (this.esInbound){
        return [
          'Correo electrónico',
          'Llamada telefónica entrada',
          'Llamada telefónica Salida',
          'Whatsapp',
          'Redes sociales',
        ];
      }else{
        return [
          "Correo electrónico",
          "Llamada telefónica",
          "Presencial",
          "Whatsapp",
          "Portal",
          "Otro"
        ]
      }
    },
  },
  mounted() {
    this.esInbound = this.roles.includes('INBOUND')
    this.getCampanias();
    this.obtenerAgentes(),
    this.obtenerEjecutivos(),
    this.obtenerProductos(),
    this.obtenerLocalStorage(),
    this.agent(),
    this.obtenerUsersCanalDirecto()
    this.agentRoles(),
    this.getTipoSolitud(),
    this.getCampaniasInbound()
    this.form.inicioVigenciaFormatted = moment(new Date()).format('DD/MM/YYYY');
  },
};
</script>
