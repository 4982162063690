<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Historial de modificaciones
      </template>
      <ViewPolizaLogCard :polizaInfo="polizaInfo"/>
    </CommonExpansionPanel>
  </div>
</template>

<script>
 import moment from "moment";
// import { mainAxios } from "@/mainAxios.js";
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewPolizaLogCard from "./ViewPolizaLogCard.vue"
export default {
  components: {
    CommonExpansionPanel,
    ViewPolizaLogCard
  },
  props:{
    polizaInfo:{
      type: Object,
      default: () => {},
    },
    productos:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
    };
  },
  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
    getFormattDate(date){
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
    },
    getFormattHour(date){
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
    }
  },
};
</script>
