<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="6" xl="6">
        <v-select
          label="Adjuntar Contrato"
          v-model="polizaData.contratoAdjunto"
          :items="contratoAdjuntoList"
          outlined
          color="#00a7e4"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('goToSave', polizaData)"
        >
          <span>
            Guardar
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  data() {
    return {
      polizaData: {
        contratoAdjunto: null,
      },

      //listas
      contratoAdjuntoList: ["Si", "No"],

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
    };
  },
};
</script>
