<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="3"
        v-for="(nombre, idx) in nombres"
        :key="idx"
        :class="{'hidden-column': valores[idx] <= 0}"
      >
        <v-card
          class="mx-auto"
          color="#F2FAFC"
          width="100%"
          style="border-radius: 20px;"
          elevation="2"
          v-if="valores[idx] > 0"
        >
          <v-card-text>
            <div style="height: 50px !important;">
              <h5 style="color: #039ECC;">{{ nombre }}</h5>
              <h2 style="color: #039ECC;">
                {{ valores[idx] }}
              </h2>
            </div>
          </v-card-text>
          <v-card-text> </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    cotizaciones: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dataOrdenado: {},
      nombres:[],
      valores:[],

    };
  },

  watch: {
    cotizaciones() {
      this.makeInfoCards();
    },
  },
  mounted(){
    this.makeInfoCards();
  },
  methods: {
    makeInfoCards(){
      const dataArray = Object.entries(this.cotizaciones);
      dataArray.sort((a, b) => b[1] - a[1]);
      const sortedJsonData = Object.fromEntries(dataArray);
      this.nombres = Object.keys(sortedJsonData);
      this.valores = Object.values(sortedJsonData);
    }
  },
};
</script>

<style scoped>
.hidden-column {
  display: none;
}
</style>
