<template>
  <v-row>
    <v-col cols="12">
      <common-card>
        <v-container>
          <v-row>
            <v-col cols="3" md="1" class="text-center">
              <img
                src="https://segurify-data.s3.amazonaws.com/componentes_ux_ui/Vector.png"
                alt=""
                height="50"
              />
            </v-col>
            <v-col cols="9">
              <h2>Cotiza Seguro de celular</h2>
              <span>
                Encuentra tu seguro ideal
              </span>
            </v-col>
            <v-col cols="12">
              <h3 align="center">Llena los siguientes datos</h3>
            </v-col>
            <formulario-celular
              :phone="phone"
              :states="states"
              @createPrice="createPrice"
            />

            <v-col cols="12" v-if="item.price > 0">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <h2 class="mb-2">Escoge la aseguradora de preferencia</h2>
                  </v-col>
                  <v-col cols="12" md="112" class="ml-1 ml-md-2">
                    <common-card-cotizacion
                      :item="item"
                      @goConfirmacion="goConfirmacion"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center justify-md-end p-0 mt-3"
                >
                  <v-col cols="3" md="2" lg="1">
                    <v-btn
                      block
                      dark
                      class="rounded-xl"
                      color="transparent"
                      icon
                    >
                      <v-row>
                        <v-col class="mb-20" cols="12">
                          <v-icon
                            class="d-inline d-md-none"
                            color="#029ECF"
                            left
                            size="x-small"
                          >
                            mdi-content-save
                          </v-icon>
                          <v-icon
                            class="d-none d-md-inline"
                            color="#029ECF"
                            left
                          >
                            mdi-content-save
                          </v-icon>
                        </v-col>
                        <v-col class="text-center" cols="12">
                          <span
                            class="d-inline d-md-none"
                            style="color: #029ECF!important; font-size: 0.6em;"
                            >Guardar</span
                          >
                          <span
                            class="d-none d-md-inline"
                            style="color: #029ECF!important"
                            >Guardar</span
                          >
                        </v-col>
                      </v-row>
                    </v-btn>
                  </v-col>
                  <v-col cols="3" md="2" lg="1">
                    <v-btn
                      block
                      dark
                      class="rounded-xl"
                      color="transparent"
                      icon
                    >
                      <v-row>
                        <v-col class="mb-20" cols="12">
                          <v-icon
                            class="d-inline d-md-none"
                            color="#029ECF"
                            left
                            size="x-small"
                          >
                            mdi-cloud-download
                          </v-icon>
                          <v-icon
                            class="d-none d-md-inline"
                            color="#029ECF"
                            left
                          >
                            mdi-cloud-download
                          </v-icon>
                        </v-col>
                        <v-col class="text-center" cols="12">
                          <span
                            class="d-inline d-md-none"
                            style="color: #029ECF!important; font-size: 0.6em;"
                            >Descargar</span
                          >
                          <span
                            class="d-none d-md-inline"
                            style="color: #029ECF!important"
                            >Descargar</span
                          >
                        </v-col>
                      </v-row>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="3" lg="3" xl="2">
                    <v-btn
                      style="widht: 100%; padding: 6px!important"
                      color="#00A7E4"
                      block
                      dark
                      class="rounded-xl"
                      @click="goConfirmacion"
                    >
                      <span
                        class="d-inline d-md-none"
                        style="font-size: 0.5em;"
                      >
                        Envíar cotización</span
                      >
                      <span class="d-none d-md-inline"> Envíar cotización</span>
                      <v-icon
                        class="d-inline d-md-none ml-1 "
                        left
                        size="x-small"
                      >
                        mdi-send
                      </v-icon>
                      <v-icon class="d-none d-md-inline ml-2 mb-9" left>
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </common-card>
    </v-col>
  </v-row>
</template>
<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import FormularioCelular from "./FormularioCelular.vue";
import { mainAxios } from "@/mainAxios.js";
import CommonCardCotizacion from "../../../../components/commonComponents/CommonCardCotizacion.vue";

export default {
  name: "CotizacionCelular",
  components: { FormularioCelular, CommonCard, CommonCardCotizacion },
  data() {
    return {
      item: {
        price: 0,
        model: "",
        invoiceAmount: 0,
        name: "",
        surname: "",
        lastname: "",
        phone: "",
        email: "",
        cotizacionGeneral: "",
      },
      phone: {
        name: "",
        surname: "",
        lastname: "",
        birthday: "",
        numberPhone: "",
        invoiceAmount: 0,
        brand: "",
        model: "",
        purchaseDate: "",
        sex: "",
        cp: "",
        state: "",
        townHall: "",
        colony: "",
        steet: "",
        numberE: "",
        numberI: "",
        email: "",
        optionPay: "",
        termsAndConditions: false,
        cotizacion: 0,
        request: "",
        response: "",
        totalPrecioAseguradora: 0,
        uuid: "",
      },
      states: [],
      token: "",
    };
  },
  methods: {
    getStates() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/catalogo/estado", config).then((response) => {
        this.states = response.data;
      });
    },
    goConfirmacion() {
      this.$router.push({
        name: "ConfirmacionDatosCelular",
        params: { phone: this.phone },
      });
    },
    getToken() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/seguro-celular/prueba", config).then((response) => {
        this.token = response.data;
      });
    },
    createPrice() {
      this.getToken();
      const data = {
        modelo: this.phone.model,
        precio_factura: this.phone.invoiceAmount,
        nombre: this.phone.name,
        apellido_paterno: this.phone.surname,
        apellido_materno: this.phone.lastname,
        telefono: this.phone.numberPhone,
        email: this.phone.email,
        codigo_postal: this.phone.cp,
        estado_id: Number(this.phone.state),
        portal: "Agentes",
        partner: "Segurify",
        agente_uuid: localStorage.agenteUuid,
        producto: "236",
        periodicity: 12,
        pago_id: Number(this.phone.optionPay),
      };
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post("/v1/seguro-celular/cotiza", data, config)
        .then(({ data }) => {
          this.item.model = data.modelo;
          this.item.price = data.total;
          this.item.cotizacionGeneral = data.cotizacionGeneral;
          this.item.email = data.email;
          this.item.name = data.nombre;
          this.item.surname = data.apellidoPaterno;
          this.item.lastname = data.apellidoMaterno;
          this.item.phone = data.telefono;
          this.item.invoiceAmount = data.precioFactura;
          this.phone.request = data.request;
          this.phone.response = data.response;
          this.phone.totalPrecioAseguradora = data.total;
          this.phone.uuid = data.uuid;
        });
    },
  },
  created() {
    if (sessionStorage.getItem("phone")) {
      this.phone = JSON.parse(sessionStorage.getItem("phone"));
    }
  },
  mounted() {
    this.getStates();
  },
};
</script>
<style scoped>
.mb-32 {
  margin-bottom: -32px;
}
.gray {
  color: #697077;
}
.mb-20 {
  margin-bottom: -20px;
}
.mb-9 {
  margin-top: 25px;
}
</style>
