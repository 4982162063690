<template>
  <div>
    <div>
      <v-row class="pt-5 pb-5">
        <v-col cols="10">
          <span class="titulo-header">
            Detalle de póliza
          </span>
        </v-col>
        <v-col cols="2" style=" text-align: end;">
          <h2>#{{ polizaId }}</h2>
        </v-col>
      </v-row>
    </div>
    <info-card-solicitudes
      :infoCards="infoCards"
      :emailRoutesConfig="emailRoutesConfig"
      @getData="updateView"
      @toCuotas="tabReplaceSelect(3)"
    >
    </info-card-solicitudes>
    <div>
      <v-row style="padding-top: 60px;">
        <v-col
          :cols="cuotas.length > 0 ? 4 : 6"
          :class="
            tabReplace == 1
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(1)"
        >
          <span
            :class="
              tabReplace == 1
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >
            Póliza</span
          >
        </v-col>
        <v-col
          :cols="cuotas.length > 0 ? 4 : 6"
          :class="
            tabReplace == 2
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(2)"
        >
          <span
            :class="
              tabReplace == 2
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >
            Cliente
          </span>
        </v-col>
        <v-col
          v-if="cuotas.length > 0"
          :cols="cuotas.length > 0 ? 4 : 6"
          :class="
            tabReplace == 3
              ? 'text-center active-color'
              : 'text-center inactive-color'
          "
          @click="tabReplaceSelect(3)"
        >
          <span
            :class="
              tabReplace == 3
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >
            Cuotas</span
          >
        </v-col>
      </v-row>
    </div>
    <div style="padding-top: 60px;">
      <div v-if="tabReplace == 1">
        <PolizaInfoIndex
          @getData="updateView"
          :polizaInfo="normalPolizaData"
          :productos="productos"
          :customData="polizaInfo"
          :ramoData="ramoData"
        >
        </PolizaInfoIndex>
      </div>
      <div v-if="tabReplace == 2">
        <div>
          <ClienteSolicitudIndex
            :polizaInfo="normalPolizaData"
            @getData="updateView"
          ></ClienteSolicitudIndex>
        </div>
        <div>
          <EmbeddedCliente
            v-if="rol == 'MESACONTROLINBOUND' || rol == 'OPERADORINBOUND' || rol == 'OPERACIONESINBOUND' || rol == 'ADMIN'"
            :telefonos="telefonosCliente"
            :cliente_id="polizaInfo.cliente"
            :is_poliza="true"
            @success="updateView"
          />
        </div>
      </div>
      <div v-if="tabReplace == 3">
        <div>
          <DataCuotasIndex :cuotas="cuotas"></DataCuotasIndex>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mainAxios } from "@/mainAxios.js";
import InfoCardSolicitudes from "./InfoCardSolicitudes.vue";
import PolizaInfoIndex from "./PolizasData/PolizaInfo/PolizaInfoIndex.vue";
import DataCuotasIndex from "../Details/cuotasData/DataCuotasIndex.vue";
import ClienteSolicitudIndex from "../Details/ClienteData/ClienteSolicitudIndex.vue";

export default {
  components: {
    InfoCardSolicitudes,
    PolizaInfoIndex,
    DataCuotasIndex,
    ClienteSolicitudIndex,
    EmbeddedCliente:()=>import('@/components/commonComponents//EmbeddedCliente.vue')
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
      infoCards: {
        docs: [],
        notas: [],
        emails: [],
        cuotas:[]
      },
      polizaInfo: {},
      productos: [],
      emailRoutesConfig: {
        sendEmailRoute: "",
        action: "",
        optionCorreosList: [],
        destinatario: "",
      },
      cuotas: [],
      normalPolizaData: {
        agente: null,
        anios: null,
        aseguradora_id: null,
        campania_id: null,
        categoriaSelected: null,
        fechaPrimerPago: null,
        fin: null,
        forma_pago: null,
        inicio: null,
        leadId: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        notas: null,
        numeroCuotas: null,
        poliza: null,
        status: null,
        producto_id: null,
        operador_id: null,
        cotizacion: null,
        tipo_poliza: null,
        campania: null,
        pago_id: null,
        prima_neta: null,
        prima_total: null,
        primer_pago: null,
        subsecuente_pago: null,
        tipo_persona: null,
        razon_social: null,
        rfc: null,
        nombre: null,
        fecha_nacimiento: null,
        edad: null,
        apellido_paterno: null,
        apellido_materno: null,
        genero: null,
        statusCivil: null,
        telefono: null,
        correo_electronico: null,
        codigo_postal: null,
        estado_id: null,
        ciudad: null,
        calle: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        nombre_cliente: null,
        cliente:null,
        solicitudId:null,
        cotizacionGeneral: null,
      },
      ramoData: {},
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      usuario:{},
      telefonosCliente:[]
    };
  },
  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
    getPoliza() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/emision/id/" + this.polizaId, config).then((value) => {
        this.polizaInfo = value.data;
        this.telefonosCliente.push(this.polizaInfo.telefonoContacto)
        this.getUpdateJson(JSON.parse(value.data.requestJson));
        if (value.data.documentos) {
          this.formatNotasDocumentos(value.data.documentos);
        }
        if (value.data.correos) {
          this.formatCorreosInfoCard(value.data.correos);
        }
        this.getCuotaEmision();
        if (value.data.agenteId) {
          this.getAgente(value.data.agenteId);
        }
      });
    },
    getAgente(agente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/id/${agente}`, config).then((response) => {
        this.polizaInfo = {
          ...this.polizaInfo,
          agentName:
            `${response.data.nombre}` +
            " " +
            `${response.data.apellidoPaterno}` +
            " " +
            `${response.data.apellidoMaterno}`,
          agentInfo: response.data,
        };
      });
    },
    formatCorreosInfoCard(correos) {
      this.infoCards.emails = [];
      correos.map((e) => {
        this.infoCards.emails.push({
          showInfo:
            e.subject +
            +" " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY") +
            " " +
            e.subject.slice(0, 10) +
            " ... " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("HH:mm"),
          dateFormat: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm"),
          Onlyfecha: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY"),
          hora: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("HH:mm"),
          url_documento:
            e.documentos.length > 0 ? e.documentos[0].url_bucket : false,
          ...e,
        });
      });
    },
    calculateRoutesEmails() {
      this.emailRoutesConfig.sendEmailRoute =
        "/v1/portal/emision/correo/enviar";
      this.emailRoutesConfig.action = "POST";
      this.emailRoutesConfig.optionCorreosList.push({correo: this.normalPolizaData.correo_electronico});
      this.emailRoutesConfig.remitente = localStorage.agenteMail;
      this.emailRoutesConfig.poliza = this.polizaInfo.id;
    },
    getNumeroCuotas() {
      const meses = this.polizaInfo.anios * 12;
      switch (this.polizaInfo.pagoId) {
        case 1:
          this.polizaInfo.numeroCuotas = meses / 12;
          break;
        case 2:
          this.polizaInfo.numeroCuotas = meses / 6;
          break;
        case 3:
          this.polizaInfo.numeroCuotas = meses / 3;
          break;
        case 4:
          this.polizaInfo.numeroCuotas = meses / 1;
          break;
        default:
          break;
      }
    },
    getProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.productos.push({
            nombre:
              `${element.ramo}` +
              " / " +
              `${element.nombre}` +
              " / " +
              `${element.nombreAseguradora}`,
            id: element.id,
            ramo: element.ramo,
          });
        });
      });
    },
    formatNotasDocumentos(docs) {
      this.infoCards.notas = [];
      this.infoCards.docs = [];
      docs.map((e) => {
        if (e.tipoRegistro == "Nota" || e.tipoRegistro == "nota")
          this.infoCards.notas.push({
            formatData:
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY") +
              " " +
              e.detalle.slice(0, 10) +
              " ... " +
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("HH:mm"),
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            ...e,
          });
        else {
          this.infoCards.docs.push({
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            onlyDate: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY"),
            url: e.url_bucket,
            ...e,
          });
        }
      });
    },
    getCuotaEmision() {
      this.cuotas = [];
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/cuota/cuotaByEmisionId/" + this.polizaId, config)
        .then((response) => {
          this.cuotas = response.data;
          this.infoCards.cuotas = response.data;

        });
    },
    getUpdateJson(json) {
      var keys = Object.keys(json);
      console.log({json})
      keys.map((e) => {
        if (e in this.normalPolizaData) this.normalPolizaData[e] = json[e];
        else this.ramoData[e] = json[e];
      });
      this.normalPolizaData.id = this.polizaInfo.id;
      this.ramoData.id = this.polizaInfo.id;
      this.ramoData.status = this.polizaInfo.status;
      this.normalPolizaData.status = this.polizaInfo.status;
      this.normalPolizaData.solicitudId = this.polizaInfo.solicitudId;
      this.normalPolizaData.operador_id = this.polizaInfo.operadorId;
      this.normalPolizaData.cotizacionGeneral = this.polizaInfo.cotizacionGeneral;
      this.infoCards.pago_id = json.pago_id ? json.pago_id.toString() : null;
      this.calculateRoutesEmails();
      if(typeof this.polizaInfo.cliente != 'undefined'){
        this.normalPolizaData.cliente = this.polizaInfo.cliente 
      }
    },
    updateView(){
      location.reload();
    },
    getAgenteLogeado(){
      const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        mainAxios.get(`/v1/agente/id/${localStorage.id}`, config).then((response) => {
          this.usuario = response.data
        });
    }
  },
  mounted() {
    this.getPoliza(), this.getProductos(), this.getAgenteLogeado();
  },
  computed:{
    isAsesorDigital(){
      if(this.rol == 'AGENT'){
        return true 
      }else
        return false
    }
  }
};
</script>

<style></style>
