<template>
  <v-dialog v-model="dialog" persistent max-width="400" > 
    <ProfileCard :esAgente="esAgente" :correo="correo" :isDialog="true" @closeMenuModal="closeMenuModal"> </ProfileCard>
  </v-dialog>
</template>

<script>
import ProfileCard from "../../../../src/views/pages/perfiles/components/profileData/ProfileCard.vue"

export default {
  components: {
    ProfileCard
  },
  props: {
    dialog: Boolean,
    esAgente: Boolean,
    correo: String
  },

  data() {
    return {};
  },
  methods: {
    userChoice(val) {
      this.$emit("userChoice", val);
    },
    closeMenuModal(){
      this.$emit('closeMenu')
    }
  },
};
</script>

<style>
.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text{
width: 369px;
height: 22px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 110%;
display: flex;
align-items: center;
text-align: center;
color: #4D5358;
flex: none;
order: 0;
flex-grow: 1;
}


.confirmation-text{
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

width: 369px;
height: 30px;

/* Subtitle/S */
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 110%;
/* or 15px */
text-align: center;

color: #555555;


/* Inside auto layout */
flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;

}

</style>
