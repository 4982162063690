<template>
  <v-row style="margin-top: 1px;">
    <v-col cols="12" v-if="rol != 'AGENTPARTNER'">
      <v-row>
        <cotizacionesHUb v-if="rol != 'AGENTCCSPONSORS' && globalConfig.showQuotesDashboard"></cotizacionesHUb>
        <!-- comparador -->
        <solicitudHUB></solicitudHUB>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import cotizacionesHUb from "../../components/homeComponents/cotizacionesHUB.vue";
import solicitudHUB from "../../components/homeComponents/solicitudHUB.vue";
export default {
  components: { cotizacionesHUb, solicitudHUB },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
    };
  },
  methods: {
    
  },
  computed: {
  },
  mounted() {

  },
};
</script>
