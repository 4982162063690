<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="12" style="text-align: center">
          <h1>Comparadores de seguros</h1>
        </v-col>

        <v-col cols="12" md="12" sm="12" xs="12">
          <v-row class="main-container">
            <!-- comparador Autos -->
            <v-col xl="2" md="4" sm="6">
              <v-card class="cardsHome flex-column " height="100%">
                <v-card-title>
                  <v-icon large left>
                    mdi-car-multiple
                  </v-icon>
                  <span class="text-subtitle-2 font-weight-light"
                    >Seguro de Autos</span
                  >
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold">
                  Comparador de seguro de auto en línea
                </v-card-text>

                <v-card-actions class="cardAccionHome">
                  <v-list-item class="grow justify-end">
                    <v-btn rounded color="info" @click="verCotizaciones()">
                      Cotizar
                    </v-btn>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-col>

            <!-- comparador Seguro celular -->
            <v-col
              xl="2"
              md="4"
              sm="6"
              v-if="rol != 'AGENT' || rol != 'AGENTCC'"
            >
              <v-card
                class="cardsHome d-flex flex-column justify-space-between"
                height="100%"
              >
                <v-card-title>
                  <v-icon large left>
                    mdi-cellphone
                  </v-icon>
                  <span class="text-subtitle-2 font-weight-light"
                    >Seguro Celulares</span
                  >
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold">
                  Cotizador de Seguro Celular
                </v-card-text>

                <v-card-actions class="cardAccionHome">
                  <v-list-item class="grow justify-end">
                    <v-btn rounded color="info" @click="verSeguroCelular()">
                      Cotizar
                    </v-btn>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      banner: 0,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  methods: {
    verCotizaciones() {
      this.$router.push("/cotiza-hub");
    },
    verSeguroCelular() {
      this.$router.push("/landing-celular");
    },
  },
};
</script>
<style scoped>
.main-container {
  justify-content: center;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
.cardsHome {
  border: 1px solid transparent;
  border-radius: 10px;
  transition: 0.3s ease-out;
}
.cardsHome:hover {
  border: 1px solid #133b7d;
  transition: all 0.3s ease-out;
}
.cardAccionHome {
  transition: all 0.3s ease-out;
}
.cardsHome:hover .cardAccionHome {
  transition: all 0.3s ease-out;
  background: #133b7d;
}
.cursor {
  cursor: pointer;
}
.cardsHome:hover:before {
  transform: scale(6.15);
}
</style>
