<template>
  <div>
    <PolizaDataIndex
      @getData="$emit('getData')"
      :polizaInfo="polizaInfo"
      :productos="productos"
    />
    <PolizaPaymentData
      @getData="$emit('getData')"
      :polizaInfo="polizaInfo"
      :productos="productos"
    />
    <PolizaDatosGenerales
      @getData="$emit('getData')"
      :polizaInfo="polizaInfo"
      :productos="productos"
      :ramoData="ramoData"
    />
    <IndexPolizaLog
      :polizaInfo="customData"
      :productos="productos"
    />
  </div>
</template>

<script>
import PolizaDataIndex from "./PolizaData/PolizaDataIndex.vue";
import PolizaPaymentData from "./PolizaPaymentData/PolizaPaymentData.vue";
import IndexPolizaLog from "./PolizaLog/IndexPolizaLog.vue";
import PolizaDatosGenerales from "./PolizaDatosGenerales/PolizaDatosGenerales.vue";

export default {
  components: {
    PolizaDataIndex,
    PolizaPaymentData,
    IndexPolizaLog,
    PolizaDatosGenerales,
  },

  data() {
    return {};
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
    customData: {
      type: Object,
      default: () => [],
    },
  },
  methods: {},
};
</script>
